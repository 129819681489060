import React from "react";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { SOUL_MODULE_USAGE } from "modules/poc-dashboard/graphql/moduleusageQuery.gql";
import CommonTableView from "modules/poc-dashboard/components/common/tableView";
import { Capitalize, displayUtcToUserTimezone } from "modules/look";
import { usage_module } from "modules/poc-dashboard/icons";
import moment from "moment";


const SoulModuleUsageContainer =(props)=>{
    const {client,permission,date_range_filter,search}=props 
    
    const [data_list_loading,setDataListLoading] = React.useState(false)
    const [list,SetList] = React.useState([])
    const [page_info,SetPageInfo] = React.useState()
    const list_ref = React.useRef([])
    
    React.useEffect(()=>{
        list_ref.current = list
    },[list])

    React.useEffect(()=>{  
        ModuleUsage({})
    },[date_range_filter])

    const timeout_ref =React.useRef()

    React.useEffect(()=>{
        clearTimeout(timeout_ref.current)
        timeout_ref.current = setTimeout(()=>{
            ModuleUsage({})
        },600)
    },[search])

    const header_list =[
        {
            label:"Employee Name"
        },
        {
            label:"Values"
        },
        {
            label:"Strength"
        },
        {
            label:"Personality"
        },
        {
            label:"Knowledge & skills"
        },
        {
            label:"Impact narrative"
        },
        {
            label:"Last Accessed Date"
        }
    ]
    React.useEffect(()=>{
        if(permission?.required_permission){
            ModuleUsage({})
        }
    },[permission])

    const ModuleUsage=async(filter)=>{
        setDataListLoading(true)
        let responseDate_Range =[]
        if(date_range_filter && date_range_filter?.start_date && date_range_filter?.end_date){
            let start_date =date_range_filter?.start_date
            let end_date = date_range_filter?.end_date
            responseDate_Range=[start_date,end_date]
        }
        else{
            responseDate_Range=[]
        }
        const { data } = await client.query({
            query: SOUL_MODULE_USAGE,
            variables: {...filter,first:10,responseDate_Range,user_FirstName:search},
            fetchPolicy: 'network-only'
        });
        if (data?.allEmployees) { 
            let list = data?.allEmployees?.edges?.map(({node})=>{
                let date = []
                if(node?.user?.last_accessed?.edges[0]?.node?.responseDate){
                    date.push(node?.user?.last_accessed?.edges[0]?.node?.responseDate)
                }
                if(node?.user?.impact_narrative?.edges[0]?.node?.createdAt){
                    date.push(node?.user?.impact_narrative?.edges[0]?.node?.createdAt)
                }
                if(node?.user?.impact_narrative?.edges[0]?.node?.updatedAt){
                    date.push(node?.user?.impact_narrative?.edges[0]?.node?.updatedAt)
                }

                if(date?.length){ 
                    date = date?.sort((a,b)=>moment(b).unix()-moment(a).unix())
                }

                let last_accessed =  date?.length? displayUtcToUserTimezone( moment(date[0]), 'DD/MM/YYYY' ) :"-"
                let row_data = [
                    {
                        type:"label",
                        value:`${Capitalize(node?.user?.firstName)} ${Capitalize(node?.user?.lastName) || ""}`
                    },
                    {
                        type:"icon",
                        value:node?.user?.values?.totalCount>=1?usage_module.green_tick:usage_module?.pending_loader
                    },
                    {
                        type:"icon",
                        value:node?.user?.strength?.totalCount>=1?usage_module.green_tick:usage_module?.pending_loader
                    },
                    {
                        type:"icon",
                        value:node?.user?.personality?.totalCount>=1?usage_module.green_tick:usage_module?.pending_loader
                    },
                    {
                        type:"icon",
                        value:node?.user?.knowledge?.totalCount>=1?usage_module.green_tick:usage_module?.pending_loader 
                    },
                    {
                        type:"icon",
                        value:node?.user?.impact_narrative?.totalCount>=1?usage_module.green_tick:usage_module?.pending_loader
                    },
                    {
                        type:"label",
                        value:last_accessed
                    }
                ]
                return row_data
            })
            if(filter?.after){
                list = list_ref?.current?.concat(list)
            }
            SetList(list)
            SetPageInfo(data?.allEmployees?.pageInfo)
            setDataListLoading(false)
        }
    }

    const onPagination=()=>{
        if(page_info?.hasNextPage){
            ModuleUsage({after:page_info?.endCursor})
        }
    }

    return (
        <>
            <CommonTableView {...props} data_list_loading={data_list_loading} header_list={header_list} list={list} page_info={page_info} onPagination={onPagination}/>
        </>
    )
}

export default compose(withApollo)(SoulModuleUsageContainer)