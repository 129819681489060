import React from 'react';
import styled from 'styled-components';
import { Spin, message, Modal, Row, Col, Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

import { getItem, compose } from '../../core';

import RegisterView from '../components/RegisterView';
import ROUTE from '../route';

import { withRegister } from './UserOperations';
import PasswordSet from './PasswordSet';
import { ACCESS_TOKEN } from 'config';
import { Capitalize } from 'modules/look';

const SignUp = props => {
  const { history, register, location } = props;
  const [loading, setLoading] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [visible, setVisible] = React.useState(false);

  const handleLoggedIn = React.useRef(() => {});

  handleLoggedIn.current = async () => {
    const storedJwt = await getItem(ACCESS_TOKEN);

    storedJwt && history.push(ROUTE.profile);
  };

  React.useEffect(() => {
    handleLoggedIn.current();
  }, []);

  const handleSubmit = async values => {
    setLoading(true);
    try {
      const { success, errors } = await register({
        email: values.user.email,
        username: Capitalize(values.user.username),
        firstName: Capitalize(values.user.firstName),
        lastName: Capitalize(values.user.lastName)
        // username: `${values.user.firstName}_${values.user.lastName}`,
        // password1: values.confirm,
        // password2: values.password
      });
      setLoading(false);
      if (success) {
        message.success('Verify email to login!!');
        setVisible(true);
      } else {
        message.error('Registration failed!!');
      }
      if (errors) setFormErrors(errors);
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const EmailSent = () => (
    <>
      <Row>
        <Col lg={24} md={24} sm={0} xs={0}>
          <br />
        </Col>
      </Row>
      <div style={{ textAlign: 'center' }}>
        <MailSent>
          <CheckCircleFilled style={{ color: '#009c4a', fontSize: '25px' }} /> &nbsp; Email Sent! <br />
        </MailSent>
        <span>We have sent you emails for verifying your email address and setting your password for the account</span>
        <Row>
          <Col lg={0} md={0} sm={24} xs={24}>
            <Button
              type="primary"
              ghost
              onClick={() => {
                history.push(ROUTE.login);
                setVisible(false);
              }}
              size="middle"
            >
              Login
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <br />
          </Col>
        </Row>
        {/* <LessDivider /> */}
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <Button
              type="primary"
              ghost
              onClick={() => {
                history.push(ROUTE.login);
                setVisible(false);
              }}
              size="middle"
            >
              Login
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  return (
    <Spin spinning={loading} size="large">
      <Modal footer={null} centered visible={visible} onCancel={() => setVisible(false)} bodyStyle={{ padding: '0px' }}>
        <ModalBody>
          <EmailSent />
        </ModalBody>
      </Modal>
      <PasswordSet location={location} />
      <RegisterView formErrors={formErrors} onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withRegister)(SignUp);

const MailSent = styled.div`
  color: #bbd33e;
  font-size: 21px;
  margin-bottom: 10px;
`;

const ModalBody = styled.div`
  padding: 24px 48px;
  @media only screen and (max-width: 480px) {
    padding: 24px;
  }
`;
