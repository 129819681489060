import React from "react";
import { Button, Col, Row } from "antd";
import { displayUtcToUserTimezone } from "modules/look";
import Agenda_close from '../../assets/agenda-close.svg'
import creator from '../../assets/one-on-one-creator.svg'
import assigne from '../../assets/one-on-one-contributor.svg'
import Agenda_title from '../../assets/agenda-title.svg'
import bookmark_disabel from '../../assets/bookmark-inactive.svg'
const AgendaPopup=(props)=>{

    const {agendaList,me,onClose}=props

    const [agendaitems,setAgendaitems]=React.useState([])

    React.useEffect(()=>{
        let list =  agendaList?.actionitemSet?.edges?.map(({node})=>node)
        let agendaLists = list.filter(item=>item?.isAgenda)
        let setAgenda =[]
        // createdAt
        // updatedAt
        agendaLists.forEach(element => {
            let data =
            {
                id:element?.id,
                title:element?.title,
                content:null,
                navigateTo:null,
                notes:element?.notes,
                createdAt:element?.createdAt,
                updatedAt:element?.updatedAt,
                okr:element?.okr
            }
              setAgenda.push(data)
        });
        setAgendaitems(setAgenda)
      },[agendaList])
      const MettingMember=(item)=>{
        let membersList =item?.member?.edges?.map(({node})=>node?.user)
        let list =membersList?.filter((data)=>data?.id!==item?.owner?.user?.id)
        return list[0]?.firstName
      }
    return(
        <>
            <Row justify="center" align="center" style={{flexDirection:"column",gap:"10px",padding:"10px",width:"100%",position:'relative'}}>
                <Row justify="space-between" align="center" style={{width:"100%",flexWrap:"nowrap",alignItems:"center",gap:"10px"}}>
                    <div className='one-on-one-date-section' style={{border:"none",height:"100%",alignItems:"center"}}>
                      <h4 className='one-on-one-date'>{displayUtcToUserTimezone(agendaList?.event, "DD MMM")}</h4>
                      <p className='one-on-one-time' style={{marginTop:"-4px",textAlign:"center"}}>{displayUtcToUserTimezone(agendaList?.event, "hh:mm a")}</p>
                    </div>
                    <h4 className='one-on-one-title-modal'style={{width:"fit-content"}}>{agendaList?.title}</h4>
                    {/* <div style={{ width: "20px", height: "10px" }}></div> */}
                    <img src={Agenda_close} alt="" style={{cursor:"pointer",visibility:"hidden"}}/>
                </Row>
                <img src={Agenda_close} alt="" style={{cursor:"pointer",position:'absolute',right:'10px',top:"10px"}} onClick={()=>onClose(true)}/>
                <div style={{display:'flex',flexDirection:"row",justifyContent:"center",gap:"10px",alignItems:"center",marginTop:"10px"}}>
                    <p className='user-name text-transform-capitalize' style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'center',gap:"5px"}}><img src={creator} alt="" /><span style={{textOverflow:"ellipsis",overflow:"hidden"}}>{agendaList?.owner?.user?.firstName}</span></p>
                    <p className='user-name' style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'center',gap:"5px"}}><img src={assigne} alt="" /><span style={{textOverflow:"ellipsis",overflow:"hidden"}}>{MettingMember(agendaList)}</span></p>
                </div>

                <img src={Agenda_title}/>
                <div className='meeting-overflow agenda-list-container'style={{width:"100%",maxHeight:"250px",overflow:'auto',marginTop:"20px"}}>
                    {agendaitems?.length?agendaitems?.map(item=>(
                        <div className="agend-list-div" style={{display:"flex",flexDirection:"row",gap:"10px",justifyContent:"space-between",alignItems:"center",marginTop:"10px"}}>
                            <h4 className="one-on-one-agenda-title">{item?.title}</h4>
                            {/* <img src={bookmark_disabel} alt="" style={{cursor:"pointer"}} /> */}
                        </div>
                    )):<div style={{justifyContent:"center",alignItems:"center",display:"flex",width:"100%",paddingTop:"50px"}}>
                    <h4 className='no-data'>No Agenda Found</h4>
                    </div>}
                </div>
            </Row>
        </>
    )
}

export default AgendaPopup