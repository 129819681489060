import React from 'react';
import { displayUtcToUserTimezone } from 'modules/look';
import { Spin } from 'antd';
const UserKudos = (props) => {
    const { kudos } = props


    return (
        <>
            {kudos ?
                kudos?.length ?
                    <div className='table-overflow'>
                        <table className='employee-profile-table'>

                            <thead>
                                <tr>
                                    <th style={{ paddingLeft: "1.5em", textAlign: "left" }}>KUDOS NAME</th>
                                    <th>AWAREDED BY</th>
                                    <th >RECEIVED DATE</th>
                                    <th >Expiry date</th>
                                </tr>
                            </thead>

                            {
                                kudos?.length ?

                                    kudos?.map(record => (
                                        <tbody>
                                            <tr className='table-body-tr'>
                                                <td className='table-body-td first-child td-main-label' style={{ textAlign: "left" }}>
                                                    <div className='employee-badge-container'>
                                                        <img src={record?.badge?.image} className='employee-badge-icon' alt="badge" /> <span>{record?.badge?.title}</span>
                                                    </div>
                                                </td>
                                                <td className='table-body-td td-main-label text-transform-capitalize'>
                                                    {record?.issuer?.user?.firstName}
                                                </td>
                                                <td className='table-body-td td-main-label' style={{ color: "#0098F0" }}>
                                                    {
                                                        record?.createdAt ?
                                                            displayUtcToUserTimezone(record?.createdAt, "DD MMM YYYY") : ''
                                                    }
                                                </td>
                                                <td className='table-body-td td-main-label last-child' style={{ color: "#E86161" }}>
                                                    {
                                                        record?.expirationDate ?
                                                            displayUtcToUserTimezone(record?.expirationDate, "DD MMM YYYY") : ''
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))
                                    :
                                    ''
                            }

                        </table>
                    </div>
                    :
                    <div style={{display:'flex',justifyContent:"center",alignItems:"center",marginTop:"3em"}}>
                        <h4 className='no-data-profile'>No Data Found</h4>
                    </div>
                :
                <div align="center" style={{ width: "100%", height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin spinning={true} ></Spin>
                </div>
            }
        </>
    )
}

export default UserKudos
