import { PlusOutlined } from '@ant-design/icons';
import { withApollo } from '@apollo/client/react/hoc';
import { Button, Input, Space } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from '../../core';
import { AdminLayout, Capitalize, displayBoolean, displayDataCheck, displayUtcToUserTimezone, GetColumnSearchProps } from '../../look';

import ROUTE from '../route';

import { withDeleteToken, withSurveyFilterUpdating, withSurveyState } from './SurveyOperations';
import { subscribeToSurveyToken } from './SurveySubscriptions';

import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { GET_ALL_TOKENS_LIST_QUERY } from '../graphql/GetAllTokensListQuery.gql';
import { SURVEY_TOKEN_SUBSCRIPTION_OPTIMIZED } from '../graphql/SurveyTokenSubscription.gql';
import ExportTokens from './ExportTokens';

export function getToken( link ) {
  const link1 = link && link.split( '/' );
  return link1 && `${link1[ link1.length - 1 ]}`;
}

export function getLink( link ) {
  const link1 = link && link.split( '/' );
  return link1 && `${ROUTE.tokenSurveyLink}${link1[ link1.length - 1 ]}`;
}

const Token = props => {
  const {
    loading,
    getAllTokensList,
    onPaginationChange,
    deleteToken,
    onOrderByChange,
    orderBy,
    onFiltersRemove,
    onUserChange,
    onSurveyChange,
    onCreatedByChange,
    filter,
    onInvitedChange,
    onValidChange,
    subscribeToMore,
    me,
    pagination,
    newFilter,
    client
  } = props;

  React.useEffect( () => {
    const subscribe = subscribeToSurveyToken( subscribeToMore /* , props.filter */ );
    return () => subscribe();
  } );
  const [ exportLoading, setExportLoading ] = React.useState( false );
  const [paginationFilter,setPaginationFilter] = React.useState({first: 10,offset: 0});

  const [ allTokenSet, setTokenSet ] = React.useState( undefined );

  const tokenDataRef = React.useRef( null );

  let tokenSubscription = undefined;
  const m = React.useRef( true )



 

  React.useEffect(()=>{
    let updatedFilters;
     if(filter?.createdBy || filter?.survey || filter?.user){
       updatedFilters = {createdBy:filter?.createdBy,survey:filter?.survey,user:filter?.user}
     }else{
       updatedFilters = {...paginationFilter,createdBy:filter?.createdBy,survey:filter?.survey,user:filter?.user}
     }
    
     getAllTokens(updatedFilters)
 
   },[filter])

  React.useEffect( () => {
    return () => {
      m.current = false
    }
  }, [] )

  React.useEffect( () => {
    m.current = true

  }, [] )


  React.useEffect( () => {
    return () => {
      if ( tokenSubscription ) {
        tokenSubscription.unsubscribe();
      }
    }
  }, [] )


  const getAllTokens = async (filters) => {
    if(filters?.first){
      setPaginationFilter({...filters})
    }
    const { data } = await client.query( {
      query: GET_ALL_TOKENS_LIST_QUERY,
      variables: {...filters, orderBy } ,
      fetchPolicy: 'network-only',
    } );

    if ( data?.getAllTokensList ) {

      setTokenSet( data?.getAllTokensList );
      tokenDataRef.current = data?.getAllTokensList;

      tokenSubscription = client.subscribe( {
        query: SURVEY_TOKEN_SUBSCRIPTION_OPTIMIZED,
      } ).subscribe( {
        next( result ) {

          switch ( result.data.surveyTokenSubscription.mutation ) {
            case 'CREATE':
              let tokenSet = tokenDataRef.current?.edges
              const deletedIfExists = tokenSet?.filter( item =>
                item?.node?.id === result.data.surveyTokenSubscription?.token?.id
                  ? false
                  : true ).concat( [ {node:result.data.surveyTokenSubscription?.token} ] )
                setTokenSet( deletedIfExists );
                tokenDataRef.current = deletedIfExists;
              break
            case 'DELETE':
              if ( m.current ) {
                let tokenSet = tokenDataRef.current?.edges
                let tokenSetWithDeletedRecord = tokenSet?.filter( ( token ) =>
                  token?.node?.id !== result.data.surveyTokenSubscription?.token?.id )
                let updatedTokenSet = { edgeCount: ( tokenDataRef.current?.edgeCount - 1 ), totalCount: ( tokenDataRef.current?.totalCount - 1 ), edges: tokenSetWithDeletedRecord }
                setTokenSet( updatedTokenSet );
                tokenDataRef.current = updatedTokenSet;
              }

              break;
            default:
              break
          }

        }
      } )

    }
  }

 
  
 
  const columns = [
    {
      title: 'User',
      key: 'user',
      // sorter: () => onOrderByChange( getOrderBy( 'user__firstName', orderBy ) ),
      sorter: (a, b) => {return a.user.firstName.localeCompare(b.user.firstName)},
      ...GetColumnSearchProps( 'name', onUserChange, 'user' ),
      // ...getColumnSearchProps('firstName', handleUserFilterChange, 'user'),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      render: ( text, record ) => record && displayDataCheck( `${Capitalize(record.user.firstName)} ${Capitalize(record.user.lastName)}` )
    },
    {
      title: 'Username',
      key: 'username',
      // sorter: () => onOrderByChange( getOrderBy( 'user__username', orderBy ) ),
      sorter: (a, b) => {return a.user.username.localeCompare(b.user.username)},
      render: ( text, record ) => record && displayDataCheck( record.user.username )
    },
    {
      title: 'Survey',
      key: 'surveyId',
      // sorter: () => onOrderByChange( getOrderBy( 'survey__name', orderBy ) ),
      sorter: (a, b) => {return a.survey.name.localeCompare(b.survey.name)},
      ...GetColumnSearchProps( 'name', onSurveyChange, 'survey' ),
      // ...getColumnSearchProps('name', handleUserFilterChange, 'survey'),
      render: ( text, record ) => record && record.survey && displayDataCheck( record.survey.name )
    },
    {
      title: 'Invited',
      key: 'invited',
      // sorter: () => onOrderByChange( getOrderBy( 'invited', orderBy ) ),
      // sorter: (a, b) => {return a.invited.localeCompare(b.survey.invited)},
      // ...GetColumnSearchProps( 'invited', onInvitedChange, 'ternary' ),
      render: ( text, record ) => displayBoolean( record.invited )
    },
    {
      title: 'Created By',
      key: 'createdBy',
      // sorter: () => onOrderByChange( getOrderBy( 'createdBy__firstName', orderBy ) ),
      sorter: (a, b) => {return a.createdBy.firstName.localeCompare(b.createdBy.firstName)},
      ...GetColumnSearchProps( 'name', onCreatedByChange, 'user' ),
      // ...getColumnSearchProps('firstName', handleUserFilterChange, 'createdBy'),
      render: ( text, record ) =>
        record && record.createdBy && displayDataCheck( `${Capitalize(record.createdBy.firstName)} ${Capitalize(record.createdBy.lastName)}` )
    },
    {
      title: 'Valid',
      key: 'valid',
      // sorter: () => onOrderByChange( getOrderBy( 'valid', orderBy ) ),
      // sorter: (a, b) => {return a.valid.localeCompare(b.createdBy.valid)},
      // ...GetColumnSearchProps( 'valid', onValidChange, 'ternary' ),
      render: ( text, record ) => displayBoolean( record.valid )
    },
    {
      title: 'Date Created',
      key: 'dateCreated',
      sorter: (a, b) => moment(a.dateCreated).unix() - moment(b.dateCreated).unix(),
      // sorter: () => onOrderByChange( getOrderBy( 'dateCreated', orderBy ) ),
      render: ( text, record ) => `${displayUtcToUserTimezone( record.dateCreated, 'YYYY-M-DD HH:mm' )}`
    },
    {
      title: 'Link',
      key: 'link',
      align: 'center',
      render: ( text, record ) => (
        <Button type="link" target="_blank" href={getLink( record.link )} disabled={!getLink( record.link )}>
          Link
        </Button>
      )
    }
  ];

  const handleDeleteRecord = async ( token ) => {
    await deleteToken( { token: token } )
  }


  return (
    <AdminLayout
      table={true}
      active={ROUTE.token}
      title="Token"
      loading={loading || exportLoading}
      tableData={
        allTokenSet!==undefined && {
          ...allTokenSet,
          edges: allTokenSet?.edges?.map( e => ( { ...e, node: { ...e.node, token: getToken( e.node.link ) } } ) )
        }
      }
      specificMutation={true}
      handleDeleteRecord={handleDeleteRecord}
      columns={columns}
      onDelete={deleteToken}
      addLink={ROUTE.addToken}
      onPaginationChange={getAllTokens}
      onFiltersRemove={onFiltersRemove}
      rowKey="token"
      extra={
        <Space size="large">
          <Button size="md" type="primary" ghost>
            <Link to={ROUTE.addMultipleToken}>
              <PlusOutlined />
              Add Multiple Token
            </Link>
          </Button>
          <ExportTokens filter={filter} getAllTokensList={allTokenSet} setLoading={setExportLoading} />
        </Space>
      }
    />
  );
};

export default compose( withSurveyState, withDeleteToken, withSurveyFilterUpdating, withApollo )( Token );


