import gql from "graphql-tag";

export const CASPER_SUBSCRIPTION =gql`
subscription casperSubscription {
  casperSubscription {
    mutation
    casper{
      id,
      user{
        id,
        firstName,
        lastName
      }
      userMsg,
      replyMsg,
      msgRecievedTimestamp
      readTimestamp
      isAudio
      syncStatus
      replyToId{
        id
      }
      replyTo
    }
  }
}
`
