import gql from "graphql-tag";

export const OKR_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String,$createdAt_Range: [DateTime],$user_FirstName: String){
 allEmployees(first:$first,after:$after,user_FirstName:$user_FirstName){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
      }
      owner:okrContributorSet(role:"OWNER",createdAt_Range:$createdAt_Range){
    	  totalCount
      }
      collabrator:okrContributorSet(role:"COLLABORATOR",createdAt_Range:$createdAt_Range){
        totalCount
      }
      assigned:okrContributorSet(role:"ASSIGNED",createdAt_Range:$createdAt_Range){
        totalCount
      }
      accepted_okr:okrContributorSet(role_In:["COLLABORATOR","ASSIGNED"],requestStatus:"ACCEPTED",createdAt_Range:$createdAt_Range){
        totalCount
      }
      last_accessed:okrContributorSet(requestStatus:"ACCEPTED",orderBy:["updatedAt"],last:1,createdAt_Range:$createdAt_Range){
 		    edges{
          node{
            id
            updatedAt
          }
        }
      }
    }
  }
 }
}
`

export const SOUL_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String,$responseDate_Range: [DateTime],$user_FirstName: String){
 allEmployees(first:$first,after:$after,user_FirstName:$user_FirstName){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
        values:responseSet(survey_In:[3],responseDate_Range:$responseDate_Range){
          totalCount
        }
        strength:responseSet(survey_In:[7],responseDate_Range:$responseDate_Range){
          totalCount
        }
        personality:responseSet(survey_In:[8],responseDate_Range:$responseDate_Range){
          totalCount
        }
        knowledge:responseSet(survey_In:[9],responseDate_Range:$responseDate_Range){
          totalCount
        }
        impact_narrative:impactnarrativeSet(last:1,updatedAt_Range:$responseDate_Range){
          totalCount
          edges{
            node{
              id
              createdAt
              updatedAt
            }
          }
        }
        last_accessed:responseSet(survey_In:[3,7,8,9],last:1){
          edges{
            node{
              id
              responseDate
            }
          }
        }
      }
    }
  }
 }
}
`

export const IDP_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String,$createdDt_Range: [DateTime],$user_FirstName: String){
 allEmployees(first:$first,after:$after,user_FirstName:$user_FirstName){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
      }
        total_idp: idpSet(status_In: ["APPROVED", "TODO", "INPROGRESS", "COMPLETED", "PEDNING_APPROVAL", "REVISE","DRAFT"],last:1,createdDt_Range:$createdDt_Range) {
          totalCount
          edges{
            node{
              id
              updatedDt
            }
          }
        }
        completed: idpSet(status_In: ["COMPLETED"],createdDt_Range:$createdDt_Range) {
          totalCount
        }
        inprogress: idpSet(status_In: ["APPROVED", "TODO", "INPROGRESS"],createdDt_Range:$createdDt_Range) {
          totalCount
        }
        pending: idpSet(status_In: ["PEDNING_APPROVAL"],createdDt_Range:$createdDt_Range) {
          totalCount
        }
        revise: idpSet(status_In: ["REVISE"],createdDt_Range:$createdDt_Range) {
          totalCount
        }
          draft: idpSet(status_In: ["DRAFT"],createdDt_Range:$createdDt_Range) {
          totalCount
        }
    }
  }
 }
}
`

export const MEETING_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String,$when_Gt: DateTime,$when_Range: [DateTime],$user_FirstName: String){
 allEmployees(first:$first,after:$after,user_FirstName:$user_FirstName){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
      }
 	      total_meeting:meetingMemberSet(last:1,when_Range:$when_Range){
        	totalCount
          edges{
            node{
              id
              updatedAt          
            }
          }
        }
        completed:meetingMemberSet(endedAt_Isnull:false,last:1,when_Range:$when_Range){
        	totalCount
          edges{
            node{
              id
              endedAt
            }
          }
        }
        upcoming:meetingMemberSet(when_Gt:$when_Gt){
          totalCount
        }
    }
  }
 }
}
`

export const READINESS_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String,$user_FirstName: String){
 allEmployees(first:$first,after:$after,user_FirstName:$user_FirstName){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
      }
    }
  }
 }
}
`

export const FEEDBACK_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String,$createdAt_Range: [DateTime],$user_FirstName: String){
 allEmployees(first:$first,after:$after,user_FirstName:$user_FirstName){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
      }
        given:feedbackGiven(last:1,createdAt_Range:$createdAt_Range){
          totalCount
          edges{
            node{
              updatedAt
            }
          }
        }
        received:feedbackReceived(createdAt_Range:$createdAt_Range){
          totalCount
        }
    }
  }
 }
}
`

export const KUDOS_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String,$createdAt_Range: [DateTime],$user_FirstName: String){
 allEmployees(first:$first,after:$after,user_FirstName:$user_FirstName){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
      }
				received:recevedBadgeAwardSet(first:1,createdAt_Range:$createdAt_Range){
          totalCount
            edges{
            node{
              createdAt
            }
          }
        }
        issued:issuedBadgeAwardSet(first:1,createdAt_Range:$createdAt_Range){
          totalCount
          edges{
            node{
              createdAt
            }
          }
        }
    }
  }
 }
}
`

export const GROW_MODULE_USAGE =gql`
query allEmployees($first:Int,$after:String,$createdAt_Range: [DateTime],$user_FirstName: String){
 allEmployees(first:$first,after:$after,user_FirstName:$user_FirstName){
  pageInfo{
    hasNextPage
    endCursor
  }
  edges{
    node{
      id
      user{
        id
        firstName
        lastName
        email
      }
				coachee(last:1,createdAt_Range:$createdAt_Range){
          totalCount
          edges{
            node{
              id
              createdAt
            }
          }
        }
        coaching(last:1,createdAt_Range:$createdAt_Range){
          totalCount
          edges{
            node{
              id
              createdAt
            }
          }
        }
        incomplete_coachee:coachee(createdAt_Range:$createdAt_Range){
          edges{
            node{
              id
              actionItems(status_Ne:"COMPLETED"){
                totalCount
              }
            }
          }
        }
        incomplete_coaching:coaching(createdAt_Range:$createdAt_Range){
          edges{
            node{
              id
              actionItems(status_Ne:"COMPLETED"){
                totalCount
              }
            }
          }
        }
    }
  }
 }
}
`

export const MODULE_USAGE_EXPORT_QUERY = gql `
  query allEmployees($offset:Int){
    allEmployees(offset:$offset){
      totalCount
      pageInfo{
        hasNextPage
        endCursor
      }
      edges{
        node{
          	user{
            id
            firstName
            lastName
            soul:responseSet(survey_In:[3,7,8,9],last:1){
                edges{
                  node{
                    id
                    responseDate
                  }
                }
              }
          	}
            coachee(last:1){
              edges{
                node{
                  id
                  createdAt
                }
              }
            }
            coaching(last:1){
              edges{
                node{
                  id
                  createdAt
                }
              }
            }
             kudos:issuedBadgeAwardSet(first:1){
              edges{
                node{
                  createdAt
                }
              }
            }
            feedback:feedbackGiven(last:1){
              edges{
                node{
                  updatedAt
                }
              }
            }
            meeting:meetingMemberSet(last:1){
              edges{
                node{
                  id
                  updatedAt          
                }
              }
            }
            idp:idpSet(status_In: ["APPROVED", "TODO", "INPROGRESS", "COMPLETED", "PEDNING_APPROVAL", "REVISE","DRAFT"],last:1) {
              edges{
                node{
                  id
                  updatedDt
                }
              }
            }
            okr:okrContributorSet(requestStatus:"ACCEPTED",orderBy:["updatedAt"],last:1){
              edges{
                node{
                  id
                  updatedAt
                }
              }
            }
            Readiness: readinessGiven(last:1){
              edges{
                node{
                  id
                  updatedAt
                }
              }
            }
        }
      }
    }
  }
`