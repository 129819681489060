import { compose } from "modules/core"
import OrgTreeView from "modules/poc-dashboard/components/organization/pocOrganizationTreeView"
import React from "react"
import { withApollo } from '@apollo/client/react/hoc';
import { ORG_TREE_QUERY } from "modules/poc-dashboard/graphql/OrganizationTreeQuery.gql";
import { Capitalize, getIntFromString } from "modules/look";
import { withCreateTeam, withCreateVertical, withDeleteTeam, withDeleteVertical } from "./organizationOperations";
import { message, Spin } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
const PocOrganizationTreeContainer = (props) => {
    const { me, client, createVertical, createTeam, deleteTeam, deleteVertical, verticalCount, search } = props

    const [org_data, SetOrgData] = React.useState()
    const [tree_data_list, SetTreeDataList] = React.useState([])
    const [is_edit_mode, SetEditMode] = React.useState(false)
    const [selected_node, SetSelectedNode] = React.useState()
    const [loading, SetLoading] = React.useState(false)
    const [child_node_delete, SetChildNodeDelete] = React.useState()
    let key_ref = React.useRef(1)
    const [tree_key, setkey] = React.useState(`poc_tree_key_${key_ref?.current}`)

    const tree_data_list_ref = React.useRef([])
    const force_delete_node = React.useRef()
    const delete_child_ref = React.useRef([])
    let node_key = React.useRef(1)
    let temp_data_storage = React.useRef([])

    React.useEffect(() => {
        tree_data_list_ref.current = tree_data_list
        if (tree_data_list?.length) {
            generateOrgStructure(tree_data_list)
        }
    }, [tree_data_list])

    React.useEffect(() => {
        if (search != "**") {
            HandleSearch(search)
        }
    }, [search])

    const node_type = {
        organization: "ORGANIZATION",
        vertical: "VERTICAL",
        team: "TEAM",
        sub_vertical: "SUB-VERTICAL",
        create_choice: "CREATE_CHOICE_NODE"
    }

    const DELETE_NODE_MUTATION = gql`
    mutation deleteNodes {
        ${child_node_delete ?
            `${child_node_delete?.filter(i => i.type == node_type.team)?.map((i, index) =>
                `delete_team${index}:deleteTeam(id:${getIntFromString(i?.id)}){
                        team{
                            id
                        }
                    }
                    `
            ).join('')
            }
            ${child_node_delete?.filter(i => i.type == node_type.sub_vertical)?.map((i, index) =>
                `delete_vertical${index}:deleteVertical(id:${getIntFromString(i?.id)}){
                        vertical{
                            id
                        }
                    }
                    `
            ).join('')
            }`
            : null
        }
    }
    `;

    const [deletenodeDefinition, { data, error }] = useMutation(DELETE_NODE_MUTATION);

    React.useEffect(() => {
        if (child_node_delete?.length) {
            deleteBulkNode()
        }
    }, [child_node_delete])

    React.useEffect(() => {
        if (tree_data_list_ref.current?.length) {
            if (!is_edit_mode) {
                let list = [].concat(tree_data_list_ref.current)
                list = list?.filter(i => i?.attribute?.node_type != "CREATE_CHOICE_NODE" && !i?.attribute?.create_node)
                list = getChildDetail(list)
                list.forEach(i => {
                    if (i?.attribute?.node_type == node_type.organization || i?.attribute?.node_type == node_type.vertical) {
                        i.attribute["hide_node"] = false
                    } else {
                        i.attribute["hide_node"] = true
                    }
                })
                SetTreeDataList(list)
            } else {
                let list = [].concat(tree_data_list_ref.current)
                list.forEach(i => {
                    i.attribute["hide_node"] = false
                })
                SetTreeDataList(list)
            }
        }
    }, [is_edit_mode])

    const deleteBulkNode = async () => {
        SetLoading(true)
        try {
            const response = await deletenodeDefinition()

            if (response?.data) {
                deleteChildNode(force_delete_node?.current)
            } else {
                force_delete_node.current = null
                delete_child_ref.current = []
                SetLoading(false)
            }
        } catch (error) {
            message.error('Failed to save changes')
        }
    }

    React.useEffect(() => {
        if (me && !tree_data_list_ref.current?.length) {
            getOrgdataStructure()
        }
    }, [me])


    const generateOrgStructure = (list) => {
        const nodes = {};
        const rootNodes = [];

        // Create a node map and initialize children arrays
        list.forEach(node => {
            nodes[node.node_id] = { ...node, children: [] };
        });
        // Build the tree structure
        list.filter(i => !i?.attribute?.hide_node).forEach(node => {
            if (node.parent_node) {
                nodes[node.parent_node].children.push(nodes[node.node_id]);
            } else {
                rootNodes.push(nodes[node.node_id]);
            }
        });
        SetOrgData(rootNodes[0])
        SetLoading(false)
    }


    const getOrgdataStructure = async () => {
        SetLoading(true)
        const { data } = await client.query({
            query: ORG_TREE_QUERY,
            fetchPolicy: 'network-only'
        });
        if (data.me?.employee?.orgPocEmployee?.edges?.length) {
            handleResponse(data.me?.employee?.orgPocEmployee?.edges[0]?.node)
        }
    }

    const handleResponse = (data) => {
        let vertical_list = data?.verticalSet?.edges?.map(({ node }) => node)
        let team_list = [].concat.apply([], vertical_list?.map(i => i?.teamSet?.edges?.map(({ node }) => node)))
        let list = [
            {
                node_id: data?.id,
                name: data?.name,
                parent_node: null,
                attribute: {
                    node_type: node_type?.organization,
                    manager: [
                        {
                            name: `${Capitalize(data?.ceo?.user?.firstName)} ${Capitalize(data?.ceo?.user?.lastName)}`,
                            email: data?.ceo?.user?.email,
                            role: "CEO",
                            profile_image: data?.ceo?.user?.profile?.profileImage,
                            empolee_id: data?.ceo?.id
                        },
                        {
                            name: `${Capitalize(data?.poc?.user?.firstName)} ${Capitalize(data?.poc?.user?.lastName)}`,
                            email: data?.poc?.user?.email,
                            role: "POC",
                            profile_image: data?.poc?.user?.profile?.profileImage,
                            empolee_id: data?.poc?.id
                        }
                    ],
                    detail: {
                        id: data?.id,
                        label: data?.name,
                        organization: {
                            id: data?.id,
                            name: data?.name,
                            email: data?.email,
                            color: data?.primaryColor,
                            logo: data?.logo
                        }

                    }
                }
            }
        ]


        let vertical_data = vertical_list?.map(i => {
            return {
                node_id: i?.id,
                name: i?.name,
                parent_node: i?.parentvertical ? i?.parentvertical?.id : data?.id,
                attribute: {
                    node_type: i?.parentvertical ? node_type?.sub_vertical : node_type?.vertical,
                    manager: i?.heads?.edges?.map(({ node }) => {
                        return {
                            name: `${Capitalize(node?.user?.firstName)} ${Capitalize(node?.user?.lastName)}`,
                            email: node?.user?.email,
                            role: "Vertical Head",
                            profile_image: node?.user?.profile?.profileImage,
                            empolee_id: node?.id
                        }
                    }),
                    employee: i?.employees?.totalCount,
                    detail: {
                        id: i?.id,
                        label: i?.name,
                        organization: {
                            id: data?.id,
                            name: data?.name,
                            email: data?.email,
                            color: data?.primaryColor,
                            logo: data?.logo
                        }
                    }
                }
            }
        })
        list = list.concat(vertical_data)

        let team_data = team_list?.map(i => {
            return {
                node_id: i?.id,
                name: i?.name,
                parent_node: i?.vertical?.id,
                attribute: {
                    node_type: node_type?.team,
                    manager: i?.managers?.edges?.map(({ node }) => {
                        return {
                            name: `${Capitalize(node?.user?.firstName)} ${Capitalize(node?.user?.lastName)}`,
                            email: node?.user?.email,
                            role: "Manager",
                            profile_image: node?.user?.profile?.profileImage,
                            empolee_id: node?.id
                        }
                    }),
                    employee: i?.employees?.totalCount,
                    detail: {
                        id: i?.id,
                        label: i?.name,
                        organization: {
                            id: data?.id,
                            name: data?.name,
                            email: data?.email,
                            color: data?.primaryColor,
                            logo: data?.logo
                        },
                    }
                }
            }
        })

        list = list.concat(team_data)
        list = getChildDetail(list)
        if (!is_edit_mode) {
            list.forEach(i => {
                if (i?.attribute?.node_type == node_type.organization || i?.attribute?.node_type == node_type.vertical) {
                    i.attribute["hide_node"] = false
                } else {
                    i.attribute["hide_node"] = true
                }
            })
        } else {
            list.forEach(i => {
                i.attribute["hide_node"] = false
            })
        }
        temp_data_storage.current = list
        SetTreeDataList(list)
    }

    const HandleSearch = (search) => {
        let list = temp_data_storage.current
        if (search) {
            let nodes = list?.filter(i => i?.name?.toLowerCase()?.includes(search?.toLowerCase()))
            let parent_node = list?.find(i => !i?.parent_node)
            let expand_ids = [parent_node?.node_id]
            nodes.forEach(i => {
                let execute = true
                let current_node = i
                while (execute) {
                    expand_ids.push(current_node?.node_id)
                    if (current_node?.parent_node) {
                        current_node = list.find(i => i?.node_id == current_node?.parent_node)
                    } else {
                        execute = false
                    }
                }
            })
            let searched_nodes = list?.filter(i => expand_ids?.includes(i?.node_id))

            if (!is_edit_mode) {
                searched_nodes = getChildDetail(searched_nodes)
                searched_nodes.forEach(i => i.attribute["hide_node"] = false)
                SetTreeDataList(searched_nodes)
            } else {
                SetTreeDataList(searched_nodes)
            }

        } else {
            if (!is_edit_mode) {
                list = getChildDetail(list)
                list.forEach(i => {
                    if (i?.attribute?.node_type == node_type.organization || i?.attribute?.node_type == node_type.vertical) {
                        i.attribute["hide_node"] = false
                    } else {
                        i.attribute["hide_node"] = true
                    }
                })
                SetTreeDataList(list)
            } else {
                SetTreeDataList(list)
            }

        }
    }

    const getChildDetail = (list) => {
        list.forEach(element => {
            let child_nodes = list?.filter(i => i?.parent_node == element?.node_id)
            let node_type_list = child_nodes?.map(i => i?.attribute?.node_type)
            let color = ""
            if (node_type_list?.includes(node_type.vertical)) {
                color = "rgba(0, 222, 252, 1)"
            }
            else if (node_type_list?.includes(node_type.sub_vertical)) {
                color = "rgba(104, 118, 237, 1)"
            }
            else {
                color = "rgba(0, 202, 100, 1)"
            }
            element.attribute["child_detail"] = {
                count: child_nodes?.length,
                color,
                show_count: [node_type?.organization, node_type?.team]?.includes(element?.attribute?.node_type) ? false : true
            }
        });
        return list
    }

    const create_choices = {
        "ORGANIZATION": [{ type: node_type?.vertical }],
        "VERTICAL": [{ type: node_type?.sub_vertical }, { type: node_type?.team }],
        "SUB-VERTICAL": [{ type: node_type?.team }],
    }



    const newNodeCreationChoice = (parent_node, toggleNode) => {
        if (!is_edit_mode) {
            return
        }
        let list = [].concat(tree_data_list_ref.current)
        let node = list?.find(i => i?.node_id == parent_node && !i?.attribute?.create_node && [node_type?.organization, node_type?.vertical, node_type?.sub_vertical]?.includes(i?.attribute?.node_type))
        let create_node = tree_data_list_ref.current?.find(i => i?.attribute?.create_node)
        if (node && !create_node) {
            list = list?.filter(i => i?.attribute?.node_type != node_type?.create_choice)
            let org_node = list?.find(i => !i?.parent_node)
            node_key.current = node_key.current + 1
            let choice = create_choices[node?.attribute?.node_type]
            if (!choice || !choice?.length) {
                message.error("Choices are not added")
                return
            }
            let data = {
                node_id: `create_node_${node_key.current}`,
                name: "",
                parent_node: parent_node,
                attribute: {
                    node_type: choice?.length <= 1 ? choice[0]?.type : node_type?.create_choice,
                    create_node: choice?.length <= 1 ? true : false,
                    create_types: choice?.length <= 1 ? choice : choice,
                    detail: {
                        organization: org_node?.attribute.detail.organization,
                    }
                }
            }
            list = [data].concat(list)
            SetTreeDataList(list)
        }

    }

    const convertToInput = (node_id, node_type) => {
        let list = [].concat(tree_data_list_ref.current)
        let node = list?.find(i => i?.node_id == node_id)
        if (node) {
            node.attribute.node_type = node_type
            node.attribute["create_node"] = true
            SetTreeDataList(list)
        }

    }

    React.useEffect(() => {
        // Define the event handler
        const handleClick = (event) => {
            const create_container = document.getElementById('create_choice_container');
            if (create_container) {
                let btns = [1, 2, 3, 4, 5, 6]
                let event_list = btns.map(i => {
                    let doc = document.getElementById(`create_choice_btn${i}`)

                    if (doc && (doc.contains(event.target) || event.target.closest(`#create_choice_btn${i}`))) {
                        return true;
                    }

                    return false;
                })

                if (!create_container?.contains(event.target) && !event_list?.some(i => i == true)) {
                    clearCreateNode()
                }
            }

        };

        // Add the event listener when the component mounts
        window.addEventListener('click', handleClick);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);

    const clearCreateNode = () => {
        let list = [].concat(tree_data_list_ref.current)
        list = list?.filter(i => i?.attribute?.node_type != node_type?.create_choice)
        SetTreeDataList(list)
    }

    const clearInput = () => {
        let list = [].concat(tree_data_list_ref.current)
        list = list?.filter(i => i?.attribute?.node_type != node_type?.create_choice && !i?.attribute?.create_node)
        SetTreeDataList(list)
    }

    const addVertical = async (data) => {
        let verticalData = {
            name: data?.name,
            organizationId: getIntFromString(data?.organization),
        }

        if (data?.type == node_type.sub_vertical) {
            verticalData["parentverticalId"] = getIntFromString(data?.parent_id)
        }
        const response = await createVertical({ verticalData })
        if (verticalCount) {
            verticalCount()
        }
        if (response?.id) {
            addNewNode(data, response?.id)
        }
    }

    const addTeam = async (data) => {
        let teamData = {
            name: data?.name,
            verticalId: getIntFromString(data?.parent_id),
        }

        const response = await createTeam({ teamData })
        if (response?.id) {
            addNewNode(data, response?.id)
        }
    }

    const addNewNode = (data, id) => {
        let list = [].concat(tree_data_list_ref.current)
        let node = list?.find(i => i?.node_id == data?.node_id)
        if (node) {
            let parent = list?.find(i => !i?.parent_node)
            node.name = data?.name
            node.node_id = id
            node.attribute.employee = 0
            node.attribute.manager = []
            node.attribute.create_node = false
            node.attribute.detail = {
                id: id,
                label: data?.name,
                organization: parent.attribute.detail.organization
            }
            SetTreeDataList(list)
        }
    }

    const handleNodeSelection = (node_id, type) => {
        let node = tree_data_list_ref.current?.find(i => i?.node_id == node_id)
        if (node) {
            let data = {
                node,
                node_id,
                node_type: node?.attribute?.node_type,
                type
            }
            SetSelectedNode(data)
        }
    }

    const UpdateNode = (data) => {
        SetSelectedNode(null)
        let list = [].concat(tree_data_list_ref.current)
        let node = list?.find(i => i?.node_id == data?.node_id)
        node = data
        SetTreeDataList(list)
    }


    const deleteChildNode = (node) => {
        if (node) {
            if (node?.attribute?.node_type !== node_type.team && delete_child_ref.current?.length) {
                let team_list = delete_child_ref?.current?.filter(i => i?.attribute?.node_type == node_type.team)?.map(i => {
                    return {
                        id: i?.node_id,
                        type: node_type.team
                    }
                })
                if (team_list?.length) {
                    delete_child_ref.current = delete_child_ref.current?.filter(i => i?.attribute?.node_type != node_type.team)
                    SetChildNodeDelete(team_list)
                    return
                }
                let sub_vertical_list = delete_child_ref?.current?.filter(i => i?.attribute?.node_type == node_type.sub_vertical)?.map(i => {
                    return {
                        id: i?.node_id,
                        type: node_type.sub_vertical
                    }
                })
                if (sub_vertical_list?.length) {
                    delete_child_ref.current = delete_child_ref.current?.filter(i => i?.attribute?.node_type != node_type.sub_vertical)
                    SetChildNodeDelete(sub_vertical_list)
                    return
                }
                deleteNode(force_delete_node.current, true)

            } else {
                deleteNode(force_delete_node.current, true)
            }
        }

    }

    const deleteNode = async (node, force_delete = false) => {
        try {
            SetLoading(true)
            SetSelectedNode(null)
            force_delete_node.current = null
            delete_child_ref.current = []
            if (!force_delete && node?.attribute?.node_type !== node_type.team && tree_data_list_ref.current?.find(i => i?.parent_node == node?.node_id)) {
                force_delete_node.current = node
                if (node?.attribute?.node_type == node_type.sub_vertical) {
                    let team_list = tree_data_list_ref.current.filter(i => i?.attribute?.node_type == node_type.team && i?.parent_node == node?.node_id)
                    delete_child_ref.current = team_list
                }
                else {
                    let sub_vertical_list = tree_data_list_ref.current.filter(i => i?.attribute?.node_type == node_type.sub_vertical && i?.parent_node == node?.node_id)
                    let team_list = tree_data_list_ref.current.filter(i => i?.attribute?.node_type == node_type.team && i?.parent_node == node?.node_id)
                    let sub_vertical_ids = sub_vertical_list?.map(i => i?.node_id)
                    team_list = team_list?.concat(tree_data_list_ref.current.filter(i => i?.attribute?.node_type == node_type.team && sub_vertical_ids?.includes(i?.parent_node)))
                    delete_child_ref.current = [...team_list, ...sub_vertical_list]
                }
                deleteChildNode(force_delete_node.current)
                return
            }
            let response = node?.attribute?.node_type == "TEAM" ? await deleteTeam({ id: getIntFromString(node?.node_id) }) : await deleteVertical({ id: getIntFromString(node?.node_id) })
            if (response) {
                force_delete_node.current = null
                delete_child_ref.current = []
                getOrgdataStructure()
            }
        } catch (error) {
            message.error("Failed to delete")
            force_delete_node.current = null
            delete_child_ref.current = []
            SetSelectedNode(null)
            SetLoading(false)
        }

    }

    const expandNode = (node_data) => {
        if (node_data?.attribute?.node_type != node_type.organization && node_data?.attribute?.node_type != node_type.team) {
            let list = [].concat(tree_data_list_ref.current)
            let expand_ids = []
            let execute = true
            let current_node = node_data
            while (execute) {
                expand_ids.push(current_node?.node_id)
                if (current_node?.parent_node) {
                    current_node = list.find(i => i?.node_id == current_node?.parent_node)
                } else {
                    execute = false
                }
            }
            let children = list.filter(i => i?.parent_node == node_data?.node_id)
            expand_ids = expand_ids?.concat(children?.map(i => i?.node_id))
            let strictly_expanded_type = [node_type.organization, node_type.vertical]
            // if(children?.length){
            //     children.forEach(i=>i.attribute["hide_node"]=false)
            //     SetTreeDataList(list)
            // }
            list.forEach(i => {
                if (expand_ids?.includes(i?.node_id) || strictly_expanded_type?.includes(i.attribute?.node_type)) {
                    i.attribute["hide_node"] = false
                } else {
                    i.attribute["hide_node"] = true
                }
            })
            SetTreeDataList(list)
        }
    }

    const recenterNode = () => {
        key_ref.current = key_ref.current + 1
        setkey(`poc_tree_key_${key_ref?.current}`)
    };

    return (

        <>
            {
                loading && (
                    <div className="org-tree-loader">
                        <div className="loader-container">
                            <Spin spinning={loading} size="large"> </Spin>
                        </div>
                    </div>
                )
            }

            <OrgTreeView {...props}
                org_data={org_data}
                newNodeCreationChoice={newNodeCreationChoice}
                convertToInput={convertToInput}
                clearInput={clearInput}
                addVertical={addVertical}
                addTeam={addTeam}
                selected_node={selected_node}
                handleNodeSelection={handleNodeSelection}
                SetSelectedNode={SetSelectedNode}
                UpdateNode={UpdateNode}
                deleteNode={deleteNode}
                loading={loading}
                is_edit_mode={is_edit_mode}
                SetEditMode={SetEditMode}
                expandNode={expandNode}
                recenterNode={recenterNode}
                tree_key={tree_key}
            />
        </>




    )

}

export default compose(withApollo, withCreateVertical, withCreateTeam, withDeleteTeam, withDeleteVertical)(PocOrganizationTreeContainer)