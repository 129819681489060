import React from "react"
import "../css/casper-view.css"
import casper_icon from "../../assets/casper_icon.svg"
import { ReactComponent as Close } from "../../assets/close-icon-without-bg.svg"
import { ReactComponent as Settings } from '../../assets/settings-gear-black-icon.svg'
import { ReactComponent as Minimize } from '../../assets/minimize_icon.svg'
import { ReactComponent as SendMessage } from '../../assets/send-message.svg'
import { ReactComponent as CasperListening } from '../../assets/casper-listening.svg'
import { compose } from "modules/core"
import { Dropdown, Input, Switch } from "antd"
import { withSendMessage } from "../container/casper-operations"
const CasperView = (props) => {
    const { me, casperExpanded, placement, setCasperExpanded, createMessage, allMessages, setAllMessages } = props;
    const [checked, setChecked] = React.useState(true)
    const [dropdownVisible, setDropdownVisible] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const messageListRef = React.useRef()

    const handleToggleChange = (e) => {
        setChecked(e)
    };

    React.useEffect(() => {
        if (allMessages) {
            const scrolled = messageListRef.current.lastChild?.scrollIntoView({ behavior: "auto", block: "end" })
        }
    }, [casperExpanded])
    const filterMenu = () => (
        <div className="custom-filter-container" style={{ width: "100%", minWidth: "25em", marginTop: "1em", boxShadow: "4px 4px 28.7px 0px rgba(170, 186, 198, 0.25)", padding: ".5em" }}>
            <div className="casper-card casper-section-container" style={{ display: "flex", flexDirection: "column", background: "#fff" }}>
                <div className="message-action-container " style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1em", borderBottom: "none", padding: "0px" }}>
                    <h3 style={{ margin: "0px", fontSize: "1.1em", fontWeight: "500" }}>Turn {checked ? 'off' : 'on'} the Casper</h3>
                    <Switch
                        className="casper-action-switch"
                        style={{ backgroundColor: "#F6FBFF !important" }}
                        checked={checked}
                        onChange={(e) => {
                            handleToggleChange(e);
                        }}
                    />
                </div>
            </div>

        </div>
    )

    const sendMessageToCasper = async () => {
        const messageToSend = {
            userId: me.id,
            userMsg: message
        }
        try {
            const resp = await createMessage({ messageInput: messageToSend });
            allMessages.edges.push({ node: { userMsg: message } })
            const newMessages = JSON.parse(JSON.stringify(allMessages));
            setAllMessages(newMessages);
            setMessage("");
        }
        catch (error) {
            console.log(error)
        }
    }
    React.useEffect(() => {
    }, [allMessages])
    return (
        <>
            <div className="casper-fixed-container">
                <div className="casper-relative-container">
                    <div className={casperExpanded ? `casper-view-mode ${placement}` : `casper-hide-mode ${placement}`}>
                        <div className="casper-view-container">
                            <div className="casper-header-section">
                                <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center" }}>
                                    <img src={casper_icon} style={{ width: "45px", margin: "10px" }} alt="" />
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", gap: "1em", alignItems: "center", paddingRight: ".5em" }}>
                                    {true && true && <Dropdown overlayStyle={{ zIndex: 1500 }} className="casper-settings-dropdown" visible={dropdownVisible} overlay={filterMenu} placement={"bottomRight"}>
                                        <Settings style={{ cursor: "pointer", fill: "#A93AD5", width: "20px" }} onClick={() => setDropdownVisible(!dropdownVisible)} />
                                    </Dropdown>}
                                    {dropdownVisible ? (<div className='overlay-dynamic-island' onClick={() => { setDropdownVisible(false) }} />) : null}
                                    <Minimize onClick={() => { setCasperExpanded(false) }} style={{ cursor: "pointer", fill: "#A93AD5", width: "23px", height: "23px" }} />
                                    <Close onClick={() => { setCasperExpanded(false) }} style={{ cursor: "pointer", color: "#A93AD5", width: "17px" }} />
                                </div>
                            </div>

                            {/* ai casper list section */}
                            <div className="casper-list" ref={messageListRef}>
                                {
                                    allMessages?.edges?.map(({ node }, index) => {
                                        return (node?.userMsg && (
                                            <>
                                                <div className="user-msg-container" key={index}>
                                                    <div className="message-content">{node.userMsg}</div>
                                                    <div className="casper-profile">
                                                    {me?.profile?.profileImage ? <img src={me?.profile?.profileImage} style={{ width: "100%" }} alt="" /> : `${me.firstName}${me.lastName}`}
                                                    </div>
                                                </div>
                                                {node?.replyMsg && <div className="reply-msg-container" key={index}>
                                                    <div className="casper-profile">
                                                        <img src={casper_icon} style={{ width: "100%", padding: "10%" }} alt="" />
                                                    </div>
                                                    <div className="message-content">{node.replyMsg}</div>
                                                </div>}
                                            </>
                                        ))
                                    }
                                    )
                                }
                            </div>
                            <div className="casper-footer-section">
                                <CasperListening />
                                <Input className="capser-message-input" placeholder="Chat with casper" value={message} onChange={(e) => setMessage(e.target.value)} />
                                <SendMessage onClick={sendMessageToCasper} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default compose(withSendMessage)(CasperView)
