import React from "react";
import { Button, Col, Input, Row, Spin, Tabs, Tooltip } from "antd";
import search from '../../assets/search.svg'
import { displayUtcToUserTimezone, permissionValidation } from "modules/look";
import DeleteConfiramtion from "./deleteConfirmationModal";
const IssuedBadgeDetail =(props)=>{
    
    const {employeeList,employeeSearch,Oncancel,loading,deleteBadgeAward,permission,selectedAwardData} = props
    console.log("employeeList!!!!!!!",employeeList)
    const [ deleteCofirm, setDeleteConfirm ] = React.useState( false )
    const [selectedAward,setSelectedAward]=React.useState()
    const[allEmployeeList,setAllEmployee]=React.useState()
    const[allBadges,setAllBadges]=React.useState()
    const[selectedEmployee,setSelectedEmployee]=React.useState()
    const[searchActive,setSearchActive]=React.useState(false)
    React.useEffect(()=>{
      if(employeeList?.length>0){
        let badgesList =employeeList?.map((item)=>(
          {
            id:item?.id,
            badge:item?.badge,
            recipient:item?.recipient,
            expirationDate:item?.expirationDate,
            evidence:item?.evidence,
            family:item?.badge?.badgeFamily?.title
          }
        ))
        setAllBadges(badgesList)
        setSelectedAward(badgesList[0])
        let list =employeeList?.map( item => item?.recipient)
        setSelectedEmployee(list[0]?.user?.id)
           var setObj = new Set();
          var result =  list.reduce((acc,item)=>{
          if(!setObj.has(item?.user?.id)){
          setObj.add(item?.user?.id)
          acc.push(item)
          }
          return acc;
        },[])
        let filterList = result.map(item=> list?.filter((n=>n?.user?.id==item?.user?.id)))
        console.log("groupedMap",filterList)
        setAllEmployee(filterList)
      }
      else if(!loading&&employeeList?.length==0&&!searchActive){
        setSelectedAward()
        setSelectedEmployee()
        Oncancel()
      }
    },[employeeList])

    const selectedEmployeeData=(value)=>{
      if(value){
        let data =allBadges?.filter((item)=>item?.recipient?.user?.id===value)
        setSelectedEmployee(value)
        setSelectedAward( data[0])
        
      }
    }

    const searchInput=(value)=>{
      console.log("value",value)
      if(value){
        setSearchActive(true)
      }
      else{
        setSearchActive(false)
      }
    }
    return (
      <>
        <Row justify="center" align="center" style={{ width: "100%",marginTop: "20px" }}>
        <Col lg={17} md={16} sm={24} xs={24} style={{ width: "100%",paddingRight:"10px" }}>
        <Spin spinning={loading}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center",padding: "10px",width:"100%", background: "rgba(246, 246, 246, 0.62)" }}>
              {employeeList?.length>0&&<div style={{width:"100%"}}>
                <div className="user-badge-award-popup-inner-container" style={{width:"100%",gap:"10px" }}>
                  <div className="user-profile-award-detail-left-container">
                    <h4 className="user-badge-award-subhead" style={{ fontWeight: "400", whiteSpace: "nowrap",paddingLeft:"10px",paddingTop:"-10px" }}>{selectedAwardData?.badge?.badgeFamily?.title}</h4>
                    <img src={selectedAward?.badge?.image} alt="" style={{width:"100%"}}/>
                  </div>
                  <div className="user-profile-award-detail-right-container" style={{width:"100%"}}>
                    <div className="user-profile-award-detail-right-container-inner">
                      <div style={{ display: 'flex', flexDirection: "row", justifyContent: "end", alignContent: "end", width: "100%" }}>
                        <h4 className="user-badge-award-selected-description-modal" style={{ color: "#0098F0", fontWeight: "400",marginRight:"30px" }}>EXPIRY DATE : {displayUtcToUserTimezone( selectedAward?.expirationDate, 'DD MMM YYYY' )}</h4>
                      </div>
                      <h4 className="user-badge-award-selected-title-modal" style={{ margin: 0, fontWeight: "700" }}>1. {selectedAward?.badge?.title}</h4>
                      <p className="user-badge-award-selected-description-modal" style={{paddingLeft:"15px"}}>{selectedAward?.badge?.behaviouralDescription}</p>
                      <h4 className="user-badge-award-selected-description-modal" style={{ color: "#000000", fontWeight: "500", paddingTop: "10px", paddingBottom: "10px", whiteSpace: "nowrap",paddingLeft:"15px" }}>AWARDED TO  :  <span className="text-transform-capitalize">{selectedAward?.recipient?.user?.firstName}</span> <span className="text-transform-capitalize">{selectedAward?.recipient?.user?.lastName}</span></h4>
                      <p className="user-badge-award-selected-description-modal" style={{paddingLeft:"15px"}}>Rationale : {selectedAward?.evidence}</p>
                    </div>
                  </div>
                </div>
                <Row justify="center" align="center" style={{ width: "100%", marginTop: "10px" }}>
                  {
                    permission?.can_award_badge &&<Button className="kudos-delete-btn" style={{ width: "fit-content",fontSize:"16px",fontWeight:"700" }} onClick={()=>{setDeleteConfirm(true);setSearchActive(false)}}>CANCEL KUDOS</Button>
                  }
                  
                </Row>

              </div>}
              {employeeList?.length==0&&<div style={{width:"100%",justifyContent:"center",alignItems:'center',display:'flex',paddingTop:"150px",paddingBottom:"100px"}}>
                <h4 className="no-data">No Kudos Found</h4>
              </div>}
          </div>
          </Spin>
        </Col>
        <Col lg={7} md={8} sm={24} xs={24} style={{ width: "100%",paddingLeft:"10px"}}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: 'start', alignItems: "center",padding: "10px 15px",background: "rgba(246, 246, 246, 0.62)",height:"100%"}}>
            <h4 className="user-badge-award-popup-title" style={{ fontWeight: "500", margin: "0px" }}>Awarded To</h4>
            <div className="kudos-search-container">
              <img className="kudos-search-icon" src={search} alt="" />
              <Input className="kudos-search-input" autoComplete='off' onChange={( e ) => {employeeSearch(e?.target.value);searchInput(e?.target.value) }} />
            </div>
            <div style={{width:"100%"}}>
              <Spin spinning={loading}>
              {employeeList?.length>0&&<div style={{paddingTop:"10px" ,display:'flex',flexDirection:"column", justifyContent:'start',alignItems:'start',width:"100%",height:"100%"}}>
                  <div className="user-badge-award-popup-overflow" style={{ overflow: 'auto', maxHeight: "400px", padding: "10px 10px",width:"100%", }}>
                    {
                      allEmployeeList?.length>0&&allEmployeeList?.map((item,index)=>(
                        <p onClick={()=>selectedEmployeeData(item[0]?.user?.id)} className={selectedEmployee==item[0]?.user?.id?"user-profile-issued-employee-name-selected":"user-profile-issued-employee-name"} style={{textAlign:"left",width:"100%",cursor:"pointer",whiteSpace:"nowrap"}}>{index+1}. <span className="text-transform-capitalize">{item[0]?.user?.firstName}</span> <span className="text-transform-capitalize">{item[0]?.user?.lastName}</span> {item?.length>1&&<span style={{color:"#4CCB1F"}}>+{item?.length-1}</span>}</p>
                      ))
                    }
                  </div>
              </div>}
              {employeeList?.length==0&&<div style={{width:"100%",justifyContent:"center",alignItems:'center',display:'flex',paddingTop:"50px",paddingBottom:"100px"}}>
              <h4 className="no-data">No Employees</h4>
          </div>}
              </Spin>
            </div>
            
          </div>
        </Col>
      </Row>
      {deleteCofirm && <DeleteConfiramtion {...props} Visible={deleteCofirm} deleteType={{ id: selectedAward?.id, type: "badge_award" }} closeModal={() => { setDeleteConfirm( false )}} />}
    </>
    )

}

export default IssuedBadgeDetail