import React from "react";
import ComparisonSlectionView from "../components/comparison-selection-popup-view";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { All_EMPLOYEE_LIST_FOR_COMPARISON, EMPLOYEE_LIST_FOR_TEAM, EMPLOYEE_LIST_FOR_VERTICAL } from "../graphql/CompletedSurvey.gql";
import { Capitalize, getIntFromString, globalPermissionValidator } from "modules/look";
import { soul_permission } from "Permissions/soul.permission";
const ComparisonSlectionContainer =(props)=>{
    const {me,client,userPermission}=props
    const [employee_list,setEmployeeList]=React.useState([])
    const [all_employee_list, setAllEmployeeList] = React.useState([])
    const [vertical_list, setVerticalList] = React.useState([])
    const [team_list, setTeamList] = React.useState([])
    const [permission, setPermission] = React.useState()
    React.useEffect(() => {

        if (userPermission?.length) {
            let soulPermission = globalPermissionValidator(soul_permission, userPermission);
            setPermission(soulPermission)
        }
    }, [userPermission])

    React.useEffect(() => {
        if (all_employee_list?.length || vertical_list?.length || team_list?.length) {
            let lists = [...all_employee_list, ...vertical_list, ...team_list]
            var setObj = new Set();
            var result = lists?.reduce((acc, item) => {
                if (!setObj.has(item?.emp_id)) {
                    setObj.add(item?.emp_id)
                    acc.push(item)
                }
                return acc;
            }, [])
            setEmployeeList(result)
        }
    }, [all_employee_list, vertical_list, team_list])

    React.useEffect(() => {
        if (permission?.required_permission) {
            if (me?.employee?.orgCeoEmployee?.totalCount > 0 || me?.employee?.orgPocEmployee?.totalCount > 0) {
                getEmployeeList()
            }
            else if (me?.employee?.verticalHeadEmployee?.totalCount > 0) {
                getEmployeeForVerticals(me?.employee?.verticalHeadEmployee?.edges?.map(({ node }) => getIntFromString(node?.id)))
            }
            else if (me?.employee?.teamManagerEmployee?.totalCount > 0) {
                getEmployeeForTeam(me?.employee?.teamManagerEmployee?.edges?.map(({ node }) => getIntFromString(node?.id)))
            }
            else if (me?.employee?.verticalHeadEmployee?.totalCount > 0 && me?.employee?.teamManagerEmployee?.totalCount > 0) {
                getEmployeeForVerticals(me?.employee?.verticalHeadEmployee?.edges?.map(({ node }) => getIntFromString(node?.id)))
                getEmployeeForTeam(me?.employee?.teamManagerEmployee?.edges?.map(({ node }) => getIntFromString(node?.id)))
            }
        }
    }, [permission])

    const getEmployeeList=async()=>{
        const { data } = await client.query({
            query: All_EMPLOYEE_LIST_FOR_COMPARISON,
            variables: {},
            fetchPolicy: 'network-only'
        });
        if(data?.allEmployees){
            let employeeList = data.allEmployees?.edges?.map(({node})=>{
                return {
                    emp_id:node?.id,
                    user_id:node?.user?.id,
                    name:Capitalize(node?.user?.firstName) + ' ' + Capitalize(node?.user?.lastName)
                }
            })
            setAllEmployeeList(employeeList)
        }
    }
    const getEmployeeForVerticals = async(ids)=>{
        try {
            const { data } = await client.query({
                query: EMPLOYEE_LIST_FOR_VERTICAL,
                variables: { id_In: ids },
                fetchPolicy: 'network-only'
            });
            if (data?.verticals) {
                let data_list = [].concat(
                    data?.verticals?.edges?.map(({ node }) => node?.employees?.edges?.map(item => {
                        return {
                            emp_id:item?.node?.id,
                            user_id:item?.node?.user?.id,
                            name:Capitalize(item?.node?.user?.firstName) + ' ' + Capitalize(item?.node?.user?.lastName)
                        }
                    }))
                ).flat()
                setVerticalList(data_list)
            }
        } catch (e) {

        }
    }
    const getEmployeeForTeam =async(ids)=>{
        try {
            const { data } = await client.query({
                query: EMPLOYEE_LIST_FOR_TEAM,
                variables: { id_In: ids },
                fetchPolicy: 'network-only'
            });
            if (data?.Teams) {
                let data_list = [].concat(data?.Teams?.edges?.map(({ node }) => node?.employees?.edges?.map(item => {
                    return {
                        emp_id:item?.node?.id,
                        user_id:item?.node?.user?.id,
                        name:Capitalize(item?.node?.user?.firstName) + ' ' + Capitalize(item?.node?.user?.lastName)
                    }
                }))).flat()
                setTeamList(data_list)
            }
        } catch (e) {

        }
    }
    return (
        <>
            <ComparisonSlectionView 
            employee_list={employee_list}
            {...props}/>
        </>
    )
}

export default compose(withApollo)(ComparisonSlectionContainer)