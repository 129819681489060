// @ts-nocheck
import React from 'react'
import { Row, Col, Button, Card, Divider,Modal,Spin,Tabs ,Dropdown,Menu,Input, Radio,Select, Form,Tooltip } from "antd";
import { Capitalize } from 'modules/look';
const CommaSeparateComponent =(props)=>{
    const { textList, showCount, title } = props;
    const [visible, setVisible] = React.useState(false)
    const [shownVals, setShownVals] = React.useState([])
    const [arrVals, setArrVals] = React.useState("")
    const [list, setList] = React.useState()
   
    React.useEffect(() => {
        if(textList?.length){
             let obj = {"Owner":textList?.find(item=>item?.role==="OWNER")}
             
             let assined=textList.filter(item=>item?.role==="ASSIGNED" && item.id!==obj?.Owner?.id)
             if(assined?.length){
                obj["Assigned_to"]=assined
             }
             let Collabarators=textList.filter(item=>item?.role==="COLLABORATOR" && item.id!==obj?.Owner?.id)
             if(Collabarators?.length){
                obj["Collaborators"]=Collabarators
             }
             console.log('textList',textList,obj);
            // var setObj = new Set();
            // var result = textList.reduce((acc,item)=>{
            //   if(!setObj.has(item?.id)){
            //     setObj.add(item?.id)
            //     acc.push(item)
            //   }
            //   return acc;
            // },[])
            obj["count"]=(obj?.Assigned_to?.length||0) + (obj?.Collaborators?.length||0)
            setList(obj)
        }
      
    }, [textList])


    const FilterItem =()=> (
        <Row align='middle' style={{flexDirection:"column",padding:"20px",position:'relative',marginTop:"-25px"}}>
           
            <div className='select-content-div'  style={{width:"170px"}}>
                <h4 className='menu-head' >Owner</h4>
                <div className='item-div' align="center" style={{padding:"0px 5px"}}>
                    <div className='selected-item' style={{background: "#F0F0F0",marginTop:"7px"}}>
                        <p style={{width:"100%",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis",color: "#595959"}}><span className='text-transform-capitalize'>{Capitalize(list?.Owner?.firstName)}</span> <span className='text-transform-capitalize'>{Capitalize(list?.Owner?.lastName)}</span></p>
                    </div>
                </div>
                
               { list?.Assigned_to?.length?
                <>
                <h4 className='menu-head' style={{marginTop:"20px"}}>Assigned To</h4>
                {list?.Assigned_to?.map((value,index)=>(
                    
                    <div className='item-div' align="center" style={{padding:"0px 5px"}}>
                        <div className='selected-item' style={{background: "#F0F0F0",marginTop:"7px"}}>
                            <p style={{width:"100%",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis",color: "#595959"}}><span className='text-transform-capitalize'>{Capitalize(value?.firstName)}</span> <span className='text-transform-capitalize'>{Capitalize(value?.lastName)}</span></p>
                        </div>
                    </div>
                ))} 
                </>
                :""}

              {  list?.Collaborators?.length?
                <>
                <h4 className='menu-head' style={{marginTop:"20px"}}>Collaborators</h4>
                {list?.Collaborators?.map((value,index)=>(
                    
                    <div className='item-div' align="center" style={{padding:"0px 5px"}}>
                        <div className='selected-item' style={{background: "#F0F0F0",marginTop:"7px"}}>
                            <p style={{width:"100%",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis",color: "#595959"}}>{Capitalize(value?.firstName)} {Capitalize(value?.lastName)}</p>
                        </div>
                    </div>
                ))} 
                </>
                :""}
                    
                
               
            </div>
        </Row>
    )

    return (
        <>
        <div className='comma-separated-text' style={{display:"flex",flexDirection:"row",flexWrap:"nowrap",marginTop:"10px",paddingBottom:"8px"}}>
            {Capitalize(list?.Owner?.firstName)}
            {list?.count>=1 && (
            <>
                <span  style={{whiteSpace:"nowrap",flexWrap:'nowrap',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                <Dropdown placement={'bottomCenter'} overlay={FilterItem} trigger="click">
                <span style={{border:"none",cursor:"pointer",marginLeft:"3px",color:"#B0CB1F"}}>+{list?.count}</span>
                </Dropdown> 
                </span>
            </>
            )}
        </div>

        </>
    )
}

export default CommaSeparateComponent