import { compose } from "modules/core";
import SurveyResponseVerticalView from "modules/poc-dashboard/components/dashboard/surveyResponseVerticalView";
import React from "react";
import { withApollo } from '@apollo/client/react/hoc';
import { ORG_POC_SURVEY_LIST, ORG_POC_VERTICAL_SURVEY_RESPONSE, ORG_SURVEY_BY_ID, ORG_VERTICAL_DETAIL, TEAM_POC_ASSESSMENT_RESPONSE } from "modules/poc-dashboard/graphql/pocDashboardQuery.gql";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import { message } from "antd";
import { getIntFromString, getSurveyBase64StringFromInt, getTeamBase64StringFromInt, getVerticalBase64StringFromInt } from "modules/look";
import { ORG_POC_TEAM_GRAPH_DATA, VERTICAL_LIST } from "modules/poc-dashboard/graphql/organizationQuery.gql";

export const SurveyResponseVertical =(props)=>{
    const {client,match,navigateRoute,me}=props
    console.log("location",match)
    const [tableData,setTableData]=React.useState([])
    const [table_loading,setTableLoading]=React.useState(false)
    const [reminder_data, SetRemainderData] = React.useState()
    const [query_loader, SetQueryLoader] = React.useState(false)
    const [selected_employees, setSelectedEmployees] = React.useState([])
    const [select_all,setSelectAll]=React.useState(false)
    const [verical_response_page_info, setVericalResponsePageInfo] = React.useState()
    const [org_survey_list,setOrgSurveyList]=React.useState()
    const [selected_survey_detail,setSelectedSurveyDetail]=React.useState()
    const [vertical_list,setVerticalList]=React.useState()
    const [selected_vertical_detail,setSelectedVerticalDetail]=React.useState()
    const table_data_ref = React.useRef([])
    const [team_assessment_data,setTeamAssessmentData]=React.useState()
    const [selected_team_detail,setSelectedTeamDetail]=React.useState()
    React.useEffect(()=>{
        table_data_ref.current = tableData
    },[tableData])

    React.useEffect(()=>{
        if(match?.params){
            let survey_id = localStorage.getItem("poc_report_survey_id")
            let vertical_id = localStorage.getItem("poc_report_vertical_id")
            setTableLoading(true)
            getOrgSurveyList()
            getSelectedSurveyDetail({id:getSurveyBase64StringFromInt(survey_id || match.params?.survey_id)})
            verticalList()
            SelectedVerticalDetail({id:getVerticalBase64StringFromInt(vertical_id || match.params?.id)})
            
        }
        else{
            navigateRoute('-1')
        }
    },[match])

    React.useEffect(()=>{
        if(selected_vertical_detail){
            localStorage.setItem("poc_report_vertical_id",getIntFromString(selected_vertical_detail?.id)) 
        }
    },[selected_vertical_detail])

    React.useEffect(()=>{
        if(selected_survey_detail){
            localStorage.setItem("poc_report_survey_id",getIntFromString(selected_survey_detail.id))
        }
    },[selected_survey_detail])

   React.useEffect(()=>{ 
    if(selected_survey_detail!==undefined && selected_vertical_detail!==undefined &&me?.employee){
        setTableLoading(true)
        setSelectedTeamDetail(null)
        getVerticalSurveyResponse()
        verticalAssessmentData({id:me?.employee?.organizationSet?.edges[0]?.node?.id,survey_id:selected_survey_detail?.id,vid:getIntFromString(selected_vertical_detail?.id)?.toString()})
    }
   },[selected_vertical_detail,selected_survey_detail,me])

   const parseObject = (str) => {
    return JSON.parse(str.replace(/'/g, '"'));
    };

   const verticalAssessmentData=async(filter)=>{
    const { data } = await client.query({
        query: ORG_POC_TEAM_GRAPH_DATA,
        variables: {...filter},
        fetchPolicy: 'network-only'
    });
    if (data?.organizationById) {
        setTeamAssessmentData(data?.organizationById?.surveySet?.edges[0]?.node?.summaryListByVerticalTeam?.map(parseObject))
    }
}

    const getOrgSurveyList =async()=>{
        const { data } = await client.query({
            query: ORG_POC_SURVEY_LIST,
            fetchPolicy: 'network-only'
          });
          if (data?.allOrgSurveysByImplicitOrgId?.edges?.length) {
            setOrgSurveyList(data?.allOrgSurveysByImplicitOrgId?.edges?.map(({node})=>node))
          }
    }
    const getSelectedSurveyDetail =async(filter)=>{
        const { data } = await client.query({
            query: ORG_SURVEY_BY_ID,
            fetchPolicy: 'network-only',
            variables: {...filter},
          });
          if (data?.SurveyById) {
            setSelectedSurveyDetail({...data?.SurveyById,is_360:data?.SurveyById?.multisourceResponse})
          }
    }
    const verticalList = async()=>{
        const { data } = await client.query({
            query: VERTICAL_LIST,
            fetchPolicy: 'network-only'
          });
          if (data.verticals?.edges?.length) {
            setVerticalList(data.verticals?.edges?.map(({node})=>node))
          }
    }
    const SelectedVerticalDetail =async(filter)=>{
        const { data } = await client.query({
            query: ORG_VERTICAL_DETAIL,
            fetchPolicy: 'network-only',
            variables: {...filter},
          });
          if (data?.vertical) {
            setSelectedVerticalDetail(data?.vertical)
          }
    }
    const ALL_REMINDER = gql`
    query remindUserSurveyById {
      ${reminder_data?.map(
        (item,index) =>
            `query_${index}: remindUserSurveyById(id:${item?.id},usr:${item?.usr},tlink:"${item?.tlink}")`
    )
        .join('')}
    }
`;

    const [emailReminder] = useLazyQuery(ALL_REMINDER, { onCompleted: (data) => { onCompletedReminder(data) }, onError: (data) => { onErrorReminder(data) } });

    React.useEffect(() => {
        if (reminder_data?.length) {
            SetQueryLoader(true)
            emailReminder()
        }
    }, [reminder_data])

    const onCompletedReminder = (data) => {
        message.success('Email reminders has been send')
        setSelectedEmployees([])
        setSelectAll(false)
        SetQueryLoader(false)
    }

    const onErrorReminder = (data) => {
        message.error('Failed to send email reminders')
        SetQueryLoader(false)
    }
    const Capitalize = (value) => value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : '';
    const getVerticalSurveyResponse =async(filter)=>{
        setTableLoading(true)
        const { data } = await client.query({
            query: ORG_POC_VERTICAL_SURVEY_RESPONSE,
            variables: {
                ...filter,
                id:selected_vertical_detail?.id,
                survey:selected_survey_detail?.id,
                first: 5
            },
            fetchPolicy: 'network-only'
          });
          if (data?.vertical) {
                setVericalResponsePageInfo({
                    hasNextPage: data?.vertical?.employees?.pageInfo?.hasNextPage,
                    cursor: data?.vertical?.employees?.pageInfo?.endCursor
                })
                let employee_list =data?.vertical?.employees?.edges?.map(({node})=>{
                    return{
                        id:node?.id,
                        user_id:node?.user?.id,
                        firstName:Capitalize(node?.user?.firstName),
                        lastName:Capitalize(node?.user?.lastName),
                        profileImage:node?.user?.profile?.profileImage,
                        status:node?.user?.responseSet?.edges?.length?'Completed':'Pending',
                        responseDate:node?.user?.responseSet?.edges[0]?.node?.responseDate,
                        team:node?.teamMembersEmployee?.edges?.length?node?.teamMembersEmployee?.edges?.map(team=>team?.node):node?.teamManagerEmployee?.edges?.map(team=>team?.node),
                        is_360:node?.user?.responseSet?.edges[0]?.node?.survey?.multisourceResponse,
                        response_id:node?.user?.responseSet?.edges[0]?.node?.id,
                        employee_id:node?.user?.employee?.id
                    }
                })
                let data_list = filter?.cursor?table_data_ref?.current.concat(employee_list):employee_list
                setTableData(data_list)
                setTableLoading(false)
          }
    }
    const getTeamSurveyResponse =async(filter)=>{
        setTableLoading(true)
        const { data } = await client.query({
            query: TEAM_POC_ASSESSMENT_RESPONSE,
            variables: {
                ...filter,
                id:getTeamBase64StringFromInt(selected_team_detail?.id),
                survey:selected_survey_detail?.id,
                first: 5
            },
            fetchPolicy: 'network-only'
          });
          if (data?.Team) {
                setVericalResponsePageInfo({
                    hasNextPage: data?.Team?.employees?.pageInfo?.hasNextPage,
                    cursor: data?.Team?.employees?.pageInfo?.endCursor
                })
                let employee_list = data?.Team?.employees?.edges?.map(({node})=>{
                    return{
                        id:node?.id,
                        user_id:node?.user?.id,
                        firstName:Capitalize(node?.user?.firstName),
                        lastName:Capitalize(node?.user?.lastName),
                        profileImage:node?.user?.profile?.profileImage,
                        status:node?.user?.responseSet?.edges?.length?'Completed':'Pending',
                        responseDate:node?.user?.responseSet?.edges[0]?.node?.responseDate,
                        is_360:node?.user?.responseSet?.edges[0]?.node?.survey?.multisourceResponse,
                        response_id:node?.user?.responseSet?.edges[0]?.node?.id,
                        employee_id:node?.user?.employee?.id,
                        team:[{id:data?.Team?.id,name:data?.Team?.name}]
                    }
                })
                let data_list = filter?.cursor?table_data_ref?.current.concat(employee_list):employee_list
                setTableData(data_list)
                setTableLoading(false)
          }
    }
    React.useEffect(()=>{
        if(selected_team_detail){
            getTeamSurveyResponse()
        }
    },[selected_team_detail])
    return (
        <>
        <SurveyResponseVerticalView {...props} 
        tableData={tableData} 
        table_loading={table_loading}
          SetRemainderData={SetRemainderData}
          selected_employees={selected_employees} 
          setSelectedEmployees={setSelectedEmployees}
          setSelectAll={setSelectAll}
          select_all={select_all}
          verical_response_page_info={verical_response_page_info}
          getVerticalResponseList={(filter)=>getVerticalSurveyResponse({...filter })}
          getTeamSurveyResponse={(filter)=>getTeamSurveyResponse({...filter })}
          org_survey_list={org_survey_list}
          selected_survey_detail={selected_survey_detail}
          vertical_list={vertical_list} 
          selected_vertical_detail={selected_vertical_detail}
          setSelectedVerticalDetail={e=>setSelectedVerticalDetail(e)}
          setSelectedSurveyDetail={(e)=>setSelectedSurveyDetail(e)}
          team_assessment_data={team_assessment_data}
          setSelectedTeamDetail={setSelectedTeamDetail}
          selected_team_detail={selected_team_detail}/>
        </>
    )
}

export default compose(withApollo)(SurveyResponseVertical)