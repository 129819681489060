// @ts-nocheck
import React from 'react'
import styled from "styled-components";
import { Capitalize, MetaTags, getIntFromString } from "../../look";
import GenericTool from "../../tool/containers/GenericTool";
import ReactMarkdown from '../../look/components/ReactMarkdown';
import { PlusCircleOutlined } from "@ant-design/icons";
import { Row, Col, Button, Slider, Spin, Form, Space, message, Modal, Badge, Tooltip } from "antd";
import DeleteIcon from "../../look/components/DeleteIcon";
import { FetchValidRuleStates } from '../../../readinessRuleConfig'
import { useParams } from 'react-router-dom';
import SelectFieldReainess from './selecteFieldReadiness';
import Addskills from './Addskills';
import new_back_arrow from '../../assets/new_back_arrow.svg';
import { READINESS_ADD_OR_EDIT_TOOL_ID, SURVEY_READINESS_LEVEL } from '../../../config';
import readiness_route, { readiness_zone_route } from '../routes/index'
import SkillField from 'modules/look/components/form-components/SkillField';
import remove_readiness from '../../assets/readiness-remove.svg'
import SideBulbToolContainer from 'modules/look-v2/Container/SideBulbToolContainer';
import WarningModal from './warningModal';
const EditReadinessView = (props) => {
    const { navigateRoute, SurveyById, readinessById, updateReadiness, createReadinessLevel, deleteReadinessLevel, withSkillList, onSkillSearch, permission } = props
    let p = useParams()
    const [loading, setloading] = React.useState(false);
    const [recipientName, setrecipientName] = React.useState();
    const [keyResultid, setkeyResultid] = React.useState();
    const [keyResultname, setkeyResultname] = React.useState();
    const [milestoneid, setmilestoneid] = React.useState();
    const [milestonename, setmilestonename] = React.useState();
    const [questionList, setquestionList] = React.useState([]);
    const [answersList, setAnswers] = React.useState([])
    const [selectedSkill, setSelectedSkill] = React.useState([])
    const [skillList, setskillList] = React.useState([])
    const [newSkill, setnewSkill] = React.useState([])
    const [sliderError, setSliderError] = React.useState({})
    const [sliderVals, setSliderVals] = React.useState({})
    const [sliderAnsIdMap, setSliderAnsIdMap] = React.useState({})
    const [sliderNewVals, setSliderNewVals] = React.useState({})
    const [sliderNewError, setSliderNewError] = React.useState({})
    const [selectedSkillbutton, setselectedSkillbutton] = React.useState([]);
    const trackStyle = { backgroundColor: '#B0CB1F' }
    const handleStyle = { backgroundColor: '#B0CB1F' }
    const [form] = Form.useForm();
    const [addSkillData, setaddSkillData] = React.useState()
    const [SkillDataList, setSkillDataList] = React.useState([]);
    const [tempSkillList, settempSkillList] = React.useState();
    const [toolReturn, setToolReturn] = React.useState()
    const [open_edit_warning,setEditWarning]=React.useState(false)
    React.useEffect(() => {
        let localSliderMap = {}
        /* eslint-disable no-unused-expressions */
        skillList?.map(({ node }) => {
            let aSet = node?.response?.answerSet
            let baseId = undefined
            aSet?.edges?.map(({ node }) => {
                if (getIntFromString(node?.question?.id) === 688) {
                    let key = String(getIntFromString(node?.question?.id)) + ":" + String(getIntFromString(node?.id))
                    let updatedValue = { [key]: [] };
                    localSliderMap = { ...localSliderMap, ...updatedValue }
                    baseId = key
                    let sliderKey = getIntFromString(node?.id)
                    setSliderVals((prevState) => ({
                        ...prevState,
                        [sliderKey]: Number(node?.answer),
                    }));
                }
            })
            aSet?.edges?.map(({ node }) => {
                if (getIntFromString(node?.question?.id) !== 688) {
                    let currValue = localSliderMap[baseId]
                    currValue.push([getIntFromString(node?.question?.id), getIntFromString(node?.id)])
                    let updatedValue = { [baseId]: currValue };
                    localSliderMap = { ...localSliderMap, ...updatedValue }
                    let sliderKey = getIntFromString(node?.id)
                    setSliderVals((prevState) => ({
                        ...prevState,
                        [sliderKey]: Number(node?.answer),
                    }));
                }
            })
        })
        setSliderAnsIdMap(localSliderMap)
    }, [skillList])

    React.useEffect(() => {
        if (withSkillList) {
            let skillset = {}
            let skillErrorSet = {}
            withSkillList.edges.forEach(s => {
                if (!sliderNewVals[getIntFromString(s.node.id)]) {
                    skillset[getIntFromString(s.node.id)] = { 688: 0, 689: 0, 690: 0, 691: 0 }
                    skillErrorSet[getIntFromString(s.node.id)] = { 688: undefined, 689: undefined, 690: undefined, 691: undefined }
                }
            })
            // setSliderNewVals(skillset)
            // setSliderNewError(skillErrorSet)
            setSliderNewVals((prevState) => ({
                ...prevState,
                ...skillset,
            }));
            setSliderNewError((prevState) => ({
                ...prevState,
                ...skillErrorSet,
            }));
            setSkillDataList(withSkillList)
            if (!tempSkillList) {
                settempSkillList(withSkillList)
            }
        }

    }, [withSkillList])

    const back = () => {
        let params = new URLSearchParams(window.location.search)
        let tab = params.get("tab")
        if(tab){
            navigateRoute(readiness_zone_route?.readinessHome?.replace(":type",tab))
        }else{
            navigateRoute("-1");
        }
    };

    React.useEffect(() => {
        setrecipientName(`${Capitalize(readinessById?.readinessTo?.user?.firstName)} ${Capitalize(readinessById?.readinessTo?.user?.lastName) || ''}`)
        setkeyResultname(readinessById?.keyResult?.title)
        setkeyResultid(getIntFromString(readinessById?.keyResult?.id))
        setmilestonename(readinessById?.milestone?.title)
        setmilestoneid(readinessById?.milestone?.id)
    }, [readinessById])

    React.useEffect(() => {
        if (withSkillList) {
            let skillset = {}
            let skillErrorSet = {}
            withSkillList.edges.forEach(s => {
                if (!sliderVals[getIntFromString(s.node.id)]) {
                    skillset[getIntFromString(s.node.id)] = { 688: 0, 689: 0, 690: 0, 691: 0 }
                    skillErrorSet[getIntFromString(s.node.id)] = { 688: undefined, 689: undefined, 690: undefined, 691: undefined }
                }
            })
            // setSliderVals(skillset)
            // setSliderError(skillErrorSet)
            setSliderVals((prevState) => ({
                ...prevState,
                ...skillset,
            }));
            setSliderError((prevState) => ({
                ...prevState,
                ...skillErrorSet,
            }));

        }
    }, [withSkillList])

    React.useEffect(() => {
        if (readinessById && withSkillList) {
            let list = readinessById?.readinesslevelSet?.edges
            setskillList(list)
            setSelectedSkill(list)
        }
    }, [readinessById, withSkillList])

    React.useEffect(() => {
        if (SurveyById) {
            let list = SurveyById?.groupSet?.edges[0]?.node?.questionSet?.edges?.map(({ node }) => node)
            setquestionList(list)
        }
    }, [SurveyById])


    const submitAnswer = (value, dataId, nodeId, skillid) => {
        let tempAnswer = [].concat(answersList)
        let previousSkill = tempAnswer.find(item => getIntFromString(item?.skillId) === getIntFromString(skillid))
        if (previousSkill) {
            let previousAnswerData = previousSkill.answers.find(item => getIntFromString(item?.questionId) === getIntFromString(dataId))
            if (previousAnswerData) {
                let index = previousSkill.answers.indexOf(previousAnswerData)
                previousSkill.answers.splice(index, 1)
                previousSkill.answers.push(
                    {
                        id: getIntFromString(dataId),
                        answer: value
                    }
                )
            }
            else {
                if (!previousSkill.answers?.length) {
                    previousSkill.answer = []
                }
                previousSkill.answers.push(
                    {
                        id: getIntFromString(dataId),
                        answer: value
                    }
                )
            }
        }
        else {
            let data = {
                answers: [
                    {
                        id: getIntFromString(dataId),
                        answer: value
                    }
                ],
                id: getIntFromString(nodeId),
                skillId: getIntFromString(skillid),
            }
            tempAnswer.push(data)
        }
        setAnswers(tempAnswer)

        //validations
        // rangeValidation(value, dataId)
    }
    function arraysEqual(a1, a2) {
        return JSON.stringify(a1) == JSON.stringify(a2);
    }
    const checkIfSliderIsDisabled = (qId, ansId) => {
        if (ansId !== undefined) {
            if (qId === 688) {
                return false
            } else {
                //find the matching key value in sliderAnsIdMap
                let parentId = undefined
                let parentAnsId = undefined
                let parentQId = undefined

                for (let [key, value] of Object.entries(sliderAnsIdMap)) {
                    value.forEach(v => {
                        if (arraysEqual(v, [qId, ansId])) {
                            parentId = key
                        }
                    })
                }

                //if parent is undefined that means 
                if (parentId === undefined) {
                    parentId = String(qId) + ':' + String(ansId)
                }
                parentQId = parentId.split(':')[0]
                parentAnsId = parentId.split(':')[1]

                if (qId === 689) {
                    if (sliderError[parentAnsId] !== undefined) {
                        return true
                    }
                    if (sliderVals[parentAnsId] === undefined) {
                        return true
                    }
                    return false
                }
                else if (qId === 690) {
                    let b = undefined
                    sliderAnsIdMap[parentId]?.forEach(p => {
                        if (p[0] === 689) {
                            b = p[1]
                        }
                    })
                    if (sliderError[parentAnsId] !== undefined) {
                        return true
                    }
                    if (sliderVals[parentAnsId] === undefined) {
                        return true
                    }
                    if (sliderError[b] !== undefined) {
                        return true
                    }
                    if (sliderVals[b] === undefined) {
                        return true
                    }
                    return false
                }
                else if (qId === 691) {
                    let b = undefined
                    let c = undefined
                    sliderAnsIdMap[parentId]?.forEach(p => {
                        if (p[0] === 689) {
                            b = p[1]
                        }
                        if (p[0] === 690) {
                            c = p[1]
                        }
                    })
                    if (sliderError[parentAnsId] !== undefined) {
                        return true
                    }
                    if (sliderVals[parentAnsId] === undefined) {
                        return true
                    }
                    if (sliderError[b] !== undefined) {
                        return true
                    }
                    if (sliderError[c] !== undefined) {
                        return true
                    }
                    if (sliderVals[b] === undefined) {
                        return true
                    }
                    if (sliderVals[c] === undefined) {
                        return true
                    }
                    return false
                }
            }
        }
        else {
            if (qId === 688) {
                return false
            } else {

                if (qId === 689) {
                    if (sliderError[688] !== undefined) {
                        return true
                    }
                    if (sliderVals[688] === undefined) {
                        return true
                    }
                    return false
                }
                else if (qId === 690) {
                    let b = undefined
                    if (sliderError[688] !== undefined) {
                        return true
                    }
                    if (sliderVals[688] === undefined) {
                        return true
                    }
                    if (sliderError[689] !== undefined) {
                        return true
                    }
                    if (sliderVals[689] === undefined) {
                        return true
                    }
                    return false
                }
                else if (qId === 691) {
                    if (sliderError[688] !== undefined) {
                        return true
                    }
                    if (sliderVals[688] === undefined) {
                        return true
                    }
                    if (sliderError[689] !== undefined) {
                        return true
                    }
                    if (sliderError[690] !== undefined) {
                        return true
                    }
                    if (sliderVals[689] === undefined) {
                        return true
                    }
                    if (sliderVals[690] === undefined) {
                        return true
                    }
                    return false
                }
            }
        }


    }
    const checkIfSliderIsDisabledNewCard = (qId, skillid) => {
        if (qId === undefined) {
            return false
        }
        if (skillid !== undefined) {
            if (qId === 688) {
                return false
            } else {

                if (qId === 689) {
                    if (sliderNewError[skillid][688] !== undefined) {
                        return true
                    }
                    if (sliderNewVals[skillid][688] === undefined) {
                        return true
                    }
                    return false
                }
                else if (qId === 690) {
                    let b = undefined
                    if (sliderNewError[skillid][688] !== undefined) {
                        return true
                    }
                    if (sliderNewVals[skillid][688] === undefined) {
                        return true
                    }
                    if (sliderNewError[skillid][689] !== undefined) {
                        return true
                    }
                    if (sliderNewVals[skillid][689] === undefined) {
                        return true
                    }
                    return false
                }
                else if (qId === 691) {
                    if (sliderNewError[skillid][688] !== undefined) {
                        return true
                    }
                    if (sliderNewVals[skillid][688] === undefined) {
                        return true
                    }
                    if (sliderNewError[skillid][689] !== undefined) {
                        return true
                    }
                    if (sliderNewError[skillid][690] !== undefined) {
                        return true
                    }
                    if (sliderNewVals[skillid][689] === undefined) {
                        return true
                    }
                    if (sliderNewVals[skillid][690] === undefined) {
                        return true
                    }
                    return false
                }
            }
        }
        else {
            return false
        }
    }


    const rangeValidation = (value, qId, ansId, prevVal) => {

        //find the matching key value in sliderAnsIdMap
        let parentId = undefined
        let parentAnsId = undefined
        let parentQId = undefined

        for (let [key, value] of Object.entries(sliderAnsIdMap)) {
            value.forEach(v => {
                if (arraysEqual(v, [qId, ansId])) {
                    parentId = key
                }
            })
        }

        //if parent is undefined that means 
        if (parentId === undefined) {
            parentId = String(qId) + ':' + String(ansId)
        }
        parentQId = parentId.split(':')[0]
        parentAnsId = parentId.split(':')[1]

        if (qId === 688) {
            let a = FetchValidRuleStates(undefined, undefined, undefined, undefined)
            if (!checkIfValInRng(value, a)) {
                let errString = "Range should be between "
                let errRange = ""
                a.forEach(valItem => {
                    if (errRange !== "") {
                        errRange = errRange + " or "
                    }
                    errRange = errRange + valItem[0] + " to " + valItem[1]
                })
                errString = errString + errRange
                let updatedValue = { [parentAnsId]: errString };
                setSliderError((prevState) => ({
                    ...prevState,
                    ...updatedValue,
                }));
            } else {
                let updatedValue = { [parentAnsId]: undefined };
                setSliderError((prevState) => ({
                    ...prevState,
                    ...updatedValue,
                }));
                setSliderVals((prevState) => ({
                    ...prevState,
                    [ansId]: value,
                }));

                // recursively call children
                let b = undefined
                sliderAnsIdMap[parentId].forEach(p => {
                    if (p[0] === 689) {
                        b = p[1]
                    }
                })
                rangeValidation(sliderVals[b], 689, b, value)
            }
        }
        if (qId === 689) {
            let l = prevVal
            if (l === undefined) {
                l = sliderVals[parentAnsId]
            }
            let b = FetchValidRuleStates(l, undefined, undefined, undefined)
            if (!checkIfValInRng(value, b)) {
                let errString = "Range should be between "
                let errRange = ""
                b.forEach(valItem => {
                    if (errRange !== "") {
                        errRange = errRange + " or "
                    }
                    errRange = errRange + valItem[0] + " to " + valItem[1]
                })
                errString = errString + errRange
                let updatedValue = { [ansId]: errString };
                setSliderError((prevState) => ({
                    ...prevState,
                    ...updatedValue,
                }));
            } else {
                let updatedValue = { [ansId]: undefined };
                setSliderError((prevState) => ({
                    ...prevState,
                    ...updatedValue,
                }));
                setSliderVals((prevState) => ({
                    ...prevState,
                    [ansId]: value,
                }));

                // recursively call children
                let c = undefined
                sliderAnsIdMap[parentId]?.forEach(p => {
                    if (p[0] === 690) {
                        c = p[1]
                    }
                })
                rangeValidation(sliderVals[c], 690, c, value)
            }
        }
        if (qId === 690) {
            let b = undefined
            sliderAnsIdMap[parentId].forEach(p => {
                if (p[0] === 689) {
                    b = p[1]
                }
            })
            let l = prevVal
            if (l === undefined) {
                l = sliderVals[b]
            }
            let c = FetchValidRuleStates(sliderVals[parentAnsId], l, undefined, undefined)
            if (!checkIfValInRng(value, c)) {
                let errString = "Range should be between "
                let errRange = ""
                c?.forEach(valItem => {
                    if (errRange !== "") {
                        errRange = errRange + " or "
                    }
                    errRange = errRange + valItem[0] + " to " + valItem[1]
                })
                errString = errString + errRange
                let updatedValue = { [ansId]: errString };
                setSliderError((prevState) => ({
                    ...prevState,
                    ...updatedValue,
                }));
            } else {
                let updatedValue = { [ansId]: undefined };
                setSliderError((prevState) => ({
                    ...prevState,
                    ...updatedValue,
                }));
                setSliderVals((prevState) => ({
                    ...prevState,
                    [ansId]: value,
                }));
                // recursively call children
                let d = undefined
                sliderAnsIdMap[parentId].forEach(p => {
                    if (p[0] === 691) {
                        d = p[1]
                    }
                })
                rangeValidation(sliderVals[d], 691, d, value)
            }
        }
        if (qId === 691) {
            let b = undefined
            let c = undefined
            sliderAnsIdMap[parentId].forEach(p => {
                if (p[0] === 689) {
                    b = p[1]
                }
                if (p[0] === 690) {
                    c = p[1]
                }
            })
            let l = prevVal
            if (l === undefined) {
                l = sliderVals[c]
            }
            let d = FetchValidRuleStates(sliderVals[parentAnsId], sliderVals[b], l, undefined)
            if (!checkIfValInRng(value, d)) {
                let errString = "Range should be between "
                let errRange = ""
                d.forEach(valItem => {
                    if (errRange !== "") {
                        errRange = errRange + " or "
                    }
                    errRange = errRange + valItem[0] + " to " + valItem[1]
                })
                errString = errString + errRange
                let updatedValue = { [ansId]: errString };
                setSliderError((prevState) => ({
                    ...prevState,
                    ...updatedValue,
                }));
            } else {
                let updatedValue = { [ansId]: undefined };
                setSliderError((prevState) => ({
                    ...prevState,
                    ...updatedValue,
                }));
                setSliderVals((prevState) => ({
                    ...prevState,
                    [ansId]: value,
                }));
            }
        }
    }
    const rangeValidationForNewSkills = (value, qId, skillid, prevVal) => {
        if (skillid === undefined) {
            return
        }
        if (qId === 688) {
            let a = FetchValidRuleStates(undefined, undefined, undefined, undefined)
            if (!checkIfValInRng(value, a)) {
                let errString = "Range should be between "
                let errRange = ""
                a.forEach(valItem => {
                    if (errRange !== "") {
                        errRange = errRange + " or "
                    }
                    errRange = errRange + valItem[0] + " to " + valItem[1]
                })
                errString = errString + errRange
                let updatedValue = sliderNewError[skillid]
                updatedValue[688] = errString
                let parent = { [skillid]: updatedValue }
                setSliderNewError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
            } else {
                let updatedValue = sliderNewError[skillid]
                updatedValue[688] = undefined
                let parent = { [skillid]: updatedValue }
                setSliderNewError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
                let updatedVals = sliderNewVals[skillid]
                updatedVals[688] = value
                setSliderNewVals((prevState) => ({
                    ...prevState,
                    ...updatedVals,
                }));

                // recursively call children
                rangeValidationForNewSkills(sliderNewVals[skillid][689], 689, skillid, value)
            }
        }
        if (qId === 689) {
            let l = prevVal
            if (l === undefined) {
                l = sliderNewVals[skillid][688]
            }
            let b = FetchValidRuleStates(l, undefined, undefined, undefined)
            if (!checkIfValInRng(value, b)) {
                let errString = "Range should be between "
                let errRange = ""
                b.forEach(valItem => {
                    if (errRange !== "") {
                        errRange = errRange + " or "
                    }
                    errRange = errRange + valItem[0] + " to " + valItem[1]
                })
                errString = errString + errRange
                let updatedValue = sliderNewError[skillid]
                updatedValue[689] = errString
                let parent = { [skillid]: updatedValue }
                // let updatedValue = { 689: errString };
                setSliderNewError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
            } else {
                let updatedValue = sliderNewError[skillid]
                updatedValue[689] = undefined
                let parent = { [skillid]: updatedValue }
                setSliderNewError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
                let updatedVals = sliderNewVals[skillid]
                updatedVals[689] = value
                setSliderNewVals((prevState) => ({
                    ...prevState,
                    ...updatedVals,
                }));

                rangeValidationForNewSkills(sliderNewVals[skillid][690], 690, skillid, value)
            }
        }
        if (qId === 690) {
            let l = prevVal
            if (l === undefined) {
                l = sliderNewVals[skillid][689]
            }
            let c = FetchValidRuleStates(sliderNewVals[688], l, undefined, undefined)
            if (!checkIfValInRng(value, c)) {
                let errString = "Range should be between "
                let errRange = ""
                c.forEach(valItem => {
                    if (errRange !== "") {
                        errRange = errRange + " or "
                    }
                    errRange = errRange + valItem[0] + " to " + valItem[1]
                })
                errString = errString + errRange
                let updatedValue = sliderNewError[skillid]
                updatedValue[690] = errString
                let parent = { [skillid]: updatedValue }
                // let updatedValue = { 690: errString };
                setSliderNewError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
            } else {
                let updatedValue = sliderNewError[skillid]
                updatedValue[690] = undefined
                let parent = { [skillid]: updatedValue }
                setSliderNewError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
                let updatedVals = sliderNewVals[skillid]
                updatedVals[690] = value
                setSliderNewVals((prevState) => ({
                    ...prevState,
                    ...updatedVals,
                }));
                // recursively call children
                rangeValidationForNewSkills(sliderNewVals[skillid][691], 691, skillid, value)
            }
        }
        if (qId === 691) {
            let l = prevVal
            if (l === undefined) {
                l = sliderNewVals[skillid][690]
            }
            let d = FetchValidRuleStates(sliderNewVals[688], sliderNewVals[689], l, undefined)
            if (!checkIfValInRng(value, d)) {
                let errString = "Range should be between "
                let errRange = ""
                d.forEach(valItem => {
                    if (errRange !== "") {
                        errRange = errRange + " or "
                    }
                    errRange = errRange + valItem[0] + " to " + valItem[1]
                })
                errString = errString + errRange
                let updatedValue = sliderNewError[skillid]
                updatedValue[691] = errString
                let parent = { [skillid]: updatedValue }
                // let updatedValue = { 691: errString };
                setSliderNewError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
            } else {
                let updatedValue = sliderNewError[skillid]
                updatedValue[691] = undefined
                let parent = { [skillid]: updatedValue }
                setSliderNewError((prevState) => ({
                    ...prevState,
                    ...parent,
                }));
                let updatedVals = sliderNewVals[skillid]
                updatedVals[691] = value
                setSliderNewVals((prevState) => ({
                    ...prevState,
                    ...updatedVals,
                }));
            }
        }
    }
    const checkIfValInRng = (val, rng) => {
        let ret = false
        rng.forEach(r => {
            if (Array.isArray(r)) {
                if (val >= Number(r[0]) && val <= Number(r[1])) {
                    ret = true
                }
            } else {
                if (val >= Number(r) && val <= Number(r)) {
                    ret = true
                }
            }
        })
        return ret
    }
    const handleValidation = () => {
        if (selectedSkillbutton && selectedSkillbutton?.length) {
            let unAnsweredOne = selectedSkillbutton.filter(item => !item.id)
            if (unAnsweredOne && unAnsweredOne?.length) {
                message.error('please select the skills')
            }
            else {
                handleSubmit()
            }
        }
        else {
            handleSubmit()
        }

    }

    async function handleSubmit() {
        let valid = true
        for (let [key, value] of Object.entries(sliderNewError)) {
            for (let [k, v] of Object.entries(value)) {
                if (v !== undefined) {
                    valid = false
                }
            }
        }
        if (!valid) {
            message.error('please select the correct ranges')
            setloading(false)
        }
        else {
            setloading(true)
            if (selectedSkillbutton?.length) {
                
                let selectedAnswes = []
                selectedSkillbutton.map(skills => {
                    let readinessLevelDataBuild = []
                    for (let [k, v] of Object.entries(sliderNewVals[getIntFromString(skills.id)])) {
                        if (v !== undefined) {
                            readinessLevelDataBuild.push({ questionId: k, answer: v })
                        }
                    }
                    let data = {
                        answers: readinessLevelDataBuild,
                        surveyId: SURVEY_READINESS_LEVEL,
                        skillId: getIntFromString(skills.id),
                        responseDuration: 1
                    }
                    selectedAnswes.push(data)
                })
                const response = await createReadinessLevel({
                    readinessId: getIntFromString(readinessById?.id),
                    readinessLevelData: selectedAnswes
                });
                if (response) {
                    if (answersList.length) {
                        handleUpdate()
                    }
                    else {
                        back()
                        setloading(false)
                    }
                }
                else {
                    setloading(false)
                }
            }
            else {
                if (answersList.length) {
                    handleUpdate()
                }
                else {
                    setloading(false)
                    message.error('You have not updated anything')
                }
            }
        }
    }
    async function handleUpdate() {
        let valid = true
    
        for (let [key, value] of Object.entries(sliderError)) {
            if (value) {
                for (let [k, v] of Object.entries(value)) {
                    if (v !== undefined) {
                        valid = false
                    }
                }
            }

        }
        if (!valid) {
            message.error('please select the correct ranges')
            setloading(false)
        }
        else {
       
            const response = await updateReadiness({
                id: getIntFromString(readinessById?.id),
                keyResultId: keyResultid ? getIntFromString(keyResultid) : getIntFromString(readinessById?.keyResult?.id),
                milestoneId: milestoneid ? getIntFromString(milestoneid) : getIntFromString(readinessById?.milestone?.id),
                readinessLevelData: answersList
            });
            if (response && response?.id) {
                setloading(false)
                back()
            } else {
                setloading(false)
            }
        }

    }

    const featchSliderValue = (value, qId, skillid) => {
        if (skillid === undefined) {
            return
        }
        if (qId === 688) {
            let updatedVals = sliderNewVals[skillid]
            updatedVals[688] = value

            setSliderVals((prevState) => ({
                ...prevState,
                ...updatedVals,
            }));
        }
        if (qId === 689) {
            let updatedVals = sliderNewVals[skillid]
            updatedVals[689] = value
            setSliderVals((prevState) => ({
                ...prevState,
                ...updatedVals,
            }));
        }
        if (qId === 690) {
            let updatedVals = sliderNewVals[skillid]
            updatedVals[690] = value
            setSliderVals((prevState) => ({
                ...prevState,
                ...updatedVals,
            }));
        }
        if (qId === 691) {
            let updatedVals = sliderNewVals[skillid]
            updatedVals[691] = value
            setSliderVals((prevState) => ({
                ...prevState,
                ...updatedVals,
            }));
        }
    }
    const handleDeleteNewCard = (skillId, index) => {
        let updatedSkillVal = { [getIntFromString(skillId)]: { 688: 0, 689: 0, 690: 0, 691: 0 } }
        setSliderNewVals((prevState) => ({
            ...prevState,
            ...updatedSkillVal,
        }));
        let updatedSkillError = { [getIntFromString(skillId)]: { 688: undefined, 689: undefined, 690: undefined, 691: undefined } }
        setSliderNewError((prevState) => ({
            ...prevState,
            ...updatedSkillError,
        }));
        // setselectedSkillbutton(selectedSkillbutton.filter(item => item?.id !== skillId));
        let x = [].concat(selectedSkillbutton)
        x.splice(index, 1)
        setselectedSkillbutton(x)
    }

  

    const changeselectedSkill = (value, index) => {
        if (typeof (value) === 'string' && SkillDataList?.edges?.find(item => item?.node?.id === value)) {
            let x = [].concat(selectedSkillbutton)
            if (x[index]) {
                x[index].id = value
                setselectedSkillbutton(x)
            }
        }

    }

    const addSkill = (e) => {

        if (e?.competencyData) {
            let edge = [].concat(SkillDataList.edges)
            edge.push({ node: e.competencyData, __typename: "CompetencyTypeEdge" })
            setSkillDataList({ edges: edge })
            let skillset = sliderVals
            let skillErrorSet = sliderError

            skillset[getIntFromString(e?.competencyData.id)] = { 688: 0, 689: 0, 690: 0, 691: 0 }
            skillErrorSet[getIntFromString(e?.competencyData.id)] = { 688: undefined, 689: undefined, 690: undefined, 691: undefined }
            setSliderNewVals(skillset)
            setSliderNewError(skillErrorSet)
            let x = [].concat(selectedSkillbutton)
            x[addSkillData?.index].id = e?.competencyData?.id
            setselectedSkillbutton(x)
            setaddSkillData(null)
        }
    }

    return (
        <>
            {
                addSkillData && (
                    <Modal
                        closable={false}
                        visible={addSkillData ? true : false}
                        destroyOnClose={true}
                        footer={false}
                        width='50%'
                        maxHeight="80vh"
                    >

                        <Addskills
                            onClose={(e) => setaddSkillData(null)}
                            onaddSkill={e => { addSkill(e) }}
                            {...props}
                        />


                    </Modal>
                )
            }
            <MetaTags
                title="Readiness Zone"
                description="This is Edit Readiness page."
            />
            <Spin spinning={loading}>
                {/* Page Title and Back Arrow */}
                <div className="readiness-root">
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: "1em" }}>
                        <img src={new_back_arrow} style={{ width: "20px", height: "20px", cursor: "pointer", marginLeft: '7px' }} onClick={()=>{answersList?.length>0?setEditWarning(true):back()}} alt="" />
                        <h4 className="readiness-page-title">Edit Readiness Level</h4>
                        <div style={{ position: 'relative' }}>
                            <SideBulbToolContainer
                                {...props}
                                widthOfContainer={24}
                                containerBorder={""}
                                spacing={'0px'}
                                hideTopDivider={true}
                                keepDesign={true}
                                top={"104px"}
                                toolReturn={e => setToolReturn(e)}
                                tool_id={READINESS_ADD_OR_EDIT_TOOL_ID}
                            />
                        </div>
                    </div>
                </div>
                <div className="readiness-main-container" style={{ background: "#fff", boxShadow: " 0px 10px 15px 0px rgba(170, 186, 198, 0.2)", width: '100%', padding: '1em 2em', borderRadius: ".5em", overflow: "auto", marginTop: "1em", paddingBottom: "6em" }}>
                    <Form
                        form={form}
                        name={"value"}
                        onFinish={() => handleValidation()}>
                        <div style={{ marginTop: '1em', display: "flex", flexDirection: "column", gap: "1em", width: "100%" }} >
                            <Row gutter={10} style={{ marginTop: '15px' }}>
                                {keyResultname && (<div>
                                    <p style={{ fontSize: '15px' }}> Key Result : <span style={{ marginTop: '10px', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: "200px" }}>{keyResultname}</span></p>
                                </div>)}
                                <div style={{ width: "100%" }}>
                                    {
                                        readinessById && (
                                            <div >
                                                <p className="readiness-form-lable">Readiness assessment for {!readinessById?.readinessTo ? 'you' : `${Capitalize(readinessById?.readinessTo?.user?.firstName)} ${Capitalize(readinessById?.readinessTo?.user?.lastName) || ''}`}</p>
                                            </div>
                                        )
                                    }
                                </div>
                            </Row>
                            <div style={{ width: "100%" }}>


                                {selectedSkill?.map((skilldata, index) =>
                                (
                                    <div style={{ padding: '1em 2em', height: "100%", background: "#F6FBFF", borderRadius: "1em", maxWidth: "70%", marginTop: "1.25em" }} >
                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "1em", justifyContent: "space-between", alignItems: 'center' }}>
                                            <div>
                                                <h4 className="readiness-form-lable">{skilldata?.node?.skill?.title}</h4>
                                                <Tooltip title={skilldata?.node?.zone?.zone}>
                                                    <p className="readiness-form-lable" style={{fontSize:".85em",fontWeight:"500"}}>Current zone : <span style={{color:skilldata?.node?.zone?.color}}>{skilldata?.node?.zone?.color}</span></p>
                                                </Tooltip>
                                            </div>
                                            {/* <div>
                                                <img style={{ width: "40px", cursor: "pointer" }} src={remove_readiness} alt="" onClick={() => handleDelete(skilldata?.node?.id)} />
                                            </div> */}
                                        </div>

                                        {skilldata?.node?.skill?.id ?
                                            skilldata?.node?.response?.answerSet?.edges.map(question =>
                                                <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: "1em" }}>
                                                    {question?.node?.question?.required && (
                                                        <h3 className="readiness-form-lable"><ReactMarkdown>{question?.node?.question?.questionText}</ReactMarkdown></h3>
                                                    )}
                                                    {!question?.node?.question?.required && (
                                                        <h3 className="readiness-form-lable"><ReactMarkdown>{question?.node?.question?.questionText}</ReactMarkdown></h3>
                                                    )}
                                                    <div className="readiness-slider">
                                                        <Slider
                                                            min={0}
                                                            max={100}
                                                            defaultValue={question?.node?.answer ? question?.node?.answer : 0}
                                                            step={1}
                                                            onChange={e => rangeValidation(e, getIntFromString(question?.node?.question?.id), getIntFromString(question?.node?.id))}
                                                            onAfterChange={e => { submitAnswer(e, question?.node?.id, skilldata?.node?.id, skilldata?.node?.skill?.id); rangeValidation(e, getIntFromString(question?.node?.question?.id), getIntFromString(question?.node?.id)) }}
                                                            trackStyle={trackStyle}
                                                            handleStyle={handleStyle}
                                                            disabled={checkIfSliderIsDisabled(getIntFromString(question?.node?.question?.id), getIntFromString(question?.node?.id))}

                                                        />
                                                    </div>

                                                    {!checkIfSliderIsDisabled(getIntFromString(question?.node?.question?.id), getIntFromString(question?.node?.id)) && question?.node?.id && sliderError[getIntFromString(question?.node?.id)] && (
                                                        < div style={{ color: "red" }}>
                                                            {sliderError[getIntFromString(question?.node?.id)]}
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                            : ''}

                                    </div>
                                )
                                )}

                                {selectedSkillbutton?.map((item, index) =>
                                (
                                    <div style={{ padding: '2em', height: "100%", background: "#F6FBFF", borderRadius: "1em", maxWidth: "70%", marginTop: "1.25em" }} >
                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "1em" }}>
                                            <div style={{ width: "100%" }}>
                                                <SkillField
                                                    id="readiness_skill"
                                                    name={"skill"}
                                                    owner={"readiness"}
                                                    form={form}
                                                    label={""}
                                                    style={{ width: "100%" }}
                                                    no_label={true}
                                                    closeAfterSelection={true}
                                                    onChange={(e) => changeselectedSkill(e?.value, index)}
                                                />
                                            </div>
                                            <div>
                                                <img style={{ width: "40px", cursor: "pointer" }} src={remove_readiness} alt="" onClick={() => handleDeleteNewCard(item?.id, index)} />
                                            </div>
                                        </div>
                                        {item?.id ?
                                            questionList.map(question =>
                                                <div style={{ marginTop: "1em", display: 'flex', flexDirection: 'column', gap: "1em" }}>
                                                    {question?.required && (
                                                        <h3 className="readiness-form-lable"><ReactMarkdown >{question?.questionText}</ReactMarkdown></h3>
                                                    )}
                                                    {!question?.required && (
                                                        <h3 className="readiness-form-lable"><ReactMarkdown >{question?.questionText}</ReactMarkdown></h3>
                                                    )}
                                                    <div className="readiness-slider">
                                                        <Slider
                                                            min={0}
                                                            max={100}
                                                            step={1}
                                                            onChange={e => featchSliderValue(e, getIntFromString(question?.id), getIntFromString(item?.id))}
                                                            onAfterChange={e => rangeValidationForNewSkills(e, getIntFromString(question?.id), getIntFromString(item?.id))}
                                                            trackStyle={trackStyle}
                                                            handleStyle={handleStyle}
                                                            value={sliderNewVals[getIntFromString(item?.id)][getIntFromString(question?.id)]}
                                                            disabled={checkIfSliderIsDisabledNewCard(getIntFromString(question?.id), getIntFromString(item?.id))}
                                                        />
                                                    </div>
                                                    {question?.id && sliderNewError[getIntFromString(item?.id)] && sliderNewError[getIntFromString(item?.id)][getIntFromString(question?.id)] && (
                                                        < div style={{ color: "red" }}>
                                                            {sliderNewError[getIntFromString(item?.id)][getIntFromString(question?.id)]}
                                                        </div>
                                                    )
                                                    }
                                                </div>
                                            )
                                            : ''}

                                    </div>
                                )
                                )}
                                {/* <div align="start" style={{ marginTop: '20px' }}>
                                    <Button className="readiness-add-btn" onClick={(e) => { addToSelectedSkill() }}>
                                        + Add more
                                    </Button>
                                </div> */}

                            </div>

                        </div>
                        <div style={{ position: "fixed", bottom: "1.5em", height: "5em", display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: "center", width: "84%", background: "#fff", paddingBottom: "1em" }} >
                            <Button className="readiness-cancel-btn" onClick={()=>{answersList?.length>0?setEditWarning(true):back()}} style={{ margin: '1em 1em 0em 1em' }}>Cancel</Button>
                            <Button className="readiness-save-btn" type="primary" htmlType="submit" style={{ margin: '1em 1em 0em 1em' }}>Submit</Button>
                        </div>
                    </Form>
                </div>
            </Spin>
            <Modal
                    visible={open_edit_warning}
                    footer={false}
                    closable={false}
                    centered={true}
                    destroyOnClose={true}>
                    <WarningModal {...props} title={"Are you sure you want to go back?"} onClose={() => { setEditWarning(false) }} onGoBack={() => back()} />
                </Modal>
        </>
    )
}

export default EditReadinessView