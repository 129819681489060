import { Modal, Spin, Tooltip } from "antd";
import { compose } from "modules/core";
import React from "react";
import { withApollo } from '@apollo/client/react/hoc';
import compare_match from '../../assets/matched-compare.svg'
import compare_not_match from '../../assets/compare-not-match.svg'
import SoulIndex from "./soul-report-index";
import readiness_close_icon from '../../assets/readiness-close-icon.svg'
const CompareResponseView = (props) => {
    const { show_comparison_report, selected_employee_list, selected_employees_response, onClose, response_loading } = props
    const soul_survey_types = [
        {
            key: "Values",
            border: "1px solid #FFDAA2",
            icon_color: 'rgba(234, 157, 39, 1)',
            background: "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 245, 210, 0.5) 100%)"
        },
        {
            key: "Strengths",
            border: "1px solid rgba(254, 189, 190, 1)",
            icon_color: 'rgba(240, 91, 94, 1)',
            background: "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 228, 229, 0.5) 100%)"
        },
        {
            key: "Personality",
            border: "1px solid rgba(147, 255, 239, 1)",
            icon_color: 'rgba(49, 205, 181, 1)',
            background: "linear-gradient(180deg, #FFFFFF 0%, rgba(224, 255, 250, 0.5) 100%)"

        },
        {
            key: "Knowledge & Skills",
            border: "1px solid rgba(137, 179, 255, 1)",
            icon_color: 'rgba(66, 105, 177, 1)',
            background: "linear-gradient(180deg, #FFFFFF 0%, rgba(223, 234, 255, 0.5) 100%)"


        }
    ]
    const findMatch = (emp_1_response, emp_2_response) => {
        if (emp_1_response && emp_2_response) {
            console.log("emp_1_response", emp_1_response, "emp_2_response", emp_2_response)
            let commonValues = emp_1_response.filter(itemA => emp_2_response.some(itemB => itemA.answer === itemB.answer)).map(item => item.answer);;
            return commonValues
        }
        return []
    }
    const matchCount = (match_count, total_count) => {
        if (match_count && total_count) {
            if (match_count == total_count) {
                return 'All Matched'
            }
            else {
                if (match_count == 0) {
                    return '0 Match'
                }
                else {
                    return `0${match_count} Match`
                }
            }
        }
        else {
            return '0 Match'
        }
    }
    return (
        <Modal
            visible={show_comparison_report}
            centered={true}
            footer={null}
            destroyOnClose={true}
            closable={false}
            width={"100%"}
            style={{ maxWidth: "85%" }}>
            <div style={{ width: '100%', display: "flex", flexDirection: "column", overflowX: "auto", maxHeight: "calc(100vh - 130px)" }}>
                <div style={{ width: "100%", borderBottom: "1px solid rgba(170, 186, 198, 1)" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "1em", alignItems: "start", width: "100%" }}>
                        <div>
                            <h4 className="comparison-card-title">{selected_employee_list?.employee_1?.name} & {selected_employee_list?.employee_2?.name} Soul identity comparison Report</h4>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: '1em', paddingBottom: "1em", paddingTop: ".5em" }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: '1em' }}>
                                    <img src={compare_match} alt="compare_match" style={{ width: "1.5em", height: "1.5em" }} />
                                    <h4 className="comparison-card-title" style={{ fontSize: "1em" }}>Similar Preferences</h4>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: '1em' }}>
                                    <img src={compare_not_match} alt="compare_not_match" style={{ width: "1.5em", height: "1.5em" }} />
                                    <h4 className="comparison-card-title" style={{ fontSize: "1em" }}>Diverse Preferences</h4>
                                </div>
                            </div>
                        </div>
                        <img src={readiness_close_icon} alt="readiness_close_icon" style={{cursor:"pointer"}} onClick={() => onClose()} />
                    </div>
                </div>

                {!response_loading ? <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'start', alignItems: "start", gap: "2em", padding: "1em", width: "100%", overflowY: "auto" }}>
                    {
                        soul_survey_types?.map(item => (
                            <div style={{ display: "flex", flexDirection: "column", width: '100%' }}>
                                <h4 className="comparison-card-title" style={{ fontSize: "1em" }}><span style={{ color: "rgba(108, 114, 127, 1)", fontWeight: "500" }}>{item?.key} -</span>  {matchCount(findMatch(selected_employees_response?.emp_1[item?.key], selected_employees_response?.emp_2[item?.key])?.length, selected_employees_response?.emp_1[item?.key]?.length)}</h4>
                                <div style={{ background: item?.background, width: "100%", borderTopLeftRadius: ".25em", borderTopRightRadius: ".25em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: 'center', marginTop: "1em" }}>
                                    <div className="soul-team-report-answer" style={{ borderRight: item?.border, background: "#fff", border: "1px solid rgba(170, 186, 198, 0.23)" }}>
                                        <h4 className="soul-team-answer text-transform-capitalize" style={{ fontWeight: "500" }}>{selected_employees_response?.emp_1["name"]}</h4>
                                    </div>
                                    {selected_employees_response?.emp_1[item?.key]?.map((value, index) => (
                                        <div className="soul-team-report-answer"
                                            style={{
                                                background: findMatch(selected_employees_response?.emp_1[item?.key], selected_employees_response?.emp_2[item?.key])?.includes(value?.answer) ? 'rgba(76, 203, 31, 0.3)' : 'none',
                                                border: findMatch(selected_employees_response?.emp_1[item?.key], selected_employees_response?.emp_2[item?.key])?.includes(value?.answer) ? '1px solid rgba(76, 203, 31, 1)' : item?.border,
                                            }}>
                                            <div>
                                                <SoulIndex index={`index_${(index + 1)}`} color={findMatch(selected_employees_response?.emp_1[item?.key], selected_employees_response?.emp_2[item?.key])?.includes(value?.answer) ? 'rgba(76, 203, 31, 1)' : item?.icon_color} />
                                            </div>

                                            <Tooltip title={value?.answer}>
                                                <h4 className="soul-team-answer" style={{ fontWeight: "500" }}>{value?.answer}</h4>
                                            </Tooltip>
                                        </div>
                                    ))}
                                </div>
                                <div style={{ background: item?.background, width: "100%", borderBottomLeftRadius: ".25em", borderBottomRightRadius: ".25em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: 'center' }}>
                                    <div className="soul-team-report-answer" style={{ borderRight: item?.border, background: "#fff", border: "1px solid rgba(170, 186, 198, 0.23)" }}>
                                        <h4 className="soul-team-answer" style={{ fontWeight: "500" }}>{selected_employees_response?.emp_2["name"]}</h4>
                                    </div>
                                    {selected_employees_response?.emp_2[item?.key]?.map((value, index) => (
                                        <div className="soul-team-report-answer"
                                            style={{
                                                background: findMatch(selected_employees_response?.emp_1[item?.key], selected_employees_response?.emp_2[item?.key])?.includes(value?.answer) ? 'rgba(76, 203, 31, 0.3)' : 'none',
                                                border: findMatch(selected_employees_response?.emp_1[item?.key], selected_employees_response?.emp_2[item?.key])?.includes(value?.answer) ? '1px solid rgba(76, 203, 31, 1)' : item?.border,
                                            }}>
                                            <div>
                                                <SoulIndex index={`index_${(index + 1)}`} color={findMatch(selected_employees_response?.emp_1[item?.key], selected_employees_response?.emp_2[item?.key])?.includes(value?.answer) ? 'rgba(76, 203, 31, 1)' : item?.icon_color} />
                                            </div>

                                            <Tooltip title={value?.answer}>
                                                <h4 className="soul-team-answer" style={{ fontWeight: "500" }}>{value?.answer}</h4>
                                            </Tooltip>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))
                    }
                </div> : <Spin spinning={response_loading}>
                    <div style={{ width: "100%", height: "50vh" }}></div>
                </Spin>}
            </div>

        </Modal>
    )
}

export default compose(withApollo)(CompareResponseView);