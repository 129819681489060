import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { Empty, Spin } from 'antd';

import { compose } from '../../../core';
import { Capitalize, getIntFromString } from '../functions';
import { withLookUserState, withLookUserFilterUpdating } from '../../containers/LookOperations';

import SelectField from './SelectField';
import { PAGINATION_LIMIT } from '../../../../config';

export const LIST_ALL_USERS_QUERY = gql`
  query listAllUsers($first: Int, $orderBy: [String], $firstName_Icontains: String, $lastName_Icontains: String) {
    listAllUsers(
      first: $first
      orderBy: $orderBy
      firstName_Icontains: $firstName_Icontains
      lastName_Icontains: $lastName_Icontains
    ) {
      edges {
        node {
          id
          firstName
          lastName
          username
          email
        }
      }
    }
  }
`;

const UserAutoComplete = props => {
  const { loading, listAllUsers, id = true, margin = true, onFirstNameChange, onFiltersRemove } = props;

  const handleFilterRemove = React.useRef(() => {});

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const choices =
    listAllUsers &&
    listAllUsers.edges.length > 0 &&
    listAllUsers.edges.map(({ node }) => ({
      label: node.firstName && node.lastName ? `${Capitalize(node.firstName)} ${Capitalize(node.lastName)}` : node.username,
      value: id ? getIntFromString(node.id) : `${node?.firstName} ${node?.lastName}, ${node?.email}`
    }));

  return (
    <SelectField
      margin={margin}
      showSearch
      choices={choices}
      style={{ width: !margin && 300, marginBottom: !margin && 0 }}
      // labelInValue
      filterOption={false}
      onSearch={onFirstNameChange}
      notFoundContent={
        loading ? (
          <div align="center">
            <Spin size="small" />
          </div>
        ) : (
          <Empty />
        )
      }
      {...props}
    />
  );
};

export default compose(
  withLookUserState,
  graphql(LIST_ALL_USERS_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { first: PAGINATION_LIMIT, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, listAllUsers, subscribeToMore, updateQuery } = data;
      return { loading, error, listAllUsers, subscribeToMore, updateQuery };
    }
  }),
  withLookUserFilterUpdating
)(UserAutoComplete);
