import React from 'react'
import { Col, Dropdown, Menu, Tooltip, Button } from 'antd'
import { ca_detail_icons } from '../icon'
import '../css/competency-assessment-detail.css'
import { getIntFromString } from 'modules/look'
const GraphAdvanceFilter = (props) => {
    const { filter_options,onApplyfilter } = props
    const [advance_filter, SetAdvanceFilter] = React.useState(false)
    const [menu_tab_opened, SetmenuTabOpen] = React.useState()
    const [apply_active, SetApply] = React.useState(false)

    const [selected_filter, SetSelectedFilter] = React.useState({
        vertical: [],
        team: [],
        employee: [],
        role: [],
        level: []
    })

    const onApply=(clearAll=false)=>{
        let filter =!clearAll?{
            employee_In:selected_filter?.employee?.map(i=>getIntFromString(i?.toEmployee?.id))||[],
            employeeLevel_In:selected_filter?.level?.map(i=>i?.name)||[],
            role_In:selected_filter?.role?.map(i=>getIntFromString(i?.id))||[],
            employee_TeamMembersEmployee_In:selected_filter?.team?.map(i=>getIntFromString(i?.id)?.toString())||[],
            employee_VerticalMembersEmployee_In:selected_filter?.vertical?.map(i=>getIntFromString(i?.id)?.toString())||[]
        }:
        {
            employee_In:[],
            employeeLevel_In:[],
            role_In:[],
            employee_TeamMembersEmployee_In:[],
            employee_VerticalMembersEmployee_In:[]
        }
        SetAdvanceFilter(false)
        SetApply(false)
        onApplyfilter(filter)
    }

    const clearAll = () => {
        SetSelectedFilter({
            vertical: [],
            team: [],
            employee: [],
            role: [],
            level: []
        })
        onApply(true)
    }

    const changeMenuTab = (key) => {
        if (menu_tab_opened == key) {
            SetmenuTabOpen(null)
        } else {
            SetmenuTabOpen(key)
        }
    }

    const filterSection = (key, data) => {
        let filter = { ...selected_filter }
        if (filter[key]?.find(item => item?.id == data?.id)) {
            filter[key] = filter[key]?.filter(item => item?.id != data?.id)
        } else {
            filter[key].push(data)
        }
        SetSelectedFilter(filter)
        SetApply(true)
    }

    const AdvanceOption = () => (
        <Menu className='ca-custom-menu-filter'>
            <div className='ca-graph-advance-filter'>
                <div className='ca-adv-filter-title-container'>
                    <h4>Filter</h4>
                    <p onClick={()=>{clearAll()}} style={{cursor:"pointer",fontWeight:"500",fontFamily:"Poppins"}}>Clear All</p>
                </div>
                {filter_options?.vertical?.length ?
                    <div>
                        <Button className='ca-graph-advance-filter-button' onClick={() => { changeMenuTab('vertical') }}>
                            <span>Vertical</span>
                            <img src={ca_detail_icons?.dropdown_Arrow} alt="" />
                        </Button>
                        {
                            menu_tab_opened === 'vertical' && (
                                <div className='graph-menu-child'>
                                    {filter_options?.vertical?.map(item => (
                                        <div className={`${selected_filter?.vertical?.find(data => data?.id == item?.id) ? 'active-child-selection' : ''}`} onClick={() => { filterSection('vertical', item) }}>{item?.name}</div>
                                    ))}
                                </div>
                            )
                        }

                        {
                            selected_filter?.vertical?.length ?
                                <div className='selected-filter-data'>
                                    {
                                        selected_filter?.vertical?.map(i => (
                                            <div>
                                                <span>{i.name}</span>
                                                <img src={ca_detail_icons?.filter_remove} style={{ cursor: "pointer" }} onClick={() => { filterSection('vertical', i) }} alt="" />
                                            </div>
                                        ))
                                    }
                                </div> : ''
                        }

                    </div>
                    : ''
                }

                {filter_options?.teams?.length ?
                    <div>
                        <Button className='ca-graph-advance-filter-button' onClick={() => { changeMenuTab('team') }} >
                            <span>Team</span>
                            <img src={ca_detail_icons?.dropdown_Arrow} alt="" />
                        </Button>

                        {
                            menu_tab_opened === 'team' && (
                                <div className='graph-menu-child'>
                                    {filter_options?.teams?.map(item => (
                                        <div className={`${selected_filter?.team?.find(data => data?.id == item?.id) ? 'active-child-selection' : ''}`} onClick={() => { filterSection('team', item) }}>{item?.name}</div>
                                    ))}
                                </div>
                            )
                        }

                        {
                            selected_filter?.team?.length ?
                                <div className='selected-filter-data'>
                                    {
                                        selected_filter?.team?.map(i => (
                                            <div>
                                                <span>{i.name}</span>
                                                <img src={ca_detail_icons?.filter_remove} style={{ cursor: "pointer" }} onClick={() => { filterSection('team', i) }} alt="" />
                                            </div>
                                        ))
                                    }
                                </div> : ''
                        }

                    </div>

                    : ''
                }

                {filter_options?.members?.length ?
                    <div>
                        <Button className='ca-graph-advance-filter-button' onClick={() => { changeMenuTab('employee') }} >
                            <span>Employee</span>
                            <img src={ca_detail_icons?.dropdown_Arrow} alt="" />
                        </Button>

                        {
                            menu_tab_opened === 'employee' && (
                                <div className='graph-menu-child'>
                                    {filter_options?.members?.map(item => (
                                        <div className={`text-transform-capitalize ${selected_filter?.employee?.find(data => data?.id == item?.id) ? 'active-child-selection' : ''}`} onClick={() => { filterSection('employee', item) }}>{item?.toEmployee?.user?.firstName}</div>
                                    ))}
                                </div>
                            )
                        }

                        {
                            selected_filter?.employee?.length ?
                                <div className='selected-filter-data'>
                                    {
                                        selected_filter?.employee?.map(i => (
                                            <div>
                                                <span className='text-transform-capitalize'>{i?.toEmployee?.user?.firstName}</span>
                                                <img src={ca_detail_icons?.filter_remove} style={{ cursor: "pointer" }} onClick={() => { filterSection('employee', i) }} alt="" />
                                            </div>
                                        ))
                                    }
                                </div> : ''
                        }
                    </div>
                    : ''
                }

                {filter_options?.role?.length ?
                    <div>
                        <Button className='ca-graph-advance-filter-button' onClick={() => { changeMenuTab('role') }} >
                            <span>Role</span>
                            <img src={ca_detail_icons?.dropdown_Arrow} alt="" />
                        </Button>

                        {
                            menu_tab_opened === 'role' && (
                                <div className='graph-menu-child'>
                                    {filter_options?.role?.map(item => (
                                        <div className={`${selected_filter?.role?.find(data => data?.id == item?.id) ? 'active-child-selection' : ''}`} onClick={() => { filterSection('role', item) }}>{item?.title}</div>
                                    ))}
                                </div>
                            )
                        }

                        {
                            selected_filter?.role?.length ?
                                <div className='selected-filter-data'>
                                    {
                                        selected_filter?.role?.map(i => (
                                            <div>
                                                <span>{i.title}</span>
                                                <img src={ca_detail_icons?.filter_remove} style={{ cursor: "pointer" }} onClick={() => { filterSection('role', i) }} alt="" />
                                            </div>
                                        ))
                                    }
                                </div> : ''
                        }
                    </div>
                    : ''
                }

                {filter_options?.employe_level?.length ?
                    <div>
                        <Button className='ca-graph-advance-filter-button' onClick={() => { changeMenuTab('level') }} >
                            <span>Level</span>
                            <img src={ca_detail_icons?.dropdown_Arrow} alt="" />
                        </Button>

                        {
                            menu_tab_opened === 'level' && (
                                <div className='graph-menu-child'>
                                    {filter_options?.employe_level?.map(item => (
                                        <div className={`${selected_filter?.level?.find(data => data?.id == item?.id) ? 'active-child-selection' : ''}`} onClick={() => { filterSection('level', item) }}>{item?.name}</div>
                                    ))}
                                </div>
                            )
                        }

                        {
                            selected_filter?.level?.length ?
                                <div className='selected-filter-data'>
                                    {
                                        selected_filter?.level?.map(i => (
                                            <div>
                                                <span>{i.name}</span>
                                                <img src={ca_detail_icons?.filter_remove} style={{ cursor: "pointer" }} onClick={() => { filterSection('level', i) }} alt="" />
                                            </div>
                                        ))
                                    }
                                </div> : ''
                        }
                    </div>
                    : ''
                }
                <div align="center" style={{ width: "100%" }}>
                    <Button className='apply-btn-ca-filter' disabled={!apply_active} onClick={()=>{onApply()}}>Apply</Button>
                </div>
            </div>
        </Menu>
    )

    return (
        <>
            <Dropdown overlayStyle={{zIndex:1060}} overlay={AdvanceOption} visible={advance_filter} placement="bottomLeft">
                <Button className='ca-dropdown-btn' style={{ borderRadius: ".75em" }} onClick={() => SetAdvanceFilter(true)}>
                    <img src={ca_detail_icons?.icon_filter} alt="" />
                    <p className='ca-drop-label' style={{ margin: "0",paddingRight:'1em' }} >Filter</p>
                </Button>
            </Dropdown>
            {advance_filter ? (<div className='overlay-dynamic-island' onClick={() => SetAdvanceFilter(false)} />) : null}
        </>
    )
}
export default GraphAdvanceFilter