import { Button, Col, Form, Input, Modal, Popover, Row, Select, Spin, message } from "antd";
import { getIntFromString } from "modules/look";
import React, { useRef } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import modal_close from '../../assets/badge-modal-close.svg';
import drop_black from '../../assets/drop-arrow-black.svg';
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg';
import left_arrow from '../../assets/left-active-carosul.svg';
import right_arrow from '../../assets/right-active-carosul.svg';
import search_icon from '../../assets/search-icon.svg';
import "../../kudos/kudos.css";
import '../user-kudos.css';
import close_icon from '../../assets/dashboard-kudos-close-icon.svg';
const AwardBadgeView = (props) => {
    const { visible, onClose, onSearch, badgeList, loading, createBadgeAward, type, selectedItem, me, badge_Family_by_id, getbadgeFailyList, onFilter, getBageFamilyById, badge_family_list,from_detail} = props

    console.log("selectedItem",selectedItem)
    const [selectedBadge, setSelectedBadge] = React.useState()
    const [detail, setdetail] = React.useState();
    const [Employees, setEmployees] = React.useState([]);
    const [selectedEmployee, setSelectedEmployee] = React.useState()
    const [selectedNotify, setSelectedNotify] = React.useState()
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenNotify, setIsOpenNotify] = React.useState(false);
    const [cat_list, setCatList] = React.useState([
        { value: "employee", label: "Employee" },
        { value: "team", label: "Team" },
        { value: "vertical", label: "Vertical" },
        { value: "organization", label: "Organization" },
        { value: "none", label: "None" },
    ])
    const [search_toggle, setSearchToggle] = React.useState(false)
    const [filter_value, setFilterValue] = React.useState()
    const [inflinite_slider, setInfiniteSlider] = React.useState(false)
    const Option = Select.Option;
    const [form] = Form.useForm();
    const selectRef = useRef(null);
    // const settings = {
    //     nextArrow: <img src={right_arrow} />,
    //     prevArrow: <img src={left_arrow} />
    // }


    React.useEffect(() => {

        if (selectedItem) {
            let emp = from_detail?selectedItem?.contributors.filter((item) => item?.id != me?.employee?.user?.id):
            selectedItem?.contributors.filter((item) => item?.user?.id != me?.employee?.user?.id)
            var setObj = new Set();
            let empList = emp.reduce((acc, item) => {
                if (!setObj.has(item?.id)) {
                    setObj.add(item?.id)
                    acc.push(item)
                }
                return acc;
            }, [])
            setEmployees(empList)
        }
    }, [selectedItem])
    const handleSearch = (event) => {
        if (event?.target?.value) {
            onSearch({ title_Icontains: event?.target?.value, behaviouralDescription_Icontains: event?.target?.value })
        }
        else {
            onSearch({ title_Icontains: "", behaviouralDescription_Icontains: "" })
        }
    }
    const badgeSelectHandler = (node) => {
        setSelectedBadge(node)
    }

    const category_option = cat_list &&
        cat_list.map((choice, key) => (
            <Option className="kudos-user-home-select-option"
                style={{ border: "1px solid #000000", borderRadius: "8px" }}
                key={key}
                value={choice?.value}
            >
                {choice?.label}
            </Option>
        ));

    const choices = Employees &&
        Employees?.map((item) => (
            <Option
                className="kudos-user-home-select-option"
                style={{ border: "1px solid #000000", borderRadius: "8px" }}
                key={item?.id}
                value={item?.id}
            >
                <span className="text-transform-capitalize">{item?.user?.firstName}</span> <span className="text-transform-capitalize">{item?.user?.lastName}</span>
            </Option>
        ))

    const handleSubmit = async (value) => {
        if (selectedEmployee && selectedNotify) {
            try {
                let badgeAwardData = {
                    recipientId: getIntFromString(selectedEmployee),
                    badgeId: getIntFromString(selectedBadge?.id),
                    relatedToId: selectedItem?.id,
                    notify: selectedNotify,
                    evidence: detail
                }
                //   setloading(true)
                const res = await createBadgeAward({ badgeAwardData })

                if (res) {
                    message.success("Kudos given successfully !!")
                    onClose(false)
                }
                else{
                    message.error("Failed to give kudos !!")
                }
                //   setloading(false)
            } catch (error) {
                
                //   setloading(false)
            }


        }
    }
    const handleSelectEmployee = (e) => {
        setSelectedEmployee(e)
    }
    const handleSelectNotify = (e) => {
        setSelectedNotify(e)
    }
    const handleBeforeChange = (oldIndex, newIndex) => {
        if (oldIndex === 0 || newIndex === 0) {
            setInfiniteSlider(false)
        }
        else {
            setInfiniteSlider(true)
        }
    }
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        arrows: true,
        nextArrow: <img src={right_arrow} alt='' />,
        prevArrow: <img src={left_arrow} />,
        slidesToShow: 3,
        // slidesToScroll: 4,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 710,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 310,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const handleMouseEnter = () => {
        setIsOpen(true);
    };
    const handleMouseLeave = () => {
        setIsOpen(false)
    };

    const filter_list = badge_family_list &&
        badge_family_list?.map(({ node }, key) => (
            <Option
                key={key}
                value={node?.id}
            >
                {node?.title}
            </Option>
        ))
    const filterSearch = (value) => {
        if (value) {
            setSelectedBadge(null)
            getbadgeFailyList({ title_Icontains: value })
        }
        else {
            getbadgeFailyList({ title_Icontains: "" })
        }

    }
    const handleFamilyFilter = (e) => {
        setFilterValue(e)
        setSelectedBadge(null)
        onFilter({ badgeFamily: e })
        getBageFamilyById({ id: e })
        filterSearch(null)
    }
    const handleBadgeSearch = (event) => {
        if (event?.target?.value) {
            onSearch({ title_Icontains: event?.target?.value, behaviouralDescription_Icontains: event?.target?.value, badgeFamily: filter_value })
        }
        else {
            onSearch({ title_Icontains: "", behaviouralDescription_Icontains: "", badgeFamily: filter_value })
        }
    }
    const TooltipData = (node) => {
        return (
            <div style={{ width: "100%", maxWidth: "400px", minWidth: "180px" }}>
                <h4 style={{ fontSize: '1.2em', fontWeight: "500", color: "rgba(0, 0, 0, 0.71)", margin: "0" }}>{node?.title}</h4>
                <p style={{ color: "#8C8CA2", fontSize: "1em", margin: "0", textAlign: 'justify' }}>{node?.behaviouralDescription}</p>
            </div>
        )
    }
    return (
        <>
            <Modal
                closable={false}
                visible={visible}
                destroyOnClose={true}
                footer={false}
                centered={true}
                width={'90%'}
                style={{ maxWidth: "500px",maxHeight:"90%"}}
            >
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: "center", gap: "1em", cursor: "pointer", padding: '.5em 1em' }}>
                    <h4 className="user-board-section-heading">Give Kudos</h4>
                    <img src={close_icon} alt="" style={{ cursor: "pointer" }} onClick={() => onClose(false)} />
                </div>
                <div style={{height:"",overflow:"auto"}}>
                    <Form
                        form={form}
                        name={"kudos"}
                        style={{ width: "100%",marginTop:'1em' }}
                        onFinish={() => { handleSubmit() }}
                        className="formLabel"
                    >   
                    
                            <Col className="kudos-form-group">
                                <Select
                                    className={selectedEmployee ? "user-badge-select-active" : "user-badge-select"}
                                    showSearch
                                    name={'employee'}
                                    id={'employee'}
                                    placeholder="Search Employee"
                                    suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />}
                                    style={{ width: '100%', maxWidth: "100%", backgroundColor: "#fff" }}
                                    {...props}
                                    onSelect={handleSelectEmployee}
                                    filterOption={(input, option) => option}
                                    disabled={false}
                                >
                                    {
                                        from_detail?Employees?.map((item) => (
                                            <Option
                                                className="kudos-user-home-select-option"
                                                key={item?.employee_id}
                                                value={item?.employee_id}
                                            >
                                                <span className="text-transform-capitalize">{item?.firstName}</span> <span className="text-transform-capitalize">{item?.lastName}</span>
                                            </Option>
                                        )):Employees?.map((item) => (
                                            <Option
                                                className="kudos-user-home-select-option"
                                                key={item?.id}
                                                value={item?.id}
                                            >
                                                <span className="text-transform-capitalize">{item?.user?.firstName}</span> <span className="text-transform-capitalize">{item?.user?.lastName}</span>
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Col>
                            <div style={{ display: "flex", flexDirection: "column", border: "1px solid #8F8D8D", borderRadius: '8px', width: "100%", padding: "2px 0px", marginTop: "1em" }}>
                                <div className="dashboard-user-badge-award-popup-input-div" style={{ display: 'flex', flexDirection: "row", borderBottom: "1px solid #8F8D8D", width: "100%" }}>
                                    {!search_toggle || filter_value ? <Select
                                        showSearch
                                        className="dasboard-filter-select-user-select"
                                        name={'filter'}
                                        id={'filter'}
                                        placeholder={`Select`}
                                        suffixIcon={<img src={drop_black} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} onClick={() => setSearchToggle(false)} />}
                                        style={{ width: filter_value && search_toggle ? '40%' : '100%', maxWidth: "100%", textOverflow: 'ellipsis', whiteSpace: "nowrap" }}
                                        {...props}
                                        onSelect={handleFamilyFilter}
                                        onSearch={filterSearch}
                                        allowClear
                                        onClear={() => { onFilter() }}
                                        onMouseEnter={() => setSearchToggle(false)}
                                        filterOption={(input, option) => option}
                                        disabled={false}
                                    >
                                        {/* {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice} */}

                                        {filter_list}

                                    </Select> : <div style={{ borderRadius: "8px 0px 0px 8px", border: "1px solid #8F8D8D", height: "40px", padding: "8px 10px" }}>
                                        <img src={drop_black} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} alt="" onClick={() => setSearchToggle(false)} />
                                    </div>}
                                    <div className="dashboard-search-input-user-kudos" style={{ display: 'flex', flexDirection: 'row',width:"100%" }}>
                                        {search_toggle && <Input placeholder="Search kudos name here" autoComplete='off' style={{ width: "100%", border: "none" }} onChange={(e) => handleBadgeSearch(e)}></Input>}
                                        <img src={search_icon} alt="" style={{ cursor: 'pointer', transform: " rotate(-270deg)", height: '20px', marginTop: "10px", marginRight: '7px', marginLeft: '7px' }} onClick={() => setSearchToggle(true)} />
                                    </div>
                                </div>
                                <div className="slider-container dasboard-badge-award-carosul-container">
                                    <Spin spinning={loading}>
                                        {loading && <div style={{ width: "100%", minWidth: "400px" }}></div>}
                                        {badgeList?.length > 0 && !selectedBadge && <Slider {...settings} style={{ width: "100%", maxWidth: "400px" }}>
                                            {badgeList?.length && badgeList?.map(({ node }, index) => (
                                                <div key={index} className={`dashboard-user-award-badge-image-selected-container ${node?.id === selectedBadge?.id ? "dashboard-user-award-badge-image-selected-container-selected" : ""}`}
                                                    onClick={() => badgeSelectHandler(node)} >
                                                    <div style={{ display: 'flex', width: "100%", alignItems: 'center', justifyContent: "center", flexDirection: "column" }}>
                                                        <img className="dashboard-user-award-badge-image-selected" src={node?.image} alt={`Slide ${index}`} />
                                                    </div>
                                                    <h4 className="dashboard-user-award-badge-image-selected-title">{node?.title}</h4>
                                                    <div key={index} className="dashboard-user-award-badge-image-selected-detail dashboard-show-kudos-detail-card" style={{ textOverflow: "ellipsis", textAlign: "center" }}>
                                                        {node?.behaviouralDescription}
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>}
                                        {selectedBadge && <div className="dashboard-selected-kudos-card" style={{ position: "relative" }}>
                                            <img src={close_icon} alt="" style={{ cursor: "pointer", position: "absolute", right: "10px", top: '10px' }} onClick={() => badgeSelectHandler()} />
                                            <div>
                                                <img style={{ height: "em", width: "6em" }} src={selectedBadge?.image} alt="" />
                                            </div>
                                            <div style={{ borderleft: "1px soild #AABAC633", marginLeft: ".5em", paddingLeft: "1em" }}>
                                                <h4 style={{ fontFamily: "Poppins", fontSize: "1em", fontWeight: "600", color: "#4CCB1F" }}>{selectedBadge?.title}</h4>
                                                <p className="dashboard-details-elipsis" style={{ fontFamily: "Poppins", fontSize: ".85em", fontWeight: "400", color: "#303030" }}>{selectedBadge?.behaviouralDescription}</p>
                                            </div>
                                        </div>}
                                        {badgeList?.length == 0 && loading == false &&
                                            <div style={{ minWidth: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <h4 className="recived-award-image-title" style={{ margin: 'auto', justifyContent: 'center', textAlign: "center" }}>No Kudos Found</h4>
                                            </div>
                                        }
                                    </Spin>
                                </div>
                            </div>
                            <Col className="kudos-form-group no-bg-drop" style={{ paddingTop: "20px" }}>
                                {/* <label style={{ fontSize: "18px",color:"#FF0404",fontWeight:"700" }}> * </label> */}
                                <Select
                                    className={selectedNotify ? "user-badge-select-active" : "user-badge-select"}
                                    name={'notify_category'}
                                    id={'notify_category'}
                                    placeholder="Select Whom To Notify"
                                    suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />}
                                    style={{ width: '100%', backgroundColor: "#fff" }}
                                    value={selectedNotify}
                                    {...props}
                                    onSelect={handleSelectNotify}
                                    disabled={false}
                                >
                                    {category_option}
                                </Select>
                            </Col>
                            <Col style={{ marginTop: '20px' }} className="kudos-form-group" >
                                {/* <label style={{ fontSize: "18px" }}>Rational</label> */}
                                <Col>
                                    <textarea style={{ width: "100%", height: "200px", backgroundColor: "#fff", border: detail?.length ? "1px solid #9CD600" : "1px solid #000000", }} placeholder="Rationale" name="description" id="" className="kudos-description" value={detail?.length ? detail : ''} onChange={(e) => { setdetail(e?.target?.value) }}></textarea>
                                </Col>
                            </Col>
                            <Row justify="center" align="center" style={{ width: "100%", marginTop: "20px" }}>
                                <Button className="save-btn" htmlType="submit" disabled={!selectedEmployee || !selectedNotify ||!detail}>
                                    Save
                                </Button>
                            </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}

export default AwardBadgeView

