import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Col, Row, Empty, Button, Popover, Steps } from 'antd';
import help_icon from '../../assets/help-text.svg'
import {
    ReactMarkdown,
    InputValuesObject,
    interpolate,
    getIntFromString,
    withMarkdownFieldValue,
    interpolateFor3cs,
    Capitalize
} from '../../look';
import SurveyCheckBoxField from './survey-checkbox-filed';
import SurveyColorField from './survey-color-filed';
import SurveyRadioField from './survey-radio-button-filed';
import SurveyRangeField from './survey-range-filed';
import SurveyTextField from './survey-text-field';
import SurveySelectField from './survey-select-filed';
import SurveyTimePicker from './survey-time-field';
import { withApollo } from '@apollo/client/react/hoc';
import { compose } from 'modules/core';
import { FILTER_SURVEY_CHOICE } from '../../diversity-survey/graphql/filterSurveyChoice.gql'
import SurveyDatePickerField from './survey-datepicker-field';
import RenderUploadWithCrop from './survey-file-upload';
import CKEditorFieldSurvey from './survey-ck-editio-filed';
import GenericMartx from './generic-metrix';
import { question_id_for_3cs, SURVEY_ID_3CS } from '../../../config';
export const TIME_LIMIT = 5;

const emailRules = { type: 'email', message: 'Not a vaild email' };

export function getTypeFromQuestion(question) {
    return question?.choiceSet?.edges[0]?.node?.inputType;
}
const Step = Steps.Step;
const GenericSurveyView = props => {

    const {
        questionList,
        matrix,
        form,
        nextStep,
        srNo,
        timeoutFunc,
        closetimer,
        currentSection,
        showOk,
        setShowOk,
        survey,
        client,
        formValues,
        selectedId,
        currentQuestionId,
        is_survey_soul,
        open_questions,
        onAnswerChange,
        form_values,
        org_employee_list,
        orgEmployeeList
    } = props;
    const [showInput, setShowInput] = React.useState(false)
    const [hide_okay_btn, SetHideOkayBtn] = React.useState(false)
    const values = form.getFieldsValue(true);
    const getRangeValues = (choiceSet, type) => {
        let defaultValue = 0,
            marks = {},
            step = 1;
        const value = choiceSet[0]?.value?.split(':') || [];
        // min:max:jump:default
        if (type === 'RANGE') {
            marks[value[0]] = value[0];
            marks[value[1]] = value[1];
            step = value[2] === '0' ? '1' : value[2];
            defaultValue = value[3];
        }
        return { step, marks, defaultValue, min: Number(value[0]), max: Number(value[1]) };
    };

    let available_type_for_okay = [
        InputValuesObject.checkbox,
        InputValuesObject.ckeditor,
        InputValuesObject.email,
        InputValuesObject.number,
        InputValuesObject.password,
        InputValuesObject.telephone,
        InputValuesObject.tel,
        InputValuesObject.text,
        InputValuesObject.textarea,
        InputValuesObject.url,
        InputValuesObject.color
    ]

    const RenderQuestion = (question, index) => {
        const [colorLoading, setColorLoading] = React.useState(false);
        const [load, setLoad] = useState(false);
        const { id, sequence, questionText, required, helpText } = question;
        const [choiceSet, setchoiceSet] = React.useState(question?.choiceSet)
        const type = getTypeFromQuestion(question);
        const [reset, setReset] = useState(false);
        const [open, setOpen] = useState(false);
        const obj = {};
        const keyArray = Object.keys(values);
        keyArray?.length > 0 &&
            keyArray.map(key => {
                obj[getIntFromString(key)] = values[key];
            });
        const onSelectFeildSearch = async (search, que) => {
            let id = survey?.id;
            const { data } = await client.query({
                query: FILTER_SURVEY_CHOICE,
                variables: { id: id, label_Icontains: search },
                // fetchPolicy: 'network-only'
            });
            if (data?.Options) {
                let qa = data?.Options.groupSet?.edges.map(item => item?.node?.questionSet?.edges)
                qa = [].concat.apply([], qa)
                let current = qa.find(item => item?.node?.id === que?.id)
                if (current) {
                    setchoiceSet(current?.node?.choiceSet)
                }
            }
        }
        const label = getIntFromString(survey?.id) != SURVEY_ID_3CS ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '0px' }}>
                <h4>{srNo}.</h4>
                {Object.keys(obj).length !== 0 ? <ReactMarkdown style={{ marginLeft: '4px' }}>{interpolate(questionText?.replace('{', '${'), obj)}</ReactMarkdown> :
                    <ReactMarkdown style={{ marginLeft: '4px' }}>{interpolate(questionText.replace(/\{\d+}/g, ''), obj)}</ReactMarkdown>}
            </div>
        ) : (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '0px' }}>
                <h4>{srNo}.</h4>
                {Object.keys(obj).includes(question_id_for_3cs?.toString()) ? <ReactMarkdown style={{ marginLeft: '4px' }}>{interpolateFor3cs(questionText?.replace('{', '${'), obj)}</ReactMarkdown> :
                    <ReactMarkdown style={{ marginLeft: '4px' }}>{interpolateFor3cs(questionText.replace(/\{\d+}/g, ''), obj)}</ReactMarkdown>}
            </div>
        );
        const renderLabel = (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "start", gap: "10px", width: "100%", paddingTop: "10px" }}>
                {required && <span style={{ color: "#DE4A4A" }}>*</span>}
                {label}
            </div>
        );

        const rules = [{ required: true, message: 'An answer is required.' }];

        const { step, marks, defaultValue, min, max } = getRangeValues(
            choiceSet?.edges?.map(({ node }) => node),
            type
        );

        const handleChange = doNext => {
            if (doNext) {
                if (showOk) {
                    setShowOk(false);
                    setShowOk(true);
                    setReset(true);
                    closetimer();
                    // currentQuestionId(id)
                } else {
                    setShowOk(true);
                    timeoutFunc(srNo);
                    // currentQuestionId(id)
                }
            } else {
                setShowOk(true);
            }
        };
        const OperandValues = (id) => {
            if (formValues?.length > 0) {
                let filer = formValues?.find(item => getIntFromString(item.questionId) === getIntFromString(id))
                if (filer) {
                    return true
                }
                else {
                    return false
                }
            }
        }
        const OperandSelectedValues = (id) => {
            if (formValues?.length > 0) {
                let filer = formValues?.find(item => getIntFromString(item.questionId) === getIntFromString(id))
                if (filer) {

                    setShowInput(true)
                }
                else {
                    setShowInput(false)
                }
            }
        }
        const helpContent = (text) => {
            return (
                <div style={{ maxWidth: "400px", width: "100%" }}>
                    <p className='generic-survey-help-text'><ReactMarkdown>{text}</ReactMarkdown></p>
                    <button className='generic-survey-help-text-btn' onClick={() => setOpen(false)}>Got it</button>
                </div>
            )
        }

        const onDataChange = (id, value, type) => {
            if (onAnswerChange) {
                onAnswerChange(id, value, type)
            }
        }

        const IsAnsweredRange = (id) => {
            let answered = is_survey_soul ? formValues?.find(i => i?.questionId == id) : formValues?.find(i => i?.questionId == getIntFromString(id))
            return answered ? true : false
        }

        let oke_time_out_ref = React.useRef()
        const handleOkayBtn = (hide_okay) => {
            if (oke_time_out_ref?.current) {
                clearTimeout(oke_time_out_ref?.current)
                oke_time_out_ref.current = undefined
            }
            SetHideOkayBtn(hide_okay)
            if (!hide_okay) {
                oke_time_out_ref.current = setTimeout(() => {
                    SetHideOkayBtn(true)
                }, 10000);
            }
        }

        const handleCkEditorData = (id, event) => {
            let data = withMarkdownFieldValue(id, { [id]: event })
            form.setFieldsValue(_.set(form.getFieldsValue(true), [id], data[id]))
        }

        return true ? (
            <div className="generic-survey-question-container" style={{ background: OperandValues(id) ? "rgba(236, 241, 244, 1)" : "rgba(244, 249, 253, 1)" }} key={index} visible={currentSection === srNo}  >
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "10px" }} onClick={() => { setShowInput(true); selectedId(id) }}>
                    <h4 className='generic-survey-question' style={{ cursor: "pointer", display: 'flex', flexDirection: "row", alignItems: "center", gap: "10px" }}>{renderLabel}</h4>
                    {helpText && <Popover visible={open} trigger="click" overlayClassName="generic-survey-help-text-popover" placement="topRight" content={helpContent(helpText)}><img src={help_icon} alt='' style={{ cursor: "pointer", width: "25px" }} onClick={() => setOpen(true)} />
                    </Popover>}
                </div>
                {(open_questions?.includes(id) || showInput) && (
                    <div style={{ marginTop: "10px" }}>

                        {(getIntFromString(question?.id) === question_id_for_3cs) ?
                            <Row>
                                <Col lg={24} xs={20}>

                                    <SurveySelectField
                                        showSearch
                                        onSearch={(e) => { orgEmployeeList({ search: e }) }}
                                        otherOption={false}
                                        name={id}
                                        offset={2}
                                        //   label={renderLabel}
                                        rules={required ? rules : []}
                                        choices={org_employee_list.map(u => ({ label: `${Capitalize(u?.firstName)} ${Capitalize(u?.lastName) || ""} (${u?.email})`, value: `${u?.firstName} ${u?.lastName || ""}  <email style="display:none">${u?.email}</email>` }))}
                                        onSelectChange={e => { handleChange(false); onDataChange(id) }}
                                        onBlur={e => e?.target?.value && handleChange(false)}
                                    />
                                </Col>
                            </Row> :
                            <>
                                {type === InputValuesObject.checkbox && (
                                    <SurveyCheckBoxField
                                        name={id}
                                        //   label={renderLabel}
                                        rules={required ? rules : []}
                                        choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                                        onChange={e => { handleChange(false); handleOkayBtn(false) }}
                                    />
                                )}
                                {type === InputValuesObject.color && (
                                    <SurveyColorField
                                        hide_okay_btn={hide_okay_btn}
                                        name={id}
                                        //   label={renderLabel}
                                        rules={required ? rules : []}
                                        choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                                        onChange={e => {
                                            form.setFieldsValue(_.set(form.getFieldsValue(true), [id], e)); handleChange(false); handleOkayBtn(false)
                                        }}
                                        value={form.getFieldValue(id)}
                                    />
                                )}
                                {type === InputValuesObject.date && (
                                    <SurveyDatePickerField
                                        margin={false}
                                        name={id}
                                        //   label={label}
                                        offset={3}
                                        format="YYYY-MM-DD"
                                        rules={[{ required, message: 'Please select a date' }]}
                                        enabledisabledDate={false}
                                        onChange={e => { handleChange(true); onDataChange(id) }}
                                    />
                                )}
                                {type === InputValuesObject.datetime && (
                                    <SurveyDatePickerField
                                        margin={false}
                                        name={id}
                                        // label={label}
                                        offset={3}
                                        showTime={true}
                                        format="YYYY-MM-DD HH:mm"
                                        rules={[{ required, message: 'Please select a date and time' }]}
                                        enabledisabledDate={false}
                                        onChange={e => { handleChange(true); onDataChange(id) }}
                                    // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                    />
                                )}
                                {type === InputValuesObject.month && (
                                    <SurveyDatePickerField
                                        margin={false}
                                        name={id}
                                        //   label={label}
                                        offset={3}
                                        rules={[{ required, message: 'Please select a month' }]}
                                        enabledisabledDate={false}
                                        picker="month"
                                        type="month"
                                        onChange={e => { handleChange(true); onDataChange(id) }}
                                    // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                    />
                                )}
                                {type === InputValuesObject.time && (
                                    <SurveyTimePicker
                                        margin={false}
                                        name={id}
                                        //   label={label}
                                        offset={3}
                                        rules={[{ required, message: 'Please select a month' }]}
                                        enabledisabledDate={false}
                                        type="time"
                                        onChange={e => { handleChange(true); onDataChange(id) }}
                                    // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                    />
                                )}
                                {type === InputValuesObject.week && (
                                    <SurveyDatePickerField
                                        margin={false}
                                        name={id}
                                        //   label={label}
                                        offset={3}
                                        rules={[{ required, message: 'Please select a month' }]}
                                        enabledisabledDate={false}
                                        picker="week"
                                        type="week"
                                        onChange={e => { handleChange(true); onDataChange(id) }}
                                    // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                                    />
                                )}
                                {type === InputValuesObject.radio && (
                                    <SurveyRadioField
                                        name={id}
                                        //   label={renderLabel}
                                        choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                                        rules={required ? rules : []}
                                        onChange={e => { handleChange(true); onDataChange(id) }}
                                    />
                                )}
                                {type === InputValuesObject.range && (
                                    <SurveyRangeField
                                        className={`generic-survey-range-slider ${IsAnsweredRange(id) ? '' : 'not-answered-slider'}`}
                                        name={id}
                                        rules={required ? rules : []}
                                        marks={marks}
                                        step={step}
                                        defaultValue={defaultValue}
                                        min={min}
                                        max={max}
                                        trackStyle={{ backgroundColor: '#B0CB1F' }}
                                        handleStyle={{ backgroundColor: '#B0CB1F' }}
                                        onAfterChange={e => { handleChange(true); onDataChange(id, e, InputValuesObject.range) }}
                                    />
                                )}
                                {type === InputValuesObject.email && (
                                    <SurveyTextField
                                        name={id}
                                        // label={renderLabel}
                                        rules={required ? rules.map(r => ({ ...r, ...emailRules })) : [{ ...emailRules }]}
                                        // rules={[{ type: 'email' }]}
                                        offset={2}
                                        onChange={e => { handleChange(false); handleOkayBtn(false) }}
                                        onBlur={e => e?.target?.value && handleChange(false)}
                                    />
                                )}
                                {type === InputValuesObject.file && (
                                    <RenderUploadWithCrop
                                        setUrl={e => { e && handleChange(true); form.setFieldsValue(_.set(form.getFieldsValue(true), id, e)) }}
                                        setLoad={setLoad}
                                        offset={2}
                                        name={id}
                                    //   label={renderLabel}
                                    />
                                )}
                                <Row>
                                    <Col lg={24} xs={20}>
                                        {type === InputValuesObject.text && (
                                            <SurveyTextField
                                                name={id}
                                                //   label={renderLabel}
                                                rules={required ? rules : []}
                                                offset={2}
                                                onChange={e => { handleChange(false); handleOkayBtn(false) }}
                                                onBlur={e => e?.target?.value && handleChange(false)}
                                            />
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={24} xs={20}>
                                        {type === InputValuesObject.url && (
                                            <SurveyTextField
                                                name={id}
                                                //   label={renderLabel}
                                                type="url"
                                                rules={required ? rules : []}
                                                offset={2}
                                                onChange={e => { handleChange(false); handleOkayBtn(false) }}
                                                onBlur={e => e?.target?.value && handleChange(false)}
                                            />
                                        )}
                                    </Col>
                                </Row>
                                {type === InputValuesObject.number && (
                                    <SurveyTextField
                                        name={id}
                                        //   label={renderLabel}
                                        rules={required ? rules : []}
                                        type="number"
                                        offset={2}
                                        onChange={e => { handleChange(false); handleOkayBtn(false) }}
                                        onBlur={e => e?.target?.value && handleChange(false)}
                                    />
                                )}
                                {type === InputValuesObject.password && (
                                    <SurveyTextField
                                        name={id}
                                        //   label={renderLabel}
                                        rules={required ? rules : []}
                                        type="password"
                                        offset={2}
                                        onChange={e => { handleChange(false); handleOkayBtn(false) }}
                                        onBlur={e => e?.target?.value && handleChange(false)}
                                    />
                                )}
                                {type === InputValuesObject.select && (
                                    <Row>
                                        <Col lg={24} xs={20}>
                                            <SurveySelectField
                                                showSearch
                                                onSearch={(e) => { onSelectFeildSearch(e, question) }}
                                                otherOption={true}
                                                name={id}
                                                offset={2}
                                                //   label={renderLabel}
                                                rules={required ? rules : []}
                                                choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                                                onSelectChange={e => { handleChange(false); onDataChange(id) }}
                                                onBlur={e => e?.target?.value && handleChange(false)}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {type === InputValuesObject.ckeditor && (
                                    <Row>
                                        <Col lg={24} xs={20}>
                                            <CKEditorFieldSurvey
                                                name={id}
                                                rules={required ? rules : []}
                                                offset={2}
                                                onChange={e => { handleCkEditorData(id, e); handleChange(false); handleOkayBtn(false) }}
                                                onBlur={e => { handleCkEditorData(id, e); handleChange(false) }}
                                            />
                                        </Col>
                                    </Row>
                                )}

                                {type === InputValuesObject.tel && (
                                    <SurveyTextField
                                        name={id}
                                        //   label={renderLabel}
                                        rules={required ? rules : []}
                                        max_length={10}
                                        type="number"
                                        offset={2}
                                        onChange={e => { handleChange(false); handleOkayBtn(false) }}
                                        onBlur={e => e?.target?.value && handleChange(false)}
                                    />
                                )}
                                {type === InputValuesObject.textarea && (
                                    <SurveyTextField
                                        name={id}
                                        type="textarea"
                                        //   label={renderLabel}
                                        rules={required ? rules : []}
                                        offset={2}
                                        onChange={e => { handleChange(false); handleOkayBtn(false) }}
                                        onBlur={e => e?.target?.value && handleChange(false)}
                                    />
                                )}
                            </>
                        }



                        {(available_type_for_okay?.includes(type) && form_values[id] && !hide_okay_btn) && (
                            <Button className='generic-survey-ok-button' style={{ height: "2.5em" }} disabled={load} onClick={e => { nextStep(srNo); onDataChange(id); handleOkayBtn(true) }} {...props}>
                                Okay
                            </Button>
                        )}
                    </div>
                )}
            </div>
        ) : null;
    };

    return matrix && matrix ? (

        <GenericMartx {...props} values={values} />

    ) : questionList?.length > 0 ? (
        questionList?.map(RenderQuestion)
    ) : (
        <Empty />
    );
};

export default compose(withApollo)(GenericSurveyView);
