// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import '../Css/callhandler.css'
import TranscriptionContainer from '../../one-on-one/containers/TranscriptionContainer';
import answecall from '../../assets/answercall.png'
import missed_call from '../../assets/missed_call.png'
import cam_on from '../../assets/cam-on.png'
import cam_off from '../../assets/cam-off.png'
import mic_on from '../../assets/mic-on.png'
import mic_off from '../../assets/mic-off.png'
import ringtone from '../../assets/audio/ring_tone.mp3'
import transcription_icon from '../../assets/transcription_only.png'
import { Tooltip, Spin, message, Modal,Button } from "antd";
import Peer from 'simple-peer';
import { getItem } from 'modules/core';
import { Capitalize, getIntFromString, globalPermissionValidator } from 'modules/look';
import { TRANSCRIPTION_SOCKET_URI, TRANSCRIPTION_SERVER_URI, CALL_SERVER_CONFIG, call_reconnection_timer, ACCESS_TOKEN } from '../../../config';
import CallingState from './CallingState';
import CallingIncomingState from './CallIncomingStatus';
import CallConnected from './CallconnectedState';
import CallEndState from './CallEndState';
import { SOULTIPS_FOR_REPORTEE, SOULTIPS_FOR_REPORTEE_CALL } from 'modules/one-on-one/graphql/SouiTip.gql';
import { call_permission } from 'Permissions/Callfeature.permission';
const CallTranscriptHandler = props => {
    const { relateddocument, document_type, recording_status, transcription_visible, transcription, setRecordingStatus, info_message, me, client, userPermission, participants, setcallState, socketError, settranscription_media_availability, settranscription_unmute } = props
    const [handlerTab, setHandlerTab] = React.useState()
    const [callstatus, setcallstatus] = React.useState()
    const callStatusRef = React.useRef(null)
    const handlerTabRef = React.useRef(null)
    const [localVideoStream, setlocalVideoStream] = React.useState()
    const [remoteVideoStream, setremoteVideoStream] = React.useState()
    const peerRef = React.useRef(null);
    const peersocketRef = React.useRef(null);
    const [peersocket_connected, SetpeersocketConnection] = React.useState(false);
    const [peersocket_error, SetpeersocketError] = React.useState(false);
    const [receivedsignalData, SetreceivedsignalData] = React.useState();
    const receivedsignal = React.useRef(null);
    const sendsignal = React.useRef(null);
    const audioTrack = React.useRef(null);
    const videoTrack = React.useRef(null);
    const streamRef = React.useRef(null);
    const callstateTimeout = React.useRef(null);
    const [video_turned_on, setVideoStaus] = React.useState(false);
    const [audio_turned_on, setAudioStaus] = React.useState(true);
    const video_ref = React.useRef(false)
    const audio_ref = React.useRef(true)
    const [system_icons, setSystemIcons] = React.useState({ cam_on, cam_off, mic_on, mic_off });
    let missed_call_ref = React.useRef(0);
    const [missedCall_count, SetmissedCall_count] = React.useState(missed_call_ref.current || 0);
    const [call_soultips, setCallSoultips] = React.useState();
    const [call_module_permission, setCallModulePermission] = React.useState();
    const [header_text, setheader_text] = React.useState();
    const [end_call_message, setEndCallmessage] = React.useState();
    const [media_availability, setmedia_availability] = React.useState();

    const callInitiator = React.useRef(false);
    const [reconnection, setReconnection] = React.useState(false);
    const reconnection_status_ref = React.useRef(false);
    const reconnection_timer = React.useRef(null);
    const [show_call_info, SetCallInfo] = React.useState(false);
    const [reconnection_for_other, setReconnectionForOther] = React.useState(false);
    const reconnection_for_other_timer = React.useRef(null);
    React.useEffect(() => {
        reconnection_status_ref.current = reconnection
    }, [reconnection])

    React.useEffect(() => {
        callStatusRef.current = callstatus
    }, [callstatus])

    React.useEffect(() => {
        handlerTabRef.current = handlerTab
        if (!handlerTab) {
            setEndCallmessage(null)
        }
    }, [handlerTab])

    React.useEffect(() => {
        if (setcallState) {
            if (handlerTab == "transcription" && recording_status) {
                setcallState("transcription")
            } else if (handlerTab == "call" && (callstatus && callstatus != "Call_ended" && callstatus != 'Decline')) {
                setcallState("call")
            } else {
                setcallState(undefined)
            }

        }
    }, [handlerTab, recording_status, callstatus])

    React.useEffect(() => {
        let audioContext = new AudioContext();

        // if (audioContext && audioContext?.state !== "suspended") {
        if (handlerTab == 'call' && (callstatus == 'Ringing' || callstatus == 'Calling' || callstatus == 'Incoming')) {
            let audio_player = document.getElementById('ringtone')
            if (audio_player) {
                try {
                    audio_player.play()
                } catch (error) {

                }
            }
        } else {
            let audio_player = document.getElementById('ringtone')
            if (audio_player) {
                try {
                    audio_player.pause()
                } catch (error) {

                }
            }
        }
        // }
    }, [callstatus, handlerTab])

    React.useEffect(() => {
        if (!handlerTab) {
            if (streamRef.current) {
                let tracklist = streamRef.current.getTracks()
                if (tracklist) {
                    tracklist.forEach(track => {
                        track.enabled = false
                        track.stop()
                    })
                }
                streamRef.current = null
            }
        }
    }, [handlerTab])

    React.useEffect(() => {
        if (relateddocument?.id && document_type) {
            if (document_type == 'one_on_one_meeting') {
                let title = `One on one meeting - ${relateddocument?.title ? relateddocument?.title : ''}`
                setheader_text(title)
            }
            checkmediaAvailability()
            SetCookie()
        }
    }, [relateddocument, document_type])

    React.useEffect(() => {
        if (userPermission?.length && !call_module_permission) {
            let permission = globalPermissionValidator(call_permission, userPermission)
            setCallModulePermission(permission)
        }
    }, [userPermission])

    React.useEffect(() => {
        if (call_module_permission && participants?.length) {
            if (call_module_permission?.soultip_permission) {
                // getCallSoulTips("values", participants[0]) // for now we are only considering one user in future handle this to change soultips if different user comes in
            }
        }
    }, [call_module_permission, participants])

    React.useEffect(() => {
        video_ref.current = video_turned_on
        if (peerRef.current) {
            navigator.mediaDevices.getUserMedia({ video: video_ref.current, audio: audio_ref.current }).then((stream) => {
                if (streamRef.current) {
                    let tracklist = streamRef.current.getTracks()
                    if (tracklist) {
                        tracklist.forEach(track => {
                            track.enabled = false
                            track.stop()
                        })
                    }
                    peerRef.current.removeStream(streamRef.current);
                }

                audioTrack.current = stream.getAudioTracks() || [];
                videoTrack.current = stream.getVideoTracks() || [];
                peerRef.current.addStream(stream);
                setlocalVideoStream(stream)
                streamRef.current = stream
            }).catch(err => {
                if (streamRef.current && !audio_turned_on) {
                    sendSignalingMessage({}, 'stream_removed')
                    let tracklist = streamRef.current.getTracks()
                    if (tracklist) {
                        tracklist.forEach(track => {
                            track.enabled = false
                            track.stop()
                        })
                    }
                    peerRef.current.removeStream(streamRef.current);
                    setlocalVideoStream(null)
                    streamRef.current = null
                }
            })
        }
    }, [video_turned_on])

    React.useEffect(() => {
        if (settranscription_unmute) {
            settranscription_unmute(audio_turned_on)
        }
        audio_ref.current = audio_turned_on
        if (peerRef.current) {
            navigator.mediaDevices.getUserMedia({ video: video_ref.current, audio: audio_ref.current }).then((stream) => {
                if (streamRef.current) {
                    let tracklist = streamRef.current.getTracks()
                    if (tracklist) {
                        tracklist.forEach(track => {
                            track.enabled = false
                            track.stop()
                        })
                    }
                    peerRef.current.removeStream(streamRef.current);
                }

                audioTrack.current = stream.getAudioTracks() || [];
                videoTrack.current = stream.getVideoTracks() || [];
                peerRef.current.addStream(stream);
                setlocalVideoStream(stream)
                streamRef.current = stream
            }).catch(err => {
                if (streamRef.current && !video_turned_on) {
                    sendSignalingMessage({}, 'stream_removed')
                    let tracklist = streamRef.current.getTracks()
                    if (tracklist) {
                        tracklist.forEach(track => {
                            track.enabled = false
                            track.stop()
                        })
                    }
                    peerRef.current.removeStream(streamRef.current);
                    setlocalVideoStream(null)
                    streamRef.current = null
                }
            })
        }
    }, [audio_turned_on])

    React.useEffect(() => {
        if (recording_status && !handlerTab) {
            setHandlerTab('transcription')
        }
    }, [recording_status])

    const getCallSoulTips = async (value, user) => {

        try {
            const { data } = await client.query({
                query: SOULTIPS_FOR_REPORTEE_CALL,
                variables: { other: true, reportUserId: getIntFromString(user) },
                fetchPolicy: 'network-only'
            });
            if (data?.getSoulAiTip?.length) {
                setCallSoultips([data?.getSoulAiTip]?.map(item=>item?.forOther))
            }
        } catch (e) {

        }

    }

    const checkmediaAvailability = () => {
        navigator.mediaDevices.enumerateDevices()
            .then((devices) => {

                let media = {
                    audio: devices?.find(item => item?.kind == 'audioinput') ? true : false,
                    video: devices?.find(item => item?.kind == 'videoinput') ? true : false
                }
                if (!media?.audio) {
                    setAudioStaus(false)
                }
                if (!media?.video) {
                    setVideoStaus(false)
                }
                if (settranscription_media_availability) {
                    settranscription_media_availability(media)
                }
                setmedia_availability(media)

            })
            .catch((error) => {
                settranscription_media_availability({ audio: false, video: false })
                setmedia_availability({ audio: false, video: false })
            });
    }

    const SetCookie = async (call_reconnection = false) => {
        let token = await getItem(ACCESS_TOKEN)
        try {
            var blob = new Blob([], { type: 'audio/wav' });
            var audio_data = new File([blob], `audio${Date.now()}.wav`, { type: 'audio/wav' });
            let form_data = new FormData()
            form_data.append('language', 'en')
            form_data.append('input_data', audio_data)
            form_data.append('model_size', 'small')
            form_data.append('meeting_id', getIntFromString(relateddocument?.id))

            fetch(`${TRANSCRIPTION_SERVER_URI}/transcribe`, {
                method: 'POST',
                body: form_data,
                credentials: 'include',
                headers: {
                    Authorization: `JWT ${token}`
                }
            })
                .then((res) => {
                    if (!call_reconnection) {
                        listenToNetwork()
                    }
                    integrateCallSocket(call_reconnection)
                })
                .catch((err) => { })
        } catch (error) { }
    };

    const listenToNetwork = () => {
        window.addEventListener('online', () => {
            if (callStatusRef.current != 'Connected') {
                if (peersocketRef.current) {
                    peersocketRef.current.close()
                    peersocketRef.current = null
                }
                SetCookie()
            } else {
                if (reconnection_timer.current && reconnection_status_ref.current) {
                    clearTimeout(reconnection_timer.current)
                    reconnection_timer.current = null
                    if (peersocketRef.current) {
                        peersocketRef.current.close()
                        peersocketRef.current = null
                    }
                    SetCookie(true)
                }
            }
        });
    }

    const integrateCallSocket = async (call_reconnection = false) => {
        try {
            SetpeersocketConnection(false)
            let requestUrl = `${TRANSCRIPTION_SOCKET_URI}/call_${getIntFromString(relateddocument?.id)}/`
            peersocketRef.current = new WebSocket(requestUrl);
            peersocketRef.current.onopen = () => {
                SetpeersocketConnection(true)
                if (call_reconnection) {
                    initiateCall(true)
                }
            };
            peersocketRef.current.onerror = (err) => { message.error('Your browser failed to connect to socket'); SetpeersocketError(true) };
            peersocketRef.current.onmessage = (value) => {
                let socket_data = JSON.parse(value?.data)
                if (socket_data?.results?.user_id != me?.employee?.id && socket_data?.results?.info == "calling") {
                    receivedsignal.current = socket_data?.results?.signaldata
                    SetreceivedsignalData(socket_data?.results)
                    if (!handlerTabRef.current || (handlerTabRef.current == "call" && (callStatusRef.current == 'Decline' || callStatusRef.current == 'Call_ended'))) {
                        setHandlerTab('call')
                        setcallstatus("Incoming")
                        missed_call_ref.current = missed_call_ref.current + 1
                        SetmissedCall_count(missed_call_ref.current)
                        if (callstateTimeout.current) {
                            clearTimeout(callstateTimeout.current)
                        }
                        callstateTimeout.current = setTimeout(() => {
                            resetCall()
                        }, 30000);
                    } else if (peerRef.current && callStatusRef.current == 'Connected') {
                        peerRef.current.signal(receivedsignal.current);
                    } else if (callStatusRef.current == 'Connected') {
                        answerCall(true)
                        if (reconnection_timer.current) {
                            clearTimeout(reconnection_timer.current)
                            reconnection_timer.current = null
                        }
                        if (reconnection_for_other_timer.current) {
                            clearTimeout(reconnection_for_other_timer.current)
                            reconnection_for_other_timer.current = null
                        }

                    }
                }
                else if (socket_data?.results?.user_id != me?.employee?.id && socket_data?.results?.info == "answer") {
                    receivedsignal.current = socket_data?.results?.signaldata
                    SetreceivedsignalData(socket_data?.results)
                    if (peerRef.current) {
                        peerRef.current.signal(socket_data?.results?.signaldata);
                    }
                }
                else if (socket_data?.results?.user_id != me?.employee?.id && socket_data?.results?.info == "cancel_call") {
                    resetCall()
                }
                else if (socket_data?.results?.user_id != me?.employee?.id && socket_data?.results?.info == "Decline") {
                    resetCall('call', 'Decline')
                }
                else if (socket_data?.results?.user_id != me?.employee?.id && socket_data?.results?.info == "Call_ended") {
                    resetCall('call', 'Call_ended')
                    setEndCallmessage(`${socket_data?.results?.user_data?.firstName} has ended your call. See you!`)
                }
                else if (socket_data?.results?.user_id != me?.employee?.id && socket_data?.results?.info == "Connecting") {
                    setHandlerTab('call')
                    setcallstatus("Connecting")
                }
                else if (socket_data?.results?.user_id != me?.employee?.id && socket_data?.results?.info == "stream_removed") {
                    setremoteVideoStream(null)
                }
                else if (socket_data?.results?.user_id != me?.employee?.id && socket_data?.results?.info == "no_micro_phone") {
                    if (transcription && media_availability?.audio && !recording_status) {
                        setRecordingStatus(true)
                    }
                }

            }
            peersocketRef.current.onclose = (event) => { }
        } catch (error) {
            console.log('errr', error);
        }

    }

    const initiateCall = (call_reconnection = false) => {
        if (!navigator.onLine) {
            message.error('Make sure you have proper network connection')
            return
        }
        if (navigator.mediaDevices) {
            if (streamRef.current) {
                let tracklist = streamRef.current.getTracks()
                if (tracklist) {
                    tracklist.forEach(track => {
                        track.enabled = false
                        track.stop()
                    })
                }
            }
            if (!call_reconnection) {
                setHandlerTab('call')
                setcallstatus("Calling")
                callInitiator.current = true
            }
            navigator.mediaDevices.getUserMedia({ video: video_ref.current, audio: audio_ref.current }).then((stream) => {
                setInitiateCallPeer(stream, call_reconnection)
            }).catch((err) => {
                setAudioStaus(false)
                setVideoStaus(false)
                setInitiateCallPeer(null, call_reconnection)
            })
        }
    }

    const setInitiateCallPeer = (stream, call_reconnection) => {
        if (stream) {
            audioTrack.current = stream.getAudioTracks() || [];
            videoTrack.current = stream.getVideoTracks() || [];
            audioTrack.current.forEach(item => {
                item.enabled = audio_turned_on
            })
        } else {
            audioTrack.current = []
            videoTrack.current = []
        }

        peerRef.current = new Peer({
            initiator: true,
            trickle: false,
            stream,
            config: {
                iceServers: [
                    {
                        urls: CALL_SERVER_CONFIG.stun_url
                    },
                    {
                        urls: CALL_SERVER_CONFIG.turn_url,
                        username: CALL_SERVER_CONFIG.turn_user,
                        credential: CALL_SERVER_CONFIG.turn_password
                    }
                ],
            }
        })

        setlocalVideoStream(stream)
        streamRef.current = stream
        peerRef.current.on("signal", (data) => {

            sendSignalingMessage(data, "calling");
            if (callStatusRef.current == 'Calling') {
                setcallstatus("Ringing")
                if (callstateTimeout.current) {
                    clearTimeout(callstateTimeout.current)
                }
                callstateTimeout.current = setTimeout(() => {
                    cancelCall('cancel_call')
                }, 30000);
            } else if (callStatusRef.current == 'Connected' && reconnection_status_ref.current && call_reconnection) {
                if (callstateTimeout.current) {
                    clearTimeout(callstateTimeout.current)
                }
                callstateTimeout.current = setTimeout(() => {
                    setReconnection(false)
                    cancelCall('cancel_call')
                }, 30000);
            }
        });

        peerRef.current.on("stream", (stream) => {
            setremoteVideoStream(stream);
        });

        peerRef.current.on("error", (error) => {

            if ((error.code == 'ERR_CONNECTION_FAILURE' || error.code == "ERR_ICE_CONNECTION_FAILURE") && callStatusRef.current == 'Connected') {
                if (peerRef.current) {
                    peerRef.current.destroy()
                    peerRef.current = null
                }
                setremoteVideoStream(null)
                setlocalVideoStream(null)
                setVideoStaus(false)
                setAudioStaus(false)
                if (streamRef.current) {
                    let tracklist = streamRef.current.getTracks()
                    if (tracklist) {
                        tracklist.forEach(track => {
                            track.enabled = false
                            track.stop()
                        })
                    }
                }

                if (!navigator.onLine) {
                    runTimer()
                } else {
                    reconnectForOther()
                    if (receivedsignalData?.user_data?.firstName) {
                        message.info(`${Capitalize(receivedsignalData?.user_data?.firstName)} network connectivity is low`)
                    } else {
                        message.info(`other participants network connectivity is low`)
                    }
                }
            } else {
                resetCall()
            }
            // There was an error in establishing the peer connection
        });

        // Event listener for when a remote peer connects
        peerRef.current.on("connect", (connection) => {
            setReconnection(false)
            missed_call_ref.current = 0
            SetmissedCall_count(missed_call_ref.current)
            if (callstateTimeout.current) {
                clearTimeout(callstateTimeout.current)
                callstateTimeout.current = null
            }
            if (reconnection_timer.current) {
                clearTimeout(reconnection_timer.current)
                reconnection_timer.current = null
            }
            if (transcription && media_availability?.audio && !recording_status && !call_reconnection) {
                setRecordingStatus(true)
            } else if (transcription && !recording_status && !call_reconnection) {
                sendSignalingMessage({}, "no_micro_phone");
            }
            setcallstatus("Connected")
        });

        peerRef.current.on("close", (err) => {

        });
    }

    const answerCall = (call_reconnection = false) => {
        if (!navigator.onLine) {
            message.error('Make sure you have proper network connection')
            return
        }
        if (navigator.mediaDevices) {
            if (streamRef.current) {
                let tracklist = streamRef.current.getTracks()
                if (tracklist) {
                    tracklist.forEach(track => {
                        track.enabled = false
                        track.stop()
                    })
                }
            }
            if (!call_reconnection) {
                setHandlerTab('call')
                setcallstatus("Connecting")
                sendSignalingMessage({}, 'Connecting')
            }
            navigator.mediaDevices.getUserMedia({ video: video_ref.current, audio: audio_ref.current }).then((stream) => {
                setanswerCallPeer(stream, call_reconnection)
            }).catch((err) => {
                setAudioStaus(false)
                setVideoStaus(false)
                setanswerCallPeer(null, call_reconnection)
            })
        }
    }

    const setanswerCallPeer = (stream, call_reconnection) => {
        if (stream) {
            audioTrack.current = stream.getAudioTracks() || [];
            videoTrack.current = stream.getVideoTracks() || [];
            audioTrack.current.forEach(item => {
                item.enabled = audio_turned_on
            })
        } else {
            audioTrack.current = []
            videoTrack.current = []
        }

        peerRef.current = new Peer({
            initiator: false,
            trickle: false,
            stream,
            config: {
                iceServers: [
                    {
                        urls: CALL_SERVER_CONFIG.stun_url
                    },
                    {
                        urls: CALL_SERVER_CONFIG.turn_url,
                        username: CALL_SERVER_CONFIG.turn_user,
                        credential: CALL_SERVER_CONFIG.turn_password
                    }
                ],
            }
        })

        setlocalVideoStream(stream)
        streamRef.current = stream
        peerRef.current.on("signal", (data) => {
            // Send the signal data to the other peer via WebSocket
            sendSignalingMessage(data, "answer");
        });

        peerRef.current.on("stream", (stream) => {
            setremoteVideoStream(stream);
        });

        peerRef.current.on("error", (error) => {

            if ((error.code == 'ERR_CONNECTION_FAILURE' || error.code == "ERR_ICE_CONNECTION_FAILURE") && callStatusRef.current == 'Connected') {
                if (peerRef.current) {
                    peerRef.current.destroy()
                    peerRef.current = null
                }
                setremoteVideoStream(null)
                setlocalVideoStream(null)
                setVideoStaus(false)
                setAudioStaus(false)
                if (streamRef.current) {
                    let tracklist = streamRef.current.getTracks()
                    if (tracklist) {
                        tracklist.forEach(track => {
                            track.enabled = false
                            track.stop()
                        })
                    }
                }

                if (!navigator.onLine) {
                    runTimer()
                } else {
                    reconnectForOther()
                    if (receivedsignalData?.user_data?.firstName) {
                        message.info(`${Capitalize(receivedsignalData?.user_data?.firstName)} network connectivity is low`)
                    } else {
                        message.info(`other participants network connectivity is low`)
                    }
                }
            } else {
                resetCall()
            }
            // There was an error in establishing the peer connection
        });

        // Event listener for when a remote peer connects
        peerRef.current.on("connect", (connection) => {
            setReconnectionForOther(false)
            setReconnection(false)
            setcallstatus("Connected")
            missed_call_ref.current = 0
            SetmissedCall_count(missed_call_ref.current)
            if (reconnection_timer.current) {
                clearTimeout(reconnection_timer.current)
                reconnection_timer.current = null
            }
            // A remote peer has successfully connected
            if (callstateTimeout.current) {
                clearTimeout(callstateTimeout.current)
                callstateTimeout.current = null
            }

            if (reconnection_timer.current) {
                clearTimeout(reconnection_timer.current)
                reconnection_timer.current = null
            }

        });

        // Event listener for when the peer connection is closed
        peerRef.current.on("close", (err) => {
            // The peer connection is closed

        });
        peerRef.current.signal(receivedsignal.current);
    }

    const reconnectForOther = () => {
        setReconnectionForOther(true)
        if (reconnection_for_other_timer.current) {
            clearTimeout(reconnection_for_other_timer.current)
            reconnection_for_other_timer.current = null
        }

        reconnection_for_other_timer.current = setTimeout(() => {
            if (reconnection_for_other_timer.current) {
                setEndCallmessage('Call ended due to network issue')
                cancelCall('Call_ended')
                setReconnectionForOther(false)
            }
        }, (call_reconnection_timer + 10000) || 40000);
    }

    const runTimer = () => {
        setReconnection(true)
        if (reconnection_timer.current) {
            clearTimeout(reconnection_timer.current)
            reconnection_timer.current = null
        }
        reconnection_timer.current = setTimeout(() => {
            setReconnection(false)
            if (reconnection_timer.current) {
                clearTimeout(reconnection_timer.current)
                reconnection_timer.current = null
            }
            resetCall()
        }, call_reconnection_timer || 30000);
    }

    const cancelCall = (type) => {
        sendSignalingMessage({}, type)
        if (type === "Call_ended") {
            resetCall('call', type)
        } else {
            resetCall()
        }

    }

    const sendSignalingMessage = (signaldata, type) => {
        // Send the signaling message via WebSocket
        if (peersocketRef.current) {
            let data = { results: { signaldata, user_id: me?.employee?.id, user_data: me, info: type } }
            peersocketRef.current.send(JSON.stringify(data));
        }
    };

    const resetCall = (tab = null, call_status = null) => {
        setReconnection(false)
        setReconnectionForOther(false)
        callInitiator.current = false
        if (streamRef.current) {
            let tracklist = streamRef.current.getTracks()
            if (tracklist) {
                tracklist.forEach(track => {
                    track.enabled = false
                    track.stop()
                })
            }
            streamRef.current = null
        }
        setHandlerTab(tab)
        setcallstatus(call_status)
        if (transcription) {
            setRecordingStatus(false)
        }

        if (peerRef.current) {
            peerRef.current.destroy()
            peerRef.current = null
        }

        if (callstateTimeout.current) {
            clearTimeout(callstateTimeout.current)
            callstateTimeout.current = null
        }

        if (audioTrack.current) {
            audioTrack.current = null
        }

        if (videoTrack.current) {
            videoTrack.current = null
        }

        setlocalVideoStream(null)
        setremoteVideoStream(null)
        receivedsignal.current = null
        sendsignal.current = null
        SetreceivedsignalData(null)
        setVideoStaus(false)
        if (media_availability?.audio) {
            setAudioStaus(true)
        } else {
            setAudioStaus(false)
        }

        if (reconnection_timer.current) {
            clearTimeout(reconnection_timer.current)
            reconnection_timer.current = null
        }

        if (reconnection_for_other_timer.current) {
            clearTimeout(reconnection_for_other_timer.current)
            reconnection_for_other_timer.current = null
        }

    }

    const resetToDefaulTab = () => {
        setHandlerTab(null)
        setcallstatus(null)
    }

    React.useEffect(() => {
        return () => {
            // Code to be executed when the component is unmounted
            resetCall()
            if (peersocketRef.current) {
                peersocketRef.current.close()
                peersocketRef.current = null
            }
            window.removeEventListener('online', () => { });
        };
    }, []);


    return (
        <>
            <Modal
                visible={show_call_info}
                closable={false}
                destroyOnClose={false}
                footer={null}
                style={{ padding: 10, borderRadius: "20px" }}
            >
                <div className='call-info-tip'>
                    <h4 className='call-info-title'>Here's how the call feature works:</h4>
                    <ul>
                        <li className='call-li-point'>Both users should be logged in to the MApp application.</li>
                        <li className='call-li-point'>Both users should start the same one-on-one meeting.</li>
                        <li className='call-li-point'>Once the application is ready, a call button appears. The user doesn't have to do anything.</li>
                        <li className='call-li-point'>However, if an issue occurs and calling is not available for some technical reason like the internet connection, the user will be notified by a message.</li>
                        <li className='call-li-point'>Upon refreshing the browser, Call will get disconnected.</li>
                    </ul>
                    <div className='call-info-row'>
                        <Button onClick={()=>{SetCallInfo(false)}} className='call-confirmation'>Cancel</Button>
                        <Button onClick={()=>{SetCallInfo(false);initiateCall()}} className='call-confirmation'>Continue</Button>
                    </div>
                </div>
            </Modal>

            {
                relateddocument?.id && (
                    <div className='call-handler-container'>
                        <audio controls id='ringtone' loop style={{ opacity: "0", height: "0px", position: "absolute" }}>
                            <source src={ringtone} type="" />
                        </audio>
                        {/* Default section */}
                        {
                            (!handlerTab && media_availability) && (
                                <div className='default-call-tab'>
                                    {
                                        (peersocket_connected || transcription_visible) ?
                                            <h4 className='handler-info'>{info_message || `Ready for an audio/video call? Before clicking on the call button, remember it will also transcribe the 1:1, ok?
                                    Or just push the Transcribe button and start your conversation. I'll take care of the transcription while you talk.`}</h4> :
                                            (!peersocket_connected && !transcription_visible && peersocket_error && socketError) ?
                                                <h4 className='handler-info'>Cannot connect to the socket</h4>
                                                :
                                                <Spin spinning={true}></Spin>
                                    }


                                    <div className='handler-icons-container'>
                                        {
                                            (peersocket_connected && (!transcription || (transcription && transcription_visible))) && (
                                                <Tooltip title='Call'>
                                                    <div className='handler-icons' onClick={() => { SetCallInfo(true) }}>
                                                        <img src={answecall} alt="" />
                                                    </div>
                                                </Tooltip>
                                            )
                                        }

                                        {
                                            (transcription_visible && transcription) && (
                                                <Tooltip title="Transcription">
                                                    <div className='handler-icons' onClick={() => { setHandlerTab('transcription') }}>
                                                        <img src={transcription_icon} alt="" />
                                                    </div>
                                                </Tooltip>
                                            )
                                        }
                                    </div>

                                    {
                                        (missedCall_count > 0) && (
                                            <div className='missed-call-contianer'>
                                                <img src={missed_call} alt="" srcset="" />
                                                <h4 className='handler-info' style={{ margin: ".5em" }}>You have {missedCall_count} missed call{missedCall_count > 1 ? 's' : ''}</h4>
                                            </div>

                                        )
                                    }

                                </div>
                            )
                        }

                        {/* Transcription section */}
                        {
                            handlerTab === "transcription" && (
                                <TranscriptionContainer media_availability={media_availability} setHandlerTab={setHandlerTab} {...props} />
                            )
                        }

                        {/* Call section */}
                        {
                            handlerTab === "call" && (
                                <>
                                    {
                                        (callstatus == 'Calling' || callstatus == 'Ringing') && (
                                            <CallingState media_availability={media_availability} relateddocument={relateddocument} callstatus={callstatus} video_turned_on={video_turned_on} audio_turned_on={audio_turned_on} setVideoStaus={setVideoStaus} setAudioStaus={setAudioStaus} system_icons={system_icons} cancelCall={() => { cancelCall('cancel_call') }} call_soultips={call_soultips} {...props} />
                                        )
                                    }

                                    {
                                        (callstatus == 'Incoming' || callstatus == 'Connecting') && (
                                            <CallingIncomingState media_availability={media_availability} relateddocument={relateddocument} callstatus={callstatus} video_turned_on={video_turned_on} audio_turned_on={audio_turned_on} setVideoStaus={setVideoStaus} setAudioStaus={setAudioStaus} system_icons={system_icons} cancelCall={() => { cancelCall('Decline') }} answerCall={() => { answerCall() }} call_soultips={call_soultips} {...props} />
                                        )
                                    }

                                    {
                                        (callstatus == 'Connected') && (
                                            <CallConnected reconnection={reconnection} reconnection_for_other={reconnection_for_other} media_availability={media_availability} relateddocument={relateddocument} callstatus={callstatus} video_turned_on={video_turned_on} audio_turned_on={audio_turned_on} setVideoStaus={setVideoStaus} setAudioStaus={setAudioStaus} system_icons={system_icons} cancelCall={() => { cancelCall('Call_ended') }} localVideoStream={localVideoStream} remoteVideoStream={remoteVideoStream} header_text={header_text} receivedsignalData={receivedsignalData} {...props} />
                                        )
                                    }

                                    {
                                        (callstatus == 'Decline' || callstatus == 'Call_ended') && (
                                            <CallEndState relateddocument={relateddocument} callstatus={callstatus} resetToDefaulTab={() => { resetToDefaulTab() }} end_call_message={end_call_message} {...props} />
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                )
            }

            {
                !relateddocument?.id && (
                    <div className='call-handler-container center-align'>
                        <Spin spinning={true} size='large' ></Spin>
                    </div>
                )
            }

        </>
    )

}

export default compose(withApollo)(CallTranscriptHandler)