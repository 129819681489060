import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import './App.less';

// Route object
import home from './modules/home';
import organization from './modules/organization';
import page from './modules/page';
import survey from './modules/survey';
import user from './modules/user';
import story from './modules/story';
import dashboard from './modules/dashboard';
import mangerDashboard from './modules/manager';
import tool from './modules/tool';
import report from './modules/report';
import diversitySurvay from './modules/diversity-survey'
import feedback from './modules/feedback';
import oneOnOne from './modules/one-on-one';

import lookResolvers from './modules/look/resolvers';
import dashboardResolvers from './modules/dashboard/resolvers';
import toolResolvers from './modules/tool/resolvers';
import storyResolvers from './modules/story/resolvers';
import pageResolvers from './modules/page/resolvers';
import userResolvers from './modules/user/resolvers';
import surveyResolvers from './modules/survey/resolvers';
import organizationResolvers from './modules/organization/resolvers';
import managerResolvers from './modules/manager/resolvers';
import organizationUserView from './modules/organization-user-view';
import scapeReport from './modules/scapeReport';
import readinessLevel from './modules/readiness-level'
import Routes from './modules/layout/Routes';
import NotFound from './modules/page/containers/NotFound';
import ErrorBoundary from './modules/look/components/ErrorBoundary';
import { IS_MAINTENANCE,GOOGLE_ANALYTICS_ID } from './config';
import notification from 'modules/notification';
import { getItem, getSyncItem, setItem } from './modules/core';
import moment from 'moment-timezone';
import grow from './modules/grow-model'
import objective_keyresult from './modules/Objective-keyresult'
import kudos from 'modules/kudos';
import kudosUser from 'modules/kudos-user';
import userManual from 'modules/user-manual';
import sales from 'modules/sales';
import Bars from './modules/Bars-module'
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import role_definition from 'modules/role_definition'
import competency_assessment from 'modules/competency-assessment'
import scape_report from 'modules/Scape-Report'
import scape_report_admin from 'modules/scape-admin-panel'
import survey_module from 'modules/survey-module'
import idp from 'modules/IDP'
import poc_dashboard from 'modules/poc-dashboard';
import learning_path from 'modules/learning-path'
import ai_tips from 'modules/ai-tips'
import casper from 'modules/casper';
export const clientResolvers = {
  defaults: {
    ...lookResolvers.defaults,
    ...dashboardResolvers.defaults,
    ...toolResolvers.defaults,
    ...storyResolvers.defaults,
    ...pageResolvers.defaults,
    ...userResolvers.defaults,
    ...surveyResolvers.defaults,
    ...organizationResolvers.defaults,
    ...managerResolvers.defaults
  },
  resolvers: {
    Mutation: {
      ...lookResolvers.resolvers.Mutation,
      ...dashboardResolvers.resolvers.Mutation,
      ...toolResolvers.resolvers.Mutation,
      ...storyResolvers.resolvers.Mutation,
      ...pageResolvers.resolvers.Mutation,
      ...userResolvers.resolvers.Mutation,
      ...surveyResolvers.resolvers.Mutation,
      ...organizationResolvers.resolvers.Mutation,
      ...managerResolvers.resolvers.Mutation
    }
  }
};



export const ROUTES = IS_MAINTENANCE
  ? [
    {
      name: 'NotFound',
      up: false,
      component: NoPermissionView
    }
  ]
  : [
      ...home,
      ...organization,
      ...readinessLevel,
      ...survey,
      ...user,
      ...story,
      ...dashboard,
      ...mangerDashboard,
      ...tool,
      ...report,
      ...organizationUserView,
      ...feedback,
      ...oneOnOne,
      ...diversitySurvay,
      ...notification,
      ...grow,
      ...objective_keyresult,
      ...kudos,
      ...kudosUser,
      ...userManual,
      ...sales,
      ...Bars,
      ...role_definition,
      ...competency_assessment,
      ...scape_report,
      ...scape_report_admin,
      ...survey_module,
      ...idp,
      ...poc_dashboard ,
      // ...learning_path,
      ...casper,
      ...ai_tips,
      // Keep this last for not-found page
      ...page,     
    ];


const App = (props) => {
  React.useEffect(() => {
    if(GOOGLE_ANALYTICS_ID){
    const head = document.head || document.getElementsByTagName('head')[0];
    const googletagmanager = document.createElement('script'); googletagmanager.async = true
    googletagmanager.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`
    const script = document.createElement('script');
    script.innerHTML =
      `window.dataLayer = window.dataLayer || [];
     function gtag(){dataLayer.push(arguments);}
     gtag('js', new Date());
     gtag('config', '${GOOGLE_ANALYTICS_ID}');`
    head.appendChild(googletagmanager)
    head.appendChild(script)
    }
  },[])

  React.useEffect(() => {
    //timezone 
    getItem("userRequestedTimezone").then(t => {
      if (t === undefined) {
        setItem("userRequestedTimezone", Intl.DateTimeFormat().resolvedOptions().timeZone)
        moment.tz.setDefault(Intl.DateTimeFormat().resolvedOptions().timeZone);
      } else {
        moment.tz.setDefault(t);
      }
    })
    
  }, [])
  return (
    <>
      <Router>
        <Suspense
          fallback={
            <div>
              <Spin size="large" />
            </div>
          }
        >
          <ErrorBoundary>
            <Switch>
              {ROUTES.map(route => (
                <Routes key={route.path} {...props} adminRoutes={ROUTES.filter(r => r.adminDropdown)} {...route} />
              ))}
            </Switch>
          </ErrorBoundary>
        </Suspense>
      </Router>
      {/* <div style={{ height: '293px', width: '100%', background: '#202020' }} /> */}
    </>
  );
};

export default App;
