// @ts-nocheck
import { useMutation } from '@apollo/client';
import { Button, Col, Dropdown, Form, Menu, message, Radio, Row, Select, Spin, Tooltip } from "antd";
import gql from 'graphql-tag';
import { getIntFromString } from 'modules/layout/Layout';
import moment from 'moment';
import React from 'react';
import styled from "styled-components";
import CloseIcon from '../../assets/close-icon-white-small.svg';
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg';
import InfoCircle from '../../assets/InfoCircle.svg';
import search_icon from '../../assets/search-gray-icon.svg';
import Supervisor from '../../assets/supervisor.svg';
import upArrow from '../../assets/up-arrow.svg';
import NumberInputField from '../../Objective-keyresult/formComponents/Number-input-field';


const ExpandableDropdown =(props)=>{
    const {me,assignes,collaborator,defaultSelectedAssigne,defaultSelectedCollaborator,record,response,allokrweights,setokrweights,loadingcollab,setloadingcollab,getOKRContributorList,deleteContributor,searchcollab,searchassignes,user_level}=props
    const [OpenMenu,setOpenMenu]=React.useState(false)
    const [eligibilityType, seteligibilityType] = React.useState('assign');
    const [selectedCollaborate,setSelectedCollaborate]=React.useState([])
    const [selectedAssigne,setSelectedAssigne]=React.useState(null)
    const [selectedFilter,setFilter]=React.useState('')
    const [AssigneList,setAssigneList]=React.useState()
    const [CollaborateList,setCollaboratList]=React.useState()
    const [filterList,setfilterList]=React.useState([])
    const [form] = Form.useForm();
    const [contributor,setContributor]=React.useState()
    const [normalemployee,setNormalemployee]=React.useState(false)
    let previous_assigne=React.useRef()
    React.useEffect(()=>{
        if(user_level){
            
            let data = user_level?.employee
            let filter_list = []
            if(data?.orgCeoEmployee?.totalCount || data?.orgPocEmployee?.totalCount){
                filter_list.push({key:"My_Organization",label:"My Organization"})
            }
            if(data?.verticalHeadEmployee?.totalCount){
                filter_list.push({key:"My_Vertical",label:"My Vertical"})
                filter_list.push({key:"All_Vertical",label:"All Vertical"})
            }
            if(data?.teamManagerEmployee?.totalCount){
                filter_list.push({key:"My_Team",label:"My Team"})
                filter_list.push({key:"All_Teams",label:"All Teams"})
            }
            if(!data?.orgCeoEmployee?.totalCount && !data?.orgPocEmployee?.totalCount && !data?.verticalHeadEmployee?.totalCount && !data?.teamManagerEmployee?.totalCount){
                seteligibilityType('collaborate')
                setNormalemployee(true)
                filter_list.push({key:"My_Team",label:"My Team"})
                filter_list.push({key:"My_Vertical",label:"My Vertical"})
            }
            setfilterList(filter_list)

            if(filter_list?.length){
                console.log('filter_list[0]?.key',filter_list[0]?.key);
                setFilter(filter_list[0]?.key)
            }
        }
    },[user_level])

    React.useEffect(()=>{
        if(selectedFilter){
          setAssigneList([])
          setCollaboratList([])
        }
    },[selectedFilter])

    React.useEffect(()=>{
        if(eligibilityType){
            setAssigneList([])
            setCollaboratList([])
            // employeeSearch('')
        }
    },[eligibilityType])

    React.useEffect(()=>{
        if(OpenMenu){
            getOKRContributorList()
            // employeeSearch('')
        }else{
          
            setContributor(null) 
            setSelectedCollaborate([])
            form.setFieldsValue({})
        }
    },[OpenMenu])

    React.useEffect(()=>{
        if(allokrweights){
            let collab = allokrweights?.edges?.filter(({node})=> node?.role === 'COLLABORATOR')
            
            if(!collab?.find(({node})=>node?.employee?.id===me?.employee?.id)){
                collab.push({
                    node:{
                        employee:me?.employee,
                        id:null,
                        weight:100,
                        role:"COLLABORATOR"
                    }
                })  
            }
           
            let assigne = allokrweights?.edges?.filter(({node})=> node?.role === 'ASSIGNED')
            let collablist = collab?.map(({node})=>{
                let value = {
                    ...node?.employee,
                    contributorId:node?.id,
                    weight:node?.weight,
                    role:node?.role
                }
                return JSON.stringify(value)
            })
            if(collablist?.length){
                seteligibilityType('collaborate')
            }
            setSelectedCollaborate(collablist)
          
            if(assigne?.length){
                let emp = assigne[0]?.node?.employee
                previous_assigne['current']=assigne[0]?.node?.employee
                setSelectedAssigne(`${emp?.id}_${emp?.user?.firstName}_${emp?.user?.lastName||''}`)
            }else{
                previous_assigne['current']=null
                setSelectedAssigne(null) 
            }

        }
    },[allokrweights,loadingcollab])

    React.useEffect(()=>{
        if(defaultSelectedAssigne){
            setSelectedAssigne(defaultSelectedAssigne)
        }
    },[defaultSelectedAssigne])

    React.useEffect(()=>{
        if(defaultSelectedCollaborator){
            setSelectedCollaborate(defaultSelectedCollaborator)
        }
    },[defaultSelectedCollaborator])
    
    const CREATE_CONTRIBUTOR_DYNAMIC =gql`
    mutation createContributor {
      ${contributor?.filter(item=>!item?.isUpdate)
        ?.map(
          (item,index) =>
           `create${index}: createContributor(contributorData:{okrId:${item?.okrId},employeeId:${item?.employeeId},role:"${item?.role}"${item?.weight?`,weight:"${item?.weight}"`:""}}) {
            contributor{
              id
              employee{
                id
                user{
                  id
                  firstName
                  lastName
                }
              }
            }
           }
         `
        )
      .join('')}
      ${contributor?.filter(item=>item?.isUpdate)
        ?.map(
          (item,index) =>
           `update${index}: updateContributor(id:${item?.contributorId},weight:"${item?.weight}") {
            contributor{
              id
              employee{
                id
                user{
                  id
                  firstName
                  lastName
                }
              }
            }
           }
         `
        )
      .join('')}
     }
    `;
  
    const [contributorDefinition ,{ data, isloading, error }] = useMutation(CREATE_CONTRIBUTOR_DYNAMIC);

    React.useEffect(()=>{
        if(contributor && contributor?.length){
            createContributorMutation()
        }
    },[contributor])

const createContributorMutation=async()=>{
        setloadingcollab(true)
        const response_data = await contributorDefinition()
        setloadingcollab(false)
        // new to do send back the response as props
        if(response_data){
            message.success(`${eligibilityType==='assign'?'Assigne':'Collaborators'} Updated`)
            response_data['role'] = eligibilityType==='assign'?'ASSIGNED':'COLLABORATOR'
            response(response_data)
            setOpenMenu(false)
        }
        
    }

    React.useEffect(()=>{
        if(assignes){
            setAssigneList(assignes)
        }
    },[assignes])
    React.useEffect(()=>{
        if(collaborator){
          
            // let list =collaborator?.employee?.organizationSet?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node))
            // @ts-ignore
            setCollaboratList(collaborator)
        }
    },[collaborator])
    const handleChange = (value) => {
       
        let user = CollaborateList?.find(item=>item?.id===value)
        if(user){

            let list = [].concat(selectedCollaborate)
            if(!list?.find(used=>JSON.parse(used)?.id===user?.id)){
                list.push(JSON.stringify(user))
                setSelectedCollaborate(list)
                RestWeight(list)
            }
        }
       
      };

    const onChange=(value)=>{
        setSelectedAssigne(value)
    }

    const changeTab=(event)=>{
        seteligibilityType(event?.target?.value)
        // setSelectedAssigne(null)
        // setSelectedCollaborate([])
    }


    const RestWeight=(list)=>{
        if(!list?.length){
            return
        }

        let weight = 100/list?.length
        let initial = {}
        list.forEach(item=>{
            let val = JSON.parse(item)
            initial[val?.id]=parseFloat(weight.toFixed(2))
        })
        form.setFieldsValue(initial)
    }

    const onAssign=()=>{
      
        if(eligibilityType==='collaborate'){
            let collabList = [].concat(selectedCollaborate)
            collabList= collabList?.map(item=>JSON.parse(item))
            let values = form.getFieldsValue()
            let noData = Object.values(values).filter((item)=>item===undefined || item===null)
            if(noData?.length){
                message.error('fill in the data')
                return
            }
            let totalweight = Object.values(values)?.reduce((a, b) => a + b, 0)
            if(Math.round(totalweight)!==100){
                message.error('Total weight should be 100%') 
                return
            }
            let data = Object.keys(values).map(item=>{
                return{
                    okrId:getIntFromString(record?.id),
                    employeeId:getIntFromString(item),
                    role:"COLLABORATOR",
                    weight:values[item],
                    isUpdate:collabList?.find(up=>up?.id===item)?.contributorId?true:false,
                    contributorId:collabList?.find(up=>up?.id===item)?.contributorId?getIntFromString(collabList?.find(up=>up?.id===item)?.contributorId):null
                }
            })
            
            setContributor(data)
           
        }else{
            let data =[
                {
                    okrId:getIntFromString(record?.id),
                    employeeId:getIntFromString(selectedAssigne?.split('_')[0]),
                    role:"ASSIGNED",
                    isUpdate:false
                }
            ]
            setContributor(data)
        }
    }
    

    const FilterItem =()=> (
        
        <Menu style={{maxHeight:'200px',overflow:'auto'}}>
          {
        
                filterList?.map((data)=>
              <Menu.Item key={data?.key} onClick={()=>{setFilter(data?.key)}}>
                {data?.label}
              </Menu.Item>
              )
            
          }
        </Menu>
    );

    const getInitialCollab =()=>{
        let initial ={}
        selectedCollaborate.forEach(item=>{
            let data = JSON.parse(item)
            if(data?.contributorId){
                initial[data?.id]=data?.weight??0
            }else if(data?.id===me?.employee?.id){
                initial[data?.id]=data?.weight??0
            }
        })
        return initial
    } 

    const deleteCollabrator=async (data)=>{
       
        let list = selectedCollaborate?.filter(item=>JSON.parse(item).id!==data?.id)
        if(data?.contributorId){     
            setloadingcollab(true)
            let response = await deleteContributor({id:getIntFromString(data?.contributorId)})
            if(response){
                let newOkrWeightList = allokrweights?.edges?.filter((node)=>node?.node?.id !== data?.contributorId)
                setSelectedCollaborate(list)
                setokrweights({edges:newOkrWeightList})
                RestWeight(list)
            }
            setloadingcollab(false)
        }else{
            
            setSelectedCollaborate(list)
            RestWeight(list)
        }
    }

    const employeeSearch=(srch)=>{

        if(eligibilityType==='assign'){
            searchassignes({search:srch,type:selectedFilter})
        }else{
            searchcollab({search:srch,type:selectedFilter})
        }
    }

    React.useEffect(()=>{
        if(eligibilityType && selectedFilter && OpenMenu){
            employeeSearch('')
        }
    },[eligibilityType,selectedFilter,OpenMenu])

    const checkPreviousAssignee=()=>{
        if(selectedAssigne){
            if(!previous_assigne['current']){
                return false
            }else{
                let assigne = selectedAssigne?.split('_')
                if(previous_assigne['current']?.id===assigne[0]){
                    return true
                }else{
                    return false
                }
            }
          
        }
        else{
           return true
        }
    }



    const dynamicIsland =()=> (
        <Menu className='custom-menu-expandable' >
        <Row align='middle'   style={{flexDirection:"column",padding:"20px",position:'relative'}}>
            <h4 className='menu-head'>Select Contributors <Tooltip overlayStyle={{zIndex:"1160"}} title={'Choose Assign if you want to delegate this task to somebody else. Choose collaborate if it a combined task.'}><img src={InfoCircle} alt="" /></Tooltip></h4>
            <img src={upArrow} alt="" style={{position:"absolute",top:'10px',right:"10px",cursor:"pointer"}} onClick={()=>setOpenMenu(false)} />
           
           {
            !normalemployee && (
                <Col>
                <Radio.Group value={eligibilityType} onChange={(e) => changeTab(e)}>
                    <RadioButton disabled={normalemployee} style={{borderRight:"none",width:"120px"}} className={`section-assign ${eligibilityType==='assign'?'active-assign':''}`} value="assign">Assign</RadioButton>
                    <RadioButton style={{width:"120px"}}  className={`section-collab ${eligibilityType ==='collaborate'?'active-collab':''}`} value="collaborate">Collaborate</RadioButton>
                </Radio.Group>
            </Col>
            )
           }

           {(moment().isBefore(moment(record?.duedate)?.endOf('day')) && moment().isBefore(moment(record?.correctionDeadline)?.endOf('day')))
            && (
                <Row style={{flexWrap:"nowrap",marginTop:'20px', marginBottom: '10px'}}>
                <div style={{position:"relative"}}>
                {eligibilityType==='assign'?
                <DynamicIslandSelect className="input-select-expand input-select-expand-assign" 
                showSearch
                onSearch={(e)=>{employeeSearch(e)}}
                filterOption={(input,option)=> option}
                showArrow={false}
                placeholder='Search employee'
                notFoundContent={  <div align='center'>No Data Found</div>}
                onChange={onChange}
                style={{ width: '100%' }}>
                {AssigneList?.map((st,key) => (
                    <Select.Option key={key} value={`${st?.id}_${st?.user?.firstName}_${st?.user?.lastName||''}`}>{<span className='text-transform-capitalize'>{st?.user?.firstName}</span>} {<span className='text-transform-capitalize'>{st?.user?.lastName}</span>}</Select.Option>
                ))} 
                </DynamicIslandSelect>:
                <DynamicIslandSelect className="input-select-expand" 
                showSearch={true}
                filterOption={(input,option)=> option}
                onSearch={(e)=>{employeeSearch(e)}}
                showArrow={false}
                notFoundContent={ <div align='center'>No Data Found</div>}
                placeholder='Search employee'
                onChange={handleChange}
                style={{ width: '100%' }}>
                    {CollaborateList?.map((st,key) => (
                    <Select.Option key={key}  value={st?.id}>{<span className='text-transform-capitalize'>{st?.user?.firstName}</span>} {<span className='text-transform-capitalize'>{st?.user?.lastName}</span>}</Select.Option>
                ))}
            </DynamicIslandSelect>}
                <img src={search_icon} alt="" style={{position:'absolute',left:"10px",width:"18px",top:"10px"}} />
                </div>  
                {
                    filterList?.length?
                    <Dropdown arrow={false} className="input-filter-expand" overlay={FilterItem}>
                    <DropdownButton >
                        <p className='selected-filter' style={{marginTop:'13px',width:'100%',overflow:'hidden',textOverflow:'ellipsis',textAlign:'left',fontSize:"12px"}}>{selectedFilter?.replace('_',' ')}</p>
                        <img src={dropdown_Arrow} alt="" style={{width:"13px",height:"10px"}}/>
                        {/* <DownOutlined /> */}
                    </DropdownButton>
                </Dropdown>
                :''
                }
            </Row>
            )
           }
            
                    <Spin spinning={loadingcollab}>
                    <div className='select-content-div'>
                {
                    selectedCollaborate?.length?
                    <Form
                    scrollToFirstError={true}
                    className="formLabel"
                    initialValues={getInitialCollab()}
                    form={form}>
                    {(eligibilityType==='collaborate'&&selectedCollaborate?.length)? <h4 className='weight-head'>Weight <Tooltip overlayStyle={{zIndex:"1160"}} title={"Weights will let you decide the contribution you want by each collaborator when there are multiple collaborators. By default the weightage will be distributed equally. You can customise it according to your needs. "}><img src={InfoCircle} alt="" style={{paddingLeft:"10px",cursor:'pointer'}}/></Tooltip></h4>:''}
                    {(eligibilityType==='collaborate'&& selectedCollaborate?.length)? selectedCollaborate?.map((data,index)=>{
                       
                        let value = JSON.parse(data)

                        return (
                            <div className='item-div'>
                            <div className='selected-item row'>
                                <p style={{width:"100%",overflow:"hidden",textOverflow:"ellipsis"}}>{<span className='text-transform-capitalize'>{value?.user?.firstName}</span>} {<span className='text-transform-capitalize'>{value?.user?.lastName}</span>}</p>
                                {
                                    value?.id!==me?.employee?.id&&moment().isBefore(moment(record?.duedate)?.endOf("day"))?
                                    <img src={CloseIcon} alt="" onClick={(e)=>{deleteCollabrator(value)}} style={{cursor:'pointer'}} />
                                    :""
                                }
                            </div>
                            {/* <input className="progress-input" type='number'style={{width:"55px",height:"40px"}} disabled={moment().isAfter(moment(record?.duedate))||moment().isAfter(moment(record?.correctionDeadline))}maxValue={100} name={value?.id}/> */}
                            <NumberInputField className="progress-input" style={{width:"55px",height:"40px"}} disabled={moment().isAfter(moment(record?.duedate)?.endOf('day'))||moment().isAfter(moment(record?.correctionDeadline)?.endOf('day'))} type='number' maxValue={100} name={value?.id}></NumberInputField>
                        </div>
                        )
                    }):''}
                </Form>
                :''
                }
                
                {
                    eligibilityType==='assign'&& selectedAssigne&&(
                        <div className='selected-item'>
                        <p style={{width:"100%",overflow:"hidden",textOverflow:"ellipsis"}}>{selectedAssigne?.split('_')[1]} {selectedAssigne?.split('_')[2]||''}</p>
                        {/* <img src={CloseIcon} alt="" style={{cursor:'pointer'}} onClick={()=> setSelectedAssigne('')}/> */}
                    </div>
                    )
                }
            </div>
                    </Spin>
        </Row>
        <Row className='bottom-div' align='middle'>
            <Button className='assign-btn' disabled={moment().isAfter(moment(record?.duedate)?.endOf('day'))||moment().isAfter(moment(record?.correctionDeadline)?.endOf('day'))||(eligibilityType==='assign'&& !selectedAssigne)||(eligibilityType==='assign'&& checkPreviousAssignee())||(eligibilityType!=='assign'&&selectedCollaborate?.length<2)||loadingcollab} onClick={()=>onAssign()}>
            {
                eligibilityType ==='collaborate'?'Collaborate':'Assign'
            }    
            </Button>
        </Row>
         </Menu>
    );


    const ref = React.useRef(null);


    return (
        <>
            <Dropdown overlay={dynamicIsland}  visible={OpenMenu} trigger="click" placement="topCenter"  onClick={()=>setOpenMenu(true)} className="input-filter-dynamic" >
                <img   src={Supervisor} alt="" style={{cursor:"pointer"}}/>
            </Dropdown>
            {OpenMenu ? (<div className='overlay-dynamic-island' onClick={() => setOpenMenu(false)} />) : null}
         
        </>
    )
}

const RadioButton = styled(Radio.Button)`
`;

export default ExpandableDropdown

const DropdownButton = styled(Button)`
width:160px;
display:flex;
height:36px;
justify-content:space-between;
align-items:center;
gap:5px
`

const DynamicIslandSelect = styled(Select)`
.ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    padding-left:20px;
}

.ant-select .ant-select-selector span::after{
    padding-left:20px;
}
.ant-select-selection-placeholder{
    margin-left:28px;
}
`
