import React from 'react';
import { Button,Tooltip,message } from "antd";
import { displayUtcToUserTimezone } from 'modules/look';
import transcription_icon from '../../assets/transcription.png'
import record_gif from '../../assets/record_gif.gif'
const TranscriptionView = props => {
    const { setRecordingStatus, recording_status, message_list, member_list, transcription_visible,setHandlerTab,media_availability,settranscription_unmute } = props

    const renderMessage = (data) => {
        let user = member_list?.find(item => item?.user_id == data?.user_id && !data?.info)
        if (user) {
            return (
                <div className='transcript-msg-container'>
                    <div className='transcript-profile-container'>
                        <div className={`transcript-img-container ${user?.profile?.profileImage ? 'transcript-img-container-noborder' : ''}`}>
                            {
                                user?.profile?.profileImage ? <img className='transcript-pro-img' src={user?.profile?.profileImage} alt="" /> : <h5 className='transcript-pro-char'>{user?.firstName.charAt(0)}</h5>
                            }
                        </div>
                        <div className='transcription-data-aligner'>
                            <div className='transcript-content'>
                                <h4 className='transcript-user-name text-transform-capitalize'>{user?.firstName}</h4>
                                <p className='transcript-date'> {displayUtcToUserTimezone(data?.time_Stap, "DD MMM hh:mm")} </p>
                            </div>
                            <p className='transcript-text'>{data?.message}</p>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            let initaited_user = member_list?.find(item => item?.user_id == data?.intiated_user)
            return (
                <h4 className='transcript-user-name'><span><img src={record_gif} style={{ width: "20px", height: "20px", marginRight: "5px" }} alt="" /></span><span>{data?.message}</span></h4>
            )
        }
    }

    const goBack=()=>{
        if(setHandlerTab){
            setHandlerTab(null)
        }
    }

    const changeRecordingStatus= ()=>{
        if(!navigator.onLine){
            message.error('Make sure you have proper network connection')
            return
        }
        if(!recording_status && !media_availability.audio){

        }else{
            if(settranscription_unmute){
                settranscription_unmute(!recording_status)
            }
            setRecordingStatus(!recording_status)
        }
    }

    return (
        <div style={{ height: "100%",padding:"15px" }}>
            <div className='transcript-scroll' id='transcript-scroll'>
                {
                    (!message_list?.length && !recording_status) ?
                        <div className='transcription-empty'>
                            <img className='transcription-icon' src={transcription_icon} alt="" />
                            <h4 className='transcription-nodata'>Start transcription to make your meeting more effective.</h4>
                        </div>
                        :
                        message_list?.map(item => renderMessage(item))
                }
            </div>
            {
                (transcription_visible && media_availability) && (
                    <>
                       <Tooltip title={(!recording_status && !media_availability.audio)?'Do not have access to microphone':''} >
                        <Button style={{cursor:(!recording_status && !media_availability.audio)?'not-allowed':'pointer'}} className='transcript-rcd-btn' onClick={() => { changeRecordingStatus() }}>{recording_status?'Stop':'Start'}</Button>
                        </Tooltip>
                        {!recording_status && (
                            <Button className='transcript-rcd-btn' onClick={() => { goBack() }}>Go Back</Button>
                        )}
                    </>
                )
            }

        </div>
    )
}
// recording_status
export default TranscriptionView