import React from "react";
import { Modal, Spin, Button, Select } from "antd"
import { dasboardIcons, profile_icon } from "modules/home/dashboard-icons";
import { displayUtcToUserTimezone, getIntFromString } from "modules/look";
import styled from 'styled-components';

const AssesssmentMemberView = (props) => {
    const { view_member, onCancel, SetRemainderData, user_survey_response ,query_loader,selected_employees,setSelectedEmployee} = props
    const [team_list, setTeamList] = React.useState([])
    const [filter, setFilter] = React.useState('all')

    React.useEffect(() => {
        if (user_survey_response) {
            if (filter === 'all') {
                setTeamList(user_survey_response)
            }
            else if (filter === 'completed') {
                const filteredList = user_survey_response?.filter(item => item?.survey_response);

                setTeamList(filteredList)
            }
            else if (filter === 'pending') {
                const filteredPendingList = user_survey_response?.filter(item => !item?.survey_response);

                setTeamList(filteredPendingList)
            }
            else if (filter === '360_survey') {
                const filteredPendingList = user_survey_response?.filter(item => item?.is_360);
                setTeamList(filteredPendingList)
            }
        }

    }, [filter, user_survey_response])

    const changeEmployeeList = (value) => {
        let lists = [...selected_employees]

        if (selected_employees?.includes(value)) {
            let index = lists?.indexOf(value)
            lists.splice(index, 1)
            setSelectedEmployee(lists)
        }
        else {
            lists.push(value)
            setSelectedEmployee(lists)
        }

    }
    const handleFilterChange = (e) => {
        setFilter(e)
    }

    const onSubmit = () => {
        let data = user_survey_response?.filter(i => selected_employees?.includes(i?.user?.id))

        let data_set = data?.map(i => {
            return {
                id: getIntFromString(view_member?.id),
                usr: getIntFromString(i?.user?.id),
                tlink: i?.is_360 ? i?.link : ""
            }
        })
        SetRemainderData(data_set)
    }

    return (

        <Modal
            visible={view_member}
            footer={null}
            centered
            closable={false}
            width={'90%'}
            style={{ maxWidth: "36em" }}>
            <Spin spinning={query_loader} >
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: "center", gap: "1em", cursor: "pointer", padding: '.5em 0em' }}>
                    <h4 className="user-board-section-heading">{view_member?.name}</h4>
                    <img src={dasboardIcons?.close_icon} alt="close_icon" style={{ cursor: "pointer" }} onClick={() => onCancel()} />
                </div>
                <CustomSelect onChange={(e) => handleFilterChange(e)} defaultValue={filter} style={{ width: "120px !important" }}>
                    <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontFamily:"Poppins",fontWeight:500  }} key='all' value='all' >All Response</Select.Option>
                    <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontFamily:"Poppins",fontWeight:500  }} key='completed' value='completed' >Completed</Select.Option>
                    <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3',fontFamily:"Poppins",fontWeight:500 }} key='pending' value='pending' >Pending</Select.Option>
                    {
                        view_member?.multisourceResponse && (
                            <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3',fontFamily:"Poppins",fontWeight:500 }} key='360_survey' value='360_survey' >360 Survey</Select.Option>
                        )
                    }

                </CustomSelect>
                <div style={{ display: "flex", flexDirection: "column", gap: '1em', justifyContent: "start", alignItems: "center", marginTop: "1em", overflow: "auto", height: "30em",marginBottom:"1em" }}>
                    {team_list?.length > 0 && team_list?.map(item => (
                        <div className="dashboard-member-section-member-container">
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center", gap: '1em' }}>
                                <div style={{ height: "3.5em", width: "3.5em", borderRadius: "50%" }}>
                                    <img style={{ height: "3.5em", width: "3.5em", borderRadius: "50%", }} src={item?.user?.profile?.profileImage ? item?.user?.profile?.profileImage : profile_icon?.profile} alt="profile" />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
                                    <h4 style={{ fontFamily: "Poppins", fontSize: '1em', fontWeight: "500", color: "#000", margin: "0" }}><span className="text-transform-capitalize">{item?.user?.firstName}</span> <span className="text-transform-capitalize">{item?.user?.lastName}</span></h4>
                                    {(!item?.survey_response) ?
                                        <p style={{ fontFamily: "Poppins", fontSize: '.85em', fontWeight: "400", color: "#8998A2", margin: "0" }}>Response  : <span style={{ color: "#F95F53" }}> Pending</span></p> :
                                        <p style={{ fontFamily: "Poppins", fontSize: '.85em', fontWeight: "400", color: "#8998A2", margin: "0" }}>Response  : <span style={{ color: "#4CCB1F" }}> {displayUtcToUserTimezone(item?.survey_response?.responseDate, "ddd MMM DD YYYY, hh:mm")} </span></p>
                                    }
                                </div>
                            </div>
                            {(!item?.survey_response) && <div>
                                {!selected_employees?.includes(item?.user?.id) ? <div className="dashboard-check-box" onClick={() => changeEmployeeList(item?.user?.id)}></div> :
                                    <img src={dasboardIcons?.check_box} style={{ width: "30px", cursor: "pointer" }} alt="check_box" onClick={() => changeEmployeeList(item?.user?.id)} />}
                            </div>}
                        </div>
                    ))}
                </div>
                <div style={{ width: "100%", marginBottom: "15px", display: 'flex', flexDirection: "row", justifyContent: 'flex-end', alignItems: "center", gap: '1em' }}>
                    <Button className="user-board-welcome-banner-button" style={{ fontSize: "1.1em", marginTop: '.75em', color: "#8998A2", border: '1px solid #8998A2', width: "130px" }} onClick={() => onCancel()} >
                        CANCEL
                    </Button>
                    <Button className="user-board-welcome-banner-button" disabled={selected_employees?.length === 0} style={{ fontSize: "1.1em", marginTop: '.75em', width: "160px" }} onClick={() => onSubmit()}>
                        SEND REMINDER
                    </Button>
                </div>
            </Spin>
        </Modal>

    )
}

export default AssesssmentMemberView

const CustomSelect = styled(Select)`
.ant-select-selector{
    border: 1px solid #AABAC6 !impoortant;
    color:  #000;
    font-weight: 500;
    font-family: Poppins;
    font-size: 1em;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align: center;
    border-radius:.5em !important;
    width:140px !important;
}
`;