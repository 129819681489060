import gql from "graphql-tag";

export const SUBMITTED_FEEDBACK_TYPE = gql`
  fragment FeedbackType on FeedbackType {
    id
    createdAt
    feedbackTo {
      id
      user {
        id
        firstName
        lastName
        profile {
          id
          profileImage
        }
      }
    }

    feedbackFrom {
      id
    }
    response {
      id
      answerSet {
        edges {
          node {
            id
            answer
          }
        }
      }
    }
    badgeAward {
      edges {
        node {
          id
          badge {
            id
            image
          }
        }
      }
    }
    okr {
      id
      title
    }
    detailFillIn
    createdAt
    updatedAt
  }
`;

export const SUBMITTED_FEEDBACK = gql`
  ${SUBMITTED_FEEDBACK_TYPE}
  query allFeedback(
    $feedbackFrom: ID!
    $feedbackTo_User_FirstName_Icontains: String,
    $feedbackTo_User_LastName_Icontains:String,
    $createdAt_Day_Gt: DateTime
    $orderBy: [String],
    $response_Answer_Answer_Icontains:String,
    $okr_Goalstatement_Icontains: String,
    $createdAt_Range:[DateTime],
    $okr_Isnull: Boolean, 
    $first: Int,
  ) {
    submittedFeedbackFirstName: allFeedback(
      feedbackFrom: $feedbackFrom,
       feedbackTo_User_FirstName_Icontains: $feedbackTo_User_FirstName_Icontains,
      createdAt_Day_Gt: $createdAt_Day_Gt,
      createdAt_Range:$createdAt_Range,
      orderBy: $orderBy,
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edgeCount
      edges {
        node {
          ...FeedbackType
        }
      }
    }

    submittedFeedbackLastName: allFeedback(
      feedbackFrom: $feedbackFrom,
      feedbackTo_User_LastName_Icontains: $feedbackTo_User_LastName_Icontains,
      createdAt_Day_Gt: $createdAt_Day_Gt,
      createdAt_Range:$createdAt_Range,
      orderBy: $orderBy,
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edgeCount
      edges {
        node {
          ...FeedbackType
        }
      }
    }

    submittedFeedbackOkr: allFeedback(
      feedbackFrom: $feedbackFrom,
      createdAt_Day_Gt: $createdAt_Day_Gt,
      orderBy: $orderBy,
      okr_Goalstatement_Icontains: $okr_Goalstatement_Icontains,
      createdAt_Range:$createdAt_Range,
      okr_Isnull: $okr_Isnull,
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edgeCount
      edges {
        node {
          ...FeedbackType
        }
      }
    }

    submittedFeedbackFeedback: allFeedback(
      feedbackFrom: $feedbackFrom,
      createdAt_Day_Gt: $createdAt_Day_Gt,
      orderBy: $orderBy,
      response_Answer_Answer_Icontains: $response_Answer_Answer_Icontains,
      createdAt_Range:$createdAt_Range,
      first: $first
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edgeCount
      edges {
        node {
          ...FeedbackType
        }
      }
    }

  }
`;

export const ACTION_REQUIRED_FOR_FEEDBACK = gql`
query team_employee($feedbackFrom: ID,$createdAt_Range: [DateTime],$first:Int,$user_FirstName:String){
  me {
    id
    employee {
      id
      teamManagerEmployee {
        edges {
          node {
            id
            employees(first:$first,user_FirstName:$user_FirstName) {
              edges {
                node {
                  id
                  feedbackReceived(feedbackFrom: $feedbackFrom, createdAt_Range: $createdAt_Range) {
                    totalCount
                  }
                  lastGivenFeedback: feedbackReceived(feedbackFrom: $feedbackFrom, last: 1) {
                    edges {
                      node {
                        id
                        createdAt
                      }
                    }
                  }
                  user {
                    id
                    firstName
                    lastName
                    email
                    profile {
                      id
                      profileImage
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const ACTION_REQUIRED_FOR_FEEDBACK_COUNT = gql`
query team_employee($feedbackFrom: ID,$createdAt_Range: [DateTime],$first:Int){
  me {
    id
    employee {
      id
      teamManagerEmployee {
        edges {
          node {
            id
            employees(first:$first) {
              edges {
                node {
                  id
                  feedbackReceived(feedbackFrom: $feedbackFrom, createdAt_Range: $createdAt_Range) {
                    totalCount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`