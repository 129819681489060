// @ts-nocheck
import React from "react";
import { Row, Col, Spin, Modal } from "antd";
import styled from "styled-components";
import { MetaTags, getIntFromString } from "../../look";
import { Capitalize, displayUtcToUserTimezone } from '../../look/components/functions'
import EditIcon from '../../look/components/EditIcon';
import DeleteIcon from "../../look/components/DeleteIcon";
import new_back_arrow from '../../assets/new_back_arrow.svg'
import readiness_route from '../routes/index'
import readiness_close_icon from '../../assets/readiness-close-icon.svg'
import remove_readiness from '../../assets/delete-readiness.svg'
import edit_readiness from '../../assets/edit-readiness.svg'
import WarningModal from "./warningModal";
const ReadinessDetailedView = React.forwardRef((props, ref) => {
    const { navigateRoute, readinessById, deleteReadiness, getToolById, submittedByMe, answerList, onClose, toolLoading, tab, me } = props
    const [loading, setloading] = React.useState(false);
    const [td, setTd] = React.useState(<></>);
    const [delete_warning, setDeleteWarning] = React.useState(false)
    React.useImperativeHandle(ref, () => ({

        rerender() {
            displaytoolData()
        },

    }));

    React.useEffect(() => {
        displaytoolData();
    }, [props])

    const dashboardData = () => {
        if (me?.employee?.id != readinessById?.readinessFrom?.user?.employee?.id && me?.employee?.id == readinessById?.readinessTo?.user?.employee?.id) {
            return <h4 style={{ marginTop: '20px' }}>Readiness assessment for you from {Capitalize(readinessById?.readinessFrom?.user?.firstName)} {Capitalize(readinessById?.readinessFrom?.user?.lastName)}</h4>
        }
        else if (me?.employee?.id === readinessById?.readinessFrom?.user?.employee?.id && me?.employee?.id !== readinessById?.readinessTo?.user?.employee?.id) {
            return <h4 style={{ marginTop: '20px' }}>Readiness assessment for {Capitalize(readinessById?.readinessTo?.user?.firstName)} {Capitalize(readinessById?.readinessTo?.user?.lastName)}</h4>
        }
        else if (readinessById?.readinessFrom?.user?.employee?.id == me?.employee?.id && readinessById?.readinessTo?.user?.employee?.id === me?.employee?.id) {
            return <h4 style={{ marginTop: '20px' }}>Your Readiness Assessment</h4>
        }
        else if (readinessById?.readinessFrom?.user?.employee?.id !== readinessById?.readinessTo?.user?.employee?.id && me?.employee?.id!==readinessById?.readinessFrom?.user?.employee?.id && readinessById?.readinessTo?.user?.employee?.id != me?.employee?.id) {
            return <h4 style={{ marginTop: '20px' }}>Readiness assessment to {Capitalize(readinessById?.readinessTo?.user?.firstName)} {Capitalize(readinessById?.readinessTo?.user?.lastName)} from {Capitalize(readinessById?.readinessFrom?.user?.firstName)} {Capitalize(readinessById?.readinessFrom?.user?.lastName)}</h4>
        }
        else if (readinessById?.readinessFrom?.user?.employee?.id === readinessById?.readinessTo?.user?.employee?.id && readinessById?.readinessTo?.user?.employee?.id != me?.employee?.id) {
            return <h4 style={{ marginTop: '20px' }}>Readiness assessment from {Capitalize(readinessById?.readinessTo?.user?.firstName)} {Capitalize(readinessById?.readinessTo?.user?.lastName)}</h4>
        }
    }
    const handleDelete = async () => {
        const response = await deleteReadiness({ id: getIntFromString(readinessById?.id) });
        if (response && response?.id) {
            setloading(false)
            onClose()
            navigateRoute(`${readiness_route?.readinessHome?.replace(":type", "submitted")}`)
        } else {
            setloading(false)
        }
    };

    const displaytoolData = () => {
        setTd(
            <>
                {
                    answerList?.map((item, index) =>
                        <>
                            {getToolById && Object.keys(getToolById).length > 0 && !toolLoading && <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'center', gap: "1em" }}>
                                <h1 className="readiness-page-title" style={{ margin: "0px", fontWeight: "700" }}>{item?.skill?.title}</h1>
                                {index == 0 && submittedByMe && <div style={{ display: "flex", gap: "1em" }}>
                                    <img style={{ width: "24px", cursor: "pointer" }} src={edit_readiness} alt="" onClick={() => navigateRoute(`${readiness_route?.editReadiness?.replace(":readid", getIntFromString(readinessById?.id))}?tab=${tab}`)} />
                                    <img style={{ width: "24px", cursor: "pointer" }} src={remove_readiness} alt="" onClick={() => setDeleteWarning(true)} />
                                </div>}
                            </div>}
                            {getToolById && Object.keys(getToolById).length > 0 && !toolLoading && <>
                                {getToolById &&
                                    getToolById[item?.response?.id]?.getToolById?.textsection?.edges.map(({ node }, i) =>
                                        <><div dangerouslySetInnerHTML={{ __html: (node?.renderedText) }} /></>)}
                                {getToolById &&
                                    getToolById[item?.response?.id]?.getToolForReporteeByToolId?.textsection?.edges.map(({ node }, i) =>
                                        <><div dangerouslySetInnerHTML={{ __html: (node?.renderedText) }} /></>)}
                            </>}
                        </>

                    )
                }
            </>
        )
    }

    return (
        <>
            <MetaTags
                title="Readiness Zone"
                description="This is readiness Detail page."
            />

            {/* Page Title and Back Arrow */}
            <Spin spinning={toolLoading}>
                <div style={{ width: "100%", minHeight: "80vh" }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'start', position: 'relative', width: '100%' }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start" }}>
                            <p className="readiness-page-title">{dashboardData()}</p>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: '1em' }}>
                                <p className="readiness-recieved-date">Received on : <span style={{ color: "#000" }}>{displayUtcToUserTimezone(readinessById?.createdAt, "DD MMM")}</span></p>
                                <p className="readiness-recieved-date">Updated on : <span style={{ color: "#000" }}>{displayUtcToUserTimezone(readinessById?.updatedAt, "DD MMM")}</span></p>
                            </div>

                        </div>
                        <img src={readiness_close_icon} alt="readiness_close_icon" onClick={() => onClose()} style={{ cursor: "pointer" }} />
                    </div>
                    {
                        readinessById && (
                            <div style={{ maxHeight: "72vh", overflow: "auto" }}>
                                <Col style={{ paddingLeft: "15px", paddingRight: '15px' }}>
                                    {td}
                                </Col>
                            </div>
                        )
                    }
                </div>
            </Spin>
            <Modal
                visible={delete_warning}
                footer={false}
                closable={false}
                centered={true}
                destroyOnClose={true}>
                <WarningModal {...props} title={"Are you sure you want to delete this readiness ?"} delete_warning={true} onClose={() => { setDeleteWarning(false) }} onGoBack={() => handleDelete()} />
            </Modal>
        </>
    );
});

export default ReadinessDetailedView