import gql from 'graphql-tag';

export const VERTICAL_LIST = gql`
  query verticals($search:String) {
    verticals(name_Icontains:$search) {
      edges {
        node {
          id
          name
          parentvertical{
            id
          }
          heads{
            edges{
              node{
                id
              }
            }
          }
          teamSet{
            totalCount
          } 
        }
      }
    }
  }
`;
export const TEAM_LIST = gql`
  query Teams($vertical:ID,$search:String) {
    Teams(vertical:$vertical,name_Icontains:$search){
      edges {
        node {
          id
          vertical{
            id
          }
          managers{
            edges{
              node{
                id
              }
            }
          }
          name
        }
      }
    }
  }
`;
export const TEAM_LIST_VERTICAL_ID = gql`
  query Teams($vertical: ID) {
    Teams(vertical:$vertical) {
      edges {
        node {
          id
          name
          vertical{
            id
          }
        }
      }
    }
  }
`;
export const CREATE_VERTICAL = gql`
mutation createVertical($verticalData: VerticalInput!) {
  createVertical(verticalData: $verticalData){
    vertical{
    id
    }
  }
}
`
export const CREATE_TEAM = gql`
mutation createTeam($teamData: TeamInput!) {
  createTeam(teamData: $teamData){
    team{
    id
    }
  }
}
`

export const VERTICAL_DETAIL_TREE = gql`
  query vertical($id: ID!,$after:String) {
    vertical(id:$id) {
      id
      name
      heads{
        edges{
          node{
            id
            user{
              id
              email
              firstName
              lastName
              profile{
                id
                profileImage
              }
            }
          }
        }
      }
      employees(after:$after){
        pageInfo{
          hasNextPage
          endCursor
        }
        edges{
          node{
            id
            memberSet(fromVertical:$id,first:1) {
              edges {
                node {
                  id
                }
              }
            }
            user{
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const ORGANIZATION_EMPLOYEE = gql`
  query me($user_FirstName: String) {
    me{
    id
    employee{
      id
      organizationSet{
        edges{
          node{
            id
            employees(user_FirstName: $user_FirstName){
              edges{
                node{
                  id
                  user{
                    id
                    email
                    firstName
                    lastName
                    profile{
                      id
                      profileImage
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }
`;

export const VERTICAL_EMPLOYEE = gql`
  query vertical($id: ID!,$user_FirstName:String) {
    vertical(id:$id) {
      id
      heads(user_FirstName:$user_FirstName){
        edges{
          node{
            id
            user{
              id
              email
              firstName
              lastName
              profile{
                id
                profileImage
              }
            }
          }
        }
      }
      employees(user_FirstName:$user_FirstName){
        edges{
          node{
            id
            user{
              id
              email
              firstName
              lastName
              profile{
                id
                profileImage
              }
            }
          }
        }
      }
    }
  }
`;

export const TEAM_DETAIL_TREE = gql`
  query Team($id: ID!,$after:String) {
    Team(id:$id) {
      id
      name
      managers{
        edges{
          node{
            id
            user{
              id
              email
              firstName
              lastName
              profile{
                id
                profileImage
              }
            }
          }
        }
      }
      employees(after:$after){
        pageInfo{
          hasNextPage
          endCursor
        }
        edges{
          node{
            id
            memberSet(fromTeam:$id,first:1) {
              edges {
                node {
                  id
                }
              }
            }
            user{
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const ALL_EMPLOYEE_LIST_QUEY = gql`
query allEmployees($user_IsActive:Boolean,$search:String,$employee_verified_cursor:String,$employee_nonverified_cursor:String){
   verified:allEmployees(user_Status_Verified:true,user_IsActive:$user_IsActive,nameIcontains:$search,first:25,after:$employee_verified_cursor){
      pageInfo{
        hasNextPage
        endCursor
      } 
      edges{
        node{
          id
          user{
            id
            firstName
            lastName
            email
            isActive
            profile{
              id
              activatedDate
              deactivatedDate
            }
          }
          orgPocEmployee{
            totalCount
          }
          orgCeoEmployee{
            totalCount
          }
          verticalHeadEmployee{
            totalCount
            edges{
              node{
                id
                name
              }
            }
          }
          verticalMembersEmployee{
            totalCount
            edges{
              node{
                id
                name
              }
            }
          }
          teamManagerEmployee{
            totalCount
            edges{
              node{
                id
                name
              }
            }
          }
          teamMembersEmployee{
            totalCount
            edges{
              node{
                id
                name
              }
            }
          }
        }
      }
    }
    nonverified:allEmployees(user_Status_Verified:false,user_IsActive:$user_IsActive,nameIcontains:$search,first:25,after:$employee_nonverified_cursor){
      pageInfo{
        hasNextPage
        endCursor
      }
      edges{
        node{
          id
          user{
            id
            firstName
            lastName
            email
            isActive
            profile{
              id
              activatedDate
              deactivatedDate
            }
          }
          orgPocEmployee{
            totalCount
          }
          orgCeoEmployee{
            totalCount
          }
          verticalHeadEmployee{
            totalCount
            edges{
              node{
                id
                name
              }
            }
          }
          verticalMembersEmployee{
            totalCount
            edges{
              node{
                id
                name
              }
            }
          }
          teamManagerEmployee{
            totalCount
            edges{
              node{
                id
                name
              }
            }
          }
          teamMembersEmployee{
            totalCount
            edges{
              node{
                id
                name
              }
            }
          }
        }
      }
    }
}
`

export const ALL_VERTICAL_EMPLOYEE_LIST_QUEY = gql`
query allEmployees($id_In: [String],$user_IsActive: Boolean,$search:String,$offset: Int){
     verticals(id_In: $id_In) {
    edges {
      node {
        id

        verified_heads: heads(offset:$offset,user_Status_Verified:true,user_IsActive:$user_IsActive,user_FirstName:$search,first:25) {
          
          pageInfo{
            hasNextPage
          }
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
                email
                isActive
                profile{
                  id
                  activatedDate
                  deactivatedDate
                }
              }
              orgPocEmployee {
                totalCount
              }
              orgCeoEmployee {
                totalCount
              }
              verticalHeadEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              verticalMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamManagerEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }

        nonverified_heads: heads(offset:$offset,user_Status_Verified:false,user_IsActive:$user_IsActive,user_FirstName:$search,first:25) {
          pageInfo{
            hasNextPage
            
          }
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
                email
                isActive
                profile{
                  id
                  activatedDate
                  deactivatedDate
                }
              }
              orgPocEmployee {
                totalCount
              }
              orgCeoEmployee {
                totalCount
              }
              verticalHeadEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              verticalMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamManagerEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        verified: employees(offset:$offset,user_Status_Verified:true,user_IsActive:$user_IsActive,user_FirstName:$search,first:10) {
          pageInfo{
            hasNextPage
            
          }
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
                email
                isActive
                profile{
                  id
                  activatedDate
                  deactivatedDate
                }
              }
              orgPocEmployee {
                totalCount
              }
              orgCeoEmployee {
                totalCount
              }
              verticalHeadEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              verticalMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamManagerEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        nonverified: employees(offset:$offset,user_Status_Verified:false,user_IsActive:$user_IsActive,user_FirstName:$search,first:10) {
          
          pageInfo{
            hasNextPage
            
          }
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
                email
                isActive
                profile{
                  id
                  activatedDate
                  deactivatedDate
                }
              }
              orgPocEmployee {
                totalCount
              }
              orgCeoEmployee {
                totalCount
              }
              verticalHeadEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              verticalMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamManagerEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
export const ORG_POC_VERTICAL_GRAPH_DATA =gql`
query organizationById($id: ID!$survey_id: ID){
  organizationById(id:$id){
    id
    surveySet(id:$survey_id){
      edges{
        node{
          id
          summaryListByVertical
        }
      }
    }
  }
}
`
export const ORG_POC_TEAM_GRAPH_DATA =gql`
query organizationById($id: ID!$survey_id: ID,$vid: String!){
  organizationById(id:$id){
    id
    surveySet(id:$survey_id){
      edges{
        node{
          id
          summaryListByVerticalTeam(vid:$vid)
        }
      }
    }
  }
}
`

export const ALL_TEAM_EMPLOYEE_LIST_QUEY = gql`
query allEmployees($id_In: [String],$user_IsActive:Boolean,$search:String,$offset:Int){
     Teams(id_In: $id_In) {
    edges {
      node {
        id

        verified_managers: managers(offset:$offset,user_Status_Verified:true,user_IsActive:$user_IsActive,user_FirstName:$search,first:25) {
          
          pageInfo{
            hasNextPage
          }
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
                email
                isActive
                profile{
                  id
                  activatedDate
                  deactivatedDate
                }
              }
              orgPocEmployee {
                totalCount
              }
              orgCeoEmployee {
                totalCount
              }
              verticalHeadEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              verticalMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamManagerEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }

        nonverified_managers: managers(offset:$offset,user_Status_Verified:false,user_IsActive:$user_IsActive,user_FirstName:$search,first:25) {
          pageInfo{
            hasNextPage
            
          }
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
                email
                isActive
                profile{
                  id
                  activatedDate
                  deactivatedDate
                }
              }
              orgPocEmployee {
                totalCount
              }
              orgCeoEmployee {
                totalCount
              }
              verticalHeadEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              verticalMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamManagerEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }

        verified: employees(offset:$offset,user_Status_Verified:true,user_IsActive:$user_IsActive,user_FirstName:$search,first:10) {
          pageInfo{
            hasNextPage
            
          }
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
                email
                isActive
                profile{
                  id
                  activatedDate
                  deactivatedDate
                }
              }
              orgPocEmployee {
                totalCount
              }
              orgCeoEmployee {
                totalCount
              }
              verticalHeadEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              verticalMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamManagerEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        nonverified: employees(offset:$offset,user_Status_Verified:false,user_IsActive:$user_IsActive,user_FirstName:$search,first:10) {
          pageInfo{
            hasNextPage
          }
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
                email
                isActive
                profile{
                  id
                  activatedDate
                  deactivatedDate
                }
              }
              orgPocEmployee {
                totalCount
              }
              orgCeoEmployee {
                totalCount
              }
              verticalHeadEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              verticalMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamManagerEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              teamMembersEmployee {
                totalCount
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
export const UPDATE_POC_EMPLOYEE_MUTATION =gql`
  mutation updateEmployee($id: ID!, $email: String,$fname: String,$lname: String,$status: Boolean) {
    updateEmployee(id:$id, email:$email, fname:$fname, lname:$lname,status:$status){
      employee{
        id
      }
    }
  }
`

export const POC_EMPLOYEE_TOTAL_COUNT =gql`
query allEmployees{
  allEmployees{
    totalCount
	}
}
`

export const EMPLOYEE_BY_ID_DETAIL =gql`
query employeeById($id: ID!){
employeeById(id:$id){
    verticalHeadEmployee{
      edges{
        node{
          id
          name
          heads{
            edges{
              node{
                id
              }
            }
          }
        }
      }
    }
    teamManagerEmployee{
      edges{
        node{
          id
          managers{
            edges{
              node{
                id
              }
            }
          }
        }
      }
    }
    memberSet{
      edges{
        node{
          id
          fromOrganization{
            id
          }
        }
      }
    }
  }
}
`

export const EMPLOYEE_EXPORT_QUERY =gql`
query allEmployees($offset: Int){
  allEmployees(first:100,offset: $offset){
  totalCount
    pageInfo{
      hasNextPage
      endCursor
    } 
   edges{
      node{
        id
        user{
          id
          firstName
          lastName
          email
          isActive
          profile{
            id
            activatedDate
            deactivatedDate
          }
        }
        orgPocEmployee{
          totalCount
        }
        orgCeoEmployee{
          totalCount
        }
        verticalHeadEmployee{
          totalCount
          edges{
            node{
              id
              name
            }
          }
        }
        verticalMembersEmployee{
          totalCount
          edges{
            node{
              id
              name
            }
          }
        }
        teamManagerEmployee{
          totalCount
          edges{
            node{
              id
              name
            }
          }
				}
        teamMembersEmployee{
          totalCount
          edges{
            node{
              id
              name
            }
          }
        }
      }
    }
  }
}
`
