import React from "react";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc' 
import { ACTION_REQUIRED_FOR_FEEDBACK } from "../graphql/SubmittedFeedback.gql";
import moment from "moment";
import FeedbackActionRequiredView from "../components/feedbackActionRequiredView";
import { getIntFromString } from "modules/look";
const FeedbackActionRequired = props => {
    const {client, me, activeTab, remove_user_from_pending, searchBarData} = props 
    const [user_list,SetUserList] = React.useState([])
    const [feedback_loader,SetFeedbackLoader] = React.useState(false)
    const user_list_ref = React.useRef([])

    React.useEffect(()=>{
        user_list_ref.current = user_list
    },[user_list])

    React.useEffect(()=>{
        if(remove_user_from_pending){ 
            let list = user_list_ref?.current?.filter(i=>i?.employee_id != remove_user_from_pending?.employee_id)
            SetUserList(list)
        }
    },[remove_user_from_pending])

    React.useEffect(()=>{
        if(me){
            let start_date = moment().startOf("day").subtract(30,"days").utc().toISOString()
            let end_date = moment().endOf("day").utc().toISOString()
            GetActionRequiredListForFeedback({feedbackFrom:me?.employee?.id,createdAt_Range:[start_date,end_date],user_FirstName:searchBarData?.input})
        }
     
    },[me,activeTab])

    const search_timer = React.useRef()
    React.useEffect(()=>{
        if(searchBarData?.input !== null && searchBarData?.input !== undefined ){
            if(search_timer.current){
                clearTimeout(search_timer.current)
            }
            search_timer.current = setTimeout(() => {
                let start_date = moment().startOf("day").subtract(30,"days").utc().toISOString()
                let end_date = moment().endOf("day").utc().toISOString()
                GetActionRequiredListForFeedback({feedbackFrom:me?.employee?.id,createdAt_Range:[start_date,end_date],user_FirstName:searchBarData?.input})
            }, 1000);
        }
    },[searchBarData])

    const GetActionRequiredListForFeedback = async(filter)=>{

        try{
            SetFeedbackLoader(true)
          const { data } = await client.query({
            query: ACTION_REQUIRED_FOR_FEEDBACK,
            variables: {  ...filter,first:100 },
            fetchPolicy: 'network-only'
            }); 
            
            if(data?.me){
                const employees = [].concat.apply([],data?.me?.employee?.teamManagerEmployee?.edges?.map(({node})=>node?.employees?.edges?.map((i)=>i?.node)))
           
                var setObj = new Set();
                var result = employees.reduce((acc, item) => {
                    if (!setObj.has(getIntFromString(item?.id))) {
                        setObj.add(getIntFromString(item?.id))
                        acc.push(item)
                    }
                    return acc;
                }, []) 
                let user_nodes = result?.filter(i=>i?.feedbackReceived?.totalCount==0)
              
                const filtered_user_list = user_nodes?.map(i=>{
                    return {
                        employee_id: i?.id,
                        user: i?.user,
                        previous_feedback: i?.lastGivenFeedback?.edges?.length ? i?.lastGivenFeedback?.edges[0]?.node : null
                    }
                })
                SetUserList(filtered_user_list?.filter(i=>getIntFromString(i?.user?.id)!=getIntFromString(me?.id)))
                SetFeedbackLoader(false)
            }

        }catch(e){  
        }
       
      }

    return (
        <FeedbackActionRequiredView {...props} user_list={user_list} feedback_loader={feedback_loader}/>
    )

}

export default compose(withApollo)(FeedbackActionRequired); 