import React from "react";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { OKR_MODULE_USAGE } from "modules/poc-dashboard/graphql/moduleusageQuery.gql";
import CommonTableView from "modules/poc-dashboard/components/common/tableView";
import { Capitalize, displayUtcToUserTimezone } from "modules/look";


const OkrModuleUsageContainer =(props)=>{
    const {client,permission,date_range_filter,search}=props 
    const [data_list_loading,setDataListLoading] = React.useState(false)
    const [list,SetList] = React.useState([])
    const [page_info,SetPageInfo] = React.useState()
    const list_ref = React.useRef([])
    
    React.useEffect(()=>{
        list_ref.current = list
    },[list])
    
    const header_list =[
        {
            label:"Employee Name"
        },
        {
            label:"Total OKR"
        },
        {
            label:"Created OKR"
        },
        {
            label:"Assigned OKR"
        },
        {
            label:"Collaborated OKR"
        },
        {
            label:"Accepted OKR"
        },
        {
            label:"Last Accessed Date"
        }
    ]
    
    React.useEffect(()=>{
        if(permission?.required_permission){
            okrModuleUsage({})
        }
    },[permission])

    React.useEffect(()=>{  
        okrModuleUsage({})
    },[date_range_filter])

    const timeout_ref =React.useRef()

    React.useEffect(()=>{
        clearTimeout(timeout_ref.current)
        timeout_ref.current = setTimeout(()=>{
            okrModuleUsage({})
        },600)
    },[search])

    const okrModuleUsage=async(filter)=>{
        setDataListLoading(true)
        let createdAt_Range =[]
        if(date_range_filter && date_range_filter?.start_date && date_range_filter?.end_date){
            let start_date =date_range_filter?.start_date
            let end_date = date_range_filter?.end_date
            createdAt_Range=[start_date,end_date]
        }
        else{
            createdAt_Range=[]
        }
        const { data } = await client.query({
            query: OKR_MODULE_USAGE,
            variables: {...filter,first:10,createdAt_Range,user_FirstName:search},
            fetchPolicy: 'network-only'
        });
        if (data?.allEmployees) { 
            let list = data?.allEmployees?.edges?.map(({node})=>{
                let row_data = [
                    {
                        type:"label",
                        value:`${Capitalize(node?.user?.firstName)} ${Capitalize(node?.user?.lastName) || ""}`
                    },
                    {
                        type:"label",
                        value:node?.owner?.totalCount + node?.collabrator?.totalCount + node?.assigned?.totalCount
                    },
                    {
                        type:"label",
                        value:node?.owner?.totalCount 
                    },
                    {
                        type:"label",
                        value:node?.assigned?.totalCount 
                    },
                    {
                        type:"label",
                        value:node?.collabrator?.totalCount 
                    },
                    {
                        type:"label",
                        value:node?.accepted_okr?.totalCount 
                    },
                    {
                        type:"label",
                        value:node?.last_accessed?.edges[0]?.node?.updatedAt? displayUtcToUserTimezone( node?.last_accessed?.edges[0]?.node?.updatedAt, 'DD/MM/YYYY' ) :"-"
                    }
                ]
                return row_data
            })
            if(filter?.after){
                list = list_ref?.current?.concat(list)
            }
            SetList(list)
            SetPageInfo(data?.allEmployees?.pageInfo)
            setDataListLoading(false)
        }
    }

    const onPagination=()=>{
        if(page_info?.hasNextPage){
            okrModuleUsage({after:page_info?.endCursor})
        }
    }

    return (
        <>
            <CommonTableView {...props} data_list_loading={data_list_loading} header_list={header_list} list={list} page_info={page_info} onPagination={onPagination}/>
        </>
    )
}

export default compose(withApollo)(OkrModuleUsageContainer)