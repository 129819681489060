import React, { useState } from 'react';
import { Button, Spin, Card, Row, Col, Space, Empty } from 'antd';

import { getIntFromString, ReactMarkdown, DeleteIcon, Pagination, displayUtcToUserTimezone } from '../../look';
import AcceptCompareRequest from './AcceptCompareRequest';
import SURVEY_ROUTE from '../../survey/route';

const IncomingRequestView = props => {
  const { loading, pagination, onPaginationChange, allComparisonRequestForUser, onAccept, onCancel } = props;
  const [accept, setAccept] = useState(null);

  async function handleSubmit(values, button) {
    const response = await onAccept(
      {
        accepted: true,
        id: getIntFromString(accept.id),
        ...values
      },
      button
    );
    response && setAccept(null);
  }

  return (
    <>
      <h3>{!accept ? 'Requests for you' : 'Select which response you want to share'}</h3>
      <br />

      <Spin spinning={loading}>
        {!accept ? (
          <Row gutter={[24, 24]} style={{ minHeight: '60vh', maxHeight: '60vh', overflowY: 'scroll' }}>
            {allComparisonRequestForUser && allComparisonRequestForUser?.edges?.length > 0 ? allComparisonRequestForUser?.edges?.map(({ node }) => (
              <Col md={12} xs={24}>
                <br />
                <Card
                  style={{ display: 'grid', height: '100%' }}
                  bodyStyle={{
                    borderRadius: '20px',
                    border: 0,
                    boxShadow: '0px 4px 15px 5px rgba(175, 176, 162, 0.15)'
                  }}
                  bordered={false}
                >
                  <div style={{ height: '87%' }}>
                    <Row justify="space-between">
                      <h4 >
                        From :<span className='text-transform-capitalize'>{node?.sender?.firstName} {node?.sender?.lastName}</span> 
                      </h4>
                      <DeleteIcon tooltipSuffix={"Request"} size="sm" type="danger" onClick={() => onCancel(getIntFromString(node.id))} />
                    </Row>
                    <br />

                    <Row justify="space-between">
                      <Col>
                        <div>
                          On : {}
                          {node?.requestDate ? displayUtcToUserTimezone(node?.requestDate, "ddd MMMM DD YYYY") : 'Not-available'}
                        </div>
                      </Col>
                      <Col>
                        <div>
                          Accepted On : {}
                          {node?.responseDate ? displayUtcToUserTimezone(node?.responseDate, "ddd MMMM DD YYYY") : 'Not-accepted'}
                        </div>
                      </Col>
                    </Row>

                    <br />
                    <div>
                      Message :
                      <div
                        style={{
                          border: '1px solid rgba(202, 202, 202, 0.25)',
                          margin: '7px',
                          padding: '15px',
                          height: '80%',
                          borderRadius: '2px'
                        }}
                      >
                        <ReactMarkdown>{node?.message}</ReactMarkdown>
                      </div>
                    </div>
                    <br />
                  </div>

                  <Row align="bottom">
                    <Col span={16}>
                      <div>Survey : {node?.survey?.name}</div>
                    </Col>

                    <Col span={8} align="right">
                      {!node?.responseDate ? (
                        <>
                          <Space align="baseline">
                            <Button type="primary" onClick={() => setAccept(node)}>
                              Accept
                            </Button>
                          </Space>
                        </>
                      ) : (
                        <Space align="baseline">
                          <Button
                            type="primary"
                            ghost
                            target="_blank"
                            href={`${SURVEY_ROUTE.surveyQuizresponseLink}${getIntFromString(node?.sharedResponse?.id)}`}
                          >
                            View
                          </Button>
                        </Space>
                      )}
                    </Col>
                  </Row>
                </Card>
                <br />
              </Col>
            )): (
                <div style={{  margin: '0',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'}}>
                  <Empty />
                </div>
            )}

            <Col span={24}>
              <br />
              {!loading && allComparisonRequestForUser && allComparisonRequestForUser?.edges?.length > 0 && (
                <Pagination
                  data={allComparisonRequestForUser}
                  pagination={pagination}
                  onPaginationChange={onPaginationChange}
                />
              )}
            </Col>
          </Row>
        ) : (
          <AcceptCompareRequest onSubmit={handleSubmit} survey={accept?.survey?.id} />
        )}
      </Spin>
    </>
  );
};

export default IncomingRequestView;
