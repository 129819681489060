
import { Button, Progress, Spin } from "antd";
import { dashboard_home_icons } from "modules/poc-dashboard/icons";
import React from "react";

const UserModuleUsageExportView = (props) => {
    const { export_data_loading, prgress_count, getCompleteUsageData } = props
    return (
        <div className='org-export-btn-parent'>
            {!export_data_loading ? <Button className="export-btn" onClick={() => getCompleteUsageData()}><img src={dashboard_home_icons?.export_icon} alt="export_icon" style={{ height: "1.25em", marginRight: "1em" }} /> Export Employees Usage Report</Button> :
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', gap: ".5em" }}>
                    <Spin spinning={export_data_loading} tip='Exporting...'></Spin>
                    <Progress type="circle" percent={prgress_count} strokeColor="#4CCB1F" strokeWidth={10}/>
                </div>}
        </div>
    )
}

export default UserModuleUsageExportView;