import React from 'react';
import { Row, Col, Spin, Card, Button, Empty } from 'antd';
import { Pagination } from '../../look';
import { USER_IMG } from '../../../config';

const EmployeesRenderView = props => {
  const {
    loading,
    allowComparison,
    data,
    handleCompare,
    pagination,
    onPaginationChange,
    handleRequest,
    employee = true
  } = props;

  return (
    <Col span={24}>
      <Spin spinning={loading}>
        <div style={{ minHeight: '40vh' }}>
          <Row gutter={[24, 24]}>
            {data?.edges.length > 0 ? (
              data?.edges?.map(({ node }) => (
                <Col md={12} xs={24}>
                  <br />
                  <Row gutter={24} align="middle">
                    <Col span={4}>
                      <img alt="" src={USER_IMG} style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                    </Col>

                    <Col span={20}>
                      <Card
                        bodyStyle={{ padding: '18px' }}
                        style={{
                          boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
                          borderRadius: '10px'
                        }}
                      >
                        <Row gutter={12}>
                          <Col lg={12} md={24} className='text-transform-capitalize'>
                            {employee
                              ? `${node?.user?.firstName} ${node?.user?.lastName}`
                              : node?.firstName && node?.lastName
                              ? `${node?.firstName} ${node?.lastName}`
                              : `${node.username}`}
                          </Col>

                          <Col lg={12} md={24} align="right">
                            {allowComparison ? (
                              <Button ghost type="primary" onClick={() => handleCompare(node)} shape="round">
                                Compare
                              </Button>
                            ) : (
                              <Button ghost type="primary" onClick={() => handleRequest(node)} shape="round">
                                Request
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              ))
            ) : (
              <Col span={24}>
                {!loading && (
                  <div align="center">
                    <Empty />
                  </div>
                )}
              </Col>
            )}
          </Row>
        </div>
        <Row>
          <Col span={24}>
            <br />
            {!loading && data?.edges.length > 0 && (
              <Pagination data={data} pagination={pagination} onPaginationChange={onPaginationChange} />
            )}
          </Col>
        </Row>
      </Spin>
    </Col>
  );
};

export default EmployeesRenderView;
