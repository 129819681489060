import React from 'react';
import { useHistory } from 'react-router-dom';
import { ca_detail_icons } from '../icon';
import '../css/competency-assessment-detail.css'
import CompetencyUserTableView from './competency-user-table-view';
import CompetencyBarChart from './ca-bar-chart';
import { Spin, Dropdown, Menu, Button } from 'antd';
import CustomYearPicker from './custom-year-picker';
import GraphAdvanceFilterContainer from '../containers/GraphAdvanceFilterContainer';
import ConversationDetailView from 'modules/grow-model/components/ConversationDetailView';
import { generteRandomColor, getIntFromString } from 'modules/look';
import CompetencyLineChart from './ca-line-chart';
import { threshold_colors } from '../color-config';
import moment from 'moment-timezone';
import { CloseCircleFilled } from "@ant-design/icons";
import ca_route from '../route/index'
const UserCompetecnyAssessmentHomeView = (props) => {
    const { assessment_details, page_loading, hide_user_detail, competency_title, permission, dropdown_data_list,rating_list,employee,competecnyAssessmentDetail,roleById,role_tenure } = props
    const history = useHistory()
    const [graph_competency_filter, SetGraphCompetencyFilter] = React.useState()
    const [graph_type, SetGraphType] = React.useState('bar')
    const [bar_graph_data,SetBarGraphData]= React.useState({
        graph_data_set:[],
        labels:[]
    })
    const [line_graph_data,SetLineGraphData]= React.useState({
        graph_data_set:[],
        labels:[]
    })
    React.useEffect(()=>{
        if(graph_competency_filter){
            if(graph_competency_filter?.type==="main"){
                let mainCompetency_In = graph_competency_filter?.children?.map(i=>getIntFromString(i?.id))
                competecnyAssessmentDetail({mainCompetency_In})
            }else{
                let mainCompetency_In = [getIntFromString(graph_competency_filter?.id)]
                competecnyAssessmentDetail({mainCompetency_In})
            }
        }
    },[graph_competency_filter])

    const onDateChange =(date)=>{
        let createdAt_Range = date
        competecnyAssessmentDetail({createdAt_Range})
    }
    React.useEffect(() => {
        
        if(assessment_details?.length&& rating_list && !page_loading){
            let data_set = {}
            let competencyassessmentSet
            SetBarGraphData()
            if(graph_competency_filter?.type==='child'){
                competencyassessmentSet = assessment_details?.map(i=>i?.competencyassessmentSet?.edges?.map(({node})=>{ return {...node,created_at:i?.createdAt}}))
                competencyassessmentSet = [].concat.apply([],competencyassessmentSet)?.filter(i=>i?.competency)?.map(asmt=>{return {...asmt,assessmentbarsSet:asmt?.assessmentbarsSet?.edges[0]?.node }})
                let competency_ids = competencyassessmentSet?.map(i=>i?.competency?.id)
                competency_ids = [...new Set(competency_ids)]
                competency_ids.forEach(i=>{
                let list = competencyassessmentSet?.filter(asmt=>asmt?.competency?.id===i)
                let all_assessment = list?.map(asmt=>asmt?.assessmentbarsSet)
                let most_repeated = mostRepeatedKey(all_assessment)
                data_set[i]={
                    id:i,
                    title:list[0]?.competency?.title,
                    competency:list[0]?.competency,
                    competencyassessmentSet:all_assessment,
                    behaviourExample:most_repeated?.behaviourExample,
                    qualifyingLevelName:most_repeated?.qualifyingLevelName,
                    value:most_repeated?.qualifyingLevelSequence,
                    color:threshold_colors[ `threshold_${most_repeated?.qualifyingLevelSequence}`]?`rgba(${threshold_colors[ `threshold_${most_repeated?.qualifyingLevelSequence}`]},0.5)`:generteRandomColor()
                }
            })
            }
            else{
                competencyassessmentSet = assessment_details?.map(i=>i?.competencyassessmentSet?.edges?.map(({node})=>{ return {...node,created_at:i?.createdAt}}))
                competencyassessmentSet = [].concat.apply([],competencyassessmentSet)?.filter(i=>i?.mainCompetency)?.map(asmt=>{return {...asmt,assessmentbarsSet:asmt?.assessmentbarsSet?.edges[0]?.node }})
                let competency_ids = competencyassessmentSet?.map(i=>i?.mainCompetency?.id)
                competency_ids = [...new Set(competency_ids)] 
                competency_ids.forEach(i=>{
                    let list = competencyassessmentSet?.filter(asmt=>asmt?.mainCompetency?.id===i)
                    let all_assessment = list?.map(asmt=>asmt?.assessmentbarsSet)
                    let most_repeated = mostRepeatedKey(all_assessment)
                    data_set[i]={
                        id:i,
                        title:list[0]?.mainCompetency?.title,
                        competency:list[0]?.mainCompetency,
                        competencyassessmentSet:all_assessment,
                        behaviourExample:most_repeated?.behaviourExample,
                        qualifyingLevelName:most_repeated?.qualifyingLevelName,
                        value:most_repeated?.qualifyingLevelSequence,
                        color:threshold_colors[ `threshold_${most_repeated?.qualifyingLevelSequence}`]?`rgba(${threshold_colors[ `threshold_${most_repeated?.qualifyingLevelSequence}`]},0.5)`:generteRandomColor()
                    }
                })
            }
           
            let graph_data_set = Object.values(data_set)
            let labels = rating_list || []
            graph_data_set.forEach(i=>{
                let rating = labels?.find(scale=>scale?.name===i.qualifyingLevelName)
                if(!rating){
                    labels.push({name:i.qualifyingLevelName,sequence:labels?.length+1})  
                }
            })
            labels=labels?.sort((a,b)=>a.sequence-b.sequence)
            labels = labels?.map((i,index)=>{ 
                return {
                    ...i,
                    value:index+1
                }
            })
            lineChartHandle(competencyassessmentSet,labels)
            graph_data_set = graph_data_set?.map(i=>{
                let rating = labels?.find(label=>label?.name===i?.qualifyingLevelName)
                return {
                    ...i,
                    sequence:rating?.value??i?.value
                }
            })
            SetBarGraphData({...{graph_data_set,labels}})
        }else{
            let bar_data = bar_graph_data
            bar_data['graph_data_set']=[]
            SetBarGraphData({...bar_data})
            let line_data = line_graph_data
            line_data['graph_data_set']=[]
            SetLineGraphData({...line_data})
        }
    }, [assessment_details,rating_list])
    const months ={
        0:'Jan',
        1:'Feb',
        2:'Mar',
        3:'Apr',
        4:'May',
        5:'Jun',
        6:'Jul',
        7:'Aug',
        8:'Sep',
        9:'Oct',
        10:'Nov',
        11:'Dec'
    }
    const lineChartHandle=(dataSet,labels)=>{
        labels = labels?.map((i,index)=>{ 
            return {
                ...i,
                value:index
            }
        })
        let graph_data_set
        let competency_ids
        SetLineGraphData()
      if(graph_competency_filter?.type==='child'){
        competency_ids = dataSet?.map(i=>i?.competency?.id)
        competency_ids = [...new Set(competency_ids)] 
        let data_set = {}
        competency_ids.forEach(i=>{
            let list = dataSet?.filter(asmt=>asmt?.competency?.id===i)
            let month_wise_data = Object.keys(months)?.map(value=>{
                let monthly_data =list?.filter(a=>moment(a?.created_at).get('month')?.toString()===value)
                let assessment_list = monthly_data?.map(asmt=>asmt?.assessmentbarsSet)
                let most_repeated 
                let rating_scale
                if(assessment_list?.length){
                    most_repeated  = mostRepeatedKey(assessment_list)
                    rating_scale= labels?.find(rating=>rating?.name==most_repeated?.qualifyingLevelName)
                }
                return {
                    id:i,
                    title:list[0]?.competency?.title,
                    competency:list[0]?.competency,
                    month:months[value],
                    monthly_data,
                    most_repeated,
                    value:rating_scale?.value||0,
                    created_at:monthly_data[0]?.created_at
                }
            })
            let year_data = month_wise_data?.find(yr=>yr?.created_at)
            let year = moment(year_data?.created_at).get('year')
            let current_year = moment().get('year')
            if(year==current_year){
                let current_month = moment().get('month')
                for (let m = current_month+1; m <=11; m++) {
                    delete month_wise_data[m] 
                }
            }
            data_set[i]=month_wise_data
        })
        graph_data_set = Object.values(data_set)?.map(i=>{
            let pointStyle = [ 'circle','rect','triangle']
            let random_number = Math.floor(Math.random() * 3);
            let color =generteRandomColor()
            return   {
                data: i?.map(monthly=>monthly?.value),
                backgroundColor: color,
                borderColor:color,
                pointStyle:pointStyle[random_number] || pointStyle[0],
                pointRadius: 10,
                barThickness: 50,
                barPercentage: 0.5, // Adjust the bar width
                borderRadius: 8, // Set the border radius for all four corners
                data_set:i,
                competency:i[0]?.competency,
                legend:{label:i[0]?.competency?.title,id:i[0]?.competency?.id}
            }
        })
      }
      else{
        competency_ids = dataSet?.map(i=>i?.mainCompetency?.id)
        competency_ids = [...new Set(competency_ids)] 
        let data_set = {}
        competency_ids.forEach(i=>{
            let list = dataSet?.filter(asmt=>asmt?.mainCompetency?.id===i)
            let month_wise_data = Object.keys(months)?.map(value=>{
                let monthly_data =list?.filter(a=>moment(a?.created_at).get('month')?.toString()===value)
                let assessment_list = monthly_data?.map(asmt=>asmt?.assessmentbarsSet)
                let most_repeated 
                let rating_scale
                if(assessment_list?.length){
                    most_repeated  = mostRepeatedKey(assessment_list)
                    rating_scale= labels?.find(rating=>rating?.name==most_repeated?.qualifyingLevelName)
                }
                return {
                    id:i,
                    title:list[0]?.mainCompetency?.title,
                    competency:list[0]?.mainCompetency,
                    month:months[value],
                    monthly_data,
                    most_repeated,
                    value:rating_scale?.value||0,
                    created_at:monthly_data[0]?.created_at
                }
            })
            let year_data = month_wise_data?.find(yr=>yr?.created_at)
            let year = moment(year_data?.created_at).get('year')
            let current_year = moment().get('year')
            if(year==current_year){
                let current_month = moment().get('month')
                for (let m = current_month+1; m <=11; m++) {
                    delete month_wise_data[m] 
                }
            }
            data_set[i]=month_wise_data
        })
        graph_data_set = Object.values(data_set)?.map(i=>{
            let pointStyle = [ 'circle','rect','triangle']
            let random_number = Math.floor(Math.random() * 3);
            let color =generteRandomColor()
            return   {
                data: i?.map(monthly=>monthly?.value),
                backgroundColor: color,
                borderColor:color,
                pointStyle:pointStyle[random_number] || pointStyle[0],
                pointRadius: 10,
                barThickness: 50,
                barPercentage: 0.5, // Adjust the bar width
                borderRadius: 8, // Set the border radius for all four corners
                data_set:i,
                competency:i[0]?.competency,
                legend:{label:i[0]?.competency?.title,id:i[0]?.competency?.id}
            }
        })
      }
    SetLineGraphData({...{graph_data_set,labels}})
    }
    const mostRepeatedKey = (arr) => {
        let list = {}
        arr.forEach(item => {
            if (list[`id_${item?.qualifyingLevelId}`]) {
                list[`id_${item?.qualifyingLevelId}`].count++
            } else {
                list[`id_${item?.qualifyingLevelId}`] = {
                    count: 1,
                    ...item
                }
            }
        })
        let higest_count = Object.values(list)?.sort((a, b) => b.count - a.count)[0]?.count
        let data = Object.values(list)?.filter(item => item?.count === higest_count).sort((a, b) => b.qualifyingLevelSequence - a.qualifyingLevelSequence )
        return data[0]
    }
    // Dropdown overlay of competencies
    const CompetencyCategoryOverlay = () => (
        <Menu expandIcon={<img src={ca_detail_icons.dropdown_submenu} />}
            mode="inline" className="ca-overlay-container">
            {dropdown_data_list?.map(item => (
                <Menu.SubMenu
                    onTitleClick={(e) => SetGraphCompetencyFilter(item)}
                    id={item?.id} title={item.title} key={item.id}
                    disabled={!item.children?.length}
                    className={item?.SelectedCompetency ? "ca-selected-row" : null}>
                    {item.children.map((child) => (
                        <Menu.Item className="ca-submenu" key={child?.id} onClick={(e) => SetGraphCompetencyFilter(child)}>
                            {child?.title}
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
            ))}
        </Menu>
    )
    const changeGraph=(e)=>{
        if(e){
            SetGraphType('line')
        }else{
            SetGraphType('bar')
        }
    }
    
    const onClearFilter = ()=>{
        SetGraphCompetencyFilter(null)
        competecnyAssessmentDetail({mainCompetency_In:[]})
    }
    return (
        <div className="ca-root">
            <Spin spinning={page_loading}>
                <div className="ca-heading-container">
                    <img onClick={(e) => history.push(ca_route?.competency_assessment_home)} style={{ paddingLeft: ".5em", cursor: "pointer" }} src={ca_detail_icons?.new_back_arrow} alt="" />
                 <h4 className={`ca-main-title ${employee?.user?.firstName?'text-transform-capitalize':''}`}>{employee?.user?.firstName || employee?.user?.email}’s Competency Assessment</h4>
                    <div></div>
                </div>
                {roleById && <div className='ca-section-container'>
                    <div className='ca-small-card-seaction'>
                        <h4 className='ca-small-detail-role'>{roleById?.title}</h4>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '2em', justifyContent: 'center', alignItems: 'center' }}>
                            {role_tenure&&<h5 className='ca-small-detail-role-title'>Role Tenure : <span style={{ color: '#0098F0' }}>{role_tenure}</span> </h5>}
                            <h5 className='ca-small-detail-role-title'>Level : <span style={{ color: '#0098F0' }}>{roleById?.employeeLevel?.name}</span></h5>
                        </div>
                    </div>
                </div>}
                <div className='ca-detail-data-container'>
                    <div className='ca-chart-container'>
                        <div className='ca-chart-filter-container'>
                        <Dropdown overlayStyle={{zIndex:1069}} placement='bottomCenter' overlay={CompetencyCategoryOverlay} trigger='click'>
                                        <Button className="ca-dropdown-btn ca-competency-filter-dropdown" style={{ display: "flex", justifyContent: "space-around", flexDirection: "row", gap: "1em", alignItems: "center" }}>
                                            <p className='ca-drop-label'>{graph_competency_filter ? graph_competency_filter?.title : 'Filter Competency'}</p>
                                            {graph_competency_filter ?
                                            <CloseCircleFilled style={{fontSize:"20px",color:"#D3D3D3",cursor:"pointer"}}
                                            onClick={()=>onClearFilter()}/>:
                                            <img src={ca_detail_icons?.dropdown_Arrow} alt="" />}
                                            
                                        </Button>
                                    </Dropdown>
                            <CustomYearPicker {...props} onDateChange={(e)=>{onDateChange(e)}} toAnnual={(e)=>{changeGraph(e)}} />
                        </div>
                        {
                            graph_type === 'bar' && (
                                <CompetencyBarChart {...props} bar_graph_data={bar_graph_data} graph_type={graph_type} />
                            )
                        }
                        {
                            graph_type === 'line' && (
                                <CompetencyLineChart  {...props} graph_data={line_graph_data} graph_type={graph_type} />
                            )
                        }
                    </div>
                    <CompetencyUserTableView type="user_table" {...props} />
                </div>
            </Spin>
        </div>
    )
}
export default UserCompetecnyAssessmentHomeView