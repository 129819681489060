import { compose } from "modules/core"
import { withApollo } from '@apollo/client/react/hoc';
import React from "react";
import CasperView from "../component/casper-view";
import { withSendMessage } from "./casper-operations";
import { ALL_MESSAGES } from "../query/casper-query.gql";
import { CASPER_SUBSCRIPTION } from "../query/casper-subscription.gql";

const CasperContainer = (props) => {
    const { client } = props;
    const [allMessages, setAllMessages] = React.useState({})
    let casperSub = undefined

    React.useEffect(() => {
        return () => {
            if (casperSub) {
                casperSub.unsubscribe();
            }
        }
    }, [])

    const getAllMessages = async () => {
        try {
            const { data } = await client.query({
                query: ALL_MESSAGES,
                fetchPolicy: 'network-only'
            })
            if (data.allMessages) {
                setAllMessages(data.allMessages)
            }
            if (!casperSub) {
                casperSub = client.subscribe({
                    query: CASPER_SUBSCRIPTION,
                }).subscribe({
                    next(result) {
                        let sub_data = result.data.casperSubscription?.casper
                        setAllMessages(prev_value => {
                            const index =prev_value.edges.findIndex(ele => !ele?.node.hasOwnProperty('id') && ele.node.userMsg === sub_data.userMsg);
                            if (index !== -1) {
                                prev_value.edges[index].node = sub_data
                            }
                            return ({ ...prev_value })
                        })
                    }
                });
            }
        } catch (error) {

        }
    }
    
    React.useEffect(() => {
        getAllMessages()
    }, [])

    return (
        <CasperView allMessages={allMessages} setAllMessages={setAllMessages} {...props} />
    )
}

export default compose(withApollo, withSendMessage)(CasperContainer)
