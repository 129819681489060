import { jobfamily_icons } from 'modules/role_definition/icon';
import React from 'react';
import { ca_detail_icons } from '../icon';
import { displayUtcToUserTimezone, getIntFromString } from 'modules/look';
import { threshold_colors } from "../color-config";

const CompetencyUserTableView = ( props ) => {
    const { assessment_details, rating_list, show_extra_column,type } = props
    const getColor = ( threshold, ui_type ) => {
        let doc = rating_list?.find( assessment =>getIntFromString(assessment?.id) === threshold )

        if ( ui_type === 'background' ) {
            return doc ? `rgba(${threshold_colors[ `threshold_${doc?.sequence}` ] || threshold_colors[ `threshold_4` ]},0.1)` : '#fff'
        }
        else if ( ui_type === 'border' && doc ) {

            return `rgba(${threshold_colors[ `threshold_${doc?.sequence}` ] || threshold_colors[ `threshold_4` ]})`
        }
    }

    const mostRepeatedKey = (arr) => {
        let list = {}
        arr.forEach(({node}) => {
            if (list[`id_${node?.assessmentbarsSet?.edges[0]?.node?.qualifyingLevelId}`]) {
                list[`id_${node?.assessmentbarsSet?.edges[0]?.node?.qualifyingLevelId}`].count++
            } else {
                list[`id_${node?.assessmentbarsSet?.edges[0]?.node?.qualifyingLevelId}`] = {
                    count: 1,
                    ...node
                }
            }
        })
        let higest_count = Object.values(list)?.sort((a, b) => b.count - a.count)[0]?.count
        let data = Object.values(list)?.filter(item => item?.count === higest_count).sort((a, b) => b.qualifyingLevelSequence - a.qualifyingLevelSequence )
        return data[0]
    }

    return (
        <div>
            <div className='ca-table-container'>
                <table className='ca-table'>
                    <thead>
                        <tr className='ca-row-header'>
                            {!show_extra_column && <th className='ca-table-row-padding-left ca-left-radious'>
                                <h4 className='ca-table-header-label' style={{ fontSize: "1.35em" }}>Competency</h4>
                            </th>}
                            {show_extra_column && <th className='ca-table-row-padding-left ca-left-radious'>
                                <h4 className='ca-table-header-label' style={{ fontSize: "1.35em" }}>Employee</h4>
                            </th>}
                            {show_extra_column && <th className='ca-table-header-label'  style={{ textAlign: "center" }}>
                                <h4 className='ca-table-header-label' style={{ fontSize: "1.35em" }}>Role</h4>
                            </th>}
                            {show_extra_column && <th className='ca-table-header-label'  style={{ textAlign: "center" }}>
                                <h4 className='ca-table-header-label' style={{ fontSize: "1.35em" }}>Level</h4>
                            </th>}
                            <th className='ca-table-header-label' style={{ textAlign: "center" }}>
                                <h4 className='ca-table-header-label' style={{ fontSize: "1.35em" }}>Rating</h4>
                            </th>
                            <th className='ca-table-header-label ca-right-radious' style={{ textAlign: "center" }}>
                                <h4 className='ca-table-header-label' style={{ fontSize: "1.35em" }}>Date & time</h4>
                            </th>
                        </tr>
                    </thead>
                    {type!=='user_table' ?<tbody>
                        {assessment_details?.map( ( item ) => (
                            <tr className='ca-row-body'>
                                <td className='ca-table-row-padding-left ca-left-radious'>
                                    <h4 className={`ca-table-body-label ${item?.employee?.user?.id?'text-transform-capitalize':''}`}>{item?.employee?.user?.id?item?.employee?.user?.firstName:item?.employeeEmail}</h4>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    <h4 className='ca-table-body-label text-transform-capitalize'>{item?.role?.title}</h4>
                                </td>
                                <td  style={{ textAlign: "center" }}>
                                    <h4 className='ca-table-body-label'>{item?.employeeLevel}</h4>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    <h4 className='ca-table-body-label'><button className='ca-table-rating-btn' style={{ border: `1px solid ${getColor( mostRepeatedKey(item?.competencyassessmentSet?.edges)?.assessmentbarsSet?.edges[0]?.node?.qualifyingLevelId, 'border' )}`, background: getColor( mostRepeatedKey(item?.competencyassessmentSet?.edges)?.assessmentbarsSet?.edges[0]?.node?.qualifyingLevelId, 'background' ) }}>{mostRepeatedKey(item?.competencyassessmentSet?.edges)?.assessmentbarsSet?.edges[0]?.node?.qualifyingLevelName}</button></h4>
                                </td>
                                <td className=' ca-right-radious' style={{ textAlign: "center" }}>
                                    <h4 className='ca-table-body-label'>{displayUtcToUserTimezone( item?.createdAt, 'DD-MMM-YY HH:MM:A' )}</h4>
                                </td>
                            </tr>
                        ) )}
                    </tbody>:
                    <tbody>
                    {assessment_details?.map(item=>item?.competencyassessmentSet?.edges?.map( ( {node} ) => (
                        <tr className='ca-row-body'>
                            <td className='ca-table-row-padding-left ca-left-radious'>
                                <h4 className='ca-table-body-label'>{node?.competency?.title || node?.mainCompetency?.title}</h4>
                            </td>
                            <td style={{ textAlign: "center" }}>
                                <h4 className='ca-table-body-label'><button className='ca-table-rating-btn' style={{ border: `1px solid ${getColor( node?.assessmentbarsSet?.edges[0]?.node?.qualifyingLevelId, 'border' )}`, background: getColor( node?.assessmentbarsSet?.edges[0]?.node?.qualifyingLevelId, 'background' ) }}>{node?.assessmentbarsSet?.edges[0]?.node?.qualifyingLevelName}</button></h4>
                            </td>
                            <td className=' ca-right-radious' style={{ textAlign: "center" }}>
                                <h4 className='ca-table-body-label'>{displayUtcToUserTimezone( item?.createdAt, 'DD-MMM-YY HH:MM:A' )}</h4>
                            </td>
                        </tr>
                    ) ))}                   
                </tbody>}
                </table>
               {assessment_details?.length===0&&<div style={{display:"flex",justifyContent:"center",alignItems:'center',marginTop:"5em"}}>
                    <h4 className='ca-table-body-label'>No Data Found</h4>
                </div>}
            </div>
        </div>
    )
}

export default CompetencyUserTableView