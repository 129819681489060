// @ts-nocheck
import React from 'react';
import { Button, Spin, Table, Modal, Tooltip } from "antd";
import styled from 'styled-components';
import { Capitalize, getIntFromString } from "../../look/components/functions";
import Filtermenu from './filter'
import readiness_view from '../../assets/rediness-view-icon.svg'
import ReadinessDetailComponent from '../containers/ReadinessDetailComponent';
import readiness_submitted_icon from '../../assets/readiness-submitted-icon.svg';
import readiness_received_icon from '../../assets/readiness-received-icon.svg'
import ReadinessSearchView from './filter';
import readiness_play from '../../assets/readiness-play-blue-icon.svg'
import VideoPreview from 'modules/look-v2/Components/video-preview';
import { readiness_demo_video_url } from '../../../config'
const ReadinessAssessmentView = props => {
    const {
        me,
        dataSource,
        onfilter,
        page_info_assessment,
        assessment_loader,
        permission
    } = props;

    const [viewReadiness, setViewReadiness] = React.useState()
    const [open_info_modal, setOpenInfoModal] = React.useState(false)


    const SubmitFilter = (data) => {
        if (data && !assessment_loader) {
            let filter = {}
            filter_data.filter(i => i?.key != Object.keys(data)[0]).forEach(i => {
                filter[i.key] = ""
            })
            filter = { ...filter, ...data, after: null }
            onfilter(filter)
        }
    }

    const filter_data = [
        {
            key: "search",
            ui_flow: "input_box",
            label: "Name",
            default: true
        },
        {
            key: "readinesslevel_Skill_Title_Icontains",
            ui_flow: "input_box",
            label: "Skill",
        }
    ]

    const ReadinessSubmitedByMe = (me, readinessById) => {
        if (me?.employee?.user?.id === readinessById?.readinessFrom?.user?.id) {
            return true
        }
        else {
            return false
        }
    }

    const getStatus = (zone) => {
        return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
            <Tooltip title={zone?.zone}>
                <h4 className={`readiness-zone-btn  ${zone?.color}-readiness-zone`} style={{ textTransform: "capitalize" }}>{zone?.color}</h4>
            </Tooltip>
        </div>
    }

    const onViewMore = () => {
        let filter = {
            after: {
                received_after: page_info_assessment?.after?.received_after,
                submitted_after: page_info_assessment?.after?.submitted_after,
            }
        }
        onfilter(filter)
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', gap: '1em', marginTop: "20px", alignItems: "center", flexWrap: "wrap" }} >
                {
                    readiness_demo_video_url?
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: ".5em", cursor: "pointer", width: "fit-content" }}
                            onClick={() => setOpenInfoModal(true)}>
                            <img src={readiness_play} alt="readiness_play" style={{ width: "20px" }} />
                            <h4 className='readiness-link-text'>Watch how readiness zone works</h4>
                        </div>:<div style={{width:"1px"}}></div>
                    
                }


                <ReadinessSearchView filterData={filter_data} onfilterChange={e => SubmitFilter(e)} {...props} />
            </div>
            <div style={{ marginTop: '20px' }}>
                <Spin spinning={assessment_loader}>
                    <div className='rd-table-container'>
                        <table className='rd-table'>
                            <thead>
                                <tr className='rd-row-header'>
                                    <th className='rd-table-row-padding-left rd-left-radious' style={{ width: "30%" }}>
                                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Name</h4>
                                    </th>
                                    <th className='rd-table-header-label' style={{ width: "30%" }}>
                                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em", textAlign: "center" }}>Skills</h4>
                                    </th>
                                    <th className='rd-table-header-label' style={{ textAlign: "center", width: "20%" }}>
                                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Readiness Zone</h4>
                                    </th>
                                    <th className='rd-table-row-padding-rigth rd-content-center rd-table-header-label rd-right-radious' style={{ textAlign: "center", width: "20%" }}>
                                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Actions</h4>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataSource?.map(item => (
                                        <tr className='rd-row-body'>
                                            <td className='rd-table-row-padding-left rd-left-radious table-border-left' style={{ width: "30%" }}>
                                                {
                                                    ReadinessSubmitedByMe(me, item) ?
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: "1em" }}>
                                                            <Tooltip overlayClassName='readiness-tooltip' placement="right" title={`Submitted (You have assessed ${Capitalize(item?.readinessTo?.user?.firstName)} ${Capitalize(item?.readinessTo?.user?.lastName)}'s Readiness Level)`}>
                                                                <img src={readiness_submitted_icon} alt="readiness_submitted_icon" />
                                                            </Tooltip>
                                                            <h4 className='rd-table-body-label' style={{ fontSize: "1.25em" }}>{Capitalize(item?.readinessTo?.user?.firstName)} {Capitalize(item?.readinessTo?.user?.lastName)}</h4>
                                                        </div> :
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: "1em" }}>
                                                            <Tooltip overlayClassName='readiness-tooltip' placement="right" title={`Received (${Capitalize(item?.readinessFrom?.user?.firstName)} ${Capitalize(item?.readinessFrom?.user?.lastName)} has assessed your Readiness Level)`}>
                                                                <img src={readiness_received_icon} alt="readiness_submitted_icon" />
                                                            </Tooltip>
                                                            <h4 className='rd-table-body-label' style={{ fontSize: "1.25em" }}>{Capitalize(item?.readinessFrom?.user?.firstName)} {Capitalize(item?.readinessFrom?.user?.lastName)}</h4>
                                                        </div>
                                                }
                                            </td>
                                            <td style={{ width: "30%" }} className='table-border-up-down'>
                                                <div style={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <h4 className='rd-table-body-label' style={{ textAlign: "center", fontSize: "1.25em" }}>{item?.zone_detail && item?.zone_detail[0]?.skill?.title}</h4>
                                                </div>
                                            </td>
                                            <td style={{ textAlign: "center", width: "20%" }} className='table-border-up-down'>
                                                {getStatus(item?.zone_detail?.length ? item?.zone_detail[0]?.zone : { color: "green" })}
                                            </td>
                                            <td className='rd-table-row-padding-rigth rd-content-center rd-right-radious table-border-right' style={{ width: "20%" }}>
                                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                    <Button className='readiness-view-btn' style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', gap: ".25em" }}
                                                        onClick={() => setViewReadiness(item)}>
                                                        <img src={readiness_view} alt="readiness_view" style={{ width: "15px" }} /> <span style={{ margin: "0px" }}>View</span>
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        {
                            (page_info_assessment && page_info_assessment?.hasNextPage && dataSource?.length && !assessment_loader) ?
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2em" }}>
                                    <h4 onClick={() => onViewMore()} className='pagination-label' style={{ cursor: "pointer" }}>View More</h4>
                                </div>
                                : ''
                        }
                        {
                            (!dataSource?.length && !assessment_loader) ?
                                <div className='rd-nodata'>
                                    <h4 className='rd-nodata-label'>No data found</h4>
                                </div> : ''
                        }
                    </div>
                </Spin>
            </div>
            <Modal
                visible={viewReadiness}
                footer={false}
                closable={false}
                width={"90%"}
                centered={true}
                destroyOnClose={true}
                className='readiness-modal'>
                <ReadinessDetailComponent {...props} tab="assessments" readiness_detail={viewReadiness} onClose={() => setViewReadiness()} />
            </Modal>
            <Modal
                visible={open_info_modal}
                footer={false}
                closable={false}
                width={"90%"}
                centered={true}
                destroyOnClose={true}
                className='video-preview-modal'>
                <VideoPreview {...props} onClose={() => { setOpenInfoModal(false) }} videoUrl={readiness_demo_video_url} />
            </Modal>
        </>
    );
};
export default ReadinessAssessmentView;

export const MyTable = styled(Table)`
  table {
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;