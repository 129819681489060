import React from "react"
import "../../css/poc-report-style.css"
import { displayUtcToUserTimezone, ReactMarkdown } from "modules/look"
import { Button, Spin, Table, Tooltip } from "antd"
import { dashboard_home_icons, organization_icons } from "modules/poc-dashboard/icons"
import GenericToolView from "modules/tool/components/GenericToolView"
import styled from "styled-components"
import { Bar } from "react-chartjs-2"
const PocUserReportView = (props) => {
    const { ui_flow_type, list, header_list, loading, basic_detail, navigateRoute, getToolById, downloadCSV } = props

    const [ReportList, setReportList] = React.useState([]);
    React.useEffect(() => {
        if (getToolById) {
            console.log('getToolById', getToolById);

            if (getToolById?.self?.length) {
                let report = getToolById
                let selfList = getToolById?.self
                selfList.forEach(element => {
                    element['overAll'] = report?.overall.filter(item => element?.answer__question__group__id === item?.answer__question__group__id)
                    element['respondents'] = report?.respondents.filter(item => element?.answer__question__group__id === item?.answer__question__group__id)
                });
                setReportList(selfList)
            }
        }
    }, [getToolById])


    const ToDecimal = (value) => {
        if (value % 1) {
            return value.toFixed(1)
        }
        else {
            return value
        }
    }
    const chartData = (chart) => {

        let Values = []
        let labels = []
        let dataSource = []
        const columns = [
            {
                title: <Tooltip title="Type of Respondent">TYPE</Tooltip>,
                key: 'TYPE',
                render: (text, record) => record &&
                    <Tooltip title={record?.type}>
                        <p style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: '5px' }}>{record?.type}</p>
                    </Tooltip>
            },
            {
                title: <Tooltip title="Average">AVG</Tooltip>,
                key: 'AVG',
                render: (text, record) => record &&
                    <p style={{ marginTop: '5px' }}>{record?.AVG}</p>
            },
            {
                title: <Tooltip title="Standard Deviation">SD</Tooltip>,
                key: 'SD',
                render: (text, record) => record &&
                    <p style={{ marginTop: '5px' }}>{record?.SD}</p>
            },

            {
                title: <Tooltip title="High">HI</Tooltip>,
                key: 'HI',
                render: (text, record) => record &&
                    <p style={{ marginTop: '5px' }}>{record?.HI}</p>
            },
            {
                title: <Tooltip title="Low">LO</Tooltip>,
                key: 'LO',
                render: (text, record) => record &&
                    <p style={{ marginTop: '5px' }}>{record?.LO}</p>
            }
        ]
        if (chart?.overAll?.length) {
            labels.push('OverAll')
            const Avg = chart?.overAll.reduce((a, b) => a + b?.Avg_response, 0)
            Values.push(Avg)
            let tableOverall = {
                type: 'OverAll',
                AVG: ToDecimal(Avg),
                SD: ToDecimal(chart?.overAll.reduce((a, b) => a + b?.StdDev_response, 0)),
                HI: ToDecimal(chart?.overAll.reduce((a, b) => a + b?.Max_response, 0)),
                LO: ToDecimal(chart?.overAll.reduce((a, b) => a + b?.Min_response, 0)),
            }
            dataSource.push(tableOverall)
        }

        if (chart?.respondents?.length) {

            chart.respondents.forEach(element => {
                const Avg = element?.Avg_response
                labels.push(element?.respondentType)
                Values.push(Avg)
                let tablerespondents = {
                    type: element?.respondentType,
                    AVG: ToDecimal(Avg),
                    SD: ToDecimal(element.StdDev_response),
                    HI: ToDecimal(element?.Max_response),
                    LO: ToDecimal(element.Min_response),
                }
                dataSource.push(tablerespondents)
            });
        }



        labels.push('Self')
        Values.push(chart?.Avg_response)
        let tableSelf = {
            type: 'Self',
            AVG: ToDecimal(chart?.Avg_response),
            SD: ToDecimal(chart?.StdDev_response),
            HI: ToDecimal(chart?.Max_response),
            LO: ToDecimal(chart?.Min_response),
        }
        dataSource.push(tableSelf)
        const chartData = {
            labels: labels,
            datasets: [{
                data: Values,
                backgroundColor: "rgba(76, 203, 31, 1)",
                borderColor: "rgba(76, 203, 31, 1)",

            }]
        };
        let maxValue = ((parseInt(Math.max(...Values) / 10) + 1) * 10)

        const options = {
            responsive: true,
            scales: {
                x: {
                    min: 0,
                    max: maxValue > 100 ? maxValue : 100
                }
            },
            indexAxis: 'y',
            elements: {
                bar: {
                    borderWidth: 1,
                    borderHeight: 1
                },
            },
            plugins: {
                legend: {
                    position: 'bottom',
                    display: false
                },
                title: {
                    display: false,
                }
            },
        };
        return (
            <>
                <div className='report-chart-table-container'>
                        <div className='report-grap-container'>
                            <Bar data={chartData} options={options} height={dataSource?.length * 30}/>
                        </div>
                        <div className='report-table-container'>
                        <MyTable
                            size={'middle'}
                            loading={loading}
                            showHeader={true}
                            pagination={false}
                            style={{width:"100%"}}
                            dataSource={
                                dataSource
                                    ? dataSource
                                    : []
                            }
                            columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}

                        />
                        </div>
                        
                </div>
           
            </>
        )
    }


    return (
        <>


            {loading ?
                <div style={{ width: "100%", height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin spinning={loading}> </Spin>
                </div>
                :

                basic_detail && (
                    <>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", gap: "1em", alignItems: "center" }}>
                            <img src={organization_icons?.back_arrow} alt="back_arrow" style={{ width: "25px", cursor: "pointer" }} onClick={() => navigateRoute("-1")} />
                            <h4 className="poc-dashboard-card-title" style={{ fontWeight: "600" }}>Report for {basic_detail?.survey_name}</h4>
                        </div>
                        <div className="poc-survey-report-root">
                            <div className="space-between-row">
                                <div>
                                    <h4 className="poc-dashboard-card-title" style={{ fontWeight: "600" }}><span className="text-transform-capitalize">{basic_detail?.user?.firstName}</span> <span className="text-transform-capitalize">{basic_detail?.user?.lastName || ""}</span></h4>
                                    <h4 className="poc-dashboard-card-title" style={{ fontWeight: "400", color: "rgba(137, 152, 162, 1)", fontSize: ".75em" }}>Date : {displayUtcToUserTimezone(basic_detail?.response_date, 'DD/MM/YYYY')}</h4>
                                </div>
                                {
                                    <div style={{ display: "flex", flexDirection: "row", gap: "0.5em", justifyContent: "center", alignItems: "center" }}>
                                        {
                                            (ui_flow_type == "EXPECTED_ANSWER" || ui_flow_type == "NORMAL_ANSWER") && (
                                                <Button className="export-btn" onClick={() => downloadCSV()}><img src={dashboard_home_icons?.export_icon} alt="export_icon" style={{ height: "1.25em", marginRight: "1em" }} /> Export</Button>
                                            )
                                        }

                                        {basic_detail?.show_score && (
                                            <h4 className="poc-dashboard-card-title">Total Score : <span style={{ color: basic_detail?.total_score <= 7 ? "red" : "#31D0AA" }}>{basic_detail?.total_score}/{basic_detail?.max_score}</span></h4>
                                        )}

                                    </div>

                                }
                            </div>
                            {
                                (ui_flow_type == "EXPECTED_ANSWER" || ui_flow_type == "NORMAL_ANSWER") && (
                                    <div className={`org-table-container poc_table_scroll module-usage-table-height`} >
                                        <table className='org-table'>
                                            <thead>
                                                <tr className='org-row-header'>
                                                    {
                                                        header_list?.map((i, index) => (
                                                            <th className={`org-table-header-label ${index == 0 ? "org-left-radious" : ""} ${index == (header_list?.length - 1) ? "org-right-radious" : ""}`} style={{ textAlign: index == 0 ? "left" : "center", ...i?.custom_style }}>
                                                                <h4 className='org-table-header-label'>{i?.label}</h4>
                                                            </th>
                                                        ))
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list?.map(item => (
                                                    <tr className='org-row-body'>
                                                        {
                                                            item?.map((i, index) => (
                                                                <td className={`${(index != 0 && index != (header_list?.length - 1)) ? "table-border-up-down" : ""} ${index == 0 ? "org-left-radious table-border-left" : ""} ${index == (header_list?.length - 1) ? "org-right-radious table-border-right" : ""}`} style={{ textAlign: "center" }}  >
                                                                    {
                                                                        i?.type == "label" && (
                                                                            <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{i?.value}</h4>
                                                                        )
                                                                    }

                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        {
                                            (!list?.length && !loading) ?
                                                <div style={{ width: "100%", height: "5em" }}>
                                                    <h4 className='rd-nodata-label' style={{ textAlign: "center" }}>No data found</h4>
                                                </div> : ''
                                        }
                                    </div>
                                )
                            }
                            {
                                ui_flow_type == "REPORT_TOOL" && (
                                    <div style={{ width: "100%", marginTop: "2em" }}>
                                        <div>
                                            {ReportList?.length ?
                                                <div >

                                                    {
                                                        ReportList.map(chart =>
                                                            // <div className="assesment-card">
                                                            <div className="assesment-card">
                                                                <div className='assessment-report-container'>
                                                                    <div className='assessment-report-text-container' style={{ width: "50%" }}>
                                                                        <h4 className="poc-dashboard-card-title" style={{ fontWeight: "normal", fontSize: "1.25em" }}><ReactMarkdown>{chart?.answer__question__group__label}</ReactMarkdown></h4>
                                                                        <h4 className="poc-dashboard-card-title" style={{ fontWeight: "400", fontSize: "1em", whiteSpace: "wrap" }}><ReactMarkdown>{chart?.answer__question__group__help_text}</ReactMarkdown></h4>
                                                                    </div>
                                                                    <div style={{ width: "fit-content" }}>
                                                                        {chartData(chart)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            // </div>
                                                        )
                                                    }

                                                </div>
                                                :
                                                !loading && <h3 style={{ textAlign: 'center', marginTop: '200px' }}>NO 360 REPORTS FOUND</h3>
                                            }
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </>
                )}

        </>
    )

}

export default PocUserReportView


export const MyTable = styled(Table)`
  table {
    border-collapse: collapse !important;
    th{
        text-align:center !important;
         border: 1px solid rgba(170, 186, 198, 1)!important;
        font-weight:600;
        font-family:Poppins,
        font-size:1em;
        padding-bottom:5px !important;
        background:#fff !important;
    }
    tbody {
            border: 1px solid rgba(170, 186, 198, 1)!important;
            background: rgba(213, 229, 242, 0.2);
        tr{
        border: 1px solid rgba(170, 186, 198, 1)!important;
        border-style: solid none !important;
        }
      td{
        font-size:1em;
        text-align:center !important;
        font-weight:400;
        font-family:Poppins;
        border: 1px solid rgba(170, 186, 198, 1)!important;
      } 
    p{
    margin:0px !important;
    }
    .ant-table-cell {
    padding: 5px 10px !important;

  }
`;
