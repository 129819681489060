// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
// import { employeeList } from '../../feedback/containers/feedbackOperations'
import { withCreateMeeting,employeeList } from '../../one-on-one/containers/One_on_oneOperations';
import { Row, Col, Button,Tooltip,Menu,Spin,Input,Form , Dropdown,Checkbox,Popover  } from "antd";
import styled from "styled-components";
import { LeftOutlined,DownOutlined,CloseOutlined,DeleteOutlined } from "@ant-design/icons";
import MultiSelectEmployee from '../../one-on-one/components/MultiSelectEmployee'
import { withApollo } from '@apollo/client/react/hoc';
import { GET_MANAGER_EMPLOYEE_LIST } from '../../one-on-one/graphql/getManagersandEmployee.gql';
import { GET_MANAGER_EMPLOYEE_LIST_SEARCH } from '../../one-on-one/graphql/getEmployeSerach.gql';
import { MetaTags, getIntFromString, scrolltoview, DatePickerField } from "../../look";
import { withUpdateContributorRequest } from '../container/objective-keyresult-operations';
import moment from 'moment-timezone';
import { LoadingOutlined } from '@ant-design/icons';
import Close from '../../assets/close.svg'
import CustomTimePicker from 'modules/look-v2/Components/custom-time-picker';
import calender_icon from '../../assets/meeting-calender-icon.svg'
import time_icon from '../../assets/time-icon.svg'
const QuickActonItem = props => {
    const {allOrganizationEmployees,me,createMeeting,onCloseQuick,allEmpoloyee,client,updateContributorRequestStatus,createActionItem,data,location,requestDetail}=props
    const [title, setTitle] = React.useState();
    const [titleInvalid, settitleInvalid] = React.useState(false);
    const [employeeList,setemployeeList]= React.useState([]);
    const [memberlist,setmemberlist]= React.useState([]);
    const [memberListInvalid, setmemberListInvalid] = React.useState(false);
    const [loading, setloading] = React.useState(false); 
    const [form] = Form.useForm();
    const [visible,setVisible]=React.useState(false)
    const [searchEmployee,setSearchEmployee]=React.useState()
    const [when, setwhen] = React.useState();
    const [dateInvalid, setdateInvalid] = React.useState(false);
    const [selectedCustomTime, setSelectedCustomTime] = React.useState(null);
    const [selected_time,setSelectedTime]=React.useState()
    const [okr_owner,setOkerOwner]=React.useState()
    const ref = React.useRef(null);
      React.useEffect(()=>{
        if(requestDetail){
          setOkerOwner([{
            ...requestDetail?.createdBy?.user,
            employee:{
              id:requestDetail?.createdBy?.id
            }
          }])
        }
      },[requestDetail])
      React.useEffect(()=>{
        if(me){
          AllEmployeeList()
          AllEmployeeListSearch()
        }
      },[me])

      const AllEmployeeList= async()=>{
        const { data } = await client.query({
          query: GET_MANAGER_EMPLOYEE_LIST,
          fetchPoicy:'network-only'
          });
          if(data?.allEmpoloyee){
            let allEmpoloyee =data?.allEmpoloyee
            if(allEmpoloyee){
              let list
              let teamMembersEmployee = [].concat.apply(
                [],allEmpoloyee?.employee?.teamMembersEmployee?.edges.map(({node})=>node?.managers?.edges.map(({node})=>node?.user)))
              let teamManager= [].concat.apply(
                [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.vertical?.heads?.edges.map(({node})=>node?.user)))
              let teamManagerEmployee= [].concat.apply(
                  [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.employees?.edges.map(({node})=>node?.user)))
              let orgPocEmployee = [].concat.apply(
                  [],allEmpoloyee?.employee?.orgPocEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
              let orgCeoEmployee=[].concat.apply(
                [],allEmpoloyee?.employee?.orgCeoEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
  
              list=[].concat(teamMembersEmployee,teamManager,teamManagerEmployee,orgPocEmployee,orgCeoEmployee)
              let data =list.filter((value, index, self) =>
              index === self.findIndex((t) => (
                t.firstName === value.firstName && t.id === value.id
              )))
              let filtered = data.filter(item=>item?.employee?.id!==me?.employee?.id)
              setemployeeList(filtered)
            }
          }   
      }

      const AllEmployeeListSearch= async(searchValue)=>{
        const { data } = await client.query({
          query: GET_MANAGER_EMPLOYEE_LIST_SEARCH,
          variables: {user_LastName: searchValue,user_FirstName: searchValue},
          fetchPoicy:'network-only'
          });
          let lastFilter
          let firstFilter
          if(data?.first){
            let allEmpoloyee =data?.first
            if(allEmpoloyee){
              let list
              let teamMembersEmployee = [].concat.apply(
                [],allEmpoloyee?.employee?.teamMembersEmployee?.edges.map(({node})=>node?.managers?.edges.map(({node})=>node?.user)))
              let teamManager= [].concat.apply(
                [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.vertical?.heads?.edges.map(({node})=>node?.user)))
              let teamManagerEmployee= [].concat.apply(
                  [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.employees?.edges.map(({node})=>node?.user)))
              let orgPocEmployee = [].concat.apply(
                  [],allEmpoloyee?.employee?.orgPocEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
              let orgCeoEmployee=[].concat.apply(
                [],allEmpoloyee?.employee?.orgCeoEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
  
              list=[].concat(teamMembersEmployee,teamManager,teamManagerEmployee,orgPocEmployee,orgCeoEmployee)
              let data =list.filter((value, index, self) =>
              index === self.findIndex((t) => (
                t.firstName === value.firstName && t.id === value.id
              )))
              firstFilter = data.filter(item=>item?.employee?.id!==me?.employee?.id)
            }
          }
          if(data?.last){
            let allEmpoloyee=data?.last
            if(allEmpoloyee){
              let list
              let teamMembersEmployee = [].concat.apply(
                [],allEmpoloyee?.employee?.teamMembersEmployee?.edges.map(({node})=>node?.managers?.edges.map(({node})=>node?.user)))
              let teamManager= [].concat.apply(
                [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.vertical?.heads?.edges.map(({node})=>node?.user)))
              let teamManagerEmployee= [].concat.apply(
                  [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.employees?.edges.map(({node})=>node?.user)))
              let orgPocEmployee = [].concat.apply(
                  [],allEmpoloyee?.employee?.orgPocEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
              let orgCeoEmployee=[].concat.apply(
                [],allEmpoloyee?.employee?.orgCeoEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
  
              list=[].concat(teamMembersEmployee,teamManager,teamManagerEmployee,orgPocEmployee,orgCeoEmployee)
              let data =list.filter((value, index, self) =>
              index === self.findIndex((t) => (
                t.firstName === value.firstName && t.id === value.id
              )))
              lastFilter = data.filter(item=>item?.employee?.id!==me?.employee?.id)
            }
            let user =firstFilter.concat(lastFilter)
            var setObj = new Set(); // create key value pair from array of array
            var result = user.reduce((acc,item)=>{
              if(!setObj.has(item?.employee?.id)){
                setObj.add(item?.employee?.id)
                acc.push(item)
              }
              return acc;
            },[])
            console.log("result",result,"1111111111",requestDetail)
            setemployeeList(result)
          }   
      }
      const selectedStyle=(node)=>{
        let prev = memberlist.find(item=>item?.employee?.id===node?.employee?.id)
        if(prev){
          return true
        }else{
          return false
        }
      }
    
      const handleMenuClick=(members)=>{
        setmemberlist(members)
        if(members?.length){
          setTitle(`${<span className='text-transform-capitalize'>{me?.firstName}</span>} and ${<span className='text-transform-capitalize'>{members[0]?.firstName}</span>}'s 1:1`)
        }
    
      }

      const handleSubmit=async()=>{
        if(title && memberlist?.length&&when){
        setloading(true)
          settitleInvalid(false)
          setmemberListInvalid(false)
          let selected_date_time
          if(when&&selected_time){
            console.log("selected_time",selected_time)
            let date =moment(when).format("YYYY-MM-DD")
            const roundedTime = moment({
              hour: selected_time.hour(),
              minute: Math.ceil( selected_time.minute() / 15) * 15,
            }).format("HH:mm");
            selected_date_time =`${date} ${roundedTime}`
            console.log("selected_date_time",selected_date_time)
          }
          else if (!selected_time){
            if(moment(when._d).minute()<45){
              let date =moment(when).format("YYYY-MM-DD")
              let roundedTime = moment({
                hour:  moment(when._d).hour(),
                minute: Math.ceil( moment(when._d).minute() / 15) * 15,
              }).format("HH:mm");
              selected_date_time =`${date} ${roundedTime}`
            }
            else{
              let date =moment(when).format("YYYY-MM-DD")
              let time_diff = 60-Math.ceil(moment(when._d).minute())
              let new_time =moment(when._d).add(time_diff, 'minutes')
              let roundedTime = moment({
                hour:moment(new_time).hour(),
                minute: Math.ceil(moment(new_time).minute() / 15) * 15,
              }).format("HH:mm"); 
              selected_date_time =`${date} ${roundedTime}`
            }
          }
          let meetingData= {
            title:title,
            meetingType: "One-On-One",
            when:moment(selected_date_time).utc().toISOString()
          }
          let members = memberlist.map(user=>getIntFromString(user?.employee?.id))
          members.push(getIntFromString(me?.employee?.id))
          const response = await createMeeting({members:members,meetingData:meetingData});
          if(response && response?.id){
            let actionItems={
                title: `Discuss ${data?.okr?.goalStatement}`,
                meetingId: getIntFromString(response?.id),
                okrId: getIntFromString(data?.okr?.id),
                isAgenda: true,
                notes: `<a href=${window?.origin}${location?.pathname} target="_blank">Go to ${data?.okr?.goalStatement}</a>`
            }
            let action = await createActionItem({actionItemData:actionItems})
            if(action){
                let updateCotributors= await updateContributorRequestStatus({id:getIntFromString(data?.id),requestStatus:"NEEDS_DISCUSSION"})
                if(updateCotributors){
                    setloading(false)
                    onCloseQuick({close:true,meeting:updateCotributors})
                }
                
            }
          }else{
            setloading(false)
          }
        }
        else{
          if(!title){
            settitleInvalid(true)
            ref.current.focus();
          }
          if(!memberlist?.length){
            setmemberListInvalid(true)
            ref.current.focus();
          }
          if(!when){
            setdateInvalid(true)
            ref.current.focus();
          }
        }
      }

      const memberName =()=>{
        let name= memberlist.map(item=>item?.firstName)
        return name.join(',')
      }

      const handleTitleSubmit=(e)=>{
        let title =e.target.value
        if(title.length>=200){
          setVisible(true)
          settitleInvalid(true)
        }
        else{
          setVisible(false)
          settitleInvalid(false)
        }
        setTitle(e.target.value)
      }

      const hide=()=>{
        setVisible(false)
      }

      const searchInput=(value)=>{
          AllEmployeeListSearch(value)
      }
      const disabledHours = () => {
        const hours = [];
        if(when && moment(when).isAfter(moment())){
          return hours;
        }
        else{
          for (let i = 0; i < moment().hour(); i += 1) hours.push(i);
          return hours;
        } 
      };

      const disabledMinutes = (selectedHour) => {
        const minutes = [];
        if(when && moment(when).isAfter(moment())){
          return minutes;
        }else{
          if (selectedHour === moment().hour()) {
            for (let i = 0; i < moment().minute(); i += 1) minutes.push(i);
          }
          return minutes;
        }
      
      };

      function disabledRangeTime(value) {
        if(value && moment(value).isAfter(moment())){
      
        }
        else{
          return {
            disabledHours: () => disabledHours(),
            disabledMinutes: (selectedHour) => disabledMinutes(selectedHour),
          };
        }
      }

      const handleTimeChange = (time) => {
        let date =moment(when).format("YYYY-MM-DD")
        let selected_date_time =`${date} ${moment(time, ['h:mm A']).format('HH:mm')}`
        setSelectedTime(moment(selected_date_time))
        setSelectedCustomTime(time);
      };

      return (
          <>
            <Spin spinning={loading}>
                <RowDiv style={{position:"relative"}}>
                <h4 className='modal-head'>Create 1 on 1 Meeting</h4>
                <img src={Close} alt="" style={{cursor:"pointer",top:'0px',position:"absolute ",right:"0px"}} onClick={()=>{onCloseQuick({close:true})}}/>
                </RowDiv>
                 <Form
                form={form}
                name={"1:1"}
                onFinish={() =>{handleSubmit()} }
                >
                    <Col span={24}>
                    <p style={{fontSize:'15px'}}><span style={{color:'#ff4d4f'}}>*</span> 1:1 with</p>
                        <MultiSelectEmployee
                            memberListInvalid={memberListInvalid}
                        dataList = {okr_owner?.length?okr_owner:employeeList}
                        onChange = {(e)=>{handleMenuClick(e)}}
                        searchKey={e=>searchInput(e)}
                        ref={ref}
                        />
                        {
                        memberListInvalid&& (
                            <p style={{color:'red',marginTop:'10px'}}>This field is required.</p>
                        )
                        }
                    </Col>
                    <Col span={24} style={{marginTop:'15px'}}>
                        <p style={{fontSize:'15px'}}><span style={{color:'#ff4d4f'}}>*</span> Title</p>
                        <Popover
                            content={<a onClick={hide}>Close</a>}
                            title="Character Limit Exceeded"
                            trigger="click"
                            visible={visible}
                            placement="topRight"
                        >
                        <Input
                            // disabled={memberlist?.length?false:true}
                            style={{
                              border: !titleInvalid ? '1px solid #D9D9D9' : '1px solid #ff4d4f',
                              borderRadius: "8px",
                              height: "40px",
                              paddingLeft: "42px"
                            }}
                            id="1_1_title_name"
                            value={title}
                            maxLength={200}
                            className="one-on-one-input-field-style"
                            onChange={(e) =>handleTitleSubmit(e)}    
                            onClick={(e)=>{scrolltoview('1_1_title_name')}}
                            ref={ref}
                            autoComplete='off'
                        />
                        </Popover>
                        {
                            titleInvalid&& (
                                <p style={{color:'red',marginTop:'10px'}}>This field is required.</p>
                            )
                        }
                    </Col>
                       <Row align='middle' style={{gap:'10px'}} className="meeting-create-selction">
                        <div style={{marginTop:"23px"}}>
                        <DatePickerField
                          ref={ref}
                          dropdownClassName="modal-datepicker-index"
                          suffixIcon={<img src={calender_icon} alt=""/>}
                          style={{ width: '100%',
                          borderRadius:"8px",
                          height:"40px",
                          border: !dateInvalid ? '' : '1px solid #ff4d4f'
                         }}
                        
                          format="YYYY-MM-DD"
                          onChange={e =>{(e?setwhen(e):setwhen(null));setdateInvalid(false)}}
                          id="1_1_when"
                          onClick={(e)=>{scrolltoview('1_1_when')}}
                          disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                          disabledTime={e=>disabledRangeTime(e)}
                          allowClear={false}
                          value={when?moment(when?._d):''}
                        />
                        </div>
                        <CustomTimePicker selectedTime={selectedCustomTime} onedit={false} disabled={when ? false : true} when={when} onChange={handleTimeChange} />
                      </Row>
                        {
                          dateInvalid&& (
                            <p style={{color:'red',marginTop:'-10px'}}>This field is required.</p>
                          )
                        }
              <div align="center">
                  <Button className='discuss-btn' htmlType="submit" style={{marginTop:'30px',maxWidth:"200px"}}>Save</Button>
              </div>
          </Form>
      </Spin>
          </>

      )
}

export default compose(withCreateMeeting,withApollo,withUpdateContributorRequest)(QuickActonItem);

const DropdownButton = styled(Button)`
width:100%;
display:flex;
justify-content:space-between;
align-items:center;
gap:5px
`
const RowDiv =styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`

const CreateButton = styled(Button)`
width:150px;
height:40px;
border-radius:5px;
margin-top:10px;
`