import { Button, Dropdown, Form, Menu, message, Spin, Switch } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";
import InformationModal from "modules/look-v2/Components/InformationModal";
const NotificationSettingsView = (props) => {
    const { notification_list, notification_settings, UpdateChanges, notification_loading, getUserNotification, onSave } = props
    const [form] = Form.useForm();
    const [showModel, setShowModel] = useState(false)
    const [do_not_disturb,setDoNotDisturb] = useState()
    const Filtermenu = (data) => {
        return (
            <Menu style={{ maxHeight: '200px', overflow: 'auto' }}>
                {
                    data && data?.values.map((i) => (
                        <Menu.Item key={i?.key} onClick={() => { UpdateChanges({ notification_data: data, selected_item: i }) }}>
                            {i?.title}
                        </Menu.Item>
                    ))
                }
            </Menu>
        );
    }

    const confirmModel=()=>{
        let updated_notification = Object.values(notification_settings)?.filter(i => i?.updated)

        if (!updated_notification?.length) {
            message.error("Please update the settings")
            return
        }
        setShowModel(true)
    }

    const handleToggleChange = (e) => {
        setDoNotDisturb(e)
      };

    return (
        <Spin spinning={notification_loading}>
            <div className="profile-notification-root">
                <div style={{width:"100%",display:'flex',flexDirection:"row",justifyContent:"space-between",alignItems:"center",gap:"1em",flexWrap:"wrap"}}>
                    <h4 className="proile-notification-title">Email notification settings</h4>
                    <div style={{display:"flex", flexDirection:"row",justifyContent:"start",alignItems:'center',gap:"0.25em"}}>
                        <h4 className="notigcation-label" style={{ fontWeight: "500", fontSize: '1em',margin:"0px" }}>Do Not Disturb: </h4>
                        <Switch
                        className="tip-action-switch"
                        style={{ marginLeft: "14px", backgroundColor: "#F6FBFF !important" }}
                        checked={do_not_disturb}
                        onChange={(e) => {
                            handleToggleChange(e);
                        }}
                        />
                    </div>
                </div>

                <Form
                    form={form}
                    layout="vertical"
                    style={{ width: '100%', fontSize: "1em", fontFamily: 'Poppins' }}
                >
                    <div className="notification-setting-custom-scroll">
                        {
                            notification_list?.map(data => (
                                <div className="notifcation-list-container" style={{ ...data?.custom_style }}>
                                    <div className="notifcation-list-left-container">
                                        <h4 className="proile-notification-title">{data?.label}</h4>
                                        <p className="notigcation-label" style={{ fontWeight: "500", fontSize: '1em' }}>{data?.info}</p>
                                        {
                                            data?.note ?
                                                <p className="notigcation-label" style={{ fontWeight: "400" }}><span style={{ fontWeight: "600" }}>Note</span> : {data?.note}</p> : ""
                                        }

                                    </div>
                                    <div className="notifcation-list-right-container">
                                        {data?.notification_list.map((item, index) => (
                                            <Form.Item
                                                style={{ width: '100%', fontSize: "1em", fontFamily: 'Poppins' }}
                                                name={item?.notification_module} >
                                                <div className="notification-type-container"
                                                    key={index}
                                                    style={{ borderBottom: index === data?.notification_list?.length - 1 ? '1px solid #CBD5E1' : 'none' }}>
                                                    <p className="notigcation-label">{item?.label}</p>
                                                    <Dropdown overlay={Filtermenu(item)}>
                                                        <DropdownButton >

                                                            <p style={{ marginTop: '10px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>{notification_settings[item?.notification_module]?.title || "Select"}</p>
                                                            <DownOutlined />

                                                        </DropdownButton>
                                                    </Dropdown>
                                                </div>
                                            </Form.Item>
                                        ))}

                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <Form.Item style={{ marginTop: "2em" }}>
                        <div className="change-password-action-button-group" style={{ justifyContent: 'end' }}>
                            <Button htmlType="submit" className="notification-action-active-button" style={{ padding: '0em 1em' }} onClick={()=>{confirmModel()}}>
                                SAVE
                            </Button>
                            <Button className="notification-action-cancel-button" onClick={()=>{getUserNotification()}}>
                                CANCEL
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
            {showModel && <InformationModal
                title="Note: The changes will be effective for future events."
                buttonLabel="OK"
                onConfirm={(e) => {setShowModel(e);onSave()}}
                visible={showModel}
            />}
        </Spin>
    )
}

export default NotificationSettingsView;
const DropdownButton = styled(Button)`
width:150px;
display:flex;
height:36px;
justify-content:space-between;
align-items:center;
gap:5px
border:1px solid #D9D9D9;
border-radius:5px;
`