import CasperContainer from './container/casper-container';
import ROUTE from './route/index';

export default [
    {
      name: 'Casper',
      path: ROUTE.casper,
      component: CasperContainer,
      exact: true,
      protect: true
    }
]
