// @ts-nocheck
import React from 'react';
import { Spin, Modal } from 'antd';
import { employee_icons } from '../icon';
import CreateAssignEmployeeContainer from '../containers/CreateAssignEmployeeContainer';
import RoleDefinitionSearchView from './RoleDefinitionSearchView';
import RoleDefinitionpagination from './roleDefinitionPagination';
import RoleDefinitionConfirmation from '../../look-v2/Components/ConfirmationDialog';
import { Capitalize } from 'modules/look';


const AssignEmployeeyHomeView = (props) => {

    const { employee_loading, employee_list, employee_delete, SetEmployeeDelete, employee_page_info, removeEmployee, assign_Employee_view, SetAssignEmployeeView, getAllMemberList,searchMemberList } = props
    
    const filter_data = [
        {
            key: "toEmployee_User_FirstName_Icontains",
            label: "Name",
            ui_flow: "input_box",
            default: true
        },

    ]

    return (
        <>
             { /* Add new competency modal*/}
            <Modal
                visible={assign_Employee_view}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-modal cp-modal-max-width'
                width={"90%"}
            >
                <CreateAssignEmployeeContainer {...props} onCancel={() => SetAssignEmployeeView( false )} />
            </Modal>

                        {/* confirmation dialog for delete employee level */}
                        <Modal
                visible={employee_delete}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-modal'
            >
                <RoleDefinitionConfirmation
                    message={`Do you want to remove the role assigned to ${Capitalize(employee_delete?.toEmployee?.user?.firstName)}?`}
                    onCancel={() => { SetEmployeeDelete(null) }}
                    onConfirm={() => { removeEmployee(employee_delete?.id) }}
                />
            </Modal>

            <div className='rd-coll-competency' style={{display:'flex',flexDirection:"row",justifyContent:'space-between',alignItems:'center',gap:".5em", flexWrap:"wrap",width:"100%"}}>
                <div className='rd-root'>
                    <button className='rd-add-button' style={{ width: "11em", marginRight: "1.5em" }} onClick={()=>{SetAssignEmployeeView(true)}} >+ Assign Role</button>
                </div>
                <div className='rd-search-container'>
                <RoleDefinitionSearchView filterData={filter_data} onfilterChange={e =>{searchMemberList(e)}}{...props} />
                </div>
            </div>
            {/* jobfamily table listing */}
            <Spin spinning={employee_loading}>
                <div className='rd-table-container'>
                    <table className='rd-table'>
                        <thead>
                            <tr className='rd-row-header'>
                                <th className='rd-table-row-padding-left rd-left-radious'>
                                    <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Name</h4>
                                </th>
                                <th className='rd-table-header-label' style={{ textAlign: "center" }}>
                                    <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Level</h4>
                                </th>
                                <th className='rd-table-header-label' style={{ textAlign: "center" }}>
                                    <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Role</h4>
                                </th>
                                <th className='rd-table-row-padding-rigth rd-content-center rd-table-header-label rd-right-radious'>
                                    <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Actions</h4>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                employee_list?.map(item => (
                                    <tr className='rd-row-body'>
                                        <td className='rd-table-row-padding-left rd-left-radious'>
                                            <h4 className='rd-table-body-label'>{Capitalize(item?.toEmployee?.user?.firstName)}</h4>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <h4 className='rd-table-body-label'>{item?.role?.employeeLevel?.name}</h4>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <h4 className='rd-table-body-label'>{item?.role?.title}</h4>
                                        </td>
                                        <td className='rd-table-row-padding-rigth rd-content-center rd-right-radious'>
                                            <div className='rd-table-actions'>
                                                {/* <img className='rd-delete-icon' src={employee_icons?.detail_page_view} alt="" /> */}
                                                <img className='rd-delete-icon' src={employee_icons?.delete_icon} alt="" onClick={()=>SetEmployeeDelete(item)} />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {
                        (!employee_list?.length && !employee_loading) ?
                            <div className='rd-nodata'>
                                <h4 className='rd-nodata-label'>No data found</h4>
                            </div> : ''
                    }
                     {
                        (employee_page_info?.hasNextPage && employee_page_info?.endCursor) && (
                            <RoleDefinitionpagination on_pagination={() => { getAllMemberList({ after: employee_page_info?.endCursor }) }} />
                        )
                    }
                </div>
            </Spin>
        </>
    )

}
export default AssignEmployeeyHomeView