import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Collapse, Popover, Row } from "antd";
import { displayUtcToUserTimezone, getIntFromString, ReactMarkdown } from "modules/look";
import React from "react";
import { useHistory } from "react-router-dom";
import bookmark_link from '../../assets/bookmark-link.svg';
import okr from '../../assets/okr-redirection.svg';


export const OneonOneDeleteModal = (props) => {
    const { selectedData, title, onCancel, deleteSeletedFeedback, type } = props
    const { Panel } = Collapse;
    const history = useHistory()
    const text = (data) => {
        let feedList = data?.response?.edges?.map(({ node }) => node)
       
        return (
            <>
            {
                type === "feedback" ?
                <div>

               
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "start", alignItems: "center", flexWrap: "wrap" }}>
                    <h4 className="action-item-markdown-heades" style={{ whiteSpace: "nowrap" }}>Last updated:<span style={{ color: "#009AF1", paddingLeft: "5px" }}>{displayUtcToUserTimezone(data?.updatedAt, "DD MMM YYYY")}</span> </h4>
                    <h4 className="action-item-markdown-heades" style={{ whiteSpace: "nowrap", fontSize: "10px" }}>NATURE:<span style={{ color: "#4CCB1F", paddingLeft: "5px" }}>{data?.content === "MOTIVATIONAL_RECOGNITION" ? 'CLAS' : 'CLAPS'}</span></h4>
                </div>

                <div className="action-item-marckdown" style={{ background: "#FFF", padding: "10px 20px", border: "1px solid #D9D9D9", borderRadius: "8px", minHeight: '50px' }}>
                    {(feedList?.length) && feedList?.map(item => (
                        <div style={{ marginBottom: '10px', width: '100%', display: 'flex', flexDirection: "row", gap: "10px" }}>
                            <div style={{ display: 'flex', flexDirection: "row", gap: "5px", marginRight: "5px" }}><ReactMarkdown style={{ color: '#4CCB1F' }}>{item?.question?.questionText[3]}</ReactMarkdown><span style={{ fontWeight: "600", color: '#4CCB1F' }}>:</span></div>
                            <div><ReactMarkdown style={{ marginLeft: '5px', marginBottom: '0px' }}>{item?.answer}</ReactMarkdown></div>
                        </div>
                    ))
                    }
                </div>
                </div>
                :
                <div>
                 <div className="action-item-marckdown" style={{ background: "#FFF", padding: "10px 20px", border: "1px solid #D9D9D9", borderRadius: "8px", minHeight: '50px' }}>
                    <ReactMarkdown>{selectedData?.notes}</ReactMarkdown>
                </div>
             
                </div>

            }
                
            </>
        )
    }
    const redirectToOkr=(okr)=>{
        if(okr?.okrType === 'OBJECTIVE'){
            history.push(`/role/objective-keyresult/view/${getIntFromString(okr?.id)}`);
        }
        else if(okr?.okrType === 'KEY_RESULT'){
            history.push(`/role/objective-keyresult/keyresult/view/${getIntFromString(okr?.id)}`);
        }
        else if (okr?.okrType === 'MILESTONE'){
            history.push(`/role/objective-keyresult/milestone/view/${getIntFromString(okr?.id)}`);
        }
    }
    return(
        <>
            <Row justify="center" align="center" >
                <p className="one-on-one-meeting-popup-para" style={{marginTop:"10px",paddingBottom:'10px'}}>{title}</p>
                {type=='feedback'&&<div style={{width:"100%"}}>
                    <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        style={{ background: '#FFF' }}
                        >
                                <Panel className="agend-list-div" style={{marginTop:"10px",marginBottom: 14,border:'none',borderRadius:"8px",padding:"0px 0px"}} header={<div style={{display:"flex",flexDirection:"row",gap:"20px",justifyContent:"space-between",alignItems:"center"}} onClick={(e)=>e.stopPropagation()}>
                                        <h4 className="one-on-one-agenda-title" style={{textAlign:"left"}}>{selectedData?.content==='MOTIVATIONAL_RECOGNITION'?'Motivational/Recognition Feedback':'Developmental/Constructive Feedback'}</h4>
                                        <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",flexWrap:"nowrap",gap:"10px"}}>
                                            {/* <img src={bookmark_disabel} alt="" style={{cursor:"pointer"}} /> */}
                                            {/* {!noMenu&&<Dropdown overlay={
                                                <Menu style={{borderRadius:" 6.5px",boxShadow:" -0.5px 3px 13.5px -4.5px rgba(0, 0, 0, 0.31)",width:"80px",display:"flex",flexDirection:"column", justifyContent:"center",alignItems:"center"}}>
                                                <Menu.Item key="edit" style={{display:"flex",flexDirection:"row",justifyContent:'left',alignItems:"center",gap:"5px",fontFamily:"Poppins",fontWeight: 500,fontSize:"12px",cursor:"pointer",width:"100%"}}  onClick={()=>EditActionItem(item)}><img src={edit_icon} alt="" /> Edit</Menu.Item>
                                                <Menu.Item key="delete" style={{display:"flex",flexDirection:"row",justifyContent:'left',alignItems:"center",gap:"5px",fontFamily:"Poppins",fontWeight: 500,fontSize:"12px",cursor:"pointer",width:"100%"}} onClick={()=>RemoveFeedback(item)}><img src={delete_icon} alt="" />Delete</Menu.Item>
                                                </Menu>}placement="bottomCenter"><img src={three_dot} alt="" style={{cursor:"pointer"}}/>
                                            </Dropdown>} */}
                                        </div>
                                    </div>} 
                            >
                                    {text(selectedData)}
                                </Panel> 
                    </Collapse>   
                </div>}
                {type==='agenda'&&<div className="agend-list-div" style={{display:"flex",flexDirection:"row",gap:"20px",justifyContent:"space-between",alignItems:"center",marginTop:"10px"}}>
                            <h4 className="one-on-one-agenda-title" style={{textAlign:"left"}}>{selectedData?.title}</h4>
                            <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",flexWrap:"nowrap",gap:"10px"}}>
                                {selectedData?.okrId?.id&&<Popover placement="bottomRight"content={<div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",width:"100%",padding:"10px 20px",maxWidth:"300px"}}>
                                    <h4 className="current-one-on-one-meeting-head" >{`Related to ${<span className="text-transform-capitalize">{selectedData?.okr?.owner?.user?.firstName}</span>}'s`}</h4>
                                    <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between",alignItems:"center",gap:"10px",marginTop:"-10px"}}>
                                        <div></div>
                                        <h4 className="current-one-on-one-meeting-head" style={{textTransform:"uppercase",color:"#009AF1",margin:"0"}}>{selectedData?.okrId?.okrType==='KEY_RESULT'?'KEY RESULT':selectedData?.okrId?.okrType==='MILE_STONE'?'MILESTONE':selectedData?.okrId?.okrType}</h4>
                                        <img src={bookmark_link} alt="" style={{cursor:"pointer"}} onClick={()=>redirectToOkr(selectedData?.okrId)} />
                                    </div>
                                    <p className="one-on-one-agenda-title" style={{paddingTop:"10px"}}>{selectedData?.okrId?.title}</p>
                                    </div>} trigger="click">
                                    <img src={okr} alt="" style={{cursor:"pointer"}}/>
                                    </Popover>}
                            </div>
                </div>}
                {type==='actionItem'&&<div style={{width:"100%"}}>
                    <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        style={{ background: '#FFF' }}
                        >
                            <Panel  className="agend-list-div" style={{marginTop:"10px",marginBottom: 14,border:'none',borderRadius:"8px",padding:"0px 0px"}} header={<div style={{display:"flex",flexDirection:"row",gap:"20px",justifyContent:"space-between",alignItems:"center"}} onClick={(e)=>e.stopPropagation()}>
                                    <h4 className="one-on-one-agenda-title" style={{textAlign:"left"}}>{selectedData?.title}</h4>
                                </div>}>
                                {text(selectedData)}
                            </Panel>  
                    </Collapse>   
                </div>}
                <Row justify="center" align="center" style={{gap:"10px",width:"100%",marginTop:"0px",paddingBottom:'17px',paddingTop:'22px'}}>
                    <Button  className="one-on-one-cancel-btn-modal" onClick={()=>onCancel()}>Cancel</Button>
                    <Button  className="one-on-one-save-btn-modal" style={{background:"#E86161",border:'1px solid #E86161'}} onClick={(e)=>deleteSeletedFeedback(selectedData?.id)}>Delete</Button>
                </Row>
            </Row>
        </>
    )
}