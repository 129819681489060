import React from 'react';
import { dasboardIcons } from '../dashboard-icons';
import { Spin } from 'antd';
import { ReactMarkdown, displayUtcToUserTimezone, getIntFromString } from 'modules/look';
import grow_route from '../../grow-model/route/index'

const CoachingConversationView = (props) => {
    const { engagement_lists, engagement_loading, me,navigateRoute } = props
    const [show_engagement, setShowengagement] = React.useState(true)
    return (
        <div className='user-dashboard-okr-performance-container'>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1em" }}>
                <h4 className='user-board-section-heading'>Coaching Conversations </h4>
                <img src={dasboardIcons?.down_arrow} style={{ width: "1.25em", transform: show_engagement ? "rotate(0deg)" : "rotate(-90deg)", cursor: "pointer", transition: ".5s" }} alt="" onClick={() => setShowengagement(!show_engagement)} />
            </div>
            {show_engagement &&
            <Spin spinning={engagement_loading}>
                {engagement_lists?.length > 0 ?
                    <div className='dashboard-engagement-overflow'>
                        {engagement_lists?.map((item) => (
                            <div className='dashboard-engagement-container'>
                                <h4 className='main-title' style={{cursor:"pointer"}} onClick={() => navigateRoute( grow_route?.grow_detail?.replace( ":convoId", getIntFromString( item?.id ) ) )}><ReactMarkdown>{item?.response?.answerSet?.edges[0]?.node?.answer}</ReactMarkdown></h4>
                                <div className='dashboard-engagement-sub-container'>
                                {
                                        item?.coachee?.user?.id!==me?.employee?.user?.id?(
                                            <div style={{ display: 'flex', flexDirection: "column" }}>
                                                <h4 className='sub-title'>My Coachee</h4>
                                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: 'center', gap: '.5em' }}>
                                                    <h4 className='content-title' style={{display:"flex",flexDirection:"row",gap:"5px"}}><span className='text-transform-capitalize'>{item?.coachee?.user?.firstName}</span> <span className='text-transform-capitalize'>{item?.coachee?.user?.lastName}</span></h4>
                                                </div>
                                            </div>
                                        ):
                                        (
                                            <div style={{ display: 'flex', flexDirection: "column" }}>
                                                <h4 className='sub-title'>My Coach</h4>
                                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: 'center', gap: '.5em' }}>
                                                    <h4 className='content-title' style={{display:"flex",flexDirection:"row",gap:"5px"}}><span className='text-transform-capitalize'>{item?.coach?.user?.firstName}</span> <span className='text-transform-capitalize'>{item?.coach?.user?.lastName}</span></h4>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {/* {
                                        item?.next_step && (
                                            <div style={{ display: 'flex', flexDirection: "column" }}>
                                                <h4 className='sub-title'>Next step</h4>
                                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: 'center', gap: '.5em' }}>
                                                    <h4 className='content-title'>{item?.next_step?.title}</h4>
                                                    <h4 className='content-title'><span className='sub-title'>By:</span> {displayUtcToUserTimezone(item?.next_step?.duedate, 'DD/MM/YYYY')}</h4>
                                                </div>
                                            </div>
                                        )
                                    } */}
                                    {/* {
                                        item?.assistance_needed && (
                                            <div style={{ display: 'flex', flexDirection: "column" }}>
                                                <h4 className='sub-title'>Assistance needed</h4>
                                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: 'center', gap: '.5em' }}>
                                                    <h4 className='content-title'>{item?.assistance_needed?.title}</h4>
                                                    <h4 className='content-title'><span className='sub-title'>By:</span> {displayUtcToUserTimezone(item?.assistance_needed?.duedate, 'DD/MM/YYYY')}</h4>
                                                </div>
                                            </div>
                                        )
                                    } */}

                                    <div style={{ display: 'flex', flexDirection: "column" }}>
                                        <h4 className='sub-title'>Follow up on</h4>
                                        <h4 className='content-title'>{item?.followUpOn ? displayUtcToUserTimezone(item?.followUpOn, 'DD/MM/YYYY') : "-"}</h4>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    :
                    (show_engagement && <div className='no-performance-container' style={{ height: "30em" }}>
                        <img style={{height:'20em'}} src={dasboardIcons?.engagement_no_data_bg} alt="" />
                        <h4 className='baner-tip-title'>Here you will see your Coaching Conversations </h4>
                    </div>)
                }
            </Spin>
            }
        </div>
    )
}

export default CoachingConversationView