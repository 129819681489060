import { Col, Tooltip,Button,Row,Menu,Modal,Spin } from "antd";
import React from "react";
import InfoCircle from '../../assets/InfoCircle.svg'
import Left_Arrow from '../../assets/left-arrow-new.svg'
import Right_arrow from '../../assets/right-arrow-new.svg'
import Table_section from '../../assets/table-section-icon.svg'
import Discard_Active from '../../assets/discard-delete-red.svg';
import Discard_inactive from '../../assets/discard-delete-disable.svg';
import { displayUtcToUserTimezone, getIntFromString} from "modules/look";
import styled from "styled-components";
import DiscardFilter from "./discardFilter";
import close_small from '../../assets/close-small.svg'
import okr_route from "../route";
 const DiscardTableView =(props)=>{
    const {discardList,discardPendingList,load,me,deleteDiscardRequest,onfilter,onPaginantion,nextPage,onPendingPaginantion,nextPendingPage,pendingLoading,navigateRoute}=props
    const [addOKR, setaddOKR] = React.useState(false)
    
    const [filterValue,setFilterValue]=React.useState()
    const [selectedFilter,setFilter]=React.useState()
    const [visible,setVisible]=React.useState(false)
    const [selectedRequest,setRequest]=React.useState()
    const filterList=[{key:'all',type:'All'},{key:'incoming',type:'Incoming'},{key:'outgoing',type:'Outgoing'}]
    let currentFilter = React.useRef({})
    let currentPendingFilter = React.useRef({})
    const [currentPage, setcurrentPage] = React.useState(1)
    const [currentPendingPage, setcurrentPendingPage] = React.useState(1)
    const [pageloading, setpageloading] = React.useState(false)
    const [pendingPageLoading,setPendingpageloading]=React.useState(false)
    const filterData = [
        {type:'All',key:'All'},
        {type:'Incoming',key:"Incoming"},
        {type:'Outgoing',key:'Outgoing'}
      ]

        const headers=[
        {
            title:"Item",
            info_icon:false,
            width:'300px'    
        },
        {
            title:"Type",
            info_icon:false, 
            width:'100px'     
        },
        {
            title:"Creator",
            info_icon:false,
            width:'150px'   
        },
       
        {
            title:"Created",
            info_icon:false,
            width:'100px'    
        },
        {
            title:"Status",
            info_icon:false,
            width:'150px'    
        },
        {
            title:"Action",
            info_icon:false,
            width:'150px'    
        },
      ]
    
      const SubmitFilter =(data)=>{
        onfilter(data)
      }
      React.useEffect(()=>{
        if(discardList){
            setpageloading(false)
        }
      },[discardList])
      React.useEffect(()=>{
        if(discardPendingList){
            setPendingpageloading(false)
        }
      },[discardPendingList])
      const Filtermenu =()=> (
        <Menu style={{maxHeight:'200px',overflow:'auto'}}>
          {
            filterList && (
                filterList?.map((data)=>
              <Menu.Item key={data?.key} onClick={()=>{setFilter(data?.key)}}>
                {data?.type}
              </Menu.Item>
              )
            )
          }
        </Menu>
      );
      const onDelete=(value)=>{
         setRequest(value)
         setVisible(true)
      }
      const cancel =()=>{
        setVisible(false)
    }

    const DeleteSelected= async()=>{
        let response =await deleteDiscardRequest({id:getIntFromString(selectedRequest?.id)})
        if (response){
            setVisible(false)
            setRequest('')
        }
    }
    const pagination=()=>{
        let current_page=currentPage
        currentFilter.current['offset']=current_page*10
        setcurrentPage(current_page+1)
        setpageloading(true)
        onPaginantion({...currentFilter?.current,pagination_value:true})
    }
    const pendingpPagination =()=>{
        let current_page=currentPendingPage
        currentPendingFilter.current['offset']=current_page*10
        setcurrentPendingPage(current_page+1)
        setPendingpageloading(true)
        onPendingPaginantion({...currentPendingFilter?.current,pagination_value:true})
    }
    return (
        <>
            {/* <Spin spinning={load}> */}
                <Row align="center" justify="center"style={{gap:"20px",padding:"20px 0px"}}>
                    {/* <Dropdown overlay={Filtermenu}  >
                        <DropdownButton >
                            <p style={{marginTop:'10px',width:'100%',overflow:'hidden',textOverflow:'ellipsis',textAlign:'left'}}>{selectedFilter?selectedFilter:'Select'}</p>
                            <DownOutlined />
                        </DropdownButton>
                    </Dropdown> */}
                    <DiscardFilter
                    me={me}
                    onfilterChange={(e) => SubmitFilter(e)}
                    filterData={filterData}
                    // defaultFilter = {filterData[0]}
                    />
                </Row>
                <div className="overflow-div">
                    <Col className="custom-table-v2-discard">
                            <div className="custom-table-tr">
                                {
                                    headers?.map(item=>(
                                        <div className="table-header-section" style={{width:item?.width||'150px',minWidth:item?.width||'150px',
                                        marginRight: (item?.title === 'Action') ? '25px' : '0px',marginLeft: (item?.title === 'Action') ? '0px' : '15px'}}>
                                            {item?.title}
                                            {
                                                item?.info_icon && (
                                                    <Tooltip title={item?.info_content}>
                                                        <img src={InfoCircle} alt="" style={{cursor:"pointer"}} />
                                                    </Tooltip>
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            <Spin spinning={pendingLoading}>
                                <div className="custom-table-body custom-table-body-discard">
                                    {
                                    discardPendingList?.map(item=>(
                                    <Col style={{paddingRight:"5px"}}>
                                        <div className="custom-table-tr table-body-tr-border" style={{borderLeft: item?.initiator?.user?.id===me?.employee?.user?.id?"5px solid #4CCB1F":"5px solid #B0CB1F"}}>
                                            
                                            <div className="table-section-td" style={{width:"300px",minWidth:"300px"}}>
                                            {item?.initiator?.user?.id===me?.employee?.user?.id&&<img src={Left_Arrow} alt="" />}
                                            { item?.initiator?.user?.id!==me?.employee?.user?.id&&<img src={Right_arrow} alt="" />}
                                                <span style={{cursor:"pointer"}} onClick={()=>navigateRoute(okr_route.discardDetail.replace(":id",getIntFromString(item?.id)))}><Tooltip title={item?.okr?.title} placement="topLeft">{item?.okr?.title}</Tooltip></span>
                                            </div>

                                            {item?.okr?.okrType==='KEY_RESULT' ? <div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                                Key Result
                                            </div>:

                                            item?.okr?.okrType==='MILESTONE' ? <div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                                Milestone
                                            </div>
                                            :
                                            item?.okr?.okrType==='OBJECTIVE' ? <div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                                Objective
                                            </div>
                                            :
                                            <div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                            </div>
                                            }

                                            <div className="table-section-td center text-transform-capitalize" style={{width:"150px",minWidth:"150px"}}>
                                                {item?.initiator?.user?.firstName}
                                            </div>
                                            
                                            <div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                                {displayUtcToUserTimezone(item?.createdAt,'DD MMM YYYY' )}
                                            </div>
                                            <div className="table-section-td center"  style={{width:"150px",minWidth:"150px",color:"#CB861F"}}>
                                                Pending
                                            </div>
                                            <div className="table-section-td center" style={{width:"150px",minWidth:"150px",justifyContent:'center',alignItems:'center',gap:"20px"}}>
                                                <Button className="view-button"  onClick={()=>navigateRoute(okr_route.discardDetail.replace(":id",getIntFromString(item?.id)))}>View</Button>
                                                {item?.initiator?.user?.id===me?.employee?.user?.id?<img src={Discard_Active} alt="" style={{width:"15px",height:"25px",cursor:"pointer"}} onClick={()=>onDelete(item)}/>:<div style={{width:"15px",height:"25px"}}></div>}
                                            </div>
                                        
                                        </div>
                                        
                                    </Col>
                                    
                                    ))
                                    }
                                    {!discardPendingList?.length&&!pageloading&&(
                                        <Row align="middle"  style={{width:"100%",justifyContent:"center",flexDirection:'column',padding:"40px 0px"}}>
                                                <h4 className="table-section-head"> No Pending Discard Requests</h4>
                                        </Row>
                                    )}
                                </div>
                            </Spin>
                            {nextPendingPage&&<Row justify="center">
                                {!pendingPageLoading?<div align="center"><h4 className="pagination-label" style={{cursor:"pointer",marginTop:"10px"}} onClick={(e)=>pendingpPagination()}>Load More</h4></div>:<Spin spinning={true}></Spin>}
                            </Row>}
                                {/* <Row justify="end">
                                    <NumberPaginantion totalPage={100}/>
                                </Row> */}
                            <Row align="middle" style={{width:"100%",justifyContent:"center",flexDirection:'column',padding:"20px 0px"}}>
                                <span style={{width:"60%",height:"2px",background:'#D9D9D9'}}></span>
                                <h4 className="table-section-head"><img src={Table_section} alt="" style={{paddingLeft:"10px",width:"30px"}}/> Resolved Requests</h4>
                            </Row>
                            <Spin spinning={load}>
                                <div className="custom-table-body custom-table-body-discard">
                                    {
                                    discardList?.map(item=>(
                                    <Col style={{paddingRight:"5px"}}>
                                        <div className="custom-table-tr table-body-tr-border" style={{borderLeft: item?.initiator?.user?.id===me?.employee?.user?.id?"5px solid #4CCB1F":"5px solid #B0CB1F"}}>
                                            
                                            <div className="table-section-td" style={{width:"300px",minWidth:"300px"}}>
                                                {item?.initiator?.user?.id===me?.employee?.user?.id&&<img src={Left_Arrow} alt="" />}
                                                {item?.initiator?.user?.id!==me?.employee?.user?.id&&<img src={Right_arrow} alt="" />}
                                                <span style={{cursor:"pointer"}} onClick={()=>navigateRoute(okr_route.discardDetail.replace(":id",getIntFromString(item?.id)))}><Tooltip title={item?.okr?.title} placement="topLeft">{item?.okr?.title}</Tooltip></span>
                                            
                                            </div>

                                            {item?.okr?.okrType==='KEY_RESULT' ? <div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                                Key Result
                                            </div>:

                                            item?.okr?.okrType==='MILESTONE' ? <div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                                Milestone
                                            </div>
                                            :
                                            item?.okr?.okrType==='OBJECTIVE' ? <div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                                Objective
                                            </div>
                                            :
                                            <div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                            </div>
                                            }

                                            <div className="table-section-td center text-transform-capitalize" style={{width:"150px",minWidth:"150px"}}>
                                                {item?.initiator?.user?.firstName}
                                            </div>
                                            
                                            <div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                            {displayUtcToUserTimezone(item?.createdAt,'DD MMM YYYY' )}
                                            </div>
                                            {item?.status=="APPROVED"&&<div className="table-section-td center"  style={{width:"150px",minWidth:"150px",color:"#4CCB1F"}}>
                                                Approved
                                            </div>}
                                            {item?.status=="DISAPPROVED"&&<div className="table-section-td center"  style={{width:"150px",minWidth:"150px",color:"#E86161"}}>
                                                Disapproved
                                            </div>}
                                            <div className="table-section-td center" style={{width:"150px",minWidth:"150px",justifyContent:"center",alignItems:"center",gap:"20px"}}>
                                                <Button className="view-button" onClick={()=>navigateRoute(okr_route.discardDetail.replace(":id",getIntFromString(item?.id)))}>View</Button>
                                                {item?.initiator?.user?.id===me?.employee?.user?.id?<img src={Discard_inactive} alt="" style={{width:" 15px",height:"25px",cursor:"pointer"}}/>:<div style={{width:"15px",height:"25px"}}></div>}
                                            </div>
                                        
                                        </div>
                                    </Col>
                                    
                                    ))
                                    }
                                    {!discardList?.length&&load&&(
                                        <Row align="middle"  style={{width:"100%",justifyContent:"center",flexDirection:'column',padding:"40px 0px"}}>
                                                <h4 className="table-section-head"> No Resolved Discard Requests</h4>
                                        </Row>
                                    )}
                                </div>
                            </Spin>
                            <Modal
                                footer={null}
                                visible={visible}
                                centered
                                closable={false}
                                destroyOnClose={true}
                                className='custom-new-modal'>
                                <Col style={{position:'relative'}}>
                                    <img style={{position:'absolute',right:'0px',top:"0px",cursor:'pointer'}} src={ close_small} onClick={()=>cancel()}/>
                                    <h3 className="modal-head">Cancel Discard Request</h3>
                                        <p className="modal-sub-head">{selectedRequest?.okr?.title}</p>
                                        <div>
                                            <p className="modal-sub-head">Are you sure you want to cancel the discard request ?</p>
                                        </div>
                                    <Row style={{width:"100%",gap:"20px",paddingTop:"20px"}} justify="center">
                                        <Button className="modal-cancel-btn" onClick={cancel}>Go Back</Button>
                                        <Button className="modal-submit-btn" style={{background: '#E86161',border:'1px solid #E86161'}} onClick={()=>DeleteSelected()}>Delete</Button>
                                    </Row>
                                </Col>
                            </Modal>
                    </Col>
                </div>
                {nextPage&&<Row justify="center">
                    {!pageloading?<div align="center"><h4 className="pagination-label" style={{cursor:"pointer"}} onClick={(e)=>pagination()}>Load More</h4></div>:<Spin spinning={true}></Spin>}
                </Row>}
            {/* </Spin> */}
        </>
    )
 }

 export default DiscardTableView

 const DropdownButton = styled(Button)`
    width:100px;
    display:flex;
    height:36px;
    justify-content:space-between;
    align-items:center;
    gap:5px
`