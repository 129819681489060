import React from "react"
import casper_icon from "../../assets/casper_icon.svg"
import tips_icon from "../../assets/ai-tips-icon_new.svg"
import "../css/global-button-view.css"
const GlobalButtonView = (props) => {
    const { Casper, AiTips, draggable, alt } = props;
    const [placement, setPlacement] = React.useState("bottom-right")
    const [tipsExpanded, setTipsExpanded] = React.useState(false)
    const [casperExpanded, setCasperExpanded] = React.useState(false)

    const handleDragStart = (e) => {
        const img = new Image();
        img.src = '';
        e.dataTransfer.setDragImage(img, 0, 0);
    };
    const handleDragEnd = (e) => {
        const container = e.target.parentNode.parentNode.getBoundingClientRect();
        const leftPos = e.clientX - container.left;

        if (leftPos < container.width / 3) {
            setPlacement('bottom-left'); 
        } else if (leftPos < (2 * container.width) / 3) {
            setPlacement('bottom-center'); 
        } else {
            setPlacement('bottom-right'); 
        }
    }
    return (
        <>
            {Casper && <Casper tipsExpanded={tipsExpanded} casperExpanded={casperExpanded} placement={placement} setCasperExpanded={setCasperExpanded} {...props} />}
            {AiTips && <AiTips casper={true} tipsExpanded={tipsExpanded} casperExpanded={casperExpanded} globalPlacement={placement} {...props} />}
            <div
                className={`global-button-container ${placement}`}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                draggable={draggable} >
                <img
                    src={casper_icon}
                    className="global-buttons-icon"
                    alt={alt}
                    onClick={() => {
                        if (casperExpanded) setCasperExpanded(false)
                        else {
                            setTipsExpanded(false);
                            setCasperExpanded(true);
                        }
                    }} />
                <img
                    src={tips_icon}
                    className="global-buttons-icon"
                    alt={alt}
                    onClick={() => {
                        if (tipsExpanded) setTipsExpanded(false)
                        else {
                            setCasperExpanded(false);
                            setTipsExpanded(true);
                        }
                    }} />
            </div>
        </>
    )
}

export default GlobalButtonView;
