// @ts-nocheck
import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'modules/core'
import React from 'react'
import ScapeReportGraphMain from '../components/scape-graph-main-view'
import { preference_colors } from '../preference-color-config'
import * as d3 from 'd3';
import { Capitalize } from 'modules/look'

const ScapeReportGraphMainContainer = (props) => {
    const { grahp_data_set,graph_level, SetGraphLevel,is_personality,effect } = props
    const counter_key = React.useRef(0)
    const [level_1_source, SetLevelOneSource] = React.useState()
    const [level_2_source, SetLevelTwoSource] = React.useState()

    React.useEffect(()=>{
        if(effect && grahp_data_set?.selected_preference_choice?.key !== "weight"){
            if(graph_level == 'level_1' && level_1_source){
              
                let nodes = level_1_source?.children?.filter(i=>i.circle_id ===`circle_id_${effect?.id}`)

                if(nodes?.length){
                    nodes.forEach(element => {
                        mouseover({data:element})
                    });
                }
            }
            else if(graph_level == 'level_2' && level_2_source){
                let list = level_2_source?.children?.map(i=>i?.content)
                list = [].concat.apply([], list)
                let nodes = list?.filter(i=>i.circle_id ===`circle_id_${effect?.id}`)
              
                if(nodes?.length){
                    nodes.forEach(element => {
                        mouseover({data:element})
                    });
                }
            }
        }
    },[effect])
    
    const  mouseover=(node)=> {
        let label_timeout_ref 
        const transition_duration = 500
        let label_height = "-40"
        let node_radious = 8
        d3.select(`.circle_${node?.data?.id}`)
            .transition()
            .duration(transition_duration)
            .attr('r', (d) => {
                if (((node?.data?.employee?.length || 1) * node_radious) <= 30) {
                    return 30
                } else {
                    return (node?.data?.employee?.length || 1) * node_radious
                }
            });
        d3.select(`.node_label_${node?.data?.id}`)
            .transition()
            .duration(transition_duration)
            .attr('dy', (d) => {
                if (((node?.data?.employee?.length || 1) * node_radious) <= 30) {
                    return label_height
                } else {
                    return `-${((d?.data?.employee?.length || 1) * node_radious) + 30}`
                }
            })

        d3.select(`.count_${node?.data?.id}`)
            .transition()
            .duration(transition_duration)
            .attr('dy', (d) => {
                if (((node?.data?.employee?.length || 1) * node_radious) <= 30) {
                    return 10
                } else {
                    return 7
                }
            })
        d3.select(`#gradiant_${node?.data?.id}`)
            .transition()
            .duration(transition_duration)
            .attr('gradientTransform', (d) => 'translate(70 56) rotate(77.1957) scale(157.927)')
        d3.selectAll('.employee_text').remove()
        if (label_timeout_ref) {
            clearTimeout(label_timeout_ref)
        }
        label_timeout_ref = setTimeout(() => {
            let right_section = node.data.employee.filter((i, no) => no % 2 === 0);
            let rd = ((node?.data?.employee?.length || 1) * node_radious)
            let rt = right_section?.slice?.(0, Math.ceil(right_section?.length / 2))
            let rb = right_section?.slice?.(Math.ceil(right_section?.length / 2), right_section?.length)
            rt.forEach((element, index) => {
                d3.select(`.right_text_${node?.data?.id}`)
                    .append('text')
                    .attr('dy', `-${index * 20}`)
                    .attr('dx', (d)=>(rd + (rt?.length===1?30:20) - (index * 7)))
                    .attr('font-size', '14')
                    .attr('fill', '#000')
                    .attr('font-weight', '500')
                    .text(element?.name?.slice(0, 10))
                    .attr('class', `employee_text`);
            });
            rb.forEach((element, index) => {
                d3.select(`.right_bottom_text_${node?.data?.id}`)
                    .append('text')
                    .attr('dy', `${(index + 1) * 20}`)
                    .attr('dx', (rd + 20 - ((index + 1) * 7)))
                    .attr('font-size', '14')
                    .attr('fill', '#000')
                    .attr('font-weight', '500')
                    .text(element?.name?.slice(0, 10))
                    .attr('class', `employee_text`);
            });

            let left_section = node.data.employee.filter((i, no) => no % 2 !== 0);
            let lt = left_section?.slice?.(0, Math.ceil(left_section?.length / 2))
            let lb = left_section?.slice?.(Math.ceil(left_section?.length / 2), left_section?.length)
            lt.forEach((element, index) => {

                d3.select(`.left_text_${node?.data?.id}`)
                    .append('text')
                    .attr('dy', `-${index * 20}`)
                    .attr('dx', -(rd + 20 - (index * 7) + (element?.name?.length >= 10 ? (11 * 7) : ((element?.name?.length + 1) * 7))))
                    .attr('font-size', '14')
                    .attr('fill', '#000')
                    .attr('font-weight', '500')
                    .text(element?.name?.slice(0, 10))
                    .attr('class', `employee_text`);
            });
            lb.forEach((element, index) => {

                d3.select(`.left_bottom_text_${node?.data?.id}`)
                    .append('text')
                    .attr('dy', `${(index + 1) * 20}`)
                    .attr('dx', -(rd + 20 - ((index + 1) * 7) + (element?.name?.length >= 10 ? (11 * 7) : ((element?.name?.length + 1) * 7))))
                    .attr('font-size', '14')
                    .attr('fill', '#000')
                    .attr('font-weight', '500')
                    .text(element?.name?.slice(0, 10))
                    .attr('class', `employee_text`);
            });
        }, transition_duration)
    }

    const getRandomHexCode = () => {
        return '#' + Math.floor( Math.random() * 16777215 ).toString( 16 );
    }
    const getOpacityColor = ( hex, opacity ) => {
        hex = hex?.replace( /^#/, '' );
        // Parse the hex color into its RGB components
        const r = parseInt( hex.slice( 0, 2 ), 16 );
        const g = parseInt( hex.slice( 2, 4 ), 16 );
        const b = parseInt( hex.slice( 4, 6 ), 16 );
        const rgb = { r, g, b }
        return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
    }
    const newGradient = () => {
        // Get two random hex codes
        var color1 = getRandomHexCode();
        var color2 = getOpacityColor( color1, .5 );
        return {
            hex: color1,
            gradient_hex: color2,
            gradient: 'linear-gradient(to right, ' + color1 + ', ' + color2 + ')'

        }

    }
    const allPersonalities = [
        {
            id: '001',
            title: "ISTJ",
            background: `${preference_colors[ `preference_${1}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${1}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${1}` ]?.gradient_hex}`
        },
        {
            id: '002',
            title: "ISFJ",
            background: `${preference_colors[ `preference_${2}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${2}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${2}` ]?.gradient_hex}`
        },
        {
            id: '003',
            title: "INFJ",
            background: `${preference_colors[ `preference_${3}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${3}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${3}` ]?.gradient_hex}`
        },
        {
            id: '004',
            title: "INTJ",
            background: `${preference_colors[ `preference_${4}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${4}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${4}` ]?.gradient_hex}`
        },
        {
            id: '005',
            title: "ISTP",
            background: `${preference_colors[ `preference_${5}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${5}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${5}` ]?.gradient_hex}`
        },
        {
            id: '006',
            title: "ISFP",
            background: `${preference_colors[ `preference_${6}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${6}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${6}` ]?.gradient_hex}`
        },
        {
            id: '007',
            title: "INFP",
            background: `${preference_colors[ `preference_${7}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${7}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${7}` ]?.gradient_hex}`
        },
        {
            id: '008',
            title: "INTP",
            background: `${preference_colors[ `preference_${8}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${8}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${8}` ]?.gradient_hex}`
        },
        {
            id: '009',
            title: "ESTP",
            background: `${preference_colors[ `preference_${9}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${9}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${9}` ]?.gradient_hex}`
        },
        {
            id: '0010',
            title: "ESFP",
            background: `${preference_colors[ `preference_${10}` ]?.gradient}`,
            hex: `${preference_colors[ `preference_${10}` ]?.hex}`,
            gradient_hex: `${preference_colors[ `preference_${10}` ]?.gradient_hex}`
        },
        {
            id: '0011',
            title: "ENFP",
            background: newGradient().gradient,
            hex: newGradient().hex,
            gradient_hex: newGradient().gradient_hex
        },
        {
            id: '0012',
            title: "ENTP",
            background: newGradient().gradient,
            hex: newGradient().hex,
            gradient_hex: newGradient().gradient_hex
        },
        {
            id: '0013',
            title: "ESTJ",
            background: newGradient().gradient,
            hex: newGradient().hex,
            gradient_hex: newGradient().gradient_hex
        },
        {
            id: '0014',
            title: "ESFJ",
            background: newGradient().gradient,
            hex: newGradient().hex,
            gradient_hex: newGradient().gradient_hex
        },
        {
            id: '0015',
            title: "ENFJ",
            background: newGradient().gradient,
            hex: newGradient().hex,
            gradient_hex: newGradient().gradient_hex
        },
        {
            id: '0016',
            title: "ENTJ",
            background: newGradient().gradient,
            hex: newGradient().hex,
            gradient_hex: newGradient().gradient_hex
        },
    ]

    const getKey =()=>{
        counter_key.current = counter_key.current+1
        return counter_key.current
    }

    React.useEffect(() => {
        if (grahp_data_set && !is_personality) {
            if(grahp_data_set?.selected_preference_choice?.key === "preference"){
                if (grahp_data_set?.type === "org") {
                    handleOrgDataForPreference(grahp_data_set)
                }
                else if (grahp_data_set?.type === "vertical") {
                    handleVerticalDataForPreference(grahp_data_set)
                }
                else if (grahp_data_set?.type === "teams") {
                    handleTeamDataForPreference(grahp_data_set)
                }
            }
            else if(grahp_data_set?.selected_preference_choice?.key === "choice"){
                if (grahp_data_set?.type === "org") {
                    handleOrgDataForChoice(grahp_data_set)
                }
                else if (grahp_data_set?.type === "vertical") {
                    handleVerticalDataForChoice(grahp_data_set)
                }
                else if (grahp_data_set?.type === "teams") {
                    handleTeamDataForChoice(grahp_data_set)
                }
            }
            else if(grahp_data_set?.selected_preference_choice?.key === "weight"){
                if (grahp_data_set?.type === "org") {
                    handleOrgDataForWeight(grahp_data_set)
                }
                else if (grahp_data_set?.type === "vertical") {
                    handleVerticalDataForWeight(grahp_data_set)
                }
                else if (grahp_data_set?.type === "teams") {
                    handleTeamDataForWeight(grahp_data_set)
                }
            }

        }else if(grahp_data_set && is_personality){
            handlePersonalityData(grahp_data_set)
        }
    }, [grahp_data_set,is_personality])

    const getWeight =(data,value)=>{
        let weight = 0 
        value.list.forEach(element => {
            weight = weight + (element.count * data[element.seq])
        });
    
        return weight
    }

    const handleOrgDataForPreference = (data) => {
        const { choice_selction, table_data, type, preference_handler,group } = data
      
        let answer_list = group?.map(g=>g?.parameterSet?.edges?.map(({node})=>node?.choice?.value))
        answer_list = [].concat.apply([], answer_list)

        let children = choice_selction?.vertical?.map(item => {
            let vertical_member = table_data?.filter(emp => emp?.verticalMembersEmployee?.edges?.map(({ node }) => node?.id)?.includes(item?.id))
            let user_list = vertical_member?.map((u, index) => {
                return {
                    id: `employee_${getKey()}`,
                    name: Capitalize(u?.user?.firstName),
                    response: u?.user?.responseSet?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)?.filter(r => r?.sequence !== null)
                }
            })?.filter(res => res?.response)
            let response_set = user_list?.map(i => i?.response)
            response_set = [].concat.apply([], response_set)

            let unique_answer = response_set?.map(i => i?.answer)
            unique_answer = [...new Set(unique_answer)]?.filter(g=>answer_list?.includes(g))
            let count = {}
            unique_answer.forEach(i => {
                let values = response_set?.filter(a => a?.answer === i)
                let x = {}
                values.forEach(element => {
                    if (x[element?.sequence]) {
                        x[element?.sequence] = x[element?.sequence] + 1
                    } else {
                        x[element?.sequence] = 1
                    }
                });
                let list = Object.entries(x).map((ent, index) => {
                    return {
                        seq: (ent[0])?.toString(),
                        count: ent[1]
                    }
                })
                list = list?.sort((a, b) => b.count - a.count)
                let highest = list[0]?.count
                list = list?.filter(hi => hi.count === highest)
                let hs = list?.slice(0)?.sort((a, b) => parseInt(a.seq) - parseInt(b.seq))
                count[i?.replaceAll(' ', '_')] = { hs: hs[0].seq, list }
            })

            let content = Object.entries(count)?.map((cnt, index) => {
                let prefernce = preference_handler?.find(pre => pre?.sequence === parseInt(cnt[1]?.hs))

                let name = cnt[0]?.replaceAll('_', ' ')
                let employees = user_list?.filter(emp => emp?.response?.find(t => t?.answer === name && t.sequence === prefernce?.sequence))
                return {
                    id: `content_${getKey()}`,
                    name,
                    color: prefernce?.hex,
                    sequnce: prefernce?.sequence,
                    circle_id : `circle_id_${prefernce?.id}`,
                    employee: employees?.map((user, no) => {
                        
                        return {
                            id: `employee_${getKey()}`,
                            name: user?.name
                        }
                    })
                }
            })
            content = content?.filter(c => c?.employee?.length)
            return {
                ...item,
                level: 1,
                color: '#AE3EC9',
                is_parent: true,
                content
            }
        })
        children = children?.filter(i => i?.content?.length)

        let graph_data = {
            id: `org_${getKey()}`,
            circle_id:`parent_${getKey()}`,
            name: choice_selction?.org?.name,
            color: '#AABAC6',
            level: 0,
            is_parent: true,
            children
        }
        SetLevelTwoSource(graph_data)
        let user_list = table_data?.map((u, index) => {
            return {
                id: `employee_${getKey()}`,
                name: Capitalize(u?.user?.firstName),
                response: u?.user?.responseSet?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)?.filter(r => r?.sequence !== null)
            }
        })?.filter(res => res?.response)
        let response_set = user_list?.map(i => i?.response)
        response_set = [].concat.apply([], response_set)
        let unique_answer = response_set?.map(i => i?.answer)
        unique_answer = [...new Set(unique_answer)]?.filter(g=>answer_list?.includes(g))
        let count = {}
        unique_answer.forEach(i => {
            let values = response_set?.filter(a => a?.answer === i)
            let x = {}
            values.forEach(element => {
                if (x[element?.sequence]) {
                    x[element?.sequence] = x[element?.sequence] + 1
                } else {
                    x[element?.sequence] = 1
                }
            });
            let list = Object.entries(x).map((ent, index) => {
                return {
                    seq: (ent[0])?.toString(),
                    count: ent[1]
                }
            })
            list = list?.sort((a, b) => b.count - a.count)
            let highest = list[0]?.count
            list = list?.filter(hi => hi.count === highest)
            let hs = list?.slice(0)?.sort((a, b) => parseInt(a.seq) - parseInt(b.seq))
            count[i?.replaceAll(' ', '_')] = { hs: hs[0].seq, list }
        })

        let content = Object.entries(count)?.map((cnt, index) => {
            let prefernce = preference_handler?.find(pre => pre?.sequence === parseInt(cnt[1]?.hs))

            let name = cnt[0]?.replaceAll('_', ' ')
            let employees = user_list?.filter(emp => emp?.response?.find(t => t?.answer === name && t.sequence === prefernce?.sequence))
            return {
                id: `content_${getKey()}`,
                name,
                level: 1,
                color: prefernce?.hex,
                sequnce: prefernce?.sequence,
                circle_id : `circle_id_${prefernce?.id}`,
                expanded: true,
                employee: employees?.map((user, no) => {
                    return {
                        id: `${no + 1}_${user?.id}`,
                        name: user?.name
                    }
                })
            }
        })
        content = content?.filter(c => c?.employee?.length)
        let graph_data_level_1 = {
            id:`org_${getKey()}`,
            circle_id:`parent_${getKey()}`,
            name: choice_selction?.org?.name,
            color: '#AABAC6',
            level: 0,
            is_parent: true,
            children: content
        }
        SetLevelOneSource(graph_data_level_1)
        SetGraphLevel(graph_level||'level_1')
    }

    const handleOrgDataForChoice = (data) => {

        const { choice_selction, table_data, type, preference_handler,group } = data
        let answer_list = group?.map(g=>g?.parameterSet?.edges?.map(({node})=>node?.choice?.value))
        answer_list = [].concat.apply([], answer_list)
      
        let children = choice_selction?.vertical?.map(item => {
            let vertical_member = table_data?.filter(emp => emp?.verticalMembersEmployee?.edges?.map(({ node }) => node?.id)?.includes(item?.id))
            let user_list = vertical_member?.map((u, index) => {
                return {
                    id: `employee_${getKey()}`,
                    name: Capitalize(u?.user?.firstName),
                    response: u?.user?.responseSet?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)?.filter(r => r?.sequence !== null)
                }
            })?.filter(res => res?.response)
            let response_set = user_list?.map(i => i?.response)
            response_set = [].concat.apply([], response_set)

            let unique_answer = response_set?.map(i => i?.answer)
            unique_answer = [...new Set(unique_answer)]?.filter(g=>answer_list?.includes(g))
            let count = {}
            unique_answer.forEach(i => {
                let values = response_set?.filter(a => a?.answer === i)
                let x = {}
                values.forEach(element => {
                    if (x[element?.sequence]) {
                        x[element?.sequence] = x[element?.sequence] + 1
                    } else {
                        x[element?.sequence] = 1
                    }
                });
                let list = Object.entries(x).map((ent, index) => {
                    return {
                        seq: (ent[0])?.toString(),
                        count: ent[1]
                    }
                })
                list = list?.sort((a, b) => b.count - a.count)
                let highest = list[0]?.count
                list = list?.filter(hi => hi.count === highest)
                let hs = list?.slice(0)?.sort((a, b) => parseInt(a.seq) - parseInt(b.seq))
                count[i?.replaceAll(' ', '_')] = { hs: hs[0].seq, list }
            })
           
            let content = Object.entries(count)?.map((cnt, index) => {
                let choice = preference_handler?.find(h=>h?.id===item?.id)
                let name = cnt[0]?.replaceAll('_', ' ')
                let employees = user_list?.filter(emp => emp?.response?.find(t => t?.answer === name ))
                return {
                    id: `content_${getKey()}`,
                    name,
                    color: choice?.hex || preference_handler[0]?.hex,
                    circle_id : `circle_id_${choice?.id || preference_handler[0]?.id}`,
                    sequnce: choice?.sequence || null,
                    employee: employees?.map((user, no) => {
                        return {
                            id: `${no + 1}_${user?.id}`,
                            name: user?.name
                        }
                    })
                }
            })
            content = content?.filter(c => c?.employee?.length)
            return {
                ...item,
                level: 1,
                color: '#AE3EC9',
                is_parent: true,
                content
            }
        })
        children = children?.filter(i => i?.content?.length)

        let graph_data = {
            id: `org_${getKey()}`,
            circle_id:`parent_${getKey()}`,
            name: choice_selction?.org?.name,
            color: '#AABAC6',
            level: 0,
            is_parent: true,
            children
        }
        SetLevelTwoSource(graph_data)
        let user_list = table_data?.map((u, index) => {
            return {
                id: `employee_${getKey()}`,
                name: Capitalize(u?.user?.firstName),
                response: u?.user?.responseSet?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)?.filter(r => r?.sequence !== null)
            }
        })?.filter(res => res?.response)
        let response_set = user_list?.map(i => i?.response)
        response_set = [].concat.apply([], response_set)
        let unique_answer = response_set?.map(i => i?.answer)
        unique_answer = [...new Set(unique_answer)]?.filter(g=>answer_list?.includes(g))
        let count = {}
        unique_answer.forEach(i => {
            let values = response_set?.filter(a => a?.answer === i)
            let x = {}
            values.forEach(element => {
                if (x[element?.sequence]) {
                    x[element?.sequence] = x[element?.sequence] + 1
                } else {
                    x[element?.sequence] = 1
                }
            });
            let list = Object.entries(x).map((ent, index) => {
                return {
                    seq: (ent[0])?.toString(),
                    count: ent[1]
                }
            })
            list = list?.sort((a, b) => b.count - a.count)
            let highest = list[0]?.count
            list = list?.filter(hi => hi.count === highest)
            let hs = list?.slice(0)?.sort((a, b) => parseInt(a.seq) - parseInt(b.seq))
            count[i?.replaceAll(' ', '_')] = { hs: hs[0].seq, list }
        })

        let content = Object.entries(count)?.map((cnt, index) => {
            let choice = preference_handler[0]

            let name = cnt[0]?.replaceAll('_', ' ')
            let employees = user_list?.filter(emp => emp?.response?.find(t => t?.answer === name))
            return {
                id: `content_${getKey()}`,
                name,
                level: 1,
                color: choice?.hex,
                sequnce: choice?.sequence,
                circle_id : `circle_id_${choice?.id || preference_handler[0]?.id}`,
                expanded: true,
                employee: employees?.map((user, no) => {
                    return {
                        id: `${no + 1}_${user?.id}`,
                        name: user?.name
                    }
                })
            }
        })
        content = content?.filter(c => c?.employee?.length)
        let graph_data_level_1 = {
            id: `org_${getKey()}`,
            circle_id:`parent_${getKey()}`,
            name: choice_selction?.org?.name,
            color: '#AABAC6',
            level: 0,
            is_parent: true,
            children: content
        }
        SetLevelOneSource(graph_data_level_1)
        SetGraphLevel(graph_level||'level_1')
    }

    const handleOrgDataForWeight = (data) => {

        const { choice_selction, table_data, type, preference_handler,group } = data
        let answer_list = group?.map(g=>g?.parameterSet?.edges?.map(({node})=>node?.choice?.value))
        answer_list = [].concat.apply([], answer_list)
        let user_list = table_data?.map((u, index) => {
            return {
                id: `employee_${getKey()}`,
                name: Capitalize(u?.user?.firstName),
                response: u?.user?.responseSet?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)?.filter(r => r?.sequence !== null)
            }
        })?.filter(res => res?.response)
        let response_set = user_list?.map(i => i?.response)
        response_set = [].concat.apply([], response_set)
        let unique_answer = response_set?.map(i => i?.answer)
        unique_answer = [...new Set(unique_answer)]?.filter(g=>answer_list?.includes(g))
        let unique_seq = response_set?.map(i => i?.sequence)
        unique_seq = [...new Set(unique_seq)]?.sort((a,b)=>a-b)
        let seq_weight = {}
        unique_seq.forEach((element,c) => {
            seq_weight[element] = unique_seq?.length - c
        });
        let count = {}
        unique_answer.forEach(i => {
            let values = response_set?.filter(a => a?.answer === i)
            let x = {}
            values.forEach(element => {
                if (x[element?.sequence]) {
                    x[element?.sequence] = x[element?.sequence] + 1
                } else {
                    x[element?.sequence] = 1
                }
            });
            let list = Object.entries(x).map((ent, index) => {
                return {
                    seq: (ent[0])?.toString(),
                    count: ent[1]
                }
            })
            list = list?.sort((a, b) => b.count - a.count)
            let highest = list[0]?.count
            list = list?.filter(hi => hi.count === highest)
            let hs = list?.slice(0)?.sort((a, b) => parseInt(a.seq) - parseInt(b.seq))
            count[i?.replaceAll(' ', '_')] = { hs: hs[0].seq, list }
        })
        let content = Object.entries(count)?.map((cnt, index) => {
            let choice = preference_handler[0]

            let name = cnt[0]?.replaceAll('_', ' ')
            
            return {
                id: `content_${getKey()}`,
                name,
                level: 1,
                color: choice?.hex,
                sequnce: choice?.sequence,
                circle_id : `circle_id_${choice?.id || preference_handler[0]?.id}`,
                expanded: true,
                weight:getWeight(seq_weight,cnt[1]),
                employee: [{name:'',id:''}]
            }
        })
        content = content?.filter(c => c?.employee?.length)
        let graph_data_level_1 = {
            id: `org_${getKey()}`,
            circle_id:`parent_${getKey()}`,
            name: choice_selction?.org?.name,
            color: '#AABAC6',
            level: 0,
            is_parent: true,
            children: content
        }
        SetLevelOneSource(graph_data_level_1)
        SetGraphLevel('level_1')
    }

    const handleVerticalDataForPreference = (data) => {
        const { choice_selction, table_data, type, preference_handler,group } = data
        let answer_list = group?.map(g=>g?.parameterSet?.edges?.map(({node})=>node?.choice?.value))
        answer_list = [].concat.apply([], answer_list)
        let children = choice_selction?.team?.map(item => {
            let team_member = table_data?.filter(emp => emp?.teamMembersEmployee?.edges?.map(({ node }) => node?.id)?.includes(item?.id))
            let user_list = team_member?.map((u, index) => {
                return {
                    id: `${index}_${u?.id}`,
                    name: Capitalize(u?.user?.firstName),
                    response: u?.user?.responseSet?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)?.filter(r => r?.sequence !== null)
                }
            })?.filter(res => res?.response)
            let response_set = user_list?.map(i => i?.response)
            response_set = [].concat.apply([], response_set)

            let unique_answer = response_set?.map(i => i?.answer)
            unique_answer = [...new Set(unique_answer)]?.filter(g=>answer_list?.includes(g))
            let count = {}
            unique_answer.forEach(i => {
                let values = response_set?.filter(a => a?.answer === i)
                let x = {}
                values.forEach(element => {
                    if (x[element?.sequence]) {
                        x[element?.sequence] = x[element?.sequence] + 1
                    } else {
                        x[element?.sequence] = 1
                    }
                });
                let list = Object.entries(x).map((ent, index) => {
                    return {
                        seq: (ent[0])?.toString(),
                        count: ent[1]
                    }
                })
                list = list?.sort((a, b) => b.count - a.count)
                let highest = list[0]?.count
                list = list?.filter(hi => hi.count === highest)
                let hs = list?.slice(0)?.sort((a, b) => parseInt(a.seq) - parseInt(b.seq))
                count[i?.replaceAll(' ', '_')] = { hs: hs[0].seq, list }
            })

            let content = Object.entries(count)?.map((cnt, index) => {
                let prefernce = preference_handler?.find(pre => pre?.sequence === parseInt(cnt[1]?.hs))

                let name = cnt[0]?.replaceAll('_', ' ')
                let employees = user_list?.filter(emp => emp?.response?.find(t => t?.answer === name && t.sequence === prefernce?.sequence))
                return {
                    id: `content_${getKey()}`,
                    name,
                    color: prefernce?.hex,
                    sequnce: prefernce?.sequence,
                    circle_id : `circle_id_${prefernce?.id}`,
                    employee: employees?.map((user, no) => {
                        return {
                            id: `employee_${getKey()}`,
                            name: user?.name
                        }
                    })
                }
            })
            content = content?.filter(c => c?.employee?.length)
            return {
                ...item,
                level: 1,
                color: '#AE3EC9',
                is_parent: true,
                content
            }
        })
        children = children?.filter(i => i?.content?.length)

        let graph_data = {
            id:`vertical_${getKey()}`,
            circle_id:`parent_${getKey()}`,
            name: choice_selction?.vertical?.name,
            color: '#AABAC6',
            level: 0,
            is_parent: true,
            children
        }
        SetLevelTwoSource(graph_data)

        let user_list = table_data?.map((u, index) => {
            return {
                id:`employee_${getKey()}`,
                name: Capitalize(u?.user?.firstName),
                response: u?.user?.responseSet?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)?.filter(r => r?.sequence !== null)
            }
        })?.filter(res => res?.response)
        let response_set = user_list?.map(i => i?.response)
        response_set = [].concat.apply([], response_set)
        let unique_answer = response_set?.map(i => i?.answer)
        unique_answer = [...new Set(unique_answer)]?.filter(g=>answer_list?.includes(g))
        let count = {}
        unique_answer.forEach(i => {
            let values = response_set?.filter(a => a?.answer === i)
            let x = {}
            values.forEach(element => {
                if (x[element?.sequence]) {
                    x[element?.sequence] = x[element?.sequence] + 1
                } else {
                    x[element?.sequence] = 1
                }
            });
            let list = Object.entries(x).map((ent, index) => {
                return {
                    seq: (ent[0])?.toString(),
                    count: ent[1]
                }
            })
            list = list?.sort((a, b) => b.count - a.count)
            let highest = list[0]?.count
            list = list?.filter(hi => hi.count === highest)
            let hs = list?.slice(0)?.sort((a, b) => parseInt(a.seq) - parseInt(b.seq))
            count[i?.replaceAll(' ', '_')] = { hs: hs[0].seq, list }
        })

        let content = Object.entries(count)?.map((cnt, index) => {
            let prefernce = preference_handler?.find(pre => pre?.sequence === parseInt(cnt[1]?.hs))

            let name = cnt[0]?.replaceAll('_', ' ')
            let employees = user_list?.filter(emp => emp?.response?.find(t => t?.answer === name && t.sequence === prefernce?.sequence))
            return {
                id: `content_${getKey()}`,
                name,
                level: 1,
                color: prefernce?.hex,
                sequnce: prefernce?.sequence,
                circle_id : `circle_id_${prefernce?.id}`,
                expanded: true,
                employee: employees?.map((user, no) => {
                    return {
                        id: `employee_${getKey()}`,
                        name: user?.name
                    }
                })
            }
        })
        content = content?.filter(c => c?.employee?.length)
        let graph_data_level_1 = {
            id: `vertical_${getKey()}`,
            circle_id:`parent_${getKey()}`,
            name: choice_selction?.vertical?.name,
            color: '#AABAC6',
            level: 0,
            is_parent: true,
            children: content
        }
        SetLevelOneSource(graph_data_level_1)
        SetGraphLevel(graph_level||'level_1')
    }

    const handleVerticalDataForChoice = (data) => {
        const { choice_selction, table_data, type, preference_handler,group } = data
        let answer_list = group?.map(g=>g?.parameterSet?.edges?.map(({node})=>node?.choice?.value))
        answer_list = [].concat.apply([], answer_list)
        let children = choice_selction?.team?.map(item => {
            let team_member = table_data?.filter(emp => emp?.teamMembersEmployee?.edges?.map(({ node }) => node?.id)?.includes(item?.id))
            let user_list = team_member?.map((u, index) => {
                return {
                    id: `employee_${getKey()}`,
                    name: Capitalize(u?.user?.firstName),
                    response: u?.user?.responseSet?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)?.filter(r => r?.sequence !== null)
                }
            })?.filter(res => res?.response)
            let response_set = user_list?.map(i => i?.response)
            response_set = [].concat.apply([], response_set)

            let unique_answer = response_set?.map(i => i?.answer)
            unique_answer = [...new Set(unique_answer)]?.filter(g=>answer_list?.includes(g))
            let count = {}
            unique_answer.forEach(i => {
                let values = response_set?.filter(a => a?.answer === i)
                let x = {}
                values.forEach(element => {
                    if (x[element?.sequence]) {
                        x[element?.sequence] = x[element?.sequence] + 1
                    } else {
                        x[element?.sequence] = 1
                    }
                });
                let list = Object.entries(x).map((ent, index) => {
                    return {
                        seq: (ent[0])?.toString(),
                        count: ent[1]
                    }
                })
                list = list?.sort((a, b) => b.count - a.count)
                let highest = list[0]?.count
                list = list?.filter(hi => hi.count === highest)
                let hs = list?.slice(0)?.sort((a, b) => parseInt(a.seq) - parseInt(b.seq))
                count[i?.replaceAll(' ', '_')] = { hs: hs[0].seq, list }
            })

            let content = Object.entries(count)?.map((cnt, index) => {
                let choice = preference_handler?.find(h=>h?.id === item?.id)

                let name = cnt[0]?.replaceAll('_', ' ')
                let employees = user_list?.filter(emp => emp?.response?.find(t => t?.answer === name))
                return {
                    id:`content_${getKey()}`,
                    name,
                    color: choice?.hex || preference_handler[0]?.hex,
                    sequnce: choice?.sequence || null,
                    circle_id : `circle_id_${choice?.id || preference_handler[0]?.id}`,
                    employee: employees?.map((user, no) => {
                        return {
                            id: `employee_${getKey()}`,
                            name: user?.name
                        }
                    })
                }
            })
            content = content?.filter(c => c?.employee?.length)
            return {
                ...item,
                level: 1,
                color: '#AE3EC9',
                is_parent: true,
                content
            }
        })
        children = children?.filter(i => i?.content?.length)

        let graph_data = {
            id: `vertical_${getKey()}`,
            circle_id:`parent_${getKey()}`,
            name: choice_selction?.vertical?.name,
            color: '#AABAC6',
            level: 0,
            is_parent: true,
            children
        }
        SetLevelTwoSource(graph_data)

        let user_list = table_data?.map((u, index) => {
            return {
                id: `employee_${getKey()}`,
                name: Capitalize(u?.user?.firstName),
                response: u?.user?.responseSet?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)?.filter(r => r?.sequence !== null)
            }
        })?.filter(res => res?.response)
        let response_set = user_list?.map(i => i?.response)
        response_set = [].concat.apply([], response_set)
        let unique_answer = response_set?.map(i => i?.answer)
        unique_answer = [...new Set(unique_answer)]?.filter(g=>answer_list?.includes(g))
        let count = {}
        unique_answer.forEach(i => {
            let values = response_set?.filter(a => a?.answer === i)
            let x = {}
            values.forEach(element => {
                if (x[element?.sequence]) {
                    x[element?.sequence] = x[element?.sequence] + 1
                } else {
                    x[element?.sequence] = 1
                }
            });
            let list = Object.entries(x).map((ent, index) => {
                return {
                    seq: (ent[0])?.toString(),
                    count: ent[1]
                }
            })
            list = list?.sort((a, b) => b.count - a.count)
            let highest = list[0]?.count
            list = list?.filter(hi => hi.count === highest)
            let hs = list?.slice(0)?.sort((a, b) => parseInt(a.seq) - parseInt(b.seq))
            count[i?.replaceAll(' ', '_')] = { hs: hs[0].seq, list }
        })

        let content = Object.entries(count)?.map((cnt, index) => {
            let choice = preference_handler[0] 

            let name = cnt[0]?.replaceAll('_', ' ')
            let employees = user_list?.filter(emp => emp?.response?.find(t => t?.answer === name))
            return {
                id: `content_${getKey()}`,
                name,
                level: 1,
                color: choice?.hex,
                sequnce: choice?.sequence,
                circle_id : `circle_id_${choice?.id || preference_handler[0]?.id}`,
                expanded: true,
                employee: employees?.map((user, no) => {
                    return {
                        id: `employee_${getKey()}`,
                        name: user?.name
                    }
                })
            }
        })
        content = content?.filter(c => c?.employee?.length)
        let graph_data_level_1 = {
            id: `vertical_${getKey()}`,
            circle_id:`parent_${getKey()}`,
            name: choice_selction?.vertical?.name,
            color: '#AABAC6',
            level: 0,
            is_parent: true,
            children: content
        }
        SetLevelOneSource(graph_data_level_1)
        SetGraphLevel(graph_level||'level_1')
    }

    const handleVerticalDataForWeight = (data) => {
        const { choice_selction, table_data, type, preference_handler,group } = data
        let answer_list = group?.map(g=>g?.parameterSet?.edges?.map(({node})=>node?.choice?.value))
        answer_list = [].concat.apply([], answer_list)
        let user_list = table_data?.map((u, index) => {
            return {
                id: `employee_${getKey()}`,
                name: Capitalize(u?.user?.firstName),
                response: u?.user?.responseSet?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)?.filter(r => r?.sequence !== null)
            }
        })?.filter(res => res?.response)
        let response_set = user_list?.map(i => i?.response)
        response_set = [].concat.apply([], response_set)
        let unique_answer = response_set?.map(i => i?.answer)
        unique_answer = [...new Set(unique_answer)]?.filter(g=>answer_list?.includes(g))
        let unique_seq = response_set?.map(i => i?.sequence)
        unique_seq = [...new Set(unique_seq)]?.sort((a,b)=>a-b)
        let seq_weight = {}
        unique_seq.forEach((element,c) => {
            seq_weight[element] = unique_seq?.length - c
        });
        let count = {}
        unique_answer.forEach(i => {
            let values = response_set?.filter(a => a?.answer === i)
            let x = {}
            values.forEach(element => {
                if (x[element?.sequence]) {
                    x[element?.sequence] = x[element?.sequence] + 1
                } else {
                    x[element?.sequence] = 1
                }
            });
            let list = Object.entries(x).map((ent, index) => {
                return {
                    seq: (ent[0])?.toString(),
                    count: ent[1]
                }
            })
            list = list?.sort((a, b) => b.count - a.count)
            let highest = list[0]?.count
            list = list?.filter(hi => hi.count === highest)
            let hs = list?.slice(0)?.sort((a, b) => parseInt(a.seq) - parseInt(b.seq))
            count[i?.replaceAll(' ', '_')] = { hs: hs[0].seq, list }
        })

        let content = Object.entries(count)?.map((cnt, index) => {
            let choice = preference_handler[0] 

            let name = cnt[0]?.replaceAll('_', ' ')
      
            return {
                id: `content_${getKey()}`,
                name,
                level: 1,
                color: choice?.hex,
                sequnce: choice?.sequence,
                circle_id : `circle_id_${choice?.id || preference_handler[0]?.id}`,
                expanded: true,
                weight:getWeight(seq_weight,cnt[1]),
                employee: [{name:'',id:''}]
            }
        })
        content = content?.filter(c => c?.employee?.length)
        let graph_data_level_1 = {
            id: `vertical_${getKey()}`,
            circle_id:`parent_${getKey()}`,
            name: choice_selction?.vertical?.name,
            color: '#AABAC6',
            level: 0,
            is_parent: true,
            children: content
        }
        SetLevelOneSource(graph_data_level_1)
        SetGraphLevel('level_1')
    }

    const handleTeamDataForPreference = (data) => {
        const { choice_selction, table_data, type, preference_handler, group } = data
        let answer_list = group?.map(g=>g?.parameterSet?.edges?.map(({node})=>node?.choice?.value))
        answer_list = [].concat.apply([], answer_list)
        let user_list = table_data?.map((u, index) => {
            return {
                id: `employee_${getKey()}`,
                name:Capitalize(u?.user?.firstName),
                response: u?.user?.responseSet?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)?.filter(r => r?.sequence !== null)
            }
        })?.filter(res => res?.response)
        let response_set = user_list?.map(i => i?.response)
        response_set = [].concat.apply([], response_set)
        let unique_answer = response_set?.map(i => i?.answer)
        unique_answer = [...new Set(unique_answer)]?.filter(g=>answer_list?.includes(g))
        let count = {}
        unique_answer.forEach(i => {
            let values = response_set?.filter(a => a?.answer === i)
            let x = {}
            values.forEach(element => {
                if (x[element?.sequence]) {
                    x[element?.sequence] = x[element?.sequence] + 1
                } else {
                    x[element?.sequence] = 1
                }
            });
            let list = Object.entries(x).map((ent, index) => {
                return {
                    seq: (ent[0])?.toString(),
                    count: ent[1]
                }
            })
            list = list?.sort((a, b) => b.count - a.count)
            let highest = list[0]?.count
            list = list?.filter(hi => hi.count === highest)
            let hs = list?.slice(0)?.sort((a, b) => parseInt(a.seq) - parseInt(b.seq))
            count[i?.replaceAll(' ', '_')] = { hs: hs[0].seq, list }
        })

        let children = Object.entries(count)?.map((cnt, index) => {
            let prefernce = preference_handler?.find(pre => pre?.sequence === parseInt(cnt[1]?.hs))

            let name = cnt[0]?.replaceAll('_', ' ')
            let employees = user_list?.filter(emp => emp?.response?.find(t => t?.answer === name && t.sequence === prefernce?.sequence))
            return {
                id: `content_${getKey()}`,
                name,
                level: 1,
                color: prefernce?.hex,
                sequnce: prefernce?.sequence,
                circle_id : `circle_id_${prefernce?.id}`,
                expanded: true,
                employee: employees?.map((user, no) => {
                    return {
                        id: `employee_${getKey()}`,
                        name: user?.name
                    }
                })
            }
        })
        children = children?.filter(c => c?.employee?.length)
        let graph_data_level_1 = {
            id: choice_selction?.team[0]?.id,
            circle_id:`parent_${getKey()}`,
            name: choice_selction?.team[0]?.name,
            color: '#AABAC6',
            level: 0,
            is_parent: true,
            children
        }

        SetLevelOneSource(graph_data_level_1)
        if (group?.length) {
            let group_set = group?.map(item => {
                let choice_list = item?.parameterSet?.edges?.map(({ node }) => node?.choice?.value)
                let content = children?.filter(ch => choice_list?.includes(ch?.name))
                return {
                    level: 1,
                    is_parent: true,
                    id: item?.id,
                    name: item?.name,
                    color: '#AE3EC9',
                    content
                }
            })
            let graph_data_level_2 = {
                id: `team_${getKey()}`,
                circle_id:`parent_${getKey()}`,
                name: choice_selction?.team[0]?.name,
                color: '#AABAC6',
                level: 0,
                is_parent: true,
                children:group_set
            }
            SetLevelTwoSource(graph_data_level_2)
        }

        SetGraphLevel(graph_level||'level_1')
    }

    const handleTeamDataForChoice = (data) => {
        const { choice_selction, table_data, type, preference_handler, group } = data
        let answer_list = group?.map(g=>g?.parameterSet?.edges?.map(({node})=>node?.choice?.value))
        answer_list = [].concat.apply([], answer_list)
        let user_list = table_data?.map((u, index) => {
            return {
                id: `employee_${getKey()}`,
                name: Capitalize(u?.user?.firstName),
                response: u?.user?.responseSet?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)?.filter(r => r?.sequence !== null)
            }
        })?.filter(res => res?.response)
        let response_set = user_list?.map(i => i?.response)
        response_set = [].concat.apply([], response_set)
        let unique_answer = response_set?.map(i => i?.answer)
        unique_answer = [...new Set(unique_answer)]?.filter(g=>answer_list?.includes(g))
        let count = {}
        unique_answer.forEach(i => {
            let values = response_set?.filter(a => a?.answer === i)
            let x = {}
            values.forEach(element => {
                if (x[element?.sequence]) {
                    x[element?.sequence] = x[element?.sequence] + 1
                } else {
                    x[element?.sequence] = 1
                }
            });
            let list = Object.entries(x).map((ent, index) => {
                return {
                    seq: (ent[0])?.toString(),
                    count: ent[1]
                }
            })
            list = list?.sort((a, b) => b.count - a.count)
            let highest = list[0]?.count
            list = list?.filter(hi => hi.count === highest)
            let hs = list?.slice(0)?.sort((a, b) => parseInt(a.seq) - parseInt(b.seq))
            count[i?.replaceAll(' ', '_')] = { hs: hs[0].seq, list }
        })

        let children = Object.entries(count)?.map((cnt, index) => {
            let prefernce = preference_handler[0] // change_according_to_data

            let name = cnt[0]?.replaceAll('_', ' ')
            let employees = user_list?.filter(emp => emp?.response?.find(t => t?.answer === name))
            return {
                id: `content_${getKey()}`,
                name,
                level: 1,
                color: prefernce?.hex,
                sequnce: prefernce?.sequence,
                expanded: true,
                circle_id : `circle_id_${prefernce?.id}`,
                employee: employees?.map((user, no) => {
                    return {
                        id: `${no + 1}_${user?.id}`,
                        name: user?.name
                    }
                })
            }
        })
        children = children?.filter(c => c?.employee?.length)
        let graph_data_level_1 = {
            id: `team_${getKey()}`,
            circle_id:`parent_${getKey()}`,
            name: choice_selction?.team[0]?.name,
            color: '#AABAC6',
            level: 0,
            is_parent: true,
            children
        }

        SetLevelOneSource(graph_data_level_1)
        if (group?.length) {
            let group_set = group?.map(item => {
                let choice_list = item?.parameterSet?.edges?.map(({ node }) => node?.choice?.value)
                let content = children?.filter(ch => choice_list?.includes(ch?.name))
                let x = group.find(d=>d?.parameterSet?.edges?.map(({node})=>node?.choice?.value)?.includes(content[0]?.name))
               
                if(x){
                    let preference = preference_handler?.find(p=>p?.title==x?.name)
                    if(preference){
                        content = content?.map(c=>{
                            return {
                                ...c,
                                color:preference?.hex,
                                circle_id : `circle_id_${preference?.id}`,
                            }
                        })
                    }
                }

                return {
                    level: 1,
                    is_parent: true,
                    id: item?.id,
                    name: item?.name,
                    color: '#AE3EC9',
                    content
                }
            })
            let graph_data_level_2 = {
                id: `team_${getKey()}`,
                circle_id:`parent_${getKey()}`,
                name: choice_selction?.team[0]?.name,
                color: '#AABAC6',
                level: 0,
                is_parent: true,
                children:group_set
            }
            SetLevelTwoSource(graph_data_level_2)
        }

        SetGraphLevel(graph_level||'level_1')
    }

    const handleTeamDataForWeight = (data) => {
        const { choice_selction, table_data, type, preference_handler, group } = data
        let answer_list = group?.map(g=>g?.parameterSet?.edges?.map(({node})=>node?.choice?.value))
        answer_list = [].concat.apply([], answer_list)
        let user_list = table_data?.map((u, index) => {
            return {
                id: `employee_${getKey()}`,
                name: Capitalize(u?.user?.firstName),
                response: u?.user?.responseSet?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)?.filter(r => r?.sequence !== null)
            }
        })?.filter(res => res?.response)
        let response_set = user_list?.map(i => i?.response)
        response_set = [].concat.apply([], response_set)
        let unique_answer = response_set?.map(i => i?.answer)
        unique_answer = [...new Set(unique_answer)]?.filter(g=>answer_list?.includes(g))
        let unique_seq = response_set?.map(i => i?.sequence)
        unique_seq = [...new Set(unique_seq)]?.sort((a,b)=>a-b)
        let seq_weight = {}
        unique_seq.forEach((element,c) => {
            seq_weight[element] = unique_seq?.length - c
        });
        let count = {}
        unique_answer.forEach(i => {
            let values = response_set?.filter(a => a?.answer === i)
            let x = {}
            values.forEach(element => {
                if (x[element?.sequence]) {
                    x[element?.sequence] = x[element?.sequence] + 1
                } else {
                    x[element?.sequence] = 1
                }
            });
            let list = Object.entries(x).map((ent, index) => {
                return {
                    seq: (ent[0])?.toString(),
                    count: ent[1]
                }
            })
            list = list?.sort((a, b) => b.count - a.count)
            let highest = list[0]?.count
            list = list?.filter(hi => hi.count === highest)
            let hs = list?.slice(0)?.sort((a, b) => parseInt(a.seq) - parseInt(b.seq))
            count[i?.replaceAll(' ', '_')] = { hs: hs[0].seq, list }
        })

        let children = Object.entries(count)?.map((cnt, index) => {
            let prefernce = preference_handler[0] 

            let name = cnt[0]?.replaceAll('_', ' ')
            return {
                id: `content_${getKey()}`,
                name,
                level: 1,
                color: prefernce?.hex,
                sequnce: prefernce?.sequence,
                expanded: true,
                weight:getWeight(seq_weight,cnt[1]),
                employee: [{name:'',id:''}]
            }
        })
        children = children?.filter(c => c?.employee?.length)
        let graph_data_level_1 = {
            id: `team_${getKey()}`,
            circle_id:`parent_${getKey()}`,
            name: choice_selction?.team[0]?.name,
            color: '#AABAC6',
            level: 0,
            is_parent: true,
            children
        }
        SetLevelOneSource(graph_data_level_1)
       

        SetGraphLevel('level_1')
    }

    const handlePersonalityData = (data)=>{
        const { choice_selction, table_data } = data

        let user_list = table_data?.map((u, index) => {
            return {
                id: `employee_${getKey()}`,
                name: Capitalize(u?.user?.firstName),
                response: u?.user?.responseSet?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)
            }
        })?.filter(res => res?.response)
        user_list = user_list?.map(i=>{
            let personality =''
            let res = i?.response?.filter(r=>r?.answer)?.sort((a,b)=>a.sequence - b.sequence)
            
            res.forEach(element => {
                personality = personality + (element?.answer === "Intuitive" ? "N":element?.answer ==="Observant"?"S" : element?.answer?.charAt(0))
            });
            return {
                ...i,
                personality : personality?.slice(0,4)
            }
        })
        let children = allPersonalities?.map((cnt, index) => {
          
            return {
                id: `content_${getKey()}`,
                name:cnt?.title,
                level: 1,
                color: cnt?.hex,
                sequnce: index,
                expanded: true,
                circle_id : `circle_id_${cnt?.id}`,
                employee: user_list?.filter(d=>d?.personality==cnt?.title)
            }
        })
        children = children?.filter(c => c?.employee?.length)
        let graph_data_level_1 = {
            id: `personality_${getKey()}`,
            circle_id:`parent_${getKey()}`,
            name: choice_selction?.graph_type=='org'?choice_selction?.org?.name:
            choice_selction?.graph_type=='vertical'?choice_selction?.vertical?.name:choice_selction?.team[0]?.name,
            color: '#AABAC6',
            level: 0,
            is_parent: true,
            children
        }
        SetLevelOneSource(graph_data_level_1)
       

        SetGraphLevel('level_1')
    }

    return (
        <ScapeReportGraphMain level_2_source={level_2_source} level_1_source={level_1_source} graph_level={graph_level} SetGraphLevel={SetGraphLevel} {...props} />
    )
}
export default compose()(ScapeReportGraphMainContainer)