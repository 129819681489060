import { Dropdown, Input, Menu, Spin, Button, message } from "antd";
import { dashboard_home_icons, organization_icons } from "modules/poc-dashboard/icons";
import React from "react";

// this file is a common file for vertical,subvertical and team edit in poc tree structure
const TreeEditView = (props) => {
    const { api_loading, edit_view, detail, managers_list, selected_employee_list, all_employee_list, setManagerList, setSelectedEmployeeList, onClose, onSearchEmployee, immediateSearchFeature, SetSaveChanges } = props
    const [name, SetName] = React.useState()
    const [employee_dropdown_visibility, SetEmployeeDropdownVisibility] = React.useState(false)
    const [manager_dropdown_visibility, SetManagerDropdownVisibility] = React.useState(false)
    const [search_variable, SetSearchVariable] = React.useState(undefined)

    React.useEffect(() => {
        if (employee_dropdown_visibility) {
            SetManagerDropdownVisibility(false)
        } else {
            immediateSearchFeature("")
            SetSearchVariable("")
        }
    }, [employee_dropdown_visibility])

    React.useEffect(() => {
        if (manager_dropdown_visibility) {
            SetEmployeeDropdownVisibility(false)
        } else {
            immediateSearchFeature("")
            SetSearchVariable("")
        }
    }, [manager_dropdown_visibility])

    React.useEffect(() => {
        if (detail) {
            SetName(detail?.name)
        }
    }, [detail])

    let labels = {
        "VERTICAL": {
            name_field: "Vertical",
            manager_field: "Vertical Head",
            title: "Edit Vertical",
            place_holder:"Vertical name"
        },
        "SUB-VERTICAL": {
            name_field: "Sub Vertical",
            manager_field: "Sub Vertical Head",
            title: "Edit Sub Vertical",
            place_holder:"Sub Vertical name"
        },
        "TEAM": {
            name_field: "Team",
            manager_field: "Team Manager",
            title: "Edit Team",
            place_holder:"Team name"
        }
    }

    const selectManager = (data) => {
        let list = [].concat(managers_list)
        if (list?.find(i => i?.user?.id == data?.user?.id)) {
            list = list?.filter(i => i?.user?.id != data?.user?.id)
        } else {
            list.push(data)
        }
        setManagerList(list)
    }

    const selectEmployee = (data) => {
        let list = [].concat(selected_employee_list)
        let node = list?.find(i => i?.user?.id == data?.user?.id)
        if (node) {
            node["member_deleted"] = node?.member_deleted?false:true
        } else {
            list.push(data)
        }       

        setSelectedEmployeeList(list)
    }

    const menuSelectionToggle = (list, id) => {
        if (list?.includes(id)) {
            return (
                <div className="tree-menu-dropdown-active-select">
                    <div className="check-mark-container">
                        <img src={organization_icons.check_mark} alt="" />
                    </div>
                </div>
            )
        } else {
            return (
                <div className="tree-menu-dropdown-inactive-select">

                </div>
            )
        }
    }

    const menuSelect=(type,data)=>{
        if(type=="manager"){
            selectManager(data)
        }else{
            selectEmployee(data)
        }
    }

    const DropDownMenu = (type,list) => (
        <Menu  >
            <Menu.Item >
                <h4 className="dropdown-menu-title"> Members</h4>
            </Menu.Item>
            <Menu.Item>
                <div className="menu-search-container">
                    <img src={dashboard_home_icons.search_icon} className="menu-search-icon" alt="" />
                    <Input className="menu-search-input" placeholder="Find employees" value={search_variable} onChange={(e) => { SetSearchVariable(e?.target?.value); onSearchEmployee(e?.target?.value) }}></Input>
                </div>
            </Menu.Item>
            {
                !all_employee_list?.length ?
                    <Menu.Item   >
                        <h4>No Employees Found</h4>
                    </Menu.Item> : ""
            }
            {all_employee_list?.map((val) =>
            (<Menu.Item key={val.id} onClick={() => menuSelect(type,val)} >
                {menuSelectionToggle(list?.map(i => i?.user?.id), val.user.id)}   <h4><span className="text-transform-capitalize">{val.user?.firstName}</span> <span className="text-transform-capitalize">{val.user?.lastName||""}</span></h4>
            </Menu.Item>)
            )}
        </Menu>
    )

    const onSave=()=>{
        if(!name || name?.trim()==""){
            message.error("Please enter vertical name")
            return
        }
        SetSaveChanges({
            name,managers_list,selected_employee_list
        })
        
    }
    return (
        <Spin spinning={api_loading}>
            {
                (employee_dropdown_visibility || manager_dropdown_visibility) && (
                    <div className="popup-menu-dropdown-overlay" onClick={() => { SetEmployeeDropdownVisibility(false); SetManagerDropdownVisibility(false) }}>

                    </div>
                )
            }
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1em" }}>
                    <h4 className="poc-dashboard-card-title" style={{ fontWeight: 600 }}>{labels[edit_view]?.title}</h4>
                    <img src={organization_icons.close_icon} alt="close_icon" style={{ cursor: "pointer" }} onClick={() => onClose()} />
                </div>
                <div style={{ marginTop: "1em" }}>
                    <h4 className='vertical-form-label'><span style={{ color: "#E86161" }}>*</span>{labels[edit_view]?.name_field}</h4>
                    <Input className='vertical-input' maxLength={200} style={{ marginTop: "0.75em" }} placeholder={labels[edit_view]?.place_holder} value={name} onChange={(e) => SetName(e?.target?.value)}></Input>
                </div>
                <div style={{ marginTop: "1em" }}>
                    <h4 className='vertical-form-label'>{labels[edit_view]?.manager_field}</h4>
                    <Dropdown overlay={DropDownMenu("manager",managers_list)} overlayStyle={{ minWidth: "none" }} visible={manager_dropdown_visibility} overlayClassName="tree-dropdown-menu" placement="bottomLeft" >
                        <div className="tree-multiselect-label-container" onClick={() => { SetManagerDropdownVisibility(true) }}>
                            {
                                managers_list?.map(i => (
                                    <div className="tree-label-chip"><h4><span className="text-transform-capitalize">{i?.user?.firstName}</span> <span className="text-transform-capitalize">{i?.user?.lastName}</span> </h4> <img src={organization_icons?.chip_close_icon} onClick={(e) => { selectManager(i); e.stopPropagation() }} className="chip-clear" alt="" /></div>
                                ))
                            }
                        </div>
                    </Dropdown>
                </div>

                <div style={{ marginTop: "1em" }}>
                    <h4 className='vertical-form-label'>Employees</h4>
                    <Dropdown overlay={DropDownMenu("employee",selected_employee_list?.filter(i=>!i?.member_deleted))} overlayStyle={{ minWidth: "none" }} visible={employee_dropdown_visibility} overlayClassName="tree-dropdown-menu" placement="bottomLeft" >
                        <div className="tree-multiselect-label-container" onClick={() => { SetEmployeeDropdownVisibility(true) }}>
                            {
                                selected_employee_list?.filter(i=>!i?.member_deleted)?.map(i => (
                                    <div className="tree-label-chip"><h4><span className="text-transform-capitalize">{i?.user?.firstName}</span> <span className="text-transform-capitalize">{i?.user?.lastName}</span> </h4> <img src={organization_icons?.chip_close_icon} onClick={(e) => { selectEmployee(i); e.stopPropagation() }} className="chip-clear" alt="" /></div>
                                ))
                            }
                        </div>
                    </Dropdown>
                </div>

                <div style={{marginTop:"5em",display:"flex",justifyContent:"end"}}>
                    <Button className="poc-active-btn" onClick={()=>{onSave()}}>Save Changes</Button>
                </div>
            </div>
        </Spin>
    )
}

export default TreeEditView;