// @ts-nocheck
// @ts-ignore
import { Button, Checkbox, Dropdown, Menu, Spin } from "antd";
import React from "react";
import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";
import { scape_report_home_icon } from "../icons";
import { Capitalize } from "modules/look";

const OrganizationLeverFilter = ( props ) => {
    // @ts-ignore
    const { scape_list, organization_level_list, selected_employe_list, selected_choice_data, organization_loading, me, choice_selection_data, enable_button } = props

    // @ts-ignore
    const [ show_teams, setShowTeams ] = React.useState()
    const [ show_employee, setShowEmployee ] = React.useState()
    const [ selected_vertical, setSelectedVertical ] = React.useState()
    const [ selected_employes, setSelectedEmployes ] = React.useState( [] )
    const [ select_all, setSelectAll ] = React.useState( false )
    const [ selected_teams, setSelectedTeams ] = React.useState( [] )
    const [ selected_employee_ids, setSelectedEmployeeIds ] = React.useState( [] )
    const [ selected_employee_teams, setSelectedEmployeeTeams ] = React.useState( [] )
    const [ selecte_parent_vertical, setSelectedParentVertical ] = React.useState()
    const [ show_drop, setShowDrop ] = React.useState( false )
    const [ title, setTitle ] = React.useState()
    const [ selected_choice, setSelectedChoiceData ] = React.useState()
    const [ graph_data, setGraphData ] = React.useState()
    const [ organization_vertical_list, setOrganizationVerticalList ] = React.useState( [] )

    React.useEffect( () => {
        if ( organization_level_list?.length ) {
            let list = organization_level_list?.filter(value=>value?.teamSet?.edges?.length>0)?.map( item => {
                return {
                    ...item,
                    teamSet: {
                        edges: item?.teamSet?.edges?.filter(item=>item?.node?.employees?.edges?.length>0)?.map( ( value ) => {
                            return {
                                node: {
                                    ...value?.node,
                                    parent_vertical: item?.name,
                                    parent_vertical_id: item?.id,
                                    employees: {
                                        edges: value?.node?.employees?.edges?.map( ( { node } ) => {
                                            return {
                                                node: {
                                                    ...node,
                                                    parent_team: value?.node?.name,
                                                    parent_id: value?.node?.id
                                                }
                                            }
                                        } )
                                    }
                                }
                            }
                        } )
                    },
                    // employees:item?.employees?.edges?.map(({node})=>{
                    //     return {
                    //         node: {
                    //             ...node,
                    //             parent_team: item?.name,
                    //             parent_id: item?.id
                    //         }
                    //     }
                    // })
                }
            } )

            setOrganizationVerticalList( list )
        }
    }, [ organization_level_list ] )

    React.useEffect( () => {
        if ( selected_employes?.length > 0 ) {
            selected_employe_list( selected_employes )
        }
        else {
            selected_employe_list( [] )
        }
    }, [ selected_employes ] )

    React.useEffect( () => {
        enable_button( show_drop )
    }, [ show_drop ] )
    React.useEffect( () => {
        if ( graph_data !== undefined || graph_data !== null ) {
            choice_selection_data( graph_data )
        }
        else {
            choice_selection_data()
        }
    }, [ graph_data ] )

    React.useEffect( () => {
        if ( selected_choice !== undefined || selected_choice !== null ) {
            selected_choice_data( selected_choice )
        }
        else {
            selected_choice_data()
        }
    }, [ selected_choice ] )

    //Select vertical filter
    const selectVerival = ( event, verticalList ) => {
        if ( select_all ) {
            setSelectAll( false )
            setTitle()
        }
        if ( selected_vertical && verticalList?.id === selected_vertical ) {
            setSelectedVertical()
            setSelectedEmployes( [] )
            setSelectedEmployeeIds( [] )
            setSelectedTeams( [] )
            setTitle()
            setSelectAll( false )
            setSelectedChoiceData()
        }
        else {
            setSelectedVertical( verticalList?.id )
            let team_list = verticalList?.teamSet?.edges?.map( ( { node } ) => node )
            setSelectedTeams( team_list?.map( team => team?.id ) )
            let slected_vertical_employees = [].concat( ...team_list?.map( ( value ) =>
                value?.employees?.edges?.map( ( { node } ) => node?.id ) ) )
            var setObj = new Set();
            let emp_list = slected_vertical_employees.reduce( ( acc, item ) => {
                if ( !setObj.has( item ) ) {
                    setObj.add( item )
                    acc.push( item )
                }
                return acc;
            }, [] )
            let selected_vertical_data = {
                vertical: {
                    id: verticalList?.id,
                    name: verticalList?.name
                },
                team: verticalList?.teamSet?.edges?.map( ( { node } ) => node ),
                graph_type: 'vertical'
            }
            setGraphData( selected_vertical_data )
            setSelectedEmployes( emp_list )
            setSelectedEmployeeIds( emp_list )
            setTitle( verticalList?.name )
            setSelectedChoiceData( { item: verticalList, type: 'vertical' } )
        }
    }

    //Select Team filter
    const selectTeam = ( team_list, vertical ) => {
        setSelectedVertical()
        if ( select_all ) {
            setSelectAll( false )
            setTitle()
        }
        setSelectedParentVertical( vertical?.id )
        if ( selecte_parent_vertical === vertical?.id || !selecte_parent_vertical ) {
            let team_ids = [ ...selected_teams ]
            if ( team_ids.includes( team_list?.id ) ) {
                let index = team_ids.indexOf( team_list?.id )
                team_ids.splice( index, 1 )
            }
            else {
                team_ids.push( team_list?.id )
            }
            let vertical_team_list = vertical?.teamSet?.edges?.map( ( { node } ) => node )
            let selected_team_list = vertical_team_list.filter( obj => team_ids.includes( obj.id ) );

            let employee_list = [].concat( ...selected_team_list?.map( item => item?.employees?.edges?.map( ( { node } ) => node?.id ) ) )
            var setObj = new Set();
            let emp_list = employee_list.reduce( ( acc, item ) => {
                if ( !setObj.has( item ) ) {
                    setObj.add( item )
                    acc.push( item )
                }
                return acc;
            }, [] )
            let selected_team_data = {
                team: selected_team_list,
                employee_list: [].concat( ...selected_team_list?.map( item => item?.employees?.edges?.map( ( { node } ) => node ) ) ),
                graph_type: selected_team_list?.length > 1 ? 'vertical' : 'teams',
                vertical: {
                    id: vertical?.id,
                    name: vertical?.name
                }
            }
            setGraphData( selected_team_data )
            setSelectedEmployes( emp_list )
            setSelectedEmployeeIds( emp_list )
            setSelectedTeams( team_ids )
            setTitle( selected_team_list.map( item => item.name ).join( ', ' ) )
            setSelectedChoiceData( { item: selected_team_list, type: "team" } )
        }
        else {
            setSelectedEmployeeIds( [] )
            setSelectedTeams( [] )
            setSelectedVertical()
            setTitle()
            setSelectedChoiceData()

            let team_ids = []
            team_ids.push( team_list?.id )

            let vertical_team_list = vertical?.teamSet?.edges?.map( ( { node } ) => node )
            let selected_team_list = vertical_team_list.filter( obj => team_ids.includes( obj.id ) );

            let employee_list = [].concat( ...selected_team_list?.map( item => item?.employees?.edges?.map( ( { node } ) => node?.id ) ) )
            var setObj = new Set();
            let emp_list = employee_list.reduce( ( acc, item ) => {
                if ( !setObj.has( item ) ) {
                    setObj.add( item )
                    acc.push( item )
                }
                return acc;
            }, [] )
            let selected_team_data = {
                team: selected_team_list,
                employee_list: [].concat( ...selected_team_list?.map( item => item?.employees?.edges?.map( ( { node } ) => node ) ) ),
                graph_type: selected_team_list?.length > 1 ? 'vertical' : 'teams',
                vertical: {
                    id: vertical?.id,
                    name: vertical?.name
                }
            }
            setGraphData( selected_team_data )
            setSelectedEmployes( emp_list )
            setSelectedEmployeeIds( emp_list )
            setSelectedTeams( team_ids )
            setTitle( selected_team_list.map( item => item.name ).join( ', ' ) )
            setSelectedChoiceData( { item: selected_team_list, type: "team" } )
        }
    }

    //Select Employees filter
    const selectEmployes = ( selected_employee, vertical, team ) => {
        if ( select_all ) {
            setSelectAll( false )
            setTitle()
        }
        setSelectedTeams( [] )
        setSelectedVertical()
        setSelectedParentVertical( vertical?.id )
        if ( selecte_parent_vertical === vertical?.id || !selecte_parent_vertical ) {

            let id_list = [ ...selected_employee_ids ]
            if ( id_list.includes( selected_employee ) ) {
                let index = id_list.indexOf( selected_employee )
                id_list.splice( index, 1 )
            }
            else {
                id_list.push( selected_employee )
            }
            let vertical_employee_list = [].concat( ...vertical?.teamSet?.edges?.map( ( { node } ) => node?.employees?.edges?.map( ( { node } ) => node ) ) )
            let selected_team_list = vertical_employee_list.filter( obj => id_list.includes( obj?.id ) );
            var setObj = new Set();
            let emp_list = selected_team_list.reduce( ( acc, item ) => {
                if ( !setObj.has( item?.id ) ) {
                    setObj.add( item?.id )
                    acc.push( item )
                }
                return acc;
            }, [] )
            let team_list = [ ...selected_employee_teams ]
            team_list.push( team )
            setSelectedEmployeeTeams( team_list )
            let selected_teams = team_list.reduce( ( acc, item ) => {
                if ( !setObj.has( item?.id ) ) {
                    setObj.add( item?.id )
                    acc.push( item )
                }
                return acc;
            }, [] )
            let selected_employee_data = {
                employee: emp_list,
                graph_type: selected_teams?.length > 1 ? 'vertical' : 'teams',
                vertical: {
                    id: vertical?.id,
                    name: vertical?.name
                },
                team: selected_teams
            }

            setGraphData( selected_employee_data )

            setSelectedEmployeeIds( id_list )
            setSelectedEmployes( emp_list?.map( item => item?.id ) )
            setTitle( emp_list?.map( ( item ) => item?.user?.firstName ).join( "," ) )
            setSelectedChoiceData( { item: emp_list, type: "employee", team: selected_teams } )
        }
        else {
            setSelectedEmployeeIds( [] )
            setSelectedEmployes( [] )
            setTitle()
            setSelectedChoiceData()
            let id_list = []
            id_list.push( selected_employee )
            let vertical_employee_list = [].concat( ...vertical?.teamSet?.edges?.map( ( { node } ) => node?.employees?.edges?.map( ( { node } ) => node ) ) )
            let selected_team_list = vertical_employee_list.filter( obj => id_list.includes( obj?.id ) );
            var setObj = new Set();
            let emp_list = selected_team_list.reduce( ( acc, item ) => {
                if ( !setObj.has( item?.id ) ) {
                    setObj.add( item?.id )
                    acc.push( item )
                }
                return acc;
            }, [] )
            let team_list = [ ...selected_employee_teams ]
            team_list.push( team )
            setSelectedEmployeeTeams( team_list )
            let selected_teams = team_list.reduce( ( acc, item ) => {
                if ( !setObj.has( item?.id ) ) {
                    setObj.add( item?.id )
                    acc.push( item )
                }
                return acc;
            }, [] )
            let selected_employee_data = {
                employee: emp_list,
                graph_type: selected_teams?.length > 1 ? 'vertical' : 'teams',
                vertical: {
                    id: vertical?.id,
                    name: vertical?.name
                },
                team: selected_teams
            }

            setGraphData( selected_employee_data )

            setSelectedEmployeeIds( id_list )
            setSelectedEmployes( emp_list?.map( item => item?.id ) )
            setTitle( emp_list?.map( ( item ) => item?.user?.firstName ).join( "," ) )
            setSelectedChoiceData( { item: emp_list, type: "employee", team: selected_teams } )

        }
    }

    const expandVertical = ( vertical_id ) => {
        if ( vertical_id != show_teams ) {
            setShowTeams( vertical_id )
        }
        else {

            setShowTeams()
        }
    }

    const expandTeams = ( teams_id ) => {
        if ( teams_id != show_employee ) {
            setShowEmployee( teams_id )
        }
        else {

            setShowEmployee()
        }

    }

    const selectAll = ( checked ) => {
        if ( checked ) {
            let all_verticals = [].concat( ...organization_vertical_list?.map( vertical => vertical?.teamSet?.edges?.map( ( { node } ) => node ) ) )
            let selelted_org_employes = [].concat( ...all_verticals?.map( value =>
                value?.employees?.edges?.map( ( item ) => item?.node?.id )
            ) )
            var setObj = new Set();
            let emp_list = selelted_org_employes.reduce( ( acc, item ) => {
                if ( !setObj.has( item ) ) {
                    setObj.add( item )
                    acc.push( item )
                }
                return acc;
            }, [] )
            setSelectedEmployes( emp_list )
            setSelectedChoiceData( { item: organization_vertical_list?.map( vertical => vertical ), type: "organization" } )
            let selected_org_data = {
                org: {
                    id: me?.employee?.organizationSet?.edges[ 0 ]?.node?.id,
                    name: me?.employee?.organizationSet?.edges[ 0 ]?.node?.name
                },
                vertical: organization_vertical_list?.map( vertical => vertical ),
                graph_type: 'org'
            }
            setGraphData( selected_org_data )
            setTitle( me?.employee?.organizationSet?.edges[ 0 ]?.node?.name )
        }
        else {
            // @ts-ignore
            setSelectedEmployes( [] )
            setSelectedChoiceData()
            setSelectedVertical()
            setSelectedEmployeeIds( [] )
            setSelectedTeams( [] )
            setTitle()
        }
        setSelectAll( checked )

    }

    const userSurveys = () => (

        <div style={{ display: 'flex', justifyContent: organization_loading ? 'center' : 'start', alignItems: "center", width: "100%", background: "#ECF1F4" }}>
            <Spin spinning={organization_loading}>
                <div className="scape-vertical-filter">
                    {organization_vertical_list?.length > 0 && <div className="scape-filter-content" style={{ marginLeft: "2em" }}>
                        <Checkbox checked={select_all} onChange={() => selectAll( !select_all )} />
                        <h4 className="scape-filter-text">Select All</h4>
                    </div>}
                    {organization_vertical_list?.map( vertical => (
                        <div style={{ display: "flex", flexDirection: "column", gap: ".5em" }}>
                            <div className="scape-filter-content">
                                {vertical?.teamSet?.edges?.length > 0?<img src={scape_report_home_icon?.dropdown_Arrow} alt="" style={{ width: "13px", height: "10px", rotate: show_teams === vertical?.id ? "0deg" : "-90deg", cursor: "pointer" }} onClick={() => expandVertical( vertical?.id )} /> : <div style={{ width: "13px", height: "10px" }}></div>}
                                <Checkbox checked={vertical?.id === selected_vertical || select_all} onChange={( e ) => selectVerival( e, vertical )} />
                                <h4 className="scape-filter-text">{vertical?.name}</h4>
                            </div>
                            {show_teams === vertical?.id && vertical?.teamSet?.edges?.map( ( { node } ) => (
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "2em", gap: ".5em" }}>
                                    <div className="scape-filter-content">
                                        {node?.employees?.edges?.length > 0 ? <img src={scape_report_home_icon?.dropdown_Arrow} alt="" style={{ width: "13px", height: "10px", rotate: show_employee === node?.id ? "0deg" : "-90deg", cursor: "pointer" }} onClick={() => expandTeams( node?.id )} /> : <div style={{ width: "13px", height: "10px" }}></div>}
                                        <Checkbox checked={selected_vertical ? ( selected_vertical == node?.parent_vertical_id && selected_teams?.includes( node?.id ) ) : selected_teams?.includes( node?.id ) || select_all} onChange={() => selectTeam( node, vertical )} />
                                        <h4 className="scape-filter-text">{node?.name}</h4>
                                    </div>
                                    {show_employee === node?.id && node?.employees?.edges?.map( ( emp ) => (
                                        <div className="scape-filter-content" style={{ marginLeft: "3em", marginTop: ".3em" }}>
                                            <Checkbox checked={( ( selected_teams?.length > 0 ? ( selected_employee_ids?.includes( emp?.node?.id ) && selected_teams?.includes( emp?.node?.parent_id ) ) : selected_employee_ids?.includes( emp?.node?.id ) ) ) || select_all} onChange={() => selectEmployes( emp?.node?.id, vertical, node )} />
                                            <h4 className="scape-filter-text">{Capitalize(emp?.node?.user?.firstName)} {Capitalize(emp?.node?.user?.lastName)}</h4>
                                        </div>
                                    ) )}
                                </div>
                            ) )}
                            {/* {show_teams === vertical?.id&&vertical?.employees?.map((item)=>(
                                <div className="scape-filter-content" style={{ marginLeft: "3em", marginTop: ".3em" }}>
                                <Checkbox checked={( ( selected_teams?.length > 0 ? ( selected_employee_ids?.includes( item?.node?.id ) && selected_teams?.includes( item?.node?.parent_id ) ) : selected_employee_ids?.includes( item?.node?.id ) ) ) || select_all} onChange={() => selectEmployes( item?.node?.id, vertical, item?.node )} />
                                <h4 className="scape-filter-text">{item?.node?.user?.firstName} {item?.node?.user?.lastName}</h4>
                                </div>
                            ))} */}
                           
                        </div>
                    ) )}
                </div>
            </Spin>
        </div>
    );

    return (

        <div>
            <Dropdown overlay={userSurveys} visible={show_drop} overlayStyle={{ height: "100px",zIndex:"1050" }}>
                <DropdownButton className='scape-dropdown'
                    // @ts-ignore
                    placement="bottom" onClick={() => setShowDrop( true )}>
                    <p style={{ marginTop: '10px', whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", color: title ? "#303030" : "#BFBFBF" }}>{title ? title : 'Select team or individuals'}</p>
                    <img src={scape_report_home_icon?.dropdown_Arrow} alt="" style={{ width: "13px", height: "10px", marginTop: "-5px" }} />
                    <DownOutlined />
                </DropdownButton>
            </Dropdown>
            {show_drop ? ( <div className='overlay-dynamic-island' onClick={() => setShowDrop( false )} /> ) : null}
        </div>
    )
}

export default OrganizationLeverFilter
const DropdownButton = styled( Button )`
// width:100%;
// display:flex;
// justify-content:space-between;
// align-items:center;
// gap:5px
// z-index:0;
`