import React from 'react';

import { compose } from '../../core';
import { AdminLayout, Capitalize, displayDataCheck, displayUtcToUserTimezone, GetColumnSearchProps, getOrderBy } from '../../look';

import ROUTE from '../route';
import {
  withAllCommentsForStoryByStoryId,
  withDeleteComment,
  withStoryFilterUpdating,
  withStoryState
} from './StoryOperations';
import { subscribeToStoryComment } from './StorySubscriptions';

const Comment = props => {
  const {
    loading,
    comments,
    onPaginationChange,
    deleteComment,
    onOrderByChange,
    orderBy,
    onFiltersRemove,
    onCommentTextChange,
    onUserChange,
    subscribeToMore
  } = props;

  React.useEffect(() => {
    const subscribe = subscribeToStoryComment(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  const columns = [
    {
      title: 'Comment',
      key: 'commentText',
      sorter: () => onOrderByChange(getOrderBy('commentText', orderBy)),
      ...GetColumnSearchProps('commentText', onCommentTextChange),
      render: (text, record) => record && displayDataCheck(record.commentText)
    },
    {
      title: 'User',
      key: 'user',
      sorter: () => onOrderByChange(getOrderBy('user', orderBy)),
      ...GetColumnSearchProps('name', onUserChange, 'user'),
      render: (text, record) => record && displayDataCheck(`${Capitalize(record.user.firstName)} ${Capitalize(record.user.lastName)}`)
    },
    {
      title: 'Date',
      key: 'commentDate',
      sorter: () => onOrderByChange(getOrderBy('commentDate', orderBy)),
      render: (text, record) => `${displayUtcToUserTimezone(record.commentDate, 'YYYY-M-DD HH:mm')}`
    }
  ];

  const handleDeleteRecord = async(id) =>{
    await deleteComment({commentId:id})
  }

  return (
    <AdminLayout
      table={true}
      active={ROUTE.story}
      title="Comments"
      showBack={true}
      loading={loading}
      tableData={comments}
      columns={columns}
      onDelete={deleteComment}
      specificMutation = {true}
      handleDeleteRecord = {handleDeleteRecord}
      // editLink={ROUTE.editCommentLink}
      // addLink={`${ROUTE.addCommentLink}${props.match.params.id}`}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
    />
  );
};

export default compose(
  withStoryState,
  withAllCommentsForStoryByStoryId,
  withDeleteComment,
  withStoryFilterUpdating
)(Comment);
