import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button,Modal, Tabs, Dropdown, Menu, Tooltip } from "antd";
import styled from 'styled-components';
import { MetaTags, getIntFromString } from '../../look';

import UpcomingMeetingContainer from '../containers/UpcomingMeetingContainer';
import PastMeetingContainer from '../containers/PastMeetingContainer';
import FutureMeetingContainer from '../containers/FutureMeetingContainer';
import { GOAL_HOME_TOOL_ID, ONEONONE_HOME_TOOL_ID, ROLEGOAL_HOME_TOOL_ID } from '../../../config'
import '../css/one-on-one.css'
import Quick1_1 from './QuickMeeting'
import prepare_meeting from '../../assets/Prepare-meeting.svg'
import previous_meeting from '../../assets/previous-meeting.svg'
import agenda_meeting from '../../assets/agenda-meeting.svg'
import learn_meeting from '../../assets/learn-more.svg'
import feedback_meeting from '../../assets/feedback-meeting.svg'
import Dropdown_arrow from '../../assets/arrow-down-one-on-one.svg';
import FilterOneOnOne from './OneOnOneFilter';
import bookmark_link from '../../assets/bookmark-link.svg'
import dropdown_arrow from '../../assets/one-on-one-dropdown-arrow.svg'
import SideToolOneOnOne from '../containers/OneOnOneSideToolContainer';
import one_on_one_route from '../route/index'
import MeetingAiTipContainer from 'modules/ai-tips/container/meeting-ai-tip-container';
const OneonOneHomeView = props => {
  const {
    loading,
    me,
    type,
    getToolById
  } = props;
  const [quickMeeting, setquickMeeting] = React.useState(false);

  const [selectedTab, setTab] = React.useState();
  const [selectedDrop, setSelectedDrop] = React.useState('This Week')
  const [filteritem, setFilterData] = React.useState()
  const [current_search_index,setCurrentSearchIndex]=React.useState()
  const [toolReturn,setToolReturn]=React.useState()
  const history = useHistory();
  const { TabPane } = Tabs;
  React.useEffect(() => {
    if (type) {
      setTab(type)
    }
  }, [type])

  const changeTab = (key) => {
    if (key === '1_1') {
      history.push(one_on_one_route?.oneOnOneHome?.replace(":type","1_1"))
    }
    else if (key === 'upcoming') {
      history.push(one_on_one_route?.oneOnOneHome?.replace(":type","upcoming"))
    }
    else if (key === 'future') {
      history.push(one_on_one_route?.oneOnOneHome?.replace(":type","future"))
    }
    setTab(key)
  }

  const closeQuick = (data) => {
    setquickMeeting(false)
    if (data?.meeting) {
      history.push(one_on_one_route?.Meeting?.replace(":meetingid",getIntFromString(data?.meeting)))
    }
  }

  const filterData = [
    { type: 'Title', key: 'Title' },
    { type: 'Action item', key: 'Action item' },
    { type: 'Agenda', key: 'Agenda' },
    { type: 'Owner', key: 'Owner' },
    { type: 'Member', key: 'Member' }
  ]

  const SubmitFilter = (data) => {
    setFilterData(data)
  }

  const navigateTo = () => {
    history.push(one_on_one_route?.addOneonOne)
  };


  return (
    <>
      <MetaTags
        title="One on One"
        description="This is One on One page."
      />
  

      <div className='one-on-one-home-container one-one-one-home-view'>
     

        <Row justify="center" align="middle" style={{ display: 'flex', flexDirection: 'column', gap: "10px", position: 'relative' }} className="one-on-one-heading-seaction ">

          <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
              <h2 className="one-on-one-head">1 : 1</h2>
            </div>
          </div>
          <div style={{ position: 'relative' }}>
            <SideToolOneOnOne
              {...props}
              widthOfContainer={24}
              containerBorder={""}
              spacing={'0px'}
              hideTopDivider={true}
              keepDesign={true}
              toolReturn={e=>setToolReturn(e)}
              tool_id={ONEONONE_HOME_TOOL_ID}
            ></SideToolOneOnOne>
          </div>
        </Row>

        {/* Page Title and Back Arrow */}
        {/* <div className="main-title-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '0em 1em 2em 1em', position: 'relative', overflow: 'auto' }}>
          <img onClick={(e) => back()} src={Back_icon} alt="back-arrow" style={{ alignContent: 'center', height: '10%' }} />
          <p className="goalheader poppins" style={{ margin: 'auto', textAlign: 'center', color: 'black' }}>Goal</p>
          <img onClick={(e) => back()} src={Back_icon} alt="back-arrow" style={{ alignContent: 'center', height: '10%', visibility: 'hidden' }} />
        </div> */}

        <Row justify='center' align='center'>
          <Col lg={23} md={23} sm={24} xs={24}>
            <Row className='greeting-section' style={{ gap: "10px" }}>
              <Col lg={8} md={24} sm={24} xs={24} className='greeting-content-section' style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "start" }}>
                <h2 className='greeting-title'>Hi <span className="text-transform-capitalize">{me?.firstName}</span>!</h2>
                <p className='greeting-paragraph'>Manage all your 1:1s here.</p>
              </Col>
              <Col lg={9} md={24} sm={24} xs={24} className='metting-button-section' style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: "center", gap: "10px", marginLeft: "-20px" }}>
                <Button className='instant-btn' onClick={() => setquickMeeting(true)}>Instant 1:1</Button>
                <Button className='schedule-btn' onClick={() => navigateTo()}>Schedule 1:1</Button>
              </Col>
            </Row>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", paddingTop: "20px" }}>
              <FilterOneOnOne
                onfilterChange={(e) => SubmitFilter(e)}
                filterData={filterData}
                defaultFilter={filterData[0]}
                seachIndex={e=>setCurrentSearchIndex(e)}
              />
            </div>
            {
              selectedTab && (
                <Row gutter={20} style={{ width: "100%", marginTop: "20px" }}>

                  <Col className='one-on-one-tab one-on-one-left-section'>
                    <StyledTabs defaultActiveKey={selectedTab} onChange={changeTab} selectedTab = {selectedTab} selectedDrop = {selectedDrop}>
                      <TabPane tab="Previous" key="1_1">
                        <div >
                          <PastMeetingContainer selectedTab={selectedTab} filteritem={filteritem} {...props}/>
                        </div>
                      </TabPane>
                      <TabPane tab={<Dropdown 
                      overlayStyle={{zIndex:"1050"}}
                      overlay={
                        <Menu className='one-on-one-menu'>
                          {selectedDrop != 'This Week' && <Menu.Item key="thisWeek" onClick={() => setSelectedDrop('This Week')}>This Week</Menu.Item>}
                          {selectedDrop != 'Today' && <Menu.Item key="today" onClick={() => setSelectedDrop('Today')}>Today</Menu.Item>}
                        </Menu>} placement="bottomCenter">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "5px"}}>
                          {selectedDrop} {selectedTab === "upcoming" ? <img src={Dropdown_arrow} alt="" /> : <img src={dropdown_arrow} alt='' />}
                        </div>
                      </Dropdown>} key="upcoming">
                        <div >
                          <UpcomingMeetingContainer selectedTab={selectedTab} selectedDrop={selectedDrop} filteritem={filteritem} {...props} />
                        </div>
                      </TabPane>
                      <TabPane tab="Upcoming" key="future">
                        <div >
                          <FutureMeetingContainer selectedTab={selectedTab} filteritem={filteritem} {...props} />
                        </div>
                      </TabPane>

                    </StyledTabs>
                  </Col>
                  <Col className="one-on-one-right-section" style={{ marginTop: '46px', height: "100%", minHeight: "485px" }}>
                    <div className='one-on-one-bookmark' style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                      <Tooltip title={'Coming Soon...'}>
                        <h4 style={{ opacity: '0.5' }} className='link-title'>BOOKMARKS</h4>
                      </Tooltip>
                      <Tooltip title={'Coming Soon...'}>
                        <img src={bookmark_link} alt="" style={{ position: "absolute", right: "10px", opacity: '0.5' }} />
                      </Tooltip>
                    </div>
                    <div className='one-on-one-quick-link-container'>
                      <Tooltip title={'Coming Soon...'}>
                        <h4 className='link-title' style={{ opacity: '0.5' }}>QUICK LINKS</h4>
                      </Tooltip>
                      <Tooltip title={'Coming Soon...'}>
                        <div style={{ opacity: '0.5' }} className='quick-link-button' >
                          <img src={previous_meeting} alt="" className='quick-link-icon' />
                          <h4 className='quick-link-title'>Action Items of previous 1:1</h4>
                        </div>
                      </Tooltip>
                      <Tooltip title={'Coming Soon...'}>
                        <div style={{ opacity: '0.5' }} className='quick-link-button'>
                          <img src={agenda_meeting} alt="" className='quick-link-icon' />
                          <h4 className='quick-link-title'>Agenda for next 1:1</h4>
                        </div>
                      </Tooltip>
                      <Tooltip title={'Coming Soon...'}>
                        <div style={{ opacity: '0.5' }} className='quick-link-button'>
                          <img src={prepare_meeting} alt="" className='quick-link-icon' />
                          <h4 className='quick-link-title'>Prepare for next 1:1</h4>
                        </div>
                      </Tooltip>

                      <div className='quick-link-button'>
                        <img src={feedback_meeting} alt="" className='quick-link-icon' />
                        <h4 onClick={(e) => history.push(one_on_one_route?.effectivenessHome)} className='quick-link-title'>1:1 Effectiveness</h4>
                      </div>

                      <Tooltip title={'Coming Soon...'}>
                        <div style={{ opacity: '0.5' }} className='quick-link-button'>
                          <img src={learn_meeting} alt="" className='quick-link-icon' />
                          <h4 className='quick-link-title'>Learn more about 1:1s</h4>
                        </div>
                      </Tooltip>
                    </div>
                  </Col>
                  {/* <ColScroll lg={{ span: 8 }}  md={{ span: 0 }} xs={{ span: 0 }} sm={{ span: 0 }} style={{height:'70vh',overflow:'auto'}}>
                  {getToolById?
                      <div style={{paddingTop:'35px'}}>
                          <Col>
                                
                                <GenericTool
                                  {...props}
                                  widthOfContainer={40}
                                  containerBorder={""}
                                  spacing={'0px'}
                                  hideTopDivider={true}
                              ></GenericTool>
                          </Col>
                      </div>:
                      <div align="center">
                          <Spin></Spin>  
                      </div> 
              }
                  </ColScroll> */}

                </Row>
              )
            }
          </Col>
        </Row>
        {
          quickMeeting ?
            <Modal
              footer={null}
              centered
              visible={quickMeeting}
              // width={"30%"}
              destroyOnClose={true}
              //  onCancel={() => {setquickMeeting(false)}}
              closable={false}
            >
              <Quick1_1 me={me} onClose={(e) => { closeQuick(e) }} />
            </Modal>
            : null
        }
      </div>
    </>
  );
};

export default OneonOneHomeView;

const RowDiv = styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`

const ColScroll = styled(Col)`
::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0;
  border-radius: 10px;
  padding-right: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#B0CB1F;
}
`

const StyledTabs = styled(Tabs)`

.ant-tabs-tab-active{
  width:${props=>props?.selectedDrop === 'This Week' && props?.selectedTab  === "upcoming" ? '100px' : props=>props?.selectedDrop === 'Today'  && props?.selectedTab  === "upcoming" ? "70px"  : 'auto'};
}

@media only screen and (min-width: 1700px) {
  .ant-tabs-tab-active{
    width:${props=>props?.selectedDrop === 'This Week'  && props?.selectedTab  === "upcoming" ? '110px' : props=>props?.selectedDrop === 'Today' && props?.selectedTab  === "upcoming" ? "80px"  : 'auto'};
  }
}


`