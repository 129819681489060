import React from 'react';
import { Button, Menu,Select,message } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { compose } from '../../core';
import { AdminLayout, displayDataCheck, GetColumnSearchProps, getIntFromString, getOrderBy } from '../../look';
import { withApollo } from '@apollo/client/react/hoc';
import ROUTE from '../route';
import '../css/employee.css'

import {
  withAllEmployees,
  withDeleteEmployee,
  withOrganizationState,
  withOrganizationFilterUpdating,
  withAllOrganization,
  withAllOrganizationList,
  withUpdateOrganization
} from './OrganizationOperation';
import { subscribeToEmployee } from './OrganizationSubscriptions';
import { EMPLOYEE_SUBSCRIPTION } from '../graphql/EmployeeSubscription.gql';

const Employee = props => {
  const {
    loading,
    onPaginationChange,
    deleteEmployee,
    allEmployees,
    onOrderByChange,
    orderBy,
    onFiltersRemove,
    onUserChange,
    subscribeToMore,
    allOrganization,
    updateOrganization,
    client,
    me
  } = props;
  const { Option } = Select;
  

  const [allEmployeesSet,setAllEmployees] = React.useState(undefined);




  const AssignOrganization = async(event,value)=>{
    
    try{
    let response =await updateOrganization({id:getIntFromString(event),employees:[getIntFromString(value)]})
    if(response){
      message.success("Organization updated successfully")
    }
  }
  catch(error){
    
  }
  }


  React.useEffect(()=>{

    if(me){
      SubscribeEmployees();
    }

  },[me])
  

  let employeeSubscription = undefined;
  const m = React.useRef(true)
  const employeeDataRef = React.useRef(null)


  React.useEffect(()=>{

    if(allEmployees){
      setAllEmployees(allEmployees)
      employeeDataRef.current = allEmployees;

    }

  },[allEmployees])



  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])


  React.useEffect(() => {
    return () => {
      if (employeeSubscription) {
        employeeSubscription.unsubscribe();
      }
    }
  }, [])
 



  const SubscribeEmployees = () => {

    employeeSubscription = client.subscribe({
      query: EMPLOYEE_SUBSCRIPTION,
    }).subscribe({
      next(result) {

        switch (result.data.employeeSubscription.mutation) {
         
          case 'DELETE':
           
            if (m.current) {
              let employees = employeeDataRef.current?.edges
              let employeeSetWithDeletedRecord = employees?.filter((employee)=>
              employee?.node?.id !== result.data.employeeSubscription?.employee?.id)
              let updatedEmployeeSet = {edgeCount:(employeeDataRef.current?.edgeCount - 1 ),totalCount:(employeeDataRef.current?.totalCount - 1 ),edges:employeeSetWithDeletedRecord}
              
              setAllEmployees(updatedEmployeeSet);
             
              employeeDataRef.current = updatedEmployeeSet;
              
            }

            break;
          default:
            break
        }

      }
    })

  }


  const columns = [
    {
      title: 'User',
      key: 'user',
      sorter: () => onOrderByChange( getOrderBy( 'user__firstName', orderBy ) ),
      ...GetColumnSearchProps( 'name', onUserChange, 'user' ),
      render: ( text, record ) => (
        <span className='text-transform-capitalize'>{record && displayDataCheck( record.user.firstName ? record.user.firstName : record.user.username )}</span>
      )
    },
    {
      title: 'Organization',
      key: 'organization',
      dataIndex: 'organization',
      sorter: () => onOrderByChange(getOrderBy('organization', orderBy)),
      render: ( text, record ) => <>{record && record?.organizationSet?.edges?.length>0?record?.organizationSet?.edges[ 0 ]?.node?.name: 
      // <Select
      //   style={{ textOverflow: "ellipsis", whiteSpace: "nowrap",width: "250px"}}
      //   showSearch
      //   // onSearch={( e ) => getSurveyLists( { name_Icontains: e } )}
      //   placeholder='select Organization'
      //   filterOption={false}
      //   onChange={(e)=>AssignOrganization(e,record?.user?.id)}
      //   {...props}
      // >
      //   {allOrganization?.edges?.map( ({node}) => (
      //     <Option
      //       key={node?.id}
      //       value={node?.id}
      //     >
      //       {node?.name}
      //     </Option>
      //   ) )}
      // </Select>
      "-"
      }</>
    },
    {
      title: 'Team',
      key: 'team',
      // sorter: () => onOrderByChange(getOrderBy('team__name', orderBy)),
      render: ( text, record ) => <>{record && record?.teamManagerEmployee?.edges[ 0 ]?.node?.name?record?.teamManagerEmployee?.edges[ 0 ]?.node?.name:'-'}</>
    },
    // {
    //   title: 'Created By',
    //   key: 'createdBy',
    //   sorter: () => onOrderByChange( getOrderBy( 'createdBy', orderBy ) ),
    //   render: ( text, record ) => <>{record && record.createdBy ? displayDataCheck( record.createdBy.email ) : '-'}</>
    // },
    // {
    //   title: 'Updated By',
    //   key: 'updatedBy',
    //   sorter: () => onOrderByChange(getOrderBy('updatedBy', orderBy)),
    //   render: (text, record) => <>{record && record.updatedBy && displayDataCheck(record.updatedBy.email)}</>
    // }
  ];


  const handleDeleteRecord = async(id) => {

    await deleteEmployee({id:id})

  }

  return (
    <AdminLayout
      active={ROUTE.employee}
      table={true}
      title="Employees"
      loading={loading}
      tableData={allEmployeesSet}
      specificMutation = {true}
      handleDeleteRecord = {handleDeleteRecord}
      columns={columns}
      onDelete={deleteEmployee}
      editLink={ROUTE.editEmployeeLink}
      addLink={ROUTE.addEmployee}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
      extra={
        <Button size="md" type="primary" ghost>
          <Link to={{ pathname: ROUTE.addEmployee, state: { type: 'multiple' } }}>
            <PlusOutlined />
            Add Multiple Employees
          </Link>
        </Button>
      }
    />
  );
};

export default compose(
  withOrganizationState,
  withDeleteEmployee,
  withAllEmployees,
  withAllOrganizationList,
  withOrganizationFilterUpdating,
  withUpdateOrganization,
  withApollo
)( Employee );
