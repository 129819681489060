import React from "react";
import GlobalButtonView from "../component/global-button-view";


const GlobalButtonContainer = (props) => {
    return (
        <GlobalButtonView {...props}/>
    )
}

export default GlobalButtonContainer
