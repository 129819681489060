import React from "react";
import { Select  } from "antd";
import { scrolltoview } from "modules/look";
import employee_icon from '../../assets/employee-profile-icon.svg'
const MultiSelectEmployee = props => {
    const { dataList,onChange,initialData,memberListInvalid,searchKey,disabled,fromQuickMeeting }=props
    const [defaultValue,setDefaultValue]=React.useState()
    console.log("dataList",dataList)
    const handleChange = (value) => {
        let ids =value?.key
        let selectedData = dataList?.filter(data => ids === data?.employee?.id)
        onChange(selectedData)
        setDefaultValue(selectedData.firstName)

        // let ids =value?.map(item=>item?.key)
        // let selectedData = dataList?.filter(data => ids?.includes(data?.employee?.id))
        // onChange(selectedData)
        // setDefaultValue(selectedData?.map(data=>data?.firstName))
    };

    React.useEffect(()=>{

        if(initialData && !defaultValue?.length){
            setDefaultValue(initialData)
        }
        
        

    },[initialData])

    const handleSearch=(value)=>{
        searchKey(value)
    }
    return (

        <>
            <div style={{position:"relative",display:'flex',alignItems:'center'}}>
                <Select
                // mode="multiple"
                // allowClear
                className="one-on-one-select-employee-dropdown"
                disabled={disabled}
                style={{
                    border: !memberListInvalid?'0px solid #CACACA':'1px solid #ff4d4f', width: '100%',
                    borderRadius:"8px"            
                }}
                placeholder="1:1 with"
                value={defaultValue}
                onChange={(value,options)=>handleChange(options)}
                id="select-employee"
                onClick={(e)=>{scrolltoview('select-employee')}}
                showSearch={true}
                onSearch={e=>handleSearch(e)}
                >
              
                    {
                        dataList?.map(item => 
                            {
                                return <Select.Option className="one-on-one-select-employee-dropdown-item" key={item?.employee?.id} value={item?.firstName} ><span className="text-transform-capitalize">{item?.firstName}</span> <span className="text-transform-capitalize">{item?.lastName}</span></Select.Option>
                            }
                        )
                    }

                </Select>

                
                <img className= {fromQuickMeeting ? "instant-meeting-form-icon" : "instant-form-icon"}  src={employee_icon} alt="" style={{position:'absolute',bottom: fromQuickMeeting ?  '17px' : '10px' 
                ,left: fromQuickMeeting ? '13px' : '10px'}} />
            </div>
           
        </>

    )

}
export default MultiSelectEmployee;