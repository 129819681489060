import React from "react"
import UserProfileImg from '../../assets/user-profile.svg';
import { Spin, Tooltip } from "antd";
import { displayUtcToUserTimezone } from "modules/look";
const FeedbackActionRequiredView = (props) => {
    const { user_list, SetActionRequiredUser, feedback_loader } = props

    return (
        <Spin spinning={feedback_loader}>
            <div style={{ padding: "1.25em",minHeight:"50vh" }}>
                <div className="feedback-action-list-container adjust-margin-feedback">
                    {
                        user_list?.map(i => (
                            <div className="action-card-main-container">
                                <div className="action-card-border-container" onClick={() => { SetActionRequiredUser(i) }} >
                                    <div className="feedback-action-dp">
                                        <img
                                            src={
                                                i?.user?.profile?.profileImage === null
                                                    ? UserProfileImg
                                                    : i?.user?.profile?.profileImage
                                            }
                                            alt="profile_image"

                                        />
                                    </div>
                                    <div className="column-section-action-reminder">
                                        <h1 className="feedback-sub-name-text text-transform-capitalize"> {i?.user?.firstName} {i?.user?.lastName}</h1>
                                        <Tooltip title={i?.user?.email}>
                                            <h4>{i?.user?.email}</h4>
                                        </Tooltip>
                                        <Tooltip title={i?.previous_feedback ? displayUtcToUserTimezone(
                                            i?.previous_feedback?.createdAt,
                                            "DD MMM YYYY"
                                        ) : ""}>
                                            <h4>
                                                last feedback: <span>{i?.previous_feedback ? displayUtcToUserTimezone(
                                                    i?.previous_feedback?.createdAt,
                                                    "DD MMM YYYY"
                                                ) : "-"}</span>
                                            </h4>
                                        </Tooltip>
                                    </div>



                                </div>
                            </div>
                        ))
                    }
                    {
                        (!user_list?.length && !feedback_loader)?
                        <div style={{width:"100%",height:"50vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <h1>No Pending Feedback</h1>
                        </div>
                        :""
                    }
                </div>
            </div>
        </Spin>
    )

}

export default FeedbackActionRequiredView