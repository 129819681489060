// @ts-nocheck
import { Col, Tooltip, Form, Button, Spin, message, Row } from "antd";
import React from "react";
import keyresult_expnasion_arrow from "../../assets/keyresult_expnasion_arrow.svg";
import plus_btn from "../../assets/plus-btn.svg";
import OkrMileStoneTableContainer from "../container/MileStoneTableContainer";
import close from "../../assets/close.svg";
import { changeToCurrentTime, displayUtcToUserTimezone, getIntFromString, replaceAITipDynamicContent } from "modules/look";
import collabrator_disable from '../../assets/collabrator_disable.svg'
import RangeDatePicker from "modules/look-v2/Components/date-range-picker";
import gear_disabled from '../../assets/gear_disabled.svg'
import moment from 'moment';
import ExpandableDropdownContainer from "modules/look-v2/Container/ExpandableDropdownContainer";
import ProgressUpdateContainer from "../formComponents/Container/progressUpdateContainer";
import okr_route from '../route/index'
import DeleteOkrView from "./deleteOkrView";
import RequestDiscardView from "./requestDiscardView";
import TableRowMenu from "modules/look-v2/Components/TableRowMenu";
import delete_outline_black from '../../assets/delete_outline_black.svg'
import black_discard from '../../assets/black_discard.svg'
import view from '../../assets/view.svg'
import edit_pencil from "../../assets/edit_pencil.svg";
import CommaSeparateComponent from "../formComponents/CommaSeparatedComponent";
import black_eye from "../../assets/black_eye.svg";
import blue_eye from "../../assets/blue_eye.svg";
import AddWeightContainer from "../formComponents/Container/AddWeightContainer";
import CustomProgress from "modules/look-v2/Components/Progress";
import grey_expansion from '../../assets/grey_expansion.svg'
import blue_expansion from '../../assets/blue_expansion.svg'
import close_transpaent from "../../assets/close_transpaent.svg";
import OkrConnectionLine from "modules/look-v2/Components/OkrConnectionLine";
import menu_del_info from '../../assets/menu_del_info.svg'
import TextInputField from "../formComponents/OkrInputField";
import CustomOkrRangeTable from "modules/look-v2/Components/customOkrDatePickerForTable";
import kodos_award from '../../assets/kodos-award-icon.svg'
import AwardBadgeContainer from "modules/kudos-user/container/awardBadgeContainer";
import AwardDetailPopup from "modules/kudos-user/container/awardDetaailPopup";
import ReceivedBadgeMenu from "modules/look-v2/Components/receivedBadgeMenu";
import { ai_trigger_content } from "modules/ai-tips/ai-trigger-content";
const KeyResultTableView = (props) => {
  const { me, createOkr, allkr, objectiveDetail, changeReference, loading, setloading, updateOkr, navigateRoute, isDiscarded, updateChildsetobj, set_okr_tree, setallkr, dataRef, permission,SetAITrigger } = props
  const [form] = Form.useForm();
  const [expandedRow, setexpandedRow] = React.useState([]);
  const [createKRCounter, setcreateKRCounter] = React.useState([]);
  const [dataSource, setDataSource] = React.useState();
  const [updateForm] = Form.useForm();
  const [discardPopupvisible, setDiscardPopupVisible] = React.useState(false);
  const [discardOKRDetail, setdiscardOKRDetail] = React.useState();
  const [deleteRecord, setDeleteRecord] = React.useState();
  const [dataLoader, setdataLoader] = React.useState(true);
  const [addAward, setAddAward] = React.useState(false)
  const [selectedKudos, setSelectedKudos] = React.useState()
  const [showDetail, setShowDetail] = React.useState(false)
  const [badgeDetail, setBadgeDetail] = React.useState()
  const [show_pop_overKr,setPopOverKr]=React.useState(false)

  let cunterRef = React.useRef(0)
  React.useEffect(() => {

    if (allkr) {
      setDataSource(allkr)
      setloading(false)
    }
  }, [allkr, isDiscarded])

  React.useEffect(() => {
    if (!loading && !dataSource?.length && dataSource && isDiscarded !== "APPROVED" && moment().utc().isBefore(objectiveDetail?.duedate)) {
      cunterRef['current'] = cunterRef['current'] + 1
      setcreateKRCounter([{ key: `kr_${cunterRef?.current}` }]);
      if(SetAITrigger){
        let trigger ={
          trigger:ai_trigger_content.okr_create_keyresult,
          dynamic_data:[objectiveDetail?.goalStatement],
          optype:"CREATE",
          custom_instruction:"keyresult title should be simple and should align with the objective title. While giving the due date consider future dates"
        }
        SetAITrigger({...trigger})
      }
    }
    else if (!loading && !dataSource?.length && dataSource && isDiscarded !== "APPROVED" && !moment().utc().isBefore(objectiveDetail?.duedate)) {
      setdataLoader(false)
    }
  }, [dataSource, loading, isDiscarded, objectiveDetail])

  const expand = (id) => {
    let list = [].concat(expandedRow);
    if (!list?.includes(id)) {
      list = [id]
    } else {
      list = list.filter((item) => item !== id);
    }
    setexpandedRow(list);
  };

  const addToKR = () => {
    if (!createKRCounter?.length) {
      cunterRef['current'] = cunterRef['current'] + 1
      let list = [].concat(createKRCounter);
      list.push({ key: `kr_${cunterRef?.current}` });
      setcreateKRCounter(list);
      if(SetAITrigger){
        let trigger = {
          trigger:ai_trigger_content.okr_create_keyresult,
          dynamic_data:[objectiveDetail?.goalStatement],
          optype:"CREATE",
          custom_instruction:"keyresult title should be simple and should align with the objective title.  While giving the due date consider future dates"
        }
        SetAITrigger({...trigger})
      }
    }
  };

  const onFinish = async (key) => {
    try {
      let value = form.getFieldsValue()

      if (value[`${key}_goalStatement`] && value[`${key}_goalStatement`]?.length <= 150 && value[`${key}_duedate`]?.length === 2) {
        let due_date = changeToCurrentTime(value[`${key}_duedate`][1])
        let okrData = {
          goalStatement: value[`${key}_goalStatement`],
          duedate: due_date?.toISOString(),
          startdate: value[`${key}_duedate`][0].toISOString(),
          parentId: getIntFromString(objectiveDetail?.id)
        }
        const response = await createOkr({ okrData })
        if (response && response?.id) {
          let list = [].concat(dataSource || [])
          if (!list?.find(item => item?.id === response?.id)) {
            let okr = {
              id: response?.id,
              title: `${okrData?.goalStatement} by ${displayUtcToUserTimezone(response?.duedate, 'DD MMM YY')}`,
              startdate: response?.startdate,
              duedate: response?.duedate,
              goalStatement: okrData?.goalStatement,
              correctionDeadline: response?.correctionDeadline,
              contributors: [{...me?.employee?.user,role:"OWNER"}],
              progress: 0,
              childSet: false,
              isDiscarded: {
                status: (isDiscarded === "APPROVED") ? isDiscarded : null
              }
            }
            list.push(okr)
            setallkr(list)

            setDataSource(list)
          }
          updateChildsetobj({ okrId: objectiveDetail?.id, childSet: true })
          removecreateKR({ key }, true)
        }
      }
    }
    catch (err) {
    }
  };

  const removecreateKR = (value, force_remove = false) => {
    if (dataSource?.length || force_remove) {
      let list = [].concat(createKRCounter)
      setcreateKRCounter(list.filter(item => item?.key !== value?.key))
    }
  };

  const addContributor = (value, item) => {
    let list = [].concat(dataSource)
    let record = list?.find(val => val?.id === item?.id)
    if (record) {
      let filter_list = record?.contributors?.filter(data => data?.role === "OWNER")
      let updates = Object.values(value?.data)?.map(({ contributor }) => {return {...contributor?.employee,role:value?.role}})
      if (updates?.length) {
        record.contributors = [...filter_list, ...updates]
        setDataSource(list)
      }
    }
  }

  const onUpdate = async (okr) => {
    let formValue = updateForm.getFieldsValue()
    let data = {}
    if (formValue[`${okr?.id}_goalStatement`]) {
      data['goalStatement'] = formValue[`${okr?.id}_goalStatement`]
    }
    if (data['goalStatement'] && data['goalStatement']?.length > 150) {
      return
    }

    if (formValue[`${okr?.id}_duedate`]?.length === 2) {
      let dates = formValue[`${okr?.id}_duedate`]
      data['startdate'] = dates[0].toISOString()
      let due_date = changeToCurrentTime(dates[1])
      data['duedate'] = due_date?.toISOString()
    }

    if (!Object.keys(data)?.length) {
      message.error('update the form')
    } else {
      data['id'] = getIntFromString(okr?.id)
      let response = await updateOkr(data)
      if (response?.id) {
        let list = [].concat(dataSource)
        let item = list?.find(val => val?.id === okr?.id)
        if (item) {
          item['duedate'] = response?.duedate
          item['startdate'] = response?.startdate
          item['title'] = response?.title
          item['goalStatement'] = response?.goalStatement
          item.isUpdate = false
        }
        setDataSource(list)
      }
    }
  }

  const updateQue = (record, isUpdate) => {
    let list = [].concat(dataSource)
    let item = list?.find(okr => okr?.id === record?.id)
    item['isUpdate'] = isUpdate
    setDataSource(list)
  }

  const onTableMenuClickAction = (record, action) => {

    if (action === 'view') {
      navigateRoute(okr_route?.keyResultDetail?.replace(':okrId', getIntFromString(record?.id)))
    } else if (action === 'edit') {
      updateQue(record, true)
    } else if (action === 'discard') {
      setdiscardOKRDetail(record);
      setDiscardPopupVisible(true);
    } else if (action === 'delete') {
      setDeleteRecord(record);
    } else if (action === 'tree') {
      set_okr_tree(record)
    }
    else if (action === 'kodos') {
      setAddAward(true)
      setSelectedKudos(record)
    }
  }

  const updateDiscardRow = (record) => {
    setDiscardPopupVisible(false);
    setdiscardOKRDetail(null)
  }

  const updateDelete = (id) => {
    setDeleteRecord(null)
    let list = [].concat(dataSource)
    list = list.filter(item => item?.id !== id)
    if (!list?.length) {
      updateChildsetobj({ okrId: objectiveDetail?.id, childSet: false })
    }
    changeReference({ okrId: id, type: 'DELETE' })
    setallkr(list)
    dataRef.current = list
  }

  const updateChildsetofKr = (detail) => {
    let list = [].concat(dataSource)
    let okr = list?.find(item => item?.id === detail?.okrId)
    if (okr) {
      okr.childSet = detail.childSet
    }
    setDataSource(list)
  }


  const update_progress = (okrid, progress) => {
    let data_source_progress = [].concat(dataSource)
    let okr = data_source_progress.find(item => item?.id === okrid)

    if (okr) {
      okr.progress = progress
    }
    setDataSource(data_source_progress)
  }

  


  return (
    <>
      <Spin spinning={loading}>
        <DeleteOkrView visible={deleteRecord} type="KeyResult" deletesuccess={(e) => { updateDelete(e) }} closeModal={() => { setDeleteRecord(null) }} okrId={deleteRecord?.id} {...props} />
        <RequestDiscardView visible={discardPopupvisible} {...props} discardData={discardOKRDetail} onClose={() => { setDiscardPopupVisible(false); setdiscardOKRDetail(null) }} discardedSuccess={(e) => { updateDiscardRow(e) }} />
        <Col className="custom-table-v2">
          {(!dataSource?.length && !createKRCounter?.length && isDiscarded !== "APPROVED") ?
            <div style={{ width: "100%", height: "60px", padding: "10px" }} align="center">
              {
                dataLoader ?
                  <Spin spinning={dataLoader} /> :
                  <h4 style={{ fontSize: "17px" }}>No keyresults found</h4>
              }
            </div> : ''
          }
          <div className="custom-table-body">

            {
              <Form
                form={updateForm}
                name="keyresult_update"
                autoComplete="off"
              >
                {dataSource?.map((item, index) => (
                  <Col>
                    <div className="row ">
                      <OkrConnectionLine />

                      {(index + 1 === dataSource?.length && isDiscarded !== 'APPROVED' && moment().utc().isBefore(objectiveDetail?.duedate)) ? (
                        <div className="expand-table-sider">
                          <Tooltip title="Add Keyresult">
                            <img
                              src={plus_btn}
                              onClick={(e) => {
                                addToKR();
                              }}
                              style={{ cursor: "pointer" }}
                              alt=""
                            />
                          </Tooltip>
                          <div>KR</div>
                        </div>
                      ) : (
                        <Row align="middle" style={{ color: '#595959', fontWeight: '500', marginBottom: '20px', fontSize: '16px' }}>KR</Row>
                      )}


                      <div
                        className="custom-table-tr table-body-tr-border"
                        style={{
                          borderLeft:
                            (item?.isDiscarded?.status === 'APPROVED') ? "5px solid #141414" :
                              (item?.isDiscarded?.status === 'PENDING') ? "5px solid #009AF1" : "5px solid #B4E045",
                          position: (item?.isDiscarded?.status === 'APPROVED' || item?.isDiscarded?.status === 'PENDING') ? "relative" : ""
                        }}
                      >
                        {
                          (item?.isDiscarded?.status === 'APPROVED') ?
                            <div className="table-overlay">
                              {/* <img src={item?.isDiscarded?.status === 'APPROVED' ? black_eye : blue_eye} onClick={() => { onTableMenuClickAction(item, 'view') }} className="table-eye" alt="" style={{ marginLeft: "270px", cursor: "pointer" }} /> */}
                            </div> : ''
                        }
                        <div
                          className="table-section-td"
                          style={{ width: "260px", minWidth: "260px" }}
                        >
                          <img
                            src={
                              item?.isDiscarded?.status === 'PENDING' ?
                                blue_expansion :
                                item?.isDiscarded?.status === 'APPROVED' ?
                                  grey_expansion :
                                  keyresult_expnasion_arrow}
                            alt=""
                            onClick={(e) => {
                              expand(item?.id);
                            }}
                            style={{ zIndex: "1" }}
                            className={`expansion-arrow ${expandedRow?.includes(item?.id)
                              ? "expansion-arrow-down"
                              : ""
                              }`}
                          />
                          {/* Badge icon section */}
                          {item?.badge_award?.length > 0 && permission?.can_award_badge && <ReceivedBadgeMenu menuIcon={item?.badge_award[0]?.node?.badge?.image} optionData={item?.badge_award}
                            margin={'300px'}
                          />}

                          {
                            item?.isUpdate ?
                              <TextInputField
                                name={`${item?.id}_goalStatement`}
                                placeholder="Measured by ..."
                                show_pop_over = {show_pop_overKr}
                                setPopOver = {setPopOverKr}
                                defaultValue={item?.goalStatement}
                              /> :
                              <Tooltip title={`${item?.goalStatement} by ${displayUtcToUserTimezone(item?.duedate, 'DD MMM YY')}`}>
                                <span onClick={(e) => onTableMenuClickAction(item, 'view')} style={{ cursor: "pointer",zIndex: "1" }}>{`${item?.goalStatement} by ${displayUtcToUserTimezone(item?.duedate, 'DD MMM YY')}`}</span>
                              </Tooltip>
                          }
                        </div>

                        <div
                          className="table-section-td center"
                          style={{ width: "280px", minWidth: "280px" }}
                        >
                          {item?.isUpdate ? (
                            <div
                              className="table-section-td center"
                              style={{ width: "280px", minWidth: "280px" }}
                            >
                              <div className="custom-okr-picker" >
                                <CustomOkrRangeTable rules={[{ required: true, message: 'dates required' }]}
                                  defaultValue={[moment(item?.startdate), moment(item?.duedate)]}
                                  disabledRange={{
                                    duedate: objectiveDetail?.duedate,
                                    startdate: moment(objectiveDetail?.startdate).isAfter(moment()) ? objectiveDetail?.startdate : moment()?._d
                                  }}
                                  selectedDateRange={(e) => updateForm.setFieldsValue({
                                    [`${item?.id}_duedate`]: e,
                                  })} currentSelected={false}
                                  disabledType={true} />
                              </div>
                              <div className="okr-picker">
                                <RangeDatePicker
                                  dropdownClassName={'custom-date-small'}
                                  name={`${item?.id}_duedate`}
                                  dateFormat="DD/MM/YY"
                                  rules={[{ required: true, message: 'dates required' }]}
                                  disabledRange={{
                                    duedate: objectiveDetail?.duedate,
                                    startdate: moment(objectiveDetail?.startdate).isAfter(moment()) ? objectiveDetail?.startdate : moment()?._d
                                  }}
                                  defaultValue={[moment(item?.startdate), moment(item?.duedate)]}
                                />
                              </div>

                            </div>)
                            :
                            (
                              displayUtcToUserTimezone(item?.duedate, "DD MMM YYYY"))
                          }
                        </div>

                        <div
                          className="table-section-td center"
                          style={{ width: "220px", minWidth: "220px" }}
                        >

                          <CommaSeparateComponent showCount={1} textList={item?.contributors?.map(data => { return { id: data?.user?.id || data?.id, firstName: data?.user?.firstName || data?.firstName, lastName: data?.user?.lastName || data?.lastName, role: data?.role } })} />
                          <ExpandableDropdownContainer okrId={item?.id} response={(e) => { addContributor(e, item) }} record={item} {...props} />
                        </div>

                        <div
                          className="table-section-td"
                          style={{ width: "250px", minWidth: "250px" }}
                        >
                          {item?.childSet && (<AddWeightContainer {...props} okrId={item?.id}

                            tooltipTitle={"When you have multiple child milestones, by default the weightage will be equally divided amongst all of them. You can adjust the weight according to the value that you would like to assign for each child milestones"}
                          />)}


                          {
                            item?.isUpdate ?
                              <div style={{ width: "100%" }} align="center"><Button className="table-save-btn" onClick={(e) => { onUpdate(item) }}>Update</Button></div>
                              :
                              <div className="row center" style={{ width: "100%", alignItems: "center" }}>
                                {!item?.childSet && (<ProgressUpdateContainer editable={moment(item?.duedate)?.endOf('day').isAfter(moment())} update_progress={(okrid, progress) => update_progress(okrid, progress)} okr={item} {...props} />)}
                                <CustomProgress showTooltip={true} record={item} progress={item?.progress} />
                              </div>
                          }

                          {
                            item?.isUpdate ?
                              <img src={close} alt="" onClick={() => { updateQue(item, false) }} style={{ cursor: "pointer" }} />
                              :
                              <TableRowMenu options={[{ label: 'view', key: 'view', icon: view, postLabelIcon: {}, disabled: false },
                              { label: 'Edit', key: 'edit', icon: edit_pencil, postLabelIcon: 'icon', disabled: moment(item?.correctionDeadline).isBefore(moment())|| moment(item?.duedate).isBefore(moment()) },
                              { label: 'Kudos', key: 'kodos', icon: kodos_award, postLabelIcon: 'icon', disabled: (permission?.can_award_badge && permission?.okr_permission && item?.contributors?.length > 1) ? false : true },
                              { label: 'Discard', key: 'discard', icon: black_discard, postLabelIcon: 'icon',post_icon:(item?.discardRequestRaised && item?.isDiscarded?.status!=='PENDING')?menu_del_info:'', tooltip:(item?.discardRequestRaised && item?.isDiscarded?.status!=='PENDING')?"You have already raised the discard request":'', disabled: (item?.discardRequestRaised || item?.isDiscarded?.status==='PENDING'?true:false) },
                              { label: 'Delete', key: 'delete', icon: delete_outline_black, postLabelIcon: 'icon', disabled: (item?.discardRequestRaised||moment(item?.correctionDeadline).isBefore(moment()) || moment(item?.duedate).isBefore(moment()))}]}
                                onClick={(e) => onTableMenuClickAction(item, e)}
                              />
                          }
                        </div>
                      </div>
                    </div>
                    {expandedRow?.includes(item?.id) && (
                      <div className="row">
                        <div className="expand-table-sider"></div>
                        <div style={{ width: "100%" }}>
                          <OkrMileStoneTableContainer updateChildset={(e) => { updateChildsetofKr(e) }} keyResultDetail={item} {...props} />
                        </div>
                      </div>
                    )}
                  </Col>
                ))}
              </Form>
            }
            {
              <Form
                form={form}
                name={"key_result"}
                autoComplete="off"
              >
                {createKRCounter?.map((item, index) => (
                  <Col>
                    <div className="row">
                      <OkrConnectionLine />
                      <Row align="middle" style={{ color: '#595959', fontWeight: '500', fontSize: '16px', marginBottom: '23px' }}>
                        KR
                      </Row>
                      <div
                        className="custom-table-tr table-body-tr-border"
                        style={{ borderLeft: "5px solid #B4E045" }}
                      >
                        <div
                          className="table-section-td"
                          style={{ width: "260px", minWidth: "260px" }}
                        >
                          <div style={{ width: "10px", height: "10px" }}></div>
                          <TextInputField
                            name={`${item?.key}_goalStatement`}
                            show_pop_over = {show_pop_overKr}
                            setPopOver = {setPopOverKr}
                            placeholder="Measured by ..."
                            rules={[
                              { required: true, message: "title required" },
                            ]}
                          />
                        </div>

                        <div
                          className="table-section-td center"
                          style={{ width: "280px", minWidth: "280px" }}
                        >

                          <div className="custom-okr-picker">
                            <CustomOkrRangeTable rules={[{ required: true, message: 'dates required' }]}
                              disabledRange={{
                                duedate: objectiveDetail?.duedate,
                                startdate: moment(objectiveDetail?.startdate).isAfter(moment()) ? objectiveDetail?.startdate : moment()?._d
                              }}

                              selectedDateRange={(e) => form.setFieldsValue({
                                [`${item?.key}_duedate`]: e,
                              })} currentSelected={false}

                              disabledType={true} />
                          </div>
                          <div className="okr-picker">
                            <RangeDatePicker
                              dropdownClassName={'custom-date-small'}
                              name={`${item?.key}_duedate`}
                              dateFormat="DD/MM/YY"
                              rules={[{ required: true, message: 'dates required' }]}
                              disabledRange={{
                                duedate: objectiveDetail?.duedate,
                                startdate: moment(objectiveDetail?.startdate).isAfter(moment()) ? objectiveDetail?.startdate : moment()?._d
                              }}
                            />
                          </div>
                        </div>

                        <div
                          className="table-section-td center"
                          style={{ width: "220px", minWidth: "220px" }}
                        >
                          <div className="row text-transform-capitalize" style={{ color: "#D9D9D9" }}>
                            {me?.firstName}
                            <img src={collabrator_disable} alt="" />
                          </div>
                        </div>

                        <div
                          className="table-section-td"
                          style={{ width: "250px", minWidth: "250px" }}
                        >
                          <img src={gear_disabled} alt="" style={{ cursor: "pointer" }} />
                          <div style={{ width: "100%" }} align="center">
                            <Button className="table-save-btn" disabled={show_pop_overKr} onClick={() => onFinish(item?.key)}>Save</Button>
                          </div>
                          {
                            dataSource?.length ?
                              <img
                                src={close}
                                alt=""
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  removecreateKR(item);
                                }}
                              /> :
                              <img
                                src={close_transpaent}
                                alt=""

                              />
                          }
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Form>
            }
          </div>
        </Col>
      </Spin>
      {
        addAward && <AwardBadgeContainer {...props} visible={addAward} onClose={(e) => setAddAward(e)} type={'OKR'} selectedItem={selectedKudos} />
      }
      {
        showDetail && <AwardDetailPopup visible={showDetail} onClose={() => { setShowDetail(false); setBadgeDetail() }} selectedAward={badgeDetail} hideShare={true} />
      }
    </>
  );
};


export default KeyResultTableView;