import { compose, getCookieFlag, getItem, removeItem, setCookieFlag } from 'modules/core';
import React, { useEffect, useState } from 'react';
import ImpactNarrativeUI from '../components/ImpactNarrativeToolUI';
import { withApollo } from '@apollo/client/react/hoc';
import { Spin, message } from "antd";
import { TRANSCRIPTION_SERVER_URI, ACCESS_TOKEN, VALUE_SUERVEY_PART_1, STRENGTH_PART_1, PERSONALITY_PART_1, KNOWLEDGE_PART_1, diversity_questions_for_in, diversity_survey_id, request_impact_narrative, impact_narrative_socket, impact_narrative_cookie, impact_narrative_cookie_expire, impact_generation_status } from '../../../config';
import { Capitalize, getQuestionBase64StringFromInt, getSurveyBase64StringFromInt, globalPermissionValidator } from 'modules/look';
import { ALL_SURVEY_RESPONSE, USER_IMAPACT_NARRATIVE } from '../graphql/ImpactNarrative.gql';
import { withAddImpactNarrative, withUpdateImpactNarrative } from './ToolOperations';
import { impact_narrative_permission } from 'Permissions/ImpactNarrative.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import moment from 'moment/moment';
import { ai_trigger_content } from 'modules/ai-tips/ai-trigger-content';

const ImpactNarrativeContainer = props => {
  const { client, me, createImpactNarrative, updateImpactNarrative, userPermission } = props
  const [first_user_narrative, setFirstUserNarrative] = useState()
  const [second_user_narrative, setSecondUserNarrative] = useState()
  const [narrativeloading, setnarrativeloading] = useState(false)
  const [generating_loader, setGeneratingLoader] = useState(false)
  const [sucess_socket_connection, setSucessSocketConnection] = useState(false)
  const [error_socket_connection, setErrorSocketConnection] = useState(false)
  const [user_narrative, setUserNarrative] = useState()
  const [user_narrative_preview, setUserNarrativePreview] = useState()
  const [show_save, setShowSave] = useState(false)
  const [narrative_stream, setNarrativeStream] = useState()
  const [permission, SetPermission] = React.useState();
  const [previous_compare, setPrevious_compare] = useState(false)
  const [data_loader, SetDataLoader] = useState(false)
  const [ai_trigger, SetAITrigger] = React.useState()
  let socket_connection_ref = React.useRef()
  let narrative_stream_ref = React.useRef('')

  let user_narrative_ref = React.useRef()
  let user_narrative_preview_ref = React.useRef()
  let allow_socket_data = React.useRef(false)

  const [selected_narratives_submit, setSelectedNarratives] = useState([])

  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(impact_narrative_permission, userPermission)
      SetPermission(permission)
      if (permission?.required_permission) {
        getCurrentUserImpactNarrative()
      }
    }

  }, [userPermission])

  React.useEffect(() => {
    SetDataLoader(generating_loader)
  }, [generating_loader])


  React.useEffect(() => {
    if (user_narrative) {
      user_narrative_ref.current = user_narrative
    }
  }, [user_narrative])

  React.useEffect(() => {
    if (user_narrative_preview) {
      user_narrative_preview_ref.current = user_narrative_preview
    }
  }, [user_narrative_preview])

  React.useEffect(() => {
    if (narrative_stream) {
      let key_words = [{ label: 'overview', value: `"overview":` }, { label: 'decisionMaking', value: `"decision_making":` }, { label: 'communication', value: `"communication":` }, { label: 'collaboration', value: `"collaboration":` }, { label: 'relationshipBuilding', value: `"relationship_building":` }]
      let narrative = {
        id: user_narrative_ref?.current?.id || null
      }
      key_words.forEach((i, index) => {
        if (narrative_stream?.includes(i?.value)) {
          let text_list = narrative_stream?.split(i.value).join('').split(`",`)
          let value = text_list[index]?.replaceAll(`"`, '').replaceAll(`{`, '').replaceAll(`}`, '').replaceAll(`\n`, '')?.trim()
          if (value) {
            narrative[i.label] = value
          }
        }
      })
      if (narrative_stream?.includes('overview')) {
        SetDataLoader(false)
      }
      setUserNarrative(narrative)
    }
  }, [narrative_stream])

  const getuserImpactNarrative = async (id, type) => {
    try {
      setnarrativeloading(true)
      const { data } = await client.query({
        query: USER_IMAPACT_NARRATIVE,
        fetchPolicy: 'network-only',
        variables: { user: id },
      });
      if (data?.allImpactNarrative?.edges?.length) {
        if (type === 'first') {
          setFirstUserNarrative(data?.allImpactNarrative?.edges[0]?.node)
        } else {
          setSecondUserNarrative(data?.allImpactNarrative?.edges[0]?.node)
        }
        setnarrativeloading(false)
      } else {
        if (type === 'first') {
          setFirstUserNarrative(null)
        }
        else {
          setSecondUserNarrative(null)
        }
        setnarrativeloading(false)
        message.error('User has not submitted impact narrative')
      }
    }
    catch (err) {
      if (type === 'first') {
        setFirstUserNarrative(null)
      }
      else {
        setSecondUserNarrative(null)
      }
      setnarrativeloading(false)
      message.error('Cannot perform this action')
    }
  }

  const cleanup = (type) => {
    if (type === "first") {
      setFirstUserNarrative(null)
    } else {
      setSecondUserNarrative(null)
    }
  }

  React.useEffect(() => {
    if (me) {
      SetCookie()
    }
  }, [me])

  const getCurrentUserImpactNarrative = async () => {
    try {
      setnarrativeloading(true)

      const { data } = await client.query({
        query: USER_IMAPACT_NARRATIVE,
        fetchPolicy: 'network-only',
        variables: { user: me?.id },
      });
      if (data?.allImpactNarrative?.edges?.length) {
        setUserNarrative(data?.allImpactNarrative?.edges[0]?.node)
        setUserNarrativePreview(data?.allImpactNarrative?.edges[0]?.node)
      }
      setnarrativeloading(false)
    } catch (error) {

    }
  }

  const SetCookie = async (reconnection = false) => {
    let token = await getItem(ACCESS_TOKEN)
    try {
      var blob = new Blob([], { type: 'audio/wav' });
      var audio_data = new File([blob], `audio${Date.now()}.wav`, { type: 'audio/wav' });
      let form_data = new FormData()
      form_data.append('language', 'en')
      form_data.append('input_data', audio_data)
      form_data.append('model_size', 'small')
      form_data.append('meeting_id', 1)

      fetch(`${TRANSCRIPTION_SERVER_URI}/transcribe`, {
        method: 'POST',
        body: form_data,
        credentials: 'include',
        headers: {
          Authorization: `JWT ${token}`
        }
      })
        .then((res) => {
          if (!reconnection) {
            listenToNetwork()
          }
          integrateSocket()
        })
        .catch((err) => {
          setErrorSocketConnection(true)
        })
    } catch (error) {
      setErrorSocketConnection(true)
    }
  };

  const listenToNetwork = () => {
    window.addEventListener('online', () => {
      if (socket_connection_ref.current) {
        socket_connection_ref.current.close()
        socket_connection_ref.current = null
      }
      SetCookie(true)
    });
  }
  React.useEffect(() => {
    return () => {
      // Code to be executed when the component is unmounted
      if (socket_connection_ref.current) {
        socket_connection_ref.current.close()
        socket_connection_ref.current = null
      }
      window.removeEventListener('online', () => { });
    };
  }, []);

  let page_load_handler = React.useRef(false)
  React.useEffect(() => {
    page_load_handler.current = generating_loader
    if (impact_generation_status) {
      let data = { impact_generation_status: generating_loader ? "INPROGRESS" : "DONE" }
      localStorage.setItem(impact_generation_status, JSON.stringify(data))
    }

  }, [generating_loader])

  React.useEffect(() => {
    const handleBeforeUnload = (event) => {

      if (page_load_handler.current) {
        event.preventDefault();
        // Chrome requires the returnValue to be set
        event.returnValue = 'Please stay on the page till impact narrative get generated';
        return event;
      }
    };

    // Attach the event listener to the window object
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      localStorage.removeItem(impact_generation_status)
      page_load_handler.current = false
      window.removeEventListener('beforeunload', () => { });
    };
  }, []);

  const onSocketError = (err) => {
    allow_socket_data.current = false
    setErrorSocketConnection(true);
    removeAIGenData();
    setSucessSocketConnection(false)
    console.log("ai_error", err);
  }

  const integrateSocket = async () => {
    try {
      let impact_narrative_url = impact_narrative_socket
      let requestUrl = `${impact_narrative_url}`
      const socket_client = new WebSocket(requestUrl);
      socket_connection_ref.current = socket_client
      socket_client.onopen = () => { setSucessSocketConnection(true); setErrorSocketConnection(false); checkRequestStatus() };
      socket_client.onerror = (err) => { onSocketError(err) };
      socket_client.onmessage = (msg) => {
        let socket_data = JSON.parse(msg?.data)
        if (socket_data) {

          if (socket_data?.results?.status == 'dispatched') {
            addCookieFlag()
          }

          if ((socket_data?.results?.status == 'dispatched' || socket_data?.results?.status == 'generating') && !allow_socket_data.current) {
            narrative_stream_ref.current = ''
            setNarrativeStream('{')
            setSelectedNarratives(
              ['overview', 'communication', 'collaboration', 'decisionMaking', 'relationshipBuilding']
            )
            setPrevious_compare(true)
            setGeneratingLoader(true)
          }
          if (socket_data?.results?.status == 'dispatched' && !allow_socket_data.current) {
            allow_socket_data.current = true
          }
          if (socket_data?.results?.status == 'dispatched') {
            narrative_stream_ref.current = ''
          } else if (socket_data?.results?.status == 'generating' && allow_socket_data.current) {
            setSelectedNarratives(
              ['overview', 'communication', 'collaboration', 'decisionMaking', 'relationshipBuilding']
            )
            let stream = narrative_stream_ref.current + socket_data?.results?.data
            narrative_stream_ref.current = stream
            setNarrativeStream(stream)
          } else if (socket_data?.results?.status == 'completed') {
            removeCookieFlag()
            try {
              if (socket_data?.results?.data) {
                let completed_data = JSON.parse(socket_data?.results?.data)
                let narrative = {
                  id: user_narrative_ref?.current?.id || null,
                  overview: completed_data?.overview,
                  communication: completed_data?.communication,
                  collaboration: completed_data?.collaboration,
                  decisionMaking: completed_data?.decision_making,
                  relationshipBuilding: completed_data?.relationship_building
                }

                setUserNarrative(narrative)
           
              }
              allow_socket_data.current = false
              setShowSave(true)
              setGeneratingLoader(false)
            } catch (error) {
              setGeneratingLoader(false)
            }

          }
          else if (socket_data?.results?.status == 'failed') {
            removeAIGenData()
            message.error('AI failed to generate impact narrative')
          }
        }

      }
      socket_client.onclose = (err) => {
        if (allow_socket_data?.current || generating_loader) {
          onSocketError(err)
        }
      }
    } catch (error) {
      console.log('catch', error);
    }

  }

  const checkRequestStatus = async () => {
    // let already_requested = await getItem(impact_narrative_cookie)
    // if(already_requested){
    //   startLoader()
    // }
  }

  const addCookieFlag = async () => {
    if (impact_narrative_cookie) {
      // try {
      //   let expires = new Date(moment().add(impact_narrative_cookie_expire,'minute').utc().toISOString())
      //   let cookie = await setCookieFlag(impact_narrative_cookie,"impact_narrative_requested",{expires})
      // } catch (error) {

      // }
    }
  }

  const removeCookieFlag = async () => {
    if (impact_narrative_cookie) {
      // let cookie = await removeItem(impact_narrative_cookie)
    }
  }

  const startLoader = () => {
    setPrevious_compare(true)
    setGeneratingLoader(true)
  }

  const generateImpactNarrative = async () => {
    try {

      let already_requested = await getItem(impact_narrative_cookie)

      if (already_requested) {
        setPrevious_compare(true)
        setGeneratingLoader(true)
        return
      }
      allow_socket_data.current = true
      setPrevious_compare(true)
      setGeneratingLoader(true)
      const { data } = await client.query({
        query: ALL_SURVEY_RESPONSE,
        fetchPolicy: "network-only",
        variables: {
          responseUser: me?.id,
          value: getSurveyBase64StringFromInt(VALUE_SUERVEY_PART_1),
          strength: getSurveyBase64StringFromInt(STRENGTH_PART_1),
          personality: getSurveyBase64StringFromInt(PERSONALITY_PART_1),
          knowledge: getSurveyBase64StringFromInt(KNOWLEDGE_PART_1),
          diversity: getSurveyBase64StringFromInt(diversity_survey_id),
          gender: getQuestionBase64StringFromInt(diversity_questions_for_in?.gender),
          HighestDegree: getQuestionBase64StringFromInt(diversity_questions_for_in?.HighestDegree),
          DiplomasCertificates: getQuestionBase64StringFromInt(diversity_questions_for_in?.DiplomasCertificates),
          // SubjectsOfInterest: getQuestionBase64StringFromInt(diversity_questions_for_in?.SubjectsOfInterest),
          YearsOfExperience: getQuestionBase64StringFromInt(diversity_questions_for_in?.YearsOfExperience),
          CurrentNationality: getQuestionBase64StringFromInt(diversity_questions_for_in?.CurrentNationality),
          Specializations: getQuestionBase64StringFromInt(diversity_questions_for_in?.Specializations),
        },
      });

      if (data) {

        let answers = {
          values: handleSurveyAnswer(data?.value?.groupSet),
          strength: handleSurveyAnswer(data?.strength?.groupSet),
          personality: handleSurveyAnswer(data?.personality?.groupSet),
          knowledge: handleSurveyAnswer(data?.knowledge?.groupSet)
        }
        let invalid = Object.values(answers)?.filter(i => i?.length < 5)
        if (invalid?.length) {
          removeAIGenData()
          message.error('Complete the other soul surveys before impact narrative')
          return
        }

        let impact_values = {
          name: Capitalize(me?.firstName)
        }

        answers.values.forEach((item, index) => {
          impact_values[`value_${index + 1}`] = item?.answer
        })

        answers.strength.forEach((item, index) => {
          impact_values[`strength_${index + 1}`] = item?.answer
        })

        answers.knowledge.forEach((item, index) => {
          impact_values[`knowledge_skills_${index + 1}`] = item?.answer
        })
        impact_values[`personality`] = answers?.personality?.map(i => i.answer)?.join(', ')

        let profile_data = data?.diversity?.edges[0]?.node
        delete profile_data?.id
        let diversity_data = {}
        if (profile_data) {
          Object.entries(profile_data).forEach(i => {
            if (i[1]?.edges?.length) {
              diversity_data[i[0]] = i[1]?.edges[0]?.node?.answer
            }
          })
        }

        impact_values['gender'] = diversity_data?.gender
        impact_values['nationality'] = diversity_data?.CurrentNationality
        impact_values['work_experience'] = `${diversity_data?.YearsOfExperience} ${parseInt(diversity_data?.YearsOfExperience) > 1 ? 'years' : 'year'}`

        let education = ''
        if (diversity_data?.HighestDegree && diversity_data?.HighestDegree?.trim() != '') {
          education = education + `My highest degree is ${diversity_data?.HighestDegree}. `
        }

        if (diversity_data?.Specializations && diversity_data?.Specializations?.trim() != '') {
          education = education + `I have done specialization in ${diversity_data?.Specializations}. `
        }

        if (diversity_data?.DiplomasCertificates && diversity_data?.DiplomasCertificates?.trim() != '') {
          education = education + `I have a diploma in ${diversity_data?.DiplomasCertificates}. `
        }

        if (education && education?.trim() != '') {
          impact_values['education'] = education
        }

        console.log('impact_values', impact_values);
        if (!impact_values?.gender || !impact_values?.nationality || !impact_values?.education || !impact_values?.work_experience) {
          removeAIGenData()
          message.error('Oops! We are unable to generate your Impact Narrative. Please complete your Diversity Profile to generate your Impact Narrative')
          return
        }

        RequestForImpactnarrative(impact_values)
      } else {
        removeAIGenData()
      }
    } catch (error) {
      removeAIGenData()
    }
  }



  const RequestForImpactnarrative = async (data) => {
    let token = await getItem(ACCESS_TOKEN)
    let form_data = new FormData()
    Object.entries(data).forEach(i => {
      form_data.append(i[0], i[1])
    })

    let url = request_impact_narrative
    fetch(url, {
      method: 'POST',
      body: form_data,
      credentials: 'include',
      headers: {
        Authorization: `JWT ${token}`
      }
    })
      .catch((err) => {
        setPrevious_compare(false)
        setGeneratingLoader(false)
      })
  }

  const handleSurveyAnswer = (groupSet) => {
    let ans = []
    if (groupSet && groupSet?.edges?.length) {
      groupSet.edges.forEach((gs) => {
        gs.node.questionSet.edges.slice(0, 5).forEach((qs, index) => {
          let d = {}
          d['question_id'] = qs?.node?.id
          qs.node.answerSet.edges.slice(0, 1).forEach((as) => {
            d['answer'] = as?.node?.answer
            d['answer_id'] = as?.node?.id
            d['response_id'] = as?.node?.response?.id
          })
          d['sequence'] = index + 1
          ans.push(d)
        })
      })
    }

    return ans?.filter(i => i?.answer)
  }

  const OnSaveAction = async (data) => {
    try {
      if (!data?.collaboration || !data?.communication || !data?.decisionMaking || !data?.relationshipBuilding || !data?.overview) {
        message.error("Please select the data you want to submit")
        return
      }
      setnarrativeloading(true)
      if (!user_narrative_ref?.current?.id) {
        let response = await createImpactNarrative({ impactNarrativeData: data })
        if (response?.id) {
          let values = { ...data }
          values['id'] = response?.id
          setUserNarrative(values)
          setUserNarrativePreview(values)
          setShowSave(false)
          setPrevious_compare(false)
        }
        setnarrativeloading(false)
      } else {
        let response = await updateImpactNarrative(data)
        if (response?.id) {
          let values = { ...data, id: user_narrative_ref?.current?.id }
          setUserNarrative(values)
          setUserNarrativePreview(values)
          setShowSave(false)
          setPrevious_compare(false)
        }
        setnarrativeloading(false)

      }
    } catch (error) {
      setnarrativeloading(false)
    }
  }

  const OnUpdateAction = async (data) => {
    try {
      setnarrativeloading(true)

      let response = await updateImpactNarrative(data)
      if (response?.id) {
        let values = { ...user_narrative_ref.current, ...data }
        setUserNarrative(values)
        setUserNarrativePreview(values)
        setShowSave(false)
      }
      setnarrativeloading(false)

    } catch (error) {

    }
  }


  const removeAIGenData = () => {
    removeCookieFlag()
    setUserNarrative(user_narrative_preview_ref.current ? { ...user_narrative_preview_ref.current } : {})
    setGeneratingLoader(false)
    setPrevious_compare(false)
    setShowSave(false)
    allow_socket_data.current = false
  }
  return (
    <>
      {
        (permission && permission?.required_permission) && (
          <ImpactNarrativeUI
            getuserImpactNarrative={(id, type) => { getuserImpactNarrative(id, type) }}
            first_user_narrative={first_user_narrative}
            second_user_narrative={second_user_narrative}
            data_clean_up={(type) => { cleanup(type) }}
            user_narrative={user_narrative}
            narrativeloading={narrativeloading}
            generateImpactNarrative={generateImpactNarrative}
            generating_loader={generating_loader}
            sucess_socket_connection={sucess_socket_connection}
            show_save={show_save}
            OnSaveAction={OnSaveAction}
            OnUpdateAction={OnUpdateAction}
            previous_compare={previous_compare}
            user_narrative_preview={user_narrative_preview}
            removeAIGenData={removeAIGenData}
            setSelectedNarratives={setSelectedNarratives}
            selected_narratives_submit={selected_narratives_submit}
            error_socket_connection={error_socket_connection}
            data_loader={data_loader}
            startLoader={startLoader}
            ai_trigger={ai_trigger}
            SetAITrigger={SetAITrigger}
            permission={permission}
            {...props} />
        )
      }

      {
        (permission && !permission?.required_permission) && (
          <NoPermissionView />
        )
      }

      {
        !permission && (
          <div style={{ paddingTop: "150px" }} align="center">
            <Spin spinning="true"></Spin>
          </div>
        )
      }
    </>

  );
};

export default compose(withApollo, withAddImpactNarrative, withUpdateImpactNarrative)(ImpactNarrativeContainer);
